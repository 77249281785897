// @flow

import React, { Component } from "react";

import { Picture as StyledPicture } from "../styles";
import Initial from "./initial";

import type { UnifizeUser } from "src/types";

type Props = {
  user: UnifizeUser,
  location?: string,
  isActive?: boolean
};

type State = {
  error: boolean
};

class Picture extends Component<Props, State> {
  state = {
    error: false
  };

  setError = () => {
    this.setState({ error: true });
  };

  render() {
    const { user, location, isActive } = this.props;
    const { error } = this.state;

    if (user.photoUrl && !error) {
      return (
        <StyledPicture
          src={user.photoUrl}
          onError={this.setError}
          alt={user.displayName}
          location={location}
          isActive={isActive}
        />
      );
    }
    return <Initial user={user} />;
  }
}

export default Picture;
