// @flow

import * as R from "ramda";
import type { ChecklistBuilderField } from "src/types";

export const filterFields = (
  fields: ChecklistBuilderField[],
  forbiddenFieldTypes: string[]
) => R.reject(field => forbiddenFieldTypes.includes(field.type), fields);

export const initialState = {
  version: 2,
  authorizedUsers: [],
  copyableFields: [],
  automations: {
    newCurrent: [
      {
        action: "addParticipants",
        active: true,
        data: []
      },
      {
        action: "removeParticipants",
        active: true,
        data: []
      },
      {
        action: "sendMessage",
        active: true,
        data: "This is the current version now."
      },
      {
        action: "updatePrivacy",
        active: true,
        data: {
          mode: "noChange",
          whitelistedUsers: []
        }
      },
      {
        action: "archive",
        active: true,
        data: false
      }
    ],
    oldCurrent: [
      {
        action: "addParticipants",
        active: true,
        data: []
      },
      {
        action: "removeParticipants",
        active: true,
        data: []
      },
      {
        action: "sendMessage",
        active: true,
        data: "This is no longer the current version."
      },
      {
        action: "updatePrivacy",
        active: true,
        data: {
          mode: "noChange",
          whitelistedUsers: []
        }
      },
      {
        action: "updateStatus",
        active: false,
        data: null
      },
      {
        action: "archive",
        active: true,
        data: true
      }
    ]
  }
};
