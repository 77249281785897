// @flow
import type {
  StackProps,
  TextProps,
  TagProps,
  AvatarProps,
  ButtonProps,
  BoxProps,
  SystemCSSProperties
} from "@chakra-ui/react";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import styled from "styled-components";
import { Overlay as StyledOverlay } from "src/styles/modal";

export const ChecklistUsersContainer: StackProps = {
  position: "relative",
  padding: "0px"
};

export const SelectionContainer: StackProps = {
  p: 0,
  borderRadius: radius.small,
  alignItems: "stretch"
};

export const ErrorMessage: TextProps = {
  color: "red",
  fontSize: "14px",
  pl: "0.5rem",
  pb: "0.5rem"
};

export const UserOrGroupItem: StackProps = {
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  px: spacing.space_m,
  py: spacing.space_s,
  _hover: {
    backgroundColor: "blackAlpha.100",
    cursor: "pointer"
  }
};

export const UserOrGroupTag: TagProps = {
  backgroundColor: colors.brightLight,
  borderRadius: 0,
  border: `solid 1px ${colors.sky}`
};

export const UserAndGroupTagText: TextProps = {
  fontSize: "11.7652px",
  ml: spacing.space_s
};

export const UserAndGroupTagTextHighlight: TextProps = {
  ...UserAndGroupTagText,
  color: "#1581BA",
  cursor: "pointer"
};

export const UserOrGroupClose: TagProps = {
  marginLeft: "auto",
  borderRadius: "100px",
  width: "1.5rem",
  height: "1.5rem",
  _focus: {
    outline: "none"
  },
  _hover: {
    backgroundColor: colors.blackAlpha500
  }
};

export const RemoveIcon: BoxProps = {
  backgroundColor: "orange",
  alignSelf: "flex-end"
};

export const UserAndGroupAvatar: AvatarProps = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: radius.medium,
  "& img": {
    borderRadius: radius.medium
  }
};

export const RemoveUser: StackProps = {
  width: "100%",
  paddingX: spacing.space_m,
  cursor: "pointer"
};

export const RemoveUserIcon: SystemCSSProperties = {
  fill: colors.disabled
};

export const RemoveUserIconHover: SystemCSSProperties = {
  fill: colors.blue500
};

export const RemoveUserText: TextProps = {
  fontSize: "14px",
  lineHeight: "14px",
  color: colors.disabled
};

export const RemoveUserTextHover: TextProps = {
  fontSize: "14px",
  lineHeight: "14px",
  color: colors.blue500
};

export const DropdownList: StackProps = {
  alignItems: "stretch",
  overflow: "overlay",
  scrollbarGutter: "auto | stable && both-edges?",
  maxHeight: "10rem"
};

export const EmptySingleUserSelection: BoxProps = {
  border: `solid 1px ${colors.sky}`,
  borderRadius: radius.small,
  p: "0.5rem"
};

export const EmptySingleUserSelectionText: TextProps = {
  p: 0,
  m: 0,
  color: colors.grey5,
  fontSize: "14px"
};

export const InviteExternal: ButtonProps = {
  color: colors.blue500,
  p: "0.5rem",
  borderTop: `solid 1px ${colors.grey2}`,
  borderRadius: 0,
  _hover: {
    backgroundColor: colors.active,
    color: colors.brightLight
  }
};

export const IconBox: BoxProps = {
  alignItems: "center",
  marginLeft: "auto"
};

export const Overlay = styled(StyledOverlay)`
  z-index: 995;
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled.div`
  z-index: 999;
  background-color: ${colors.brightLight};
  border-radius: ${radius.medium};
  min-width: 308px;
  min-height: 82px;
  overflow: auto;
  border-radius: 4px;
  padding: 20px 19px;
  ${props => !props.isOpen && "display: none;"}
`;

export const DropdownWrapper = {
  position: "absolute",
  top: "100%",
  width: "100%"
};
