// @flow

import * as R from "ramda";
import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";

import type { Action, FormState, FormsById, FormInstance } from "src/types";

const lastCreatedForm = (state: ?Object = {}, { type, payload }) => {
  switch (type) {
    case atypes.CREATE_FORM_SUCCESS:
      return payload.form;
    default:
      return state;
  }
};

const byId = (state: FormsById = {}, { type, payload }) => {
  switch (type) {
    case atypes.CREATE_FORM_SUCCESS:
      return { ...state, [payload.form.id]: payload.form };
    case atypes.DELETE_FORM_SUCCESS:
      return R.filter(form => form.id !== payload.formId, state);
    case atypes.FETCH_FORMS_SUCCESS:
      return payload.forms;
    default:
      return state;
  }
};

const openedForm = (state: ?Object = {}, { type, payload }) => {
  switch (type) {
    case atypes.FETCH_FORM_SUCCESS:
      return payload || {};
    case atypes.UPDATE_OPENED_FORM:
      return payload || state || {};
    case atypes.CLOSE_FORM_MODAL:
      return {};
    default:
      return state;
  }
};

const formModal = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.OPEN_FORM_MODAL:
      return true;
    case atypes.CLOSE_FORM_MODAL:
      return false;
    default:
      return state;
  }
};

const searchResults = (state: Array<number> = [], { type, payload }) => {
  switch (type) {
    case atypes.SEARCH_FORMS_SUCCESS:
      return payload.result;
    default:
      return state;
  }
};

const instances = (state: Array<FormInstance> = [], { type, payload }) => {
  switch (type) {
    case atypes.SET_FORMS_REQUEST:
      return [];
    case atypes.GET_FORM_INSTANCES_SUCCESS:
      return payload.results;
    case atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_SUCCESS:
    case atypes.UPDATE_CHECKLIST_FROM_FORM_MANAGE_VIEW_FAILURE:
      return [
        ...state.slice(0, payload.index),
        payload.values,
        ...state.slice(payload.index + 1)
      ];
    default:
      return state;
  }
};

const instanceCount = (state: string = "Loading", { type, payload }) => {
  switch (type) {
    case atypes.SET_FORMS_REQUEST:
      return "Loading";
    case atypes.GET_FORM_INSTANCES_SUCCESS:
      return payload.totalCount;
    default:
      return state;
  }
};

const loadingInstances = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.SET_FORMS_SUCCESS:
      return true;
    case atypes.GET_FORM_INSTANCES_FAILURE:
    case atypes.GET_FORM_INSTANCES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const selectedRows = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_FORM_ROW_SELECTION:
      return R.includes(payload.row, state)
        ? R.reject(R.equals(payload.row), state)
        : [...state, payload.row];
    case atypes.CLEAR_FORM_ROW_SELECTION:
      return [];
    default:
      return state;
  }
};

const form = combineReducers({
  lastCreatedForm,
  byId,
  openedForm,
  formModal,
  searchResults,
  instances,
  instanceCount,
  loadingInstances,
  selectedRows
});

export default form;

export const getForm = (state: FormState, id: number) => state.byId[`${id}`];

export const getFormTitle = (state: FormState, id: number) =>
  state.byId[`${id}`]?.title;

export const isFormRowSelected = (state: FormState, id: string) =>
  R.includes(id, state.selectedRows);

export const getOpenedForm = (state: FormState) => state.openedForm;
