// @flow

import React, { useCallback } from "react";

import Single from "./Single";
import { Field, DateInput, DateDone, Done } from "./styles";
import type {
  FieldId,
  ChecklistValue,
  RoomId,
  ColumnId,
  ChecklistField
} from "src/types";
import { extractChecklistValue } from "src/utils/checklist";

type Props = {
  formId: ?number,
  checklistValue: ChecklistValue,
  setChecklistValue: Function,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  details: ChecklistField,
  promptCallback?: ?Function,
  edit?: boolean,
  value?: any,
  handleChange?: Function,
  openEdit?: Function,
  closeEdit?: Function,
  locked: boolean,
  isMandatory?: boolean
};

const Date = ({
  formId,
  checklistValue,
  setChecklistValue,
  roomId,
  columnId,
  fieldId,
  promptCallback,
  value,
  handleChange,
  openEdit,
  closeEdit,
  edit,
  locked,
  isMandatory
}: Props) => {
  const updateChecklistValue = useCallback(() => {
    const prevValue = extractChecklistValue(checklistValue);

    if (prevValue !== value)
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: value === "" ? null : value,
          type: "date",
          checked: !!value
        },
        progress: true,
        formId,
        columnId
      });

    if (promptCallback) promptCallback();
    if (closeEdit) {
      closeEdit();
    }
  }, [roomId, formId, columnId, fieldId, value, promptCallback]);

  const forwardProps = {
    edit,
    update: updateChecklistValue,
    value,
    type: "date",
    openEdit,
    clickToEdit: true,
    disabled: locked,
    isMandatory
  };

  return (
    <Single {...forwardProps}>
      <Field>
        <DateInput
          type="date"
          placeholder="MMM DD, YYYY"
          value={value || ""}
          onChange={handleChange}
        />
        <DateDone>
          <Done onClick={updateChecklistValue}>Done</Done>
        </DateDone>
      </Field>
    </Single>
  );
};

Date.defaultProps = {
  promptCallback: null,
  value: "",
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  edit: false,
  locked: false
};

export default Date;
