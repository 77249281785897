// @flow

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getSignatureURLs,
  clearChecklistFieldError
} from "src/actions/checklist";
import {
  getChecklistFileUploadProgress,
  getSignatureByType
} from "src/reducers/checklist";
import {
  getRoomFieldValueStatus,
  getRoomFieldValueError,
  getIsUploadingFile
} from "src/reducers";
import type { AppState, RoomId, FieldId } from "src/types";
import Upload from "src/containers/chatroom/Upload";
import ProgressBar from "src/components/Dock/Checklist/File/ProgressBar";
import { dataStages } from "src/constants";

import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  Radio,
  RadioGroup,
  VStack,
  HStack,
  Image,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Box
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean,
  systemSignature: ?string,
  customSignature: ?string,
  roomId: RoomId,
  fieldId: FieldId,
  isUploading: boolean,
  progress: number,
  loading: boolean,
  error: ?string,
  handleSign: Function,
  onClose: Function,
  _getSignatureURLs: Function,
  _clearChecklistFieldError: Function,
  isCommentRequired: boolean
};

const signatureTypesMap = {
  custom: true,
  system: false
};

const approvalTypesMap = {
  approval: true,
  rejection: false
};

const Modal = ({
  isOpen,
  systemSignature,
  customSignature,
  roomId,
  fieldId,
  handleSign,
  isUploading,
  progress,
  loading,
  error,
  _getSignatureURLs,
  onClose,
  _clearChecklistFieldError,
  isCommentRequired
}: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [comment, setComment] = useState("");
  const [signatureType, setSignatureType] = useState(
    customSignature ? "custom" : "system"
  );
  const [approvalType, setApprovalType] = useState("approval");
  const [cropping, setCropping] = useState(false);

  const resetForm = () => {
    setCropping(false);
    setEmail("");
    setPassword("");
    setComment("");
    setSignatureType(customSignature ? "custom" : "system");
    setApprovalType("approval");
  };

  const hideModal = () => {
    resetForm();
    _clearChecklistFieldError(fieldId);
    onClose();
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    const formValue = {
      customSignature: signatureTypesMap[signatureType],
      approved: approvalTypesMap[approvalType],
      comment,
      username: email,
      password
    };

    handleSign({ value: formValue });
  };

  useEffect(() => {
    if (!loading && !error) hideModal();
  }, [loading, error]);

  useEffect(() => {
    _getSignatureURLs();
  }, [_getSignatureURLs]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={hideModal}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent borderRadius="4px" width="325px">
        <ModalHeader
          bgColor="unifize_grey0"
          p={4}
          height="56px"
          fontSize="xl"
          fontWeight={700}
          m={0}
          borderTopRadius="4px"
        >
          Apply your signature
        </ModalHeader>

        <ModalBody px={4} py={6}>
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <VStack spacing={4}>
              <FormControl>
                <RadioGroup value={approvalType} onChange={setApprovalType}>
                  <HStack spacing={4}>
                    <Radio id="approval" value="approval">
                      Approval
                    </Radio>
                    <Radio id="rejection" value="rejection">
                      Rejection
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Enter email</FormLabel>
                <Input
                  type="email"
                  placeholder="e.g. JohnDDoe@gmail.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="off"
                  isInvalid={!email}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="new-password"
                  isInvalid={!password}
                />
              </FormControl>

              <FormControl isRequired={isCommentRequired}>
                <FormLabel>Comment</FormLabel>
                <Textarea
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  placeholder="Enter comment here"
                  resize="vertical"
                />
              </FormControl>

              <FormControl>
                <RadioGroup value={signatureType} onChange={setSignatureType}>
                  <VStack alignItems="start" spacing={4}>
                    <VStack alignItems="start">
                      <Radio id="custom" value="custom">
                        Upload your own signature
                      </Radio>

                      {!cropping && customSignature && !isUploading && (
                        <Image
                          src={customSignature}
                          alt="custom-signature"
                          marginLeft="20px !important"
                          maxWidth="94px"
                          maxHeight="49px"
                        />
                      )}

                      {isUploading && progress > 0 && (
                        <ProgressBar
                          progress={progress}
                          style={{
                            width: "100%",
                            marginLeft: "20px",
                            marginBottom: 0
                          }}
                        />
                      )}

                      <Box ml="20px !important">
                        <Upload
                          location="signature-upload"
                          cropping={cropping}
                          setCropping={setCropping}
                          roomId={roomId}
                          fieldId={fieldId}
                          render={openFileBrowser => (
                            <Button
                              onClick={openFileBrowser}
                              size="xs"
                              variant={
                                customSignature
                                  ? "uBlueOutlined"
                                  : "uBlueFilled"
                              }
                              padding="4px 8px !important"
                            >
                              {customSignature ? "Upload new" : "Upload"}
                            </Button>
                          )}
                        />
                      </Box>
                    </VStack>

                    <VStack alignItems="start">
                      <Radio id="system" value="system">
                        Use system generated signature
                      </Radio>
                      <Image
                        src={systemSignature}
                        alt="system signature"
                        maxWidth="102px"
                        maxheight="44px"
                        marginLeft="20px !important"
                      />
                    </VStack>
                  </VStack>
                </RadioGroup>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>

        <VStack px={4} py={2} pb={6} alignItems="start">
          <Text fontSize="sm" color="unifize_red600" my={0}>
            {error}
          </Text>

          <HStack alignItems="start">
            <Button
              variant="uPrimary"
              onClick={handleFormSubmit}
              isLoading={loading}
            >
              Confirm and sign
            </Button>
            <Button variant="uSecondary" onClick={hideModal}>
              Cancel
            </Button>
          </HStack>
        </VStack>
      </ModalContent>
    </ChakraModal>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId, fieldId }: Props
) => ({
  systemSignature: getSignatureByType(app.checklist, "system"),
  customSignature: getSignatureByType(app.checklist, "custom"),
  isUploading: getIsUploadingFile(app),
  progress: getChecklistFileUploadProgress(app.checklist, roomId, fieldId),
  loading:
    getRoomFieldValueStatus(app, fieldId, roomId) === dataStages.updating,
  error: getRoomFieldValueError(app, fieldId, roomId)
});

export default connect(mapStateToProps, {
  _getSignatureURLs: getSignatureURLs,
  _clearChecklistFieldError: clearChecklistFieldError
})(Modal);
