// @flow

import styled from "@emotion/styled";
import { statusColor } from "src/constants/approvalStatus";
import { Cell } from "../styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { EditIconWrapper } from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import * as radius from "src/styles/constants/radius";
import { Thumbnail, ImageThumbnail } from "src/components/Dock/styles";

export const CaretUp = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.grey2};
  height: ${spacing.space_l};
  width: ${spacing.space_l};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  top: 0px;
  margin-left: 0.25em;
  > svg {
    height: 1em;
    width: 1em;
    > path {
      stroke: ${colors.secondary};
    }
  }
`;

export const Text = styled(Cell)`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.selected ? colors.highlight : colors.brightLight};
  }
`;

export const ValueWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: ${props => (props.showDropdown ? "visible" : "hidden")};
  height: 100%;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: start;
  > div > img {
    margin-bottom: ${props => (props.isUserWrapper ? "-4px" : "0")};
    margin-right: ${props => (props.isUserWrapper ? "2px" : "0")};
  }
  > div > div {
    margin-right: ${props => (props.isUserWrapper ? "2px" : 0)};
  }
`;

export const NoValueWrapper = styled(Text)`
  min-height: 45px;
  padding: ${spacing.space_m};
  background-color: ${props =>
    props.selected ? colors.highlight : colors.brightLight};
`;

export const ConversationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const Count = styled.div`
  position: relative;
  color: ${colors.unifizeBlue};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1px 3px;
  margin-left: 4px;
  background-color: ${colors.grey12};
  border-radius: 3px;
  font-weight: bold;
`;

export const Select = styled(Text)`
  overflow: hidden;
  justify-content: space-between;
  align-items: start;
`;

export const FormItem = styled(Select)`
  color: ${colors.active};
  cursor: pointer;
`;

export const PicklistWrapper = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
`;

export const SinglePicklistWrapper = styled(PicklistWrapper)`
  max-height: 35px;
`;

export const MultiPicklistWrapper = styled(PicklistWrapper)`
  max-height: 35px;
`;

export const FormWrapper = styled.span`
  :first-of-type {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
`;

export const Conversation = styled.th`
  padding: ${spacing.space_m};
  display: flex;
  align-items: center;
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  > div:nth-of-type(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div > a > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const MultiTable = styled.table`
  width: 100%;
  height: ${props => (!props.fullHeight ? "100%" : "auto")};
  border-collapse: collapse;
`;

export const NestedMultiTable = styled(MultiTable)``;

export const MultiContainer = styled.td`
  background-color: ${props =>
    props.selected ? colors.highlight : colors.grey35};
  min-width: ${props => props.minWidth ?? "50px"};
  max-width: ${props => props.width};
  overflow-x: hidden;
  vertical-align: top;
  min-height: 45px;
  padding: 0;
  border-left: ${props =>
    props.borderRequired ? `1px solid ${colors.grey2}` : "none"};
`;

export const StyledMultiConversation = styled.th`
  background-color: white;
  overflow-y: hidden;
  padding: ${spacing.space_m};
  font-weight: normal;
  display: flex;
  align-items: center;
  margin-left: -2px;
  min-height: 45px;
  min-width: ${props => props.minWidth ?? "50px"};
  max-width: ${props => props.width};
  > div:nth-of-type(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div > a > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  ${EditIconWrapper} {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &:hover {
    ${EditIconWrapper} {
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const StyledMultiForm = styled(StyledMultiConversation)`
  margin-left: -1px;
`;

export const MultiConversationRow = styled.tr`
  max-height: ${props => (props.expandedField ? "none" : "45px")};
  display: ${props => (props.expandedField ? "table-row" : "flex")};
  vertical-align: top;
  overflow-y: hidden;
`;

export const Title = styled.div`
  max-width: 14em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.primary};
  display: flex;
  align-items: center;
`;

export const MultipleConversation = styled(Cell)`
  display: flex;
`;

export const NoValue = styled.div`
  font-style: oblique;
  padding-right: 0.5em;
  padding-top: 4px;
`;

export const StyledMultiField = styled.td`
  background-color: white;
  display: flex;
  align-items: center;
  min-height: 45px;
  max-height: 45px;
  padding: ${spacing.space_m};
  ${props =>
    props.maxWidth && `max-width: ${props.maxWidth}; overflow-x: hidden`};
  ${EditIconWrapper} {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &:hover {
    ${EditIconWrapper} {
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const NestedMultiRow = styled.tr`
  max-height: 45px;
  border-bottom: ${props =>
    props.bottomBorder ? `1px solid ${colors.grey2}` : "none"};
  :last-child {
    border-bottom: ${props =>
      props.lastBorderBottom ? `1px solid ${colors.grey2}` : "none"};
  }
`;

export const MultipleSelect = styled.td`
  overflow: hidden;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    color: ${colors.primary};
    cursor: "text";
  }
`;

export const EmptyUserField = styled.td`
  min-width: ${props => props.minWidth};
  max-width: ${props => props.width};
  background: ${props => (props.selected ? colors.highlight : "transparent")};
  padding: 0;
  border-left: ${props =>
    props.borderRequired ? `1px solid ${colors.grey2}` : "none"};
  ${EditIconWrapper} {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &:hover {
    ${EditIconWrapper} {
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const EmptyUserValueWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.space_m};
  ${props =>
    props.maxWidth && `max-width: ${props.maxWidth}; overflow-x: hidden`};
`;

export const MultipleUser = styled(Select)`
  > div {
    display: flex;
    align-items: center;
    span {
      color: ${colors.primary};
    }
  }
`;

export const MultipleForm = styled(Select)`
  > div {
    display: flex;
    align-items: center;
    color: ${colors.primary};
    cursor: pointer;
  }
`;

export const Form = styled.th`
  display: flex;
  align-items: center;
  color: ${colors.active};
  cursor: pointer;
  span {
    max-width: 12em;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
`;

export const Th = styled.th`
  text-transform: uppercase;
  text-align: left;
  padding: 8px;
`;

export const ChecklistItem = styled.td`
  text-align: left;
  vertical-align: top;
  padding: 8px;
  ${EditIconWrapper} {
    > svg {
      display: none;
    }
  }
  ${Cell} {
    padding: 0;
    min-height: auto;
  }
`;

export const Modal = styled.div`
  z-index: 999;
  background-color: ${colors.brightLight};
  border-radius: ${radius.small};
  min-width: 308px;
  min-height: 62px;
  overflow: auto;
  border-radius: 4px;
  padding: 10px 8px;
  position: relative;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.brightLight};
  float: right;
  > svg {
    height: 0.8em;
    width: 0.8em;
    cursor: pointer !important;
    > g {
      stroke: ${colors.primary};
      stroke-width: 3px;
    }
  }
`;

export const Address = styled.strong`
  padding-left: 2px;
  color: ${colors.grey21};
  font-size: 11px;
`;

export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const FileWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  ${props =>
    props.maxWidth && `max-width: ${props.maxWidth}; overflow: hidden;`}
`;

export const MultipleConversationsContainer = styled(Cell)`
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
`;

export const StyledThumbnail = styled(Thumbnail)`
  > div {
    width: 16px;
    height: 16px;
  }
`;

export const StyledImageThumbnail = styled(ImageThumbnail)`
  width: 16px;
  height: 16px;
`;

export const ApprovalStatusContainer = styled.div`
  padding: 0 4px;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  height: 18px;
  align-items: center;
  color: ${props => statusColor[props.status]};
  border: 1px solid ${props => statusColor[props.status]};
`;

export const ApprovalStatusTextWrapper = styled.p`
  padding: 0;
  margin: 0;
`;

export const MembersContainer = styled.div`
  display: flex;
  align-items: center;
  > div > div > svg {
    margin-bottom: -4px;
    margin-right: 2px;
  }
  > div > img {
    margin-bottom: -4px;
  }
`;
