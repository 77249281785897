// @flow

import * as React from "react";
import * as R from "ramda";

import {
  chakra,
  omitThemingProps,
  useFormControl,
  useMergeRefs,
  useMultiStyleConfig,
  usePopper,
  Checkbox
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { mergeWith } from "@chakra-ui/utils";
import { useSelect } from "downshift";
import * as customStyles from "./styles";

type Props<T> = {
  value: T[],
  children: React.Node,
  placeholder?: string,
  onChange: (item: T) => void,
  handleSelectAll?: (event: SyntheticEvent<T>) => void,
  handleDeselectAll?: (event: SyntheticEvent<T>) => void
};

const CustomSelect = <T>(): React.AbstractComponent<Props<T>, HTMLElement> =>
  React.forwardRef<Props<T>, HTMLElement>((props, ownRef) => {
    const {
      id,
      children,
      placeholder,
      value,
      onChange,
      handleSelectAll,
      handleDeselectAll,
      ...rest
    } = omitThemingProps(props);

    const ownButtonProps = useFormControl(rest);

    const styles = useMultiStyleConfig("CustomSelect", props);

    // Convert children to an array
    const dropdownChildren = React.Children.toArray(children);

    const options = dropdownChildren
      .filter(R.hasPath(["props", "value"]))
      .map(R.path(["props", "value"]));

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getItemProps
    } = useSelect({
      id,
      items: options,
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;

        switch (type) {
          case useSelect.stateChangeTypes.MenuKeyDownEnter:
          case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            return {
              ...changes,
              highlightedIndex: state.highlightedIndex,
              isOpen: true // keep the menu open after selection.
            };
        }

        return changes;
      },
      onStateChange: ({ type, selectedItem }) => {
        switch (type) {
          case useSelect.stateChangeTypes.MenuKeyDownEnter:
          case useSelect.stateChangeTypes.MenuKeyDownSpaceButton: {
            onChange(selectedItem);
            break;
          }

          default:
            break;
        }
      }
    });

    const { referenceRef: popperRef, getPopperProps } = usePopper({
      enabled: isOpen,
      gutter: 2
    });

    const {
      ref: useSelectToggleButtonRef,
      ...useSelectToggleButtonProps
    } = getToggleButtonProps();

    const toggleButtonRef = useMergeRefs(
      ownRef,
      useSelectToggleButtonRef,
      popperRef
    );

    const toggleButtonProps = mergeWith(
      ownButtonProps,
      useSelectToggleButtonProps
    );

    const preview = R.isEmpty(value)
      ? placeholder
      : `${value.length} items selected`;

    return (
      <chakra.div position="relative">
        <chakra.button
          ref={toggleButtonRef}
          __css={{ ...styles.field, ...customStyles.toggleButton }}
          data-focus-visible-added={isOpen}
          {...toggleButtonProps}
        >
          <chakra.span>{preview}</chakra.span>

          <ChevronDownIcon />
        </chakra.button>
        <chakra.div
          zIndex="1"
          width="100%"
          {...mergeWith(getPopperProps(), {
            style: {
              visibility: isOpen ? "visible" : "hidden",
              ...customStyles.optionsContainer
            }
          })}
        >
          <chakra.ul
            __css={{
              ...styles.menu,
              ...customStyles.optionsList
            }}
            data-focus-visible-added={isOpen}
            {...getMenuProps()}
          >
            {handleSelectAll && (
              <chakra.li
                onClick={handleSelectAll}
                __css={{
                  ...styles.option,
                  ...customStyles.option
                }}
              >
                Select all
              </chakra.li>
            )}

            {handleDeselectAll && (
              <chakra.li
                onClick={handleDeselectAll}
                __css={{
                  ...styles.option,
                  ...customStyles.option
                }}
              >
                Deselect all
              </chakra.li>
            )}

            {isOpen &&
              dropdownChildren.map(child => {
                // If the child has a value prop, then render it as an option
                // Else render it as is
                if (child.props.value) {
                  const { value: item } = child.props;
                  const itemProps = getItemProps({
                    item,
                    index: options.findIndex(option => option === item)
                  });

                  return (
                    <chakra.li
                      __css={{
                        ...styles.option,
                        ...customStyles.option
                      }}
                      {...itemProps}
                      onClick={e => {
                        itemProps.onClick(e);
                        onChange(item);
                      }}
                      key={item}
                    >
                      <Checkbox
                        isChecked={value.includes(item)}
                        {...itemProps}
                        onChange={itemProps.onClick}
                        onClick={e => {
                          e.preventDefault();
                        }}
                      />

                      {child}
                    </chakra.li>
                  );
                }

                return child;
              })}
          </chakra.ul>
        </chakra.div>
      </chakra.div>
    );
  });

export default CustomSelect;
