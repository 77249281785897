// @flow

import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";
import type { Action, AppState } from "src/types";

const main = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_DOCK:
      return payload.dockContent;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return null;
    case atypes.TOGGLE_DOCK:
      return state !== null ? null : payload.dockContent;
    default:
      return state;
  }
};

const headerHeight = (state: number = 0, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_HEADER_HEIGHT:
      return payload;
    default:
      return state;
  }
};
const srw = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_SRW_DOCK:
      return payload.dockContent;
    case atypes.HIDE_SRW_DOCK:
      return null;
    default:
      return state;
  }
};

const activeDock = combineReducers({
  main,
  srw,
  headerHeight
});

export default activeDock;

export const getMainActiveDock = (state: AppState) => state.activeDock.main;

export const getSRWActiveDock = (state: AppState) => state.activeDock.srw;
