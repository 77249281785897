// @flow

import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { arrayMove } from "react-sortable-hoc";

import { Checkbox } from "@chakra-ui/react";
import SortableList from "./SortableList";
import { omitedFields } from "src/constants/processInstanceColumns";
import usePortal from "react-useportal";
import { Overlay } from "src/components/Manage/Reports/Modal/styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { processColumnNames } from "src/constants/display";
import Icon from "src/icons";
import { CloseButton } from "src/styles";
import {
  Organize as StyledOrganize,
  SortHeader,
  Footer,
  Heading,
  SubHeading
} from "./styles";
import { Button, Ternary } from "src/styles/buttons";
import { reOrderProcessColumn } from "src/actions/workflows";
import defaultColumn from "src/constants/processInstanceColumns";
import {
  getProcessTableColumns,
  getProcessFilterId,
  getChatroomHideStatus,
  getInstanceReportId,
  getReportsTableColumns
} from "src/reducers";
import { mergeNewFields } from "src/utils/checklist";

import type { AppState } from "src/types";

type Props = {
  options: Array<Object>,
  id: ?string,
  hide: boolean,
  reportId: ?string,
  handleClose: Function,
  _reOrderProcessColumn: Function,
  fields: Array<Object>
};

type processColumnNamesKeys = $Keys<typeof processColumnNames>;

const fixedColumns: processColumnNamesKeys[] = ["seqNo", "title"];

const Organize = ({
  options,
  handleClose,
  id,
  hide,
  reportId,
  _reOrderProcessColumn,
  fields
}: Props) => {
  const optionsIncludesFields = options.filter(f => f.label).length > 0;

  const filteredColumn = R.filter(column => {
    if (hide && column.key === "status") {
      return false;
    }
    return true;
  }, options);

  const optionsWithFields = [
    ...(optionsIncludesFields
      ? mergeNewFields(fields, filteredColumn, !!reportId)
      : filteredColumn),
    ...(fields && !optionsIncludesFields
      ? fields
          .map(field => ({ ...field, key: field.id, active: true }))
          .filter(column => !R.includes(column.type, omitedFields))
      : [])
  ];

  const [localOptions, setLocalOptions] = useState(optionsWithFields);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const handleSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
      const newSort = arrayMove(localOptions, oldIndex, newIndex);
      setLocalOptions(newSort);
    },
    [localOptions, setLocalOptions]
  );

  const handleCheckbox = useCallback(
    column => {
      setLocalOptions(
        R.map(x => {
          if (x.key === column) {
            return { ...x, key: x.key, active: !x.active };
          }
          return x;
        }, localOptions)
      );
    },
    [localOptions, setLocalOptions]
  );

  const handleSubmit = useCallback(() => {
    if (id) {
      _reOrderProcessColumn(id, localOptions, reportId);
      handleClose();
    }
  }, [reportId, localOptions, _reOrderProcessColumn, id, handleClose]);

  const resetColumn = useCallback(() => {
    const updatedLocalOptions = [
      ...defaultColumn,
      ...(fields
        ? fields
            .map(field => ({ ...field, key: field.id, active: true }))
            .filter(column => !R.includes(column.type, omitedFields))
        : [])
    ];
    setLocalOptions(updatedLocalOptions);
    if (id) {
      _reOrderProcessColumn(id, updatedLocalOptions, reportId);
    }
  }, [reportId, setLocalOptions, fields, id]);

  const areAllOptionsVisible = localOptions.every(R.propEq("active", true));
  const areSomeOptionsVisible =
    localOptions.some(R.propEq("active", true)) && !areAllOptionsVisible;

  const handleViewOptionsChange = () => {
    if (areAllOptionsVisible) {
      setLocalOptions(
        R.map(x => {
          const columnKey = x.key;
          return R.includes(columnKey, fixedColumns)
            ? { ...x, active: true }
            : { ...x, active: false };
        })
      );
    } else {
      setLocalOptions(R.map(x => ({ ...x, active: true })));
    }
  };

  const { Portal } = usePortal();

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={handleClose}>
          <StyledOrganize>
            <CloseButton type="button" onClick={handleClose}>
              <Icon type="close" />
            </CloseButton>

            <Heading>Customize Table View</Heading>

            <SubHeading>To arrange drag up or down</SubHeading>

            <SortHeader>
              <SubHeading>Column Title</SubHeading>

              <div onClick={handleViewOptionsChange}>
                <Checkbox
                  isChecked={areAllOptionsVisible}
                  isIndeterminate={areSomeOptionsVisible}
                />
              </div>

              <SubHeading>Order</SubHeading>
            </SortHeader>

            <SortableList
              items={localOptions}
              onSortEnd={handleSort}
              handleCheckbox={handleCheckbox}
              fixedColumns={fixedColumns}
              useDragHandle
            />

            <Footer>
              <Ternary onClick={resetColumn}>Reset</Ternary>
              <Button onClick={handleSubmit}>Apply</Button>
            </Footer>
          </StyledOrganize>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const id = getProcessFilterId(app);
  const reportId = getInstanceReportId(app);
  return {
    id,
    reportId,
    options: reportId
      ? getReportsTableColumns(app, reportId || "")
      : getProcessTableColumns(app, id || ""),
    hide: getChatroomHideStatus(app, id || ""),
    fields: app.workflow.principalChecklist.fields
  };
};

export default connect(mapStateToProps, {
  _reOrderProcessColumn: reOrderProcessColumn
})(Organize);
