// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import { NameOrEmail } from "src/components/user/styles";
import { size } from "src/constants/processInstanceColumns";

export const depthColor = {
  ["0"]: colors.brightLight,
  ["1"]: colors.grey14,
  ["2"]: colors.grey13,
  ["3"]: colors.highlight
};

export const EditIconWrapper = styled.div`
  > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    visibility: hidden;
  }
`;

export const Row = styled.tr`
  display: flex;
  align-items: stretch;
  white-space: nowrap;
  background-color: ${colors.grey35};
  ${props => props.depth && `background: ${depthColor[props.depth]} !important`}
  width: 100%;
  > div {
    background: ${props => depthColor[props.depth]} !important;
  }
`;

export const Cell = styled.td`
  padding: ${spacing.space_m};
  background-color: ${props => (props.selected ? colors.highlight : "white")};
  display: flex;
  align-items: ${props => (props.embedded ? "center" : "start")};
  min-height: 45px;
  gap: 5px;
  ${props => (props.embedded || props.fullHeight) && "height: 100%;"}
  border-left: ${props =>
    props.borderRequired ? `1px solid ${colors.grey2}` : "none"};
  max-width: ${props => props.width};
  max-height: 45px;
  > div:nth-of-type(1) {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    > div > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      max-width: 18.5em;
    }
  }
  ${EditIconWrapper} {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &:hover {
    ${EditIconWrapper} {
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const CenterCell = styled(Cell)`
  text-align: center;
  justify-content: center;
  min-width: ${props => props.width};
`;

export const StickyCell = styled(Cell)`
  background: ${props =>
    props.depth
      ? depthColor[props.depth]
      : props.selected
      ? colors.highlight
      : colors.brightLight};
  position: sticky;
  left: 0;
  align-items: center;
  z-index: 1;
  > div:nth-of-type(1) {
    overflow: visible;
  }
`;

export const Selector = styled(StickyCell)`
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid ${colors.grey2};
  max-height: 100%;
`;

export const SeqNo = styled(StickyCell)`
  border: none;
  max-height: 100%;
  justify-content: center;
  align-items: start;
  left: 36px;
  min-width: ${size.seqNo};
`;

export const User = styled(Cell)`
  min-width: ${props => props.width};
  ${NameOrEmail} {
    max-width: 9em;
  }
`;

export const NoValue = styled.div`
  font-style: oblique;
`;

export const Age = styled(CenterCell)`
  color: ${props => (props.completed ? colors.success : colors.primary)};
`;

export const StyledCheckboxWrapper = styled.div`
  align-self: baseline;
`;
