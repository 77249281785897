// @flow

import * as R from "ramda";
import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  getCurrentChecklistBuilderFields,
  getWorkflowBuilderStatuses
} from "src/reducers";
import type { RevisionFieldSettings, RevisionFieldSettingsv2 } from "src/types";
import { automationDataUpdater } from "src/components/Manage/Builder/Checklist/SettingsBuilder/Automations/utils";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import { filterFields } from "./utils";
import * as morpheus from "src/utils/morpheus";

import { FormLabel, VStack, Text } from "@chakra-ui/react";
import { FormControl } from "src/styles/checklist";
import UserSelectMultiple from "src/containers/user/SelectMultiple";
import Select from "src/components/Select";
import Accordion from "src/components/Accordion.v2";
import AccordionContent from "src/components/Manage/Builder/Checklist/SettingsBuilder/FieldSettings/AdvancedApproval/AccordionContent";
import Automations from "src/components/Manage/Builder/Checklist/SettingsBuilder/Automations";
import * as styles from "./styles";

type Props = {
  type: string,
  settings: RevisionFieldSettings | RevisionFieldSettingsv2,
  saveSettings: Function
};

export default function Revision({ settings, saveSettings }: Props) {
  const [
    updatedSettings,
    setUpdatedSettings
  ] = useState<RevisionFieldSettingsv2>(morpheus.revision(settings));

  const checklistFields = useSelector(({ app }) =>
    filterFields(getCurrentChecklistBuilderFields(app), forbiddenFieldTypes)
  );

  const allStatuses = useSelector(({ app }) => getWorkflowBuilderStatuses(app));

  useEffect(() => {
    saveSettings(updatedSettings);
  }, [updatedSettings]);

  const handleAuthorizedUsersChange = currUser => {
    setUpdatedSettings(currSettings => {
      let updatedUsers = currSettings.authorizedUsers;

      if (R.includes(currUser, updatedUsers)) {
        updatedUsers = R.reject(R.equals(currUser), updatedUsers);
      } else {
        updatedUsers = R.uniq([...updatedUsers, currUser]);
      }

      return R.assocPath(["authorizedUsers"], updatedUsers, currSettings);
    });
  };

  const handleCopyableFieldsChange = currField => {
    setUpdatedSettings(currSettings => {
      let updatedFields = currSettings.copyableFields;

      if (!currField) {
        updatedFields = [];
      } else {
        if (R.includes(currField, updatedFields)) {
          updatedFields = R.reject(R.equals(currField), updatedFields);
        } else {
          updatedFields = R.uniq([...updatedFields, currField]);
        }
      }

      return R.mergeDeepRight(currSettings, {
        copyableFields: updatedFields
      });
    });
  };

  const handleSelectAllCopyableFields = () => {
    setUpdatedSettings(
      R.mergeDeepLeft({
        copyableFields: R.pluck("id")(checklistFields)
      })
    );
  };

  const handleClearAllCopyableFields = () => {
    setUpdatedSettings(
      R.mergeDeepLeft({
        copyableFields: []
      })
    );
  };

  const handleAutomationDataChange = (
    automation: "newCurrent" | "oldCurrent",
    action:
      | "addParticipants"
      | "removeParticipants"
      | "sendMessage"
      | "updatePrivacy"
      | "updateStatus"
      | "archive"
  ) => data => {
    setUpdatedSettings(currSettings => {
      const actionValue = currSettings.automations[automation];
      // $FlowFixMe
      const index = R.findIndex(R.propEq("action", action))(actionValue);
      const currAutomationAction = actionValue[index];

      const updatedAutomationAction = {
        ...currAutomationAction,
        // $FlowFixMe
        ...automationDataUpdater[action]({
          data,
          // $FlowFixMe
          currentData: currAutomationAction.data
        })
      };

      const mergeObj = {
        automations: {
          [automation]: R.update(index, updatedAutomationAction, actionValue)
        }
      };

      return R.mergeDeepRight(currSettings, mergeObj);
    });
  };

  const handleAutomationActiveChange = (
    automation: "newCurrent" | "oldCurrent",
    action:
      | "addParticipants"
      | "removeParticipants"
      | "sendMessage"
      | "updatePrivacy"
      | "updateStatus"
      | "archive"
  ) => newActiveState => {
    setUpdatedSettings(currSettings => {
      const actionValue = currSettings.automations[automation];
      // $FlowFixMe
      const index = R.findIndex(R.propEq("action", action))(actionValue);
      const currAutomationAction = actionValue[index];

      const updatedAutomationAction = {
        ...currAutomationAction,
        active: newActiveState
      };

      const mergeObj = {
        automations: {
          [automation]: R.update(index, updatedAutomationAction, actionValue)
        }
      };

      return R.mergeDeepRight(currSettings, mergeObj);
    });
  };

  return (
    <VStack as="form" {...styles.container}>
      <FormControl>
        <FormLabel>Select users who can create versions</FormLabel>
        <UserSelectMultiple
          value={updatedSettings.authorizedUsers}
          handleChange={handleAuthorizedUsersChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Select fields to copy to revision:</FormLabel>
        <Select
          multiple
          value={updatedSettings.copyableFields}
          onChange={handleCopyableFieldsChange}
          onSelectAll={handleSelectAllCopyableFields}
          onClearAll={handleClearAllCopyableFields}
          placeholder="Select checklist fields to copy"
          dropdownProps={{
            zIndex: 3
          }}
        >
          {checklistFields.map(item => (
            <Text key={item.id} value={item.id} my={0}>
              {item.label}
            </Text>
          ))}
        </Select>
      </FormControl>

      <Accordion title="For conversation being marked current">
        <AccordionContent>
          <VStack alignItems="start" spacing={4}>
            {updatedSettings.automations.newCurrent.map(automation => (
              <Automations
                key={automation.action}
                action={automation.action}
                value={automation.data}
                isNewCurrent
                onChange={handleAutomationDataChange(
                  "newCurrent",
                  automation.action
                )}
                isActive={automation.active}
                onActiveChange={handleAutomationActiveChange(
                  "oldCurrent",
                  automation.action
                )}
                allStatuses={allStatuses}
              />
            ))}
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="For conversation that was current previously">
        <AccordionContent>
          <VStack alignItems="start" spacing={4}>
            {updatedSettings.automations.oldCurrent.map(automation => (
              <Automations
                key={automation.action}
                action={automation.action}
                value={automation.data}
                onChange={handleAutomationDataChange(
                  "oldCurrent",
                  automation.action
                )}
                isActive={automation.active}
                onActiveChange={handleAutomationActiveChange(
                  "oldCurrent",
                  automation.action
                )}
                allStatuses={allStatuses}
              />
            ))}
          </VStack>
        </AccordionContent>
      </Accordion>
    </VStack>
  );
}
