// @flow

import React from "react";
import usePortal from "react-useportal";

import List from "./List";
import CurrentSelection from "./CurrentSelection";
import {
  Overlay,
  Container,
  Content as StyledContent
} from "src/styles/splitPane";

import Close from "src/containers/Close";

const OrgSettings = () => {
  const { Portal } = usePortal();

  return (
    <Portal>
      <Close />
      <Overlay style={{ overflowX: "hidden" }}>
        <Container>
          <List />
          <StyledContent>
            <CurrentSelection />
          </StyledContent>
        </Container>
      </Overlay>
    </Portal>
  );
};

export default OrgSettings;
