// @flow

import * as atypes from "src/constants/actionTypes";
import type { ComponentPermissionName } from "src/types";

export const systemRolesDispMap: { [string]: string } = {
  "super-admin": "Super Admin",
  admin: "Admin",
  member: "Org Member",
  collaborator: "External User",
  vendor: "Vendor",
  contact: "Contact",
  reader: "Read Only"
};

export const systemRolesDispOrder = [
  "Super Admin",
  "Admin",
  "Org Member",
  "External User",
  "Vendor",
  "Read Only"
];

export const roleTitles = {
  superAdmin: "super-admin",
  admin: "admin",
  member: "member",
  collaborator: "collaborator",
  vendor: "vendor",
  contact: "contact",
  reader: "reader"
};

export const componentPermissions = {
  contacts: "contacts",
  manage: "manage",
  filterEveryone: "filterEveryone",
  newConversation: "newConversation",
  home: "home",
  homeSettings: "homeSettings",
  checklistNewConversation: "checklistNewConversation",
  expandChart: "expandChart",
  changeConversationType: "changeConversationType",
  manageIcon: "manageIcon",
  downloadChecklist: "downloadChecklist",
  dashboard: "dashboard",
  processSettings: "processSettings",
  chatInput: "chatInput",
  chatDelete: "chatDelete",
  csvUpload: "csvUpload"
};

export const routeComponentPermissionsMap: {
  [string]: Array<ComponentPermissionName>
} = {
  [atypes.SET_CONTACTS_REQUEST]: [componentPermissions.contacts],
  [atypes.SET_PROCESS_REQUEST]: [componentPermissions.manage],
  [atypes.SET_HOME_SCREEN_REQUEST]: [componentPermissions.home],
  [atypes.SET_ORG_SETTINGS_REQUEST]: [componentPermissions.homeSettings],
  [atypes.SET_REPORTS_REQUEST]: [
    componentPermissions.expandChart,
    componentPermissions.manage
  ],
  [atypes.SET_FORMS_REQUEST]: [componentPermissions.manage],
  [atypes.SET_DASHBOARD_REQUEST]: [componentPermissions.dashboard],
  [atypes.EDIT_PROCESS_REQUEST]: [componentPermissions.processSettings]
};
