// @flow

import React, { useState } from "react";

import type { Roles as RolesById } from "src/types";

import MultiSelect2 from "src/components/ChakraPro/MultiSelect2";
import { Text } from "@chakra-ui/react";

type Props = {
  value: number[],
  onChange: Function,
  rolesById: RolesById
};

export default function RolesMultiSelect({
  value,
  onChange,
  rolesById,
  ...restProps
}: Props) {
  const allRoleIds = Object.keys(rolesById).map(id => Number(id));

  const [options, setOptions] = useState<number[]>(allRoleIds);

  const inputValueChangeHandler = inputValue => {
    setOptions(
      allRoleIds.filter(roleId =>
        rolesById[Number(roleId)].title
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
    );
  };

  const itemRenderer = roleId => (
    <Text textStyle="label">
      {rolesById[Number(roleId)]?.title || "Deleted Role"}
    </Text>
  );

  return (
    <MultiSelect2
      value={value}
      options={options}
      onQueryChange={inputValueChangeHandler}
      itemHandler={onChange}
      itemRenderer={itemRenderer}
      inputPlaceholder="Select roles"
      {...restProps}
    >
      {allRoleIds.map(roleId => (
        <p key={roleId} value={Number(roleId)}>
          {rolesById[roleId].title}
        </p>
      ))}
    </MultiSelect2>
  );
}
