// @flow

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useCallback } from "react";

import Value from "src/components/chatroom/DueDate/Value";
import { DueDate as StyledDueDate } from "src/components/chatroom/DueDate/styles";
import Calendar from "src/components/date/Calendar";
import { getDueDate, isStatusDisabled, getActiveState } from "src/reducers";
import autoPosition from "src/components/autoPosition";
import { setAttribute } from "src/actions/chatroom";
import Tooltip from "src/components/Tooltip";
import useAutoPositionDropdown from "src/hooks/useAutoPositionDropdown";
import { getDueDate as getDate } from "src/utils";

import type { RoomId } from "src/types";

type Props = {
  location: string,
  roomId: RoomId
};

const CalendarSelector = autoPosition(Calendar, {
  bottomOffset: "0px",
  topOffset: "30px",
  rightOffset: "0px",
  position: "top"
});

const DueDate = ({ roomId, location }: Props) => {
  const [dropdown, setDropdown] = useState(false);
  const outerRef = useRef(null);
  const edit = dropdown;
  const { fieldRef, dropdownRef, positionUp } = useAutoPositionDropdown({
    edit
  });

  const dispatch = useDispatch();

  const dueDate = useSelector(({ app }) => getDueDate(app, roomId));
  const active = useSelector(({ app }) => getActiveState(app, roomId));
  const disabled = useSelector(({ app }) => isStatusDisabled(app, roomId));
  const value: string | Object = getDate(dueDate);

  const handleDropdown = useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown, setDropdown]);

  const handleDueDate = useCallback(
    (dueDate: string) => {
      dispatch(
        setAttribute(
          roomId,
          {
            dueDate
          },
          location
        )
      );
    },
    [roomId, location]
  );

  if (disabled) {
    return <></>;
  }
  return (
    <StyledDueDate
      onClick={handleDropdown}
      onKeyPress={handleDropdown}
      ref={outerRef}
      data-cy="dueDate"
    >
      <Tooltip title="Set due date">
        <span>
          <Value value={value} active={active} fieldRef={fieldRef} />
        </span>
      </Tooltip>

      {dropdown ? (
        <CalendarSelector
          dropdownRef={dropdownRef}
          positionUp={positionUp}
          handleChange={handleDueDate}
          outerRef={outerRef.current}
          value={value}
          handleClose={handleDropdown}
        />
      ) : null}
    </StyledDueDate>
  );
};

export default DueDate;
