// @flow

import { connect } from "react-redux";

import type { State } from "src/types";
import { getRoles } from "src/reducers";

import RoleMultiSelect from "src/components/Role/MultiSelect";

const mapStateToProps = ({ app }: State) => ({
  rolesById: getRoles(app)
});

export default connect(mapStateToProps)(RoleMultiSelect);
