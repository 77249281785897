// @flow

import React from "react";

import type { UnifizeChatRoom } from "src/types";

import Icons from "src/icons";
import {
  Button,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  CheckboxGroup,
  Checkbox,
  Text
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";

import * as styles from "../styles";

type Props = {
  autoLink: boolean,
  versions: UnifizeChatRoom[],
  selectedVersions: string[],
  processTitle: string,
  onEnterEditMode: Function
};

const Viewer = ({
  autoLink,
  versions,
  selectedVersions,
  processTitle,
  onEnterEditMode
}: Props) => {
  return (
    <>
      <ModalBody {...styles.modalBody}>
        <VStack {...styles.radioOptions}>
          <HStack {...styles.centerAlign}>
            <Icons fill={colors.purple} type="currentRevision" />
            <HStack {...styles.autoLinkText}>
              <Text {...styles.boldText}>Auto-select all revisions is</Text>
              <Text
                {...styles.boldText}
                color={autoLink ? "unifize_darkGreen" : "unifize_inkLightest"}
              >
                {autoLink ? "enabled" : "disabled"}
              </Text>
            </HStack>
          </HStack>

          <CheckboxGroup value={selectedVersions}>
            {versions.map(version => (
              <Checkbox
                key={version.id}
                value={version.id}
                isDisabled
                {...styles.radio}
              >
                <Text {...styles.normalText}>
                  {processTitle} {`#${version.autoNo || version.seqNo || ""}`}
                  {version.currentVersion && "C"}: {version.title}
                </Text>
                <Text {...styles.versionComment}>{version.versionComment}</Text>
              </Checkbox>
            ))}
          </CheckboxGroup>
        </VStack>
      </ModalBody>

      <ModalFooter {...styles.modalFooter}>
        <Button onClick={onEnterEditMode} {...styles.editButton}>
          Edit selected revision(s)
        </Button>
      </ModalFooter>
    </>
  );
};

export default Viewer;
