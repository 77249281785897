// @flow

import styled from "@emotion/styled";

import {
  AccordionSection as StyledAccordionSection,
  AccordionTitle as StyledAccordionTitle,
  StatusType as StyledStatusType,
  AccordionContent as StyledAccordionContent
} from "../Accordion/styles";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

const AccordionTitle = styled(StyledAccordionTitle)`
  ${props => props.isFieldInsideSection && "margin-top: 0px"};
  margin-bottom: ${spacing.space_m};
  background-color: ${colors.brightLight};
  border-bottom: 1px solid
    ${props => (props.hasMandatoryFields ? colors.danger : colors.skyDark)};
  position: sticky;
  top: 2em;
  z-index: 1;
`;

const AccordionSection = styled(StyledAccordionSection)`
  margin-top: ${props =>
    props.isFieldInsideSection ? spacing.space_m : spacing.space_s};
`;

const StatusType = styled(StyledStatusType)`
  display: block;
  /* Prevent captalizing letter inside () eg Defect(s) in chrome */
  text-transform: none;
  &::first-letter {
    text-transform: capitalize;
  }
  font-weight: normal;
  color: ${props =>
    props.hasMandatoryFields ? colors.danger : colors.purpleDark};
`;

const AccordionContent = styled(StyledAccordionContent)`
  ${props => props.active && `padding-top: 2px`};
`;

export { AccordionSection, AccordionTitle, StatusType, AccordionContent };
