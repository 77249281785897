// @flow

import { combineReducers } from "redux";
import { Map as ImmutableMap } from "immutable";

import * as atypes from "src/constants/actionTypes";
import type { Action, Prompts, RoomId, DismissedFields } from "src/types";
import * as R from "ramda";

const byRoom = (state: any = new ImmutableMap(), { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_PROMPT_FIELDS:
      return state.merge(ImmutableMap(payload.promptFields));
    case atypes.REMOVE_PROMPT_FIELD:
      return state.set(`${payload.roomId}`, {
        id: state.get(`${payload.roomId}`).id,
        fields: R.without(
          [payload.fieldId],
          state.get(`${payload.roomId}`).fields
        )
      });
    default:
      return state;
  }
};

const dismissed = (
  state: DismissedFields = new ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.DISMISS_PROMPT_SUCCESS:
      return state.mergeWith((oldVal, newVal) => newVal, payload);
    case atypes.UNDO_DISMISS_PROMPT_SUCCESS:
      return state.delete(payload);
    case atypes.SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS:
      if (state.hashCode() !== ImmutableMap(payload.dismissed).hashCode()) {
        return state.merge(payload.dismissed);
      }
      return state;
    default:
      return state;
  }
};

const prompts = combineReducers({
  byRoom,
  dismissed
});

export default prompts;

export const getCurrentPromptFields = (state: Prompts, roomId: RoomId) =>
  state.byRoom.get(`${roomId}`);

export const getDismissed = (state: Prompts, room: RoomId) =>
  state.dismissed.get(room);
