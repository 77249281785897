// @flow

import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import type {
  ApprovalFieldSettingsV2,
  FieldId,
  RoomId,
  ChecklistValue,
  ApprovalFieldValue,
  DataStage,
  UID
} from "src/types";
import { getMinApprovers } from "src/utils/checklist";
import { approvalFieldStatuses } from "src/constants";
import {
  getAllowedSignatories,
  getCurrentUserId,
  getAllowedCancellers,
  getAllSignatories
} from "src/reducers";
import { dataStages } from "src/constants";

import { VStack } from "@chakra-ui/react";
import Card from "./Card";
import CancelCard from "./CancelCard";
import CancelButton from "./CancelButton";
import SignatureButtonGroup from "./SignatureButtonGroup";
import RequestApprovalButton from "./RequestApprovalButton";
import * as styles from "./styles";

export type HandleCreateRevision = (comment: ?string) => void;

type Props = {
  fieldId: FieldId,
  roomId: RoomId,
  settings: ApprovalFieldSettingsV2,
  checklistValue: ChecklistValue & {
    value: ApprovalFieldValue
  },
  setFieldValue: Function,
  locked: boolean,
  valueStatus: ?DataStage
};

export default function ApprovalV2({
  fieldId,
  roomId,
  settings,
  checklistValue,
  locked,
  setFieldValue,
  valueStatus
}: Props) {
  const updating = valueStatus === dataStages.updating;
  const { value } = checklistValue || {
    value: {
      lockChecklistFields: false,
      adhocConfig: null,
      config: null,
      completed: false,
      revisions: [],
      status: "not-started"
    }
  };

  const currentUserId = useSelector(state => getCurrentUserId(state.app));
  const requiredSignatures = getMinApprovers(settings, value);
  const allowedSignatories = useSelector(state =>
    getAllowedSignatories(state.app, fieldId, roomId)
  );
  const allSignatories = useSelector(state =>
    getAllSignatories(state.app, fieldId)
  );
  const allowedCancellers = useSelector(state =>
    getAllowedCancellers(state.app, fieldId, roomId)
  );

  const canCurrentUserCancel =
    settings.allowedCancellers === "everyone"
      ? true
      : allowedCancellers.includes(currentUserId);

  const disabled = getDisabledState({
    value,
    allowedSignatories,
    canCurrentUserCancel,
    locked,
    currentUserId
  });

  const lastApprovalSignatureCount = getLastApprovalSignatureCount(value);

  const handleCreateRevision: HandleCreateRevision = comment => {
    let params = {
      revision: true
    };

    if (comment) {
      params = { ...params, comment };
    }

    setFieldValue({ value: params });
  };

  if (value.status === approvalFieldStatuses.notStarted) {
    if (settings.showRequestApprovalButton)
      return (
        <RequestApprovalButton
          settings={settings}
          setFieldValue={setFieldValue}
          disabled={locked}
          signatories={allSignatories}
          updating={updating}
        />
      );

    return (
      <VStack sx={styles.container}>
        <SignatureButtonGroup
          bordered
          hasUserSigned={false}
          done={0}
          total={requiredSignatures}
          handleSign={setFieldValue}
          disabled={disabled}
          signatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          settings={settings}
        />

        {settings.canCancelPartialApproval &&
          settings.showRequestApprovalButton && (
            <CancelButton
              createRevision={handleCreateRevision}
              disabled={!canCurrentUserCancel}
              updating={updating}
              isCommentRequired={settings.requireCancellationComment}
              signatureCount={lastApprovalSignatureCount}
            />
          )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.started) {
    return (
      <VStack sx={styles.container}>
        <Revisions
          value={value}
          settings={settings}
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.canCancelPartialApproval && (
          <CancelButton
            createRevision={handleCreateRevision}
            disabled={!canCurrentUserCancel}
            updating={updating}
            isCommentRequired={settings.requireCancellationComment}
            signatureCount={lastApprovalSignatureCount}
          />
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.approved) {
    return (
      <VStack sx={styles.container}>
        <Revisions
          value={value}
          settings={settings}
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.allowCancelOnApproval && (
          <CancelButton
            createRevision={handleCreateRevision}
            disabled={!canCurrentUserCancel}
            updating={updating}
            isCommentRequired={settings.requireCancellationComment}
            signatureCount={lastApprovalSignatureCount}
          />
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.rejected) {
    return (
      <VStack sx={styles.container}>
        <Revisions
          value={value}
          settings={settings}
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.showRequestApprovalButton ? (
          <RequestApprovalButton
            settings={settings}
            setFieldValue={setFieldValue}
            disabled={locked}
            signatories={allSignatories}
            updating={updating}
          />
        ) : (
          <>
            <SignatureButtonGroup
              bordered
              hasUserSigned={false}
              done={0}
              total={requiredSignatures}
              handleSign={setFieldValue}
              disabled={disabled}
              signatories={allowedSignatories}
              roomId={roomId}
              fieldId={fieldId}
              settings={settings}
            />

            {settings.canCancelPartialApproval &&
              settings.showRequestApprovalButton && (
                <CancelButton
                  createRevision={handleCreateRevision}
                  disabled={!canCurrentUserCancel}
                  updating={updating}
                  isCommentRequired={settings.requireCancellationComment}
                  signatureCount={lastApprovalSignatureCount}
                />
              )}
          </>
        )}
      </VStack>
    );
  }

  if (value.status === approvalFieldStatuses.cancelled) {
    return (
      <VStack sx={styles.container}>
        <Revisions
          value={value}
          settings={settings}
          setFieldValue={setFieldValue}
          allowedSignatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />

        {settings.showRequestApprovalButton && (
          <RequestApprovalButton
            settings={settings}
            setFieldValue={setFieldValue}
            disabled={locked}
            signatories={allSignatories}
            updating={updating}
          />
        )}
      </VStack>
    );
  }

  return null;
}

type RevisionsProps = {
  value: ApprovalFieldValue,
  settings: $PropertyType<Props, "settings">,
  setFieldValue: $PropertyType<Props, "setFieldValue">,
  allowedSignatories: UID[],
  roomId: $PropertyType<Props, "roomId">,
  fieldId: $PropertyType<Props, "fieldId">,
  disabled: boolean
};

const Revisions = ({
  value,
  settings,
  setFieldValue,
  allowedSignatories,
  roomId,
  fieldId,
  disabled
}: RevisionsProps) =>
  value.revisions.map(revision => (
    <>
      {revision.approvals.map(approval => (
        <Card
          key={`${revision.revisionId}-${approval.id}`}
          id={`${revision.revisionId}-${approval.id}`}
          settings={settings}
          value={value}
          completed={value.completed}
          approval={approval}
          deprecated={revision.deprecated}
          handleSign={setFieldValue}
          signatories={allowedSignatories}
          roomId={roomId}
          fieldId={fieldId}
          disabled={disabled}
        />
      ))}

      {revision.deprecated &&
        revision.deprecatedBy &&
        revision.deprecatedAt && (
          <CancelCard
            author={revision.deprecatedBy}
            comment={revision.comment}
            date={revision.deprecatedAt}
          />
        )}
    </>
  ));

const getDisabledState = ({
  value,
  allowedSignatories,
  canCurrentUserCancel,
  locked,
  currentUserId
}) => {
  if (value.status === approvalFieldStatuses.approved)
    return locked || !canCurrentUserCancel;

  return locked || !allowedSignatories.includes(currentUserId);
};

const getLastApprovalSignatureCount = (value: ApprovalFieldValue) => {
  const lastRevision = R.last(value.revisions);

  if (!lastRevision) return 0;

  const lastApproval = R.last(lastRevision.approvals);

  if (!lastApproval) return 0;

  return lastApproval.signatures.length;
};
