// @flow

import React, { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";
import {
  IconButton,
  Input,
  Tag,
  TagLabel,
  useDisclosure,
  Text,
  Box,
  Flex
} from "@chakra-ui/react";
import * as R from "ramda";

import { fieldTagStyles, inputFieldStyles } from "../styles";
import OldIcon from "src/components/InlineSVG";
import type { UID, ChecklistBuilderField } from "src/types";
import DeleteFieldModal from "../DeleteFieldModal";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import {
  checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import ParentConversation from "../ParentConversation";
import AddFieldModal from "../AddFieldModal";
import Tooltip from "src/components/Tooltip";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";
import * as styles from "./styles";

type Props = {
  position: number,
  lastOrg: number,
  value: ChecklistBuilderField,
  updateField: Function,
  removeField: Function,
  openSettings: Function,
  openPromptRules: Function,
  currentUserId: UID,
  toggleConditions: Function,
  setWorkflowBuiderAttributes: Function,
  setChecklistBuilderAttribute: Function,
  addFields: Function
};

const DragHandle = SortableHandle(() => (
  <IconButton
    display="flex"
    variant="unstyled"
    aria-label="Drag Handle"
    icon={<Icon type="dragIndicator" />}
  />
));

const settingsTab = {
  fieldSettings: "field-settings",
  conditions: "conditions",
  prompts: "prompts"
};

const Field = ({
  position,
  value,
  updateField,
  removeField,
  addFields,
  openSettings,
  toggleConditions,
  setWorkflowBuiderAttributes,
  setChecklistBuilderAttribute
}: Props) => {
  const [hover, setHover] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    position: null
  });
  const [fieldType, setFieldType] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onFieldCardPress = type => {
    setFieldType(type);
  };

  const addFieldHandler = params => {
    try {
      if (fieldType) {
        addFields({
          index: position + 1,
          fields: params?.fields || [{ label: "", fieldType }]
        });
      }

      setFieldType(null);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const closeAddFieldModal = () => {
    setFieldType(null);
    onClose();
  };

  const mouseEnterHandler = () => {
    setHover(true);
  };

  const mouseLeaveHandler = () => {
    setHover(false);
  };

  const toggleHideField = () => {
    updateField(!value.hidden, position, "hidden");
  };

  const saveSettings = (settings: Object) => {
    updateField(settings || {}, position, "settings");
  };

  // Persist old prompts info so we can restore if user wants to cancel changes
  const saveOldSettings = () => {
    const { promptRules } = value;

    setChecklistBuilderAttribute({
      id: "oldSettings",
      value: {
        promptRules: promptRules ? promptRules : { roles: [], users: [] }
      }
    });
  };

  const removeFieldHandler = (e: any, position: number) => {
    setDeleteModal(prevState => ({
      show: !prevState.show,
      position
    }));
  };

  const toggleDeleteFieldModal = () => {
    setDeleteModal({
      show: false,
      position: NaN
    });
  };

  const onDeletePressHandler = (e: any) => {
    const { position } = deleteModal;

    removeField(e, position);
    toggleDeleteFieldModal();
  };

  const toggleSettings = () => {
    const { label, type } = value;

    saveOldSettings();

    openSettings && openSettings();

    setWorkflowBuiderAttributes({
      checklistFieldSettings: {
        tab: settingsTab.fieldSettings,
        label,
        type,
        position
      }
    });
  };

  const toggleConditionsBuilder = () => {
    const { label, type, settings, id } = value;

    saveOldSettings();

    openSettings && openSettings();

    setWorkflowBuiderAttributes({
      checklistFieldSettings: {
        tab: settingsTab.conditions,
        label,
        type,
        position
      }
    });

    toggleConditions({ settings, position, id });
  };

  const togglePromptsBuilder = () => {
    const { label, type } = value;

    saveOldSettings();
    openSettings && openSettings();

    setWorkflowBuiderAttributes({
      checklistFieldSettings: {
        tab: settingsTab.prompts,
        label,
        type,
        position
      }
    });
  };

  const { label, id, type, settings, hidden, promptRules } = value;

  const conditionsExist = !R.isNil(settings?.conditionBlocks);
  const promptsExist =
    !R.isNil(promptRules) && // Hide prompts icon when promptRules doesn't exist
    (!R.isEmpty(promptRules?.roles) || !R.isEmpty(promptRules?.users));

  // Only if checklist download is allowed, show fieldIds
  // on builder for user
  const checklistDownloadAllowed = useComponentPermission(
    componentPermissions.downloadChecklist
  );

  return (
    <Flex
      sx={styles.container}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Flex sx={styles.detailsContainer} alignItems="center">
        <IconButton
          display="flex"
          opacity={hover ? 1 : 0}
          variant="unstyled"
          aria-label="Add new field"
          onClick={onOpen}
          icon={<OldIcon icon="hollowPlusCircle" color={colors.blue500} />}
        />

        <DragHandle />

        <Box sx={styles.positionLabelWrapper}>
          <Text sx={styles.positionLabel}>{position + 1}</Text>
        </Box>

        <Tag
          size="md"
          sx={{ ...fieldTagStyles, ...styles.checklistFieldTagStyles }}
          variant="solid"
          bgColor={checklistFieldIcons[type].hexCode}
        >
          <Icon
            type={checklistFieldIcons[type].icon}
            width="16px"
            height="16px"
            fill="#fff"
          />
          <TagLabel ml="4px">{checklistFieldNames[type]}</TagLabel>
        </Tag>

        <Input
          type="text"
          value={label}
          sx={{ ...inputFieldStyles, ...styles.checklistInputFieldStyles }}
          placeholder="Field Name"
          onChange={e => updateField(e.target.value, position, "label")}
          required
        />
      </Flex>

      <Flex sx={styles.actionsContainer}>
        <Tooltip title="Settings" placement="right">
          <IconButton
            display="flex"
            variant="unstyled"
            aria-label="Field Settings"
            onClick={toggleSettings}
            icon={<Icon type="gear" />}
          />
        </Tooltip>

        <ParentConversation
          type={type}
          hover={hover}
          settings={settings}
          handleClose={toggleSettings}
          saveSettings={saveSettings}
          index={position}
        />

        <Tooltip title="Prompts" placement="right">
          <IconButton
            display={type !== "form" ? "flex" : "none"}
            opacity={promptsExist ? 1 : hover ? 1 : 0}
            variant="unstyled"
            aria-label="Prompts"
            onClick={togglePromptsBuilder}
            icon={<Icon type="prompts" fill={colors.blue} />}
          />
        </Tooltip>

        <Tooltip title="Conditions" placement="right">
          <IconButton
            display="flex"
            opacity={conditionsExist ? 1 : hover ? 1 : 0}
            variant="unstyled"
            aria-label="Conditions"
            onClick={toggleConditionsBuilder}
            icon={<Icon type="conditions" fill={colors.blue} />}
          />
        </Tooltip>

        <Tooltip title="Show / Hide field" placement="right">
          <IconButton
            display="flex"
            opacity={hidden ? 1 : hover ? 1 : 0}
            variant="unstyled"
            aria-label="Show / Hide Field"
            onClick={toggleHideField}
            icon={
              <Icon
                type="visibilityOff"
                fill={hidden ? colors.blue : colors.inkLightest}
              />
            }
          />
        </Tooltip>

        <Tooltip title="Delete field" placement="right">
          <IconButton
            display="flex"
            opacity={hover ? 1 : 0}
            variant="unstyled"
            aria-label="Delete Field"
            onClick={e => removeFieldHandler(e, position)}
            icon={<Icon type="deleteTrash" fill={colors.redDark} />}
          />
        </Tooltip>

        <span>{checklistDownloadAllowed ? id : null}</span>
      </Flex>

      <DeleteFieldModal
        label={label}
        isOpen={deleteModal.show}
        onDelete={onDeletePressHandler}
        toggleDeleteFieldModal={toggleDeleteFieldModal}
      />
      <AddFieldModal
        isOpen={isOpen}
        onClose={closeAddFieldModal}
        onFieldCardPress={onFieldCardPress}
        onSave={addFieldHandler}
        fieldType={fieldType}
      />
    </Flex>
  );
};

export default Field;
