// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import { SelectionMultiple } from "src/styles/dropdown";

export const Settings = styled.div`
  padding: 15px 12.5px;
  text-align: left;
  background-color: ${colors.brightLight};
  max-width: 25rem;
  margin-bottom: 5.25rem;
`;

export const Label = styled.div`
  font-weight: bold;
  color: ${colors.primary};
  margin-bottom: ${spacing.space_m};
`;

export const FormGroup = styled.div`
  margin-bottom: ${spacing.space_l};
`;

export const Sort = styled(FormGroup)`
  display: flex;
  align-items: center;
  > div {
    margin-left: 0.8em;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: ${spacing.space_l};
  justify-content: space-between;
`;

export const FileSelect = styled.div`
  input {
    margin: 0;
  }
  ${SelectionMultiple} {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.2em;
`;

export const SelectedFormWrapper = styled.div`
  > div {
    margin-bottom: 0.4em;
    padding: 0.5em 0.6em;
  }
`;

export const SectionContainer: SystemCSSProperties = {
  minHeight: "105px"
};

export const SectionWrapper: SystemCSSProperties = {
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap"
};
