// @flow

import type {
  AccordionProps,
  AccordionPanelProps,
  StackProps,
  SystemProps
} from "@chakra-ui/react";

export const accordion: AccordionProps = {
  display: "flex",
  flexDirection: "column",
  gridGap: 4
};

export const accordionPanel: AccordionPanelProps = {
  py: 4,
  px: 3,
  display: "flex",
  flexDirection: "column",
  gridGap: 4
};

export const formItem: StackProps = {
  width: "100%",
  alignItems: "start"
};

export const verticalRadioGroupList: StackProps = {
  alignItems: "start"
};

export const numberInput: SystemProps = {
  mx: 1,
  my: 0,
  maxW: "47px"
};

export const numberInputField: SystemProps = {
  maxHeight: "24px"
};
