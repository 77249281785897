// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import { CloseButton } from "src/components/Manage/Workflow/Instances/styles";

export const Loader = styled.div`
  position: relative;
  > img {
    display: block;
    margin: 2em auto;
    width: 28%;
  }
  > h3 {
    position: absolute;
    top: 50%;
    left: 45%;
  }
`;

export const AdditionalFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -1.2em;
  > div {
    background-color: ${colors.yellow};
    > span {
      color: ${colors.primary};
    }
  }

  ${CloseButton} {
    > svg > g {
      stroke: ${colors.primary};
    }
  }
`;

export const IconButton: SystemCSSProperties = {
  bg: "none",
  h: "1rem",
  w: "1rem",
  minW: "auto",
  _hover: {
    bg: "none"
  },
  _active: {
    bg: "none"
  }
};

export const ChartHeader: SystemCSSProperties = {
  alignItems: "center",
  mt: "0.5rem",
  ml: "0.5rem"
};

export const ChartTitle: SystemCSSProperties = {
  fontSize: "1.5rem",
  lineHeight: "2rem",
  fontWeight: "700",
  my: 0
};

export const ChartDiscription: SystemCSSProperties = {
  my: 0,
  ml: "1rem",
  fontSize: "14px",
  lineHeight: "20px"
};

export const ChartActionIconsWrapper: SystemCSSProperties = {
  alignItems: "center",
  ml: "auto",
  gap: "1rem"
};
