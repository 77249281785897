// @flow

import * as atypes from "src/constants/actionTypes";
import type { UID, Action } from "src/types";

const continueToApp = (): Action => ({
  type: atypes.CONTINUE_TO_APP,
  payload: {}
});

const setCurrentUser = (payload: { name: string, email: string }): Action => ({
  type: atypes.SET_CURRENT_USER,
  payload
});

const setCurrentUserEmail = (email: string): Action => ({
  type: atypes.SET_SIGN_IN_EMAIL,
  payload: { email }
});

const setResetCode = (resetCode: string): Action => ({
  type: atypes.SET_RESET_CODE,
  payload: {
    resetCode
  }
});

const resetPassword = ({
  email,
  password
}: {
  email: string,
  password: string
}): Action => ({
  type: atypes.RESET_PASSWORD_REQUEST,
  payload: { email, password }
});

const resetPasswordFailed = (error: any): Action => ({
  type: atypes.RESET_PASSWORD_FAILURE,
  payload: error
});

const authRequest = ({
  email,
  password
}: {
  email: string,
  password: string
}): Action => ({
  type: atypes.API_AUTH_REQUEST,
  payload: { email, password }
});

const authenticationFailed = (error: Object): Action => ({
  type: atypes.API_AUTH_FAILURE,
  payload: error
});

const forgotPassword = (email: string): Action => ({
  type: atypes.FORGOT_PASSWORD_REQUEST,
  payload: { email }
});

const forgotPasswordFailed = (error: Object): Action => ({
  type: atypes.FORGOT_PASSWORD_FAILURE,
  payload: error
});

const signin = () => ({
  type: atypes.SIGN_IN,
  payload: {}
});

const resetSent = () => ({
  type: atypes.SIGN_IN,
  payload: {
    page: "reset-sent"
  }
});

const resetSuccess = () => ({
  type: atypes.SIGN_IN,
  payload: {
    page: "reset-success"
  }
});

const clearAllErrors = () => ({
  type: atypes.CLEAR_SIGN_IN_ERRORS,
  payload: {}
});

const loginAs = (uid: UID) => ({
  type: atypes.LOGIN_AS_REQUEST,
  payload: { uid }
});

const loadConversations = () => ({
  type: atypes.LOAD_CONVERSATIONS,
  payload: {}
});

const clearSignupDetails = () => ({
  type: atypes.CLEAR_SIGNUP_DETAILS,
  payload: {}
});

export const userSignup = (email: string) => ({
  type: atypes.USER_SIGNUP_REQUEST,
  payload: {
    email
  }
});

export const changePassword = (newPassword: string) => ({
  type: atypes.CHANGE_PASSWORD_REQUEST,
  payload: {
    newPassword
  }
});

export const getAuthProvider = (email: string) => ({
  type: atypes.GET_AUTH_PROVIDER_REQUEST,
  payload: {
    email
  }
});

export const sendVerificationEmail = ({
  email,
  resendEmailInvite
}: {
  email: string,
  resendEmailInvite?: boolean
}) => ({
  type: atypes.SEND_VERIFICATION_EMAIL_REQUEST,
  payload: {
    email,
    resendEmailInvite
  }
});

export const clearAuthProvider = () => ({
  type: atypes.CLEAR_AUTH_PROVIDER,
  payload: {}
});

export {
  authRequest,
  authenticationFailed,
  setCurrentUserEmail,
  clearAllErrors,
  setCurrentUser,
  forgotPassword,
  forgotPasswordFailed,
  resetPassword,
  resetPasswordFailed,
  continueToApp,
  signin,
  resetSent,
  resetSuccess,
  setResetCode,
  loginAs,
  loadConversations,
  clearSignupDetails
};
