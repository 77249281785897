// @flow

import React from "react";
import { useSelector } from "react-redux";

import File from "./File";
import {
  CaretUp,
  Count,
  FileWrapper,
  MultiContainer,
  MultiTable,
  StyledMultiField
} from "./styles";
import { FILE_PREVIEW_LENGTH } from "src/constants/manageView";
import { EditIconWrapper } from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import { getFieldWidth } from "src/reducers";
import Icon from "src/icons";
import useRowExpansion from "src/hooks/useRowExpansion";
import type {
  WorkflowInstances,
  ColumnId,
  FieldId,
  UnifizeFile
} from "src/types";

type Props = {
  process: WorkflowInstances,
  autoNo: string,
  columnId: ColumnId,
  selected: boolean,
  value: Array<Array<UnifizeFile>>,
  rowIndex: number,
  openChecklistEditModal: Function,
  fieldId: FieldId,
  roomId: string,
  hideEdit?: boolean,
  type: string,
  embeddedIndex?: number
};

const MultipleFiles = ({
  autoNo,
  columnId,
  value,
  rowIndex,
  openChecklistEditModal,
  fieldId,
  roomId,
  hideEdit,
  embeddedIndex,
  selected
}: Props) => {
  const { toggleExpand, isRowExpanded } = useRowExpansion(
    columnId,
    autoNo,
    roomId
  );
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));

  return (
    <MultiContainer
      selected={selected}
      width={fieldWidth}
      borderRequired={true}
    >
      <MultiTable>
        <tbody>
          {Array.from({
            length: isRowExpanded ? Math.max(value.length, 1) : 1
          }).map((_, fieldIndex) => {
            const fieldValue = value[fieldIndex] || [];
            return (
              <tr key={fieldIndex}>
                <StyledMultiField>
                  <FileWrapper maxWidth={fieldWidth}>
                    {fieldValue
                      .slice(0, FILE_PREVIEW_LENGTH)
                      .map((file, index) => (
                        <File
                          roomId={roomId}
                          file={file}
                          key={file?.name || index}
                        />
                      ))}
                    {!isRowExpanded && value?.length > 1 && (
                      <Count onClick={toggleExpand}>
                        +{fieldValue?.length - 1}
                      </Count>
                    )}
                    {isRowExpanded && embeddedIndex === 0 && (
                      <CaretUp onClick={toggleExpand}>
                        <Icon type="caretUp" />
                      </CaretUp>
                    )}
                    {!hideEdit && (
                      <EditIconWrapper
                        onClick={e => {
                          openChecklistEditModal(
                            e,
                            rowIndex,
                            fieldId,
                            roomId,
                            fieldValue,
                            columnId,
                            embeddedIndex
                          );
                        }}
                      >
                        <Icon type="editPencil" />
                      </EditIconWrapper>
                    )}
                  </FileWrapper>
                </StyledMultiField>
              </tr>
            );
          })}
        </tbody>
      </MultiTable>
    </MultiContainer>
  );
};

export default MultipleFiles;
