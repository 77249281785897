// @flow

import React from "react";

import FormName from "src/components/Form/Name";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import FieldName from "src/containers/checklist/FieldName";
import { CloseIconStyle, ListItemText } from "./styles";

type Props = {
  value: Array<string>,
  disable: ?boolean,
  handleSelect: Function,
  toggleDropdown: Function,
  isForm: ?boolean,
  fieldData?: Object,
  isLoading?: boolean
};

const Selection = ({
  value,
  disable,
  handleSelect,
  toggleDropdown,
  isForm,
  fieldData = {},
  isLoading = false
}: Props) => {
  const handleRemove = (event: any) => {
    event.stopPropagation();
    handleSelect(null);
  };
  const handleKeydown = (event: any) => {
    event.stopPropagation();
    if (event.key === "Delete" || event.key === "Backspace") {
      handleSelect(null);
    } else if (!disable) {
      toggleDropdown();
    }
  };

  if (value) {
    return (
      <Flex
        size="sm"
        tabIndex="0"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="2px"
        p={2}
        h={8}
        justifyContent="space-between"
        backgroundColor={disable ? "gray.100" : "white"}
        alignItems="center"
        type="button"
        cursor="pointer"
        fontSize="sm"
        onClick={e => !disable && toggleDropdown(e)}
        onKeyDown={handleKeydown}
        role="select"
        _focus={{
          borderColor: "blue.400",
          boxShadow: "xs"
        }}
      >
        {isLoading && <Spinner size="xs" color="unifize_inkLightest" />}
        <span>
          {isForm ? (
            <Flex>
              <FormName id={fieldData.id} />
              {fieldData.linkedOrFormFieldId && (
                <Text sx={ListItemText} color="unifize_inkLightest">
                  (FIELD: <FieldName id={fieldData.linkedOrFormFieldId} />)
                </Text>
              )}
            </Flex>
          ) : (
            <Text sx={ListItemText}>{fieldData && fieldData.name}</Text>
          )}
        </span>
        <CloseIcon sx={CloseIconStyle} onClick={handleRemove} />
      </Flex>
    );
  }

  return (
    <Flex
      size="sm"
      border="1px solid"
      tabIndex="0"
      borderColor="gray.300"
      borderRadius="2px"
      p={2}
      h={8}
      justifyContent="space-between"
      alignItems="center"
      type="button"
      fontSize="sm"
      cursor={disable ? "not-allowed" : "pointer"}
      color={disable ? "gray.400" : "black"}
      backgroundColor={disable ? "gray.100" : "white"}
      onClick={e => !disable && toggleDropdown(e)}
      onKeyDown={handleKeydown}
      role="select"
      _focus={{
        borderColor: "blue.400"
      }}
    >
      <span>Select Field</span>
      <ChevronDownIcon w={4} h={4} />
    </Flex>
  );
};

export default Selection;
