// @flow

import React from "react";

import Field from "../Field";
import {
  FieldPreview as StyledFieldPreview,
  ButtonLink,
  ExpandedField as StyledExpandedField,
  NestedFieldContainer
} from "./styles";
import ChecklistPreview from "./ChecklistPreview";
import Icon from "src/icons";

type Props = {
  fields: Array<number>,
  roomId: number,
  showFieldNames: boolean,
  expand: boolean,
  toggleExpand: Function
};

const NestedFields = ({
  fields,
  roomId,
  showFieldNames,
  expand,
  toggleExpand
}: Props) => {
  if (!expand) {
    return (
      <NestedFieldContainer>
        {showFieldNames && (
          <StyledFieldPreview>
            {fields.map((fieldId: number) => (
              <ChecklistPreview
                key={fieldId}
                fieldId={fieldId}
                roomId={roomId}
              />
            ))}
          </StyledFieldPreview>
        )}
        <ButtonLink type="button" onClick={toggleExpand}>
          View all fields <Icon type="down" />
        </ButtonLink>
      </NestedFieldContainer>
    );
  }
  return (
    <StyledExpandedField>
      {fields.map((fieldId: number) => (
        <Field key={fieldId} id={fieldId} roomId={roomId} />
      ))}
    </StyledExpandedField>
  );
};

export default NestedFields;
