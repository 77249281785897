// @flow
import * as React from "react";
import { Button as StyledButton } from "src/styles/buttons";

type Props = {
  type: string,
  children: React.Node,
  disabled?: boolean,
  handleClick?: Function
};

const Button = ({ type, children, disabled, handleClick }: Props) => (
  <StyledButton type={type} disabled={disabled} onClick={handleClick}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  disabled: false,
  handleClick: () => {}
};

export default Button;
