// @flow

import { useState, useEffect, useCallback } from "react";

import { extractChecklistValue, getSettings } from "src/utils/checklist";
import type { ChecklistValue, ChecklistField } from "src/types";

type Props = {
  checklistValue: ChecklistValue,
  details: ChecklistField
};

const useFields = ({ checklistValue, details }: Props) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue]: any = useState(
    extractChecklistValue(checklistValue)
  );

  const extractedValue = extractChecklistValue(checklistValue);

  useEffect(() => {
    setValue(extractedValue);
  }, [extractedValue]);

  const settings: any = getSettings(details ? details.get("settings") : "");

  const handleChange = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const openEdit = useCallback(() => setEdit(true), []);
  const closeEdit = useCallback(() => setEdit(false), []);

  const increment = useCallback(
    () => setValue(prev => (prev ? parseInt(prev, 10) + 1 : `${0}`)),
    []
  );
  const decrement = useCallback(
    () => setValue(prev => (prev ? parseInt(prev, 10) - 1 : `${0}`)),
    []
  );

  return {
    edit,
    value,
    setValue,
    settings,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement
  };
};

export default useFields;
