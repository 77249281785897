// @flow

import styled from "@emotion/styled";

import { Cell } from "../styles";

export const DueDate = styled(Cell)`
  height: auto;
  position: relative;
  > div {
    justify-content: flex-start;
  }
`;

export const BulkChange = styled(Cell)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  cursor: pointer;
`;
