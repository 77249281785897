// @flow

import React, { useEffect } from "react";
import * as R from "ramda";
import {
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Button
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import {
  getPrincipalChecklistFieldsList,
  getChecklistFieldLabel
} from "src/reducers";
import { chatroomAttributes } from "src/constants/chatroom";
import useBoolean from "src/hooks/useBoolean";
import { fetchForm } from "src/actions/forms";
import SelectForm from "./SelectForm";
import * as styles from "./styles";

import type { FieldMappings } from "src/types";

type Props = {
  mappedFields: FieldMappings,
  localFieldMapping: FieldMappings,
  headerValue: string,
  clickHandler: Function,
  mappedFieldIndex: number
};

const SelectFields = ({
  mappedFields,
  localFieldMapping,
  headerValue,
  clickHandler,
  mappedFieldIndex
}: Props) => {
  const dispatch = useDispatch();
  const selectedFieldId =
    localFieldMapping[headerValue]?.fieldId ||
    mappedFields[headerValue]?.fieldId;

  const allChecklistFields = useSelector(({ app }) =>
    getPrincipalChecklistFieldsList(app)
  );

  const selectedFieldLabel =
    useSelector(({ app }) => getChecklistFieldLabel(app, selectedFieldId)) ||
    R.find(R.propEq("id", selectedFieldId), chatroomAttributes)?.label ||
    "Select Field";

  const {
    value: isChecklistFieldsOpen,
    toggleBoolean: toggleChecklistFields
  } = useBoolean();

  const {
    value: isChatroomAttributesOpen,
    toggleBoolean: toggleChatroomAttributes
  } = useBoolean();

  const formId = (localFieldMapping[headerValue] || mappedFields[headerValue])
    ?.checklistId;

  useEffect(() => {
    if (formId) {
      dispatch(fetchForm(formId, false));
    }
  }, [formId]);

  return (
    <Td>
      <Menu isLazy>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {selectedFieldLabel}
            </MenuButton>
            <MenuList sx={styles.Dropdown}>
              <MenuGroup
                onClick={toggleChecklistFields}
                as={Button}
                rightIcon={
                  isChecklistFieldsOpen ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
                title="Checklist Fields"
              >
                {isChecklistFieldsOpen &&
                  allChecklistFields.map(field => {
                    const { id, label, type } = field;
                    return type === "form" ? (
                      <SelectForm
                        key={id}
                        checklistFormId={id}
                        mappedFieldIndex={mappedFieldIndex}
                        clickHandler={clickHandler}
                        formLabel={label}
                      />
                    ) : (
                      <MenuItem
                        key={id}
                        sx={styles.ChecklistFields}
                        onClick={e =>
                          clickHandler({
                            e,
                            formFieldId: null,
                            checklistId: null,
                            mappedFieldIndex
                          })
                        }
                        data-value={id}
                      >
                        {label}
                      </MenuItem>
                    );
                  })}
              </MenuGroup>
              <MenuDivider />
              <MenuGroup
                onClick={toggleChatroomAttributes}
                as={Button}
                rightIcon={
                  isChatroomAttributesOpen ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
                title="Chatroom Attributes"
              >
                {chatroomAttributes.map(
                  field =>
                    isChatroomAttributesOpen && (
                      <MenuItem
                        key={field.id}
                        sx={styles.ChecklistFields}
                        onClick={e =>
                          clickHandler({
                            e,
                            formFieldId: null,
                            checklistId: null,
                            mappedFieldIndex
                          })
                        }
                        data-value={field.id}
                      >
                        {field.label}
                      </MenuItem>
                    )
                )}
              </MenuGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Td>
  );
};

export default SelectFields;
