// @flow
import * as colors from "src/styles/constants/colors";
import {
  ModalContentProps,
  ModalHeaderProps,
  ModalBodyProps,
  TextProps,
  TextareaProps,
  StackProps,
  ButtonProps,
  IconButtonProps
} from "@chakra-ui/react";

export const modalContent: ModalContentProps = {
  borderRadius: "4px",
  width: "325px",
  height: "536px"
};

export const modalHeader: ModalHeaderProps = {
  fontWeight: 700,
  fontSize: "xl",
  bgColor: "unifize_grey0",
  p: 4,
  height: "56px",
  m: 0,
  borderTopRadius: "4px"
};

export const modalBody: ModalBodyProps = {
  display: "flex",
  flexDirection: "column",
  gridGap: 4,
  alignItems: "start",
  px: 4,
  width: "100%"
};

export const metadataList: StackProps = {
  alignItems: "start",
  spacing: 2,
  width: "100%"
};

export const label1: TextProps = {
  my: 0,
  mt: 2,
  fontWeight: 600,
  fontSize: "16px"
};

export const label2: TextProps = {
  my: 0,
  fontWeight: 400,
  fontSize: "14px"
};

export const textarea: TextareaProps = {
  fontSize: "14px",
  px: 2,
  resize: "none",
  minHeight: "60px",
  height: "60px"
};

export const modalFooter: StackProps = {
  px: 4,
  py: 2,
  pb: 6,
  spacing: 4
};

export const commentContainer: StackProps = {
  width: "100%",
  alignItems: "start",
  spacing: 1
};

export const link: ButtonProps = {
  variant: "link",
  bg: "transparent",
  color: "unifize_active",
  _focus: {
    boxShadow: null
  }
};

export const backIcon: IconButtonProps = {
  bgColor: "transparent",
  mr: 4
};

export const fieldContainer = ({ scrollContent }: Object): StackProps => ({
  alignItems: "start",
  width: "100%",
  justifyContent: "space-between",
  maxHeight: scrollContent ? "400px" : "100%"
});

export const revisionList = ({ scrollContent }: Object): StackProps => ({
  alignItems: "start",
  width: "100%",
  maxHeight: scrollContent ? "100%" : "100%",
  paddingRight: scrollContent ? "1rem" : 0
});

export const createVersion = ({ scrollContent }: Object): StackProps => ({
  rootProps: {
    width: "100%",
    paddingRight: scrollContent ? "1rem" : 0
  },
  width: "100%"
});

export const RevisionButton: StackProps = {
  overflow: "hidden",
  cursor: "pointer",
  padding: "0.25rem",
  borderRadius: "0.25rem",
  _hover: {
    background: colors.grey35
  }
};
