// @flow

import styled from "@emotion/styled";
import { SystemCSSProperties } from "@chakra-ui/react";

import { colors as colorsOld, spacing, fontSize } from "./constants";
import * as colors from "./constants/colors";
import logo from "src/img/unifize-logo.svg";
import requestSentLogo from "src/img/email-verified.svg";
import * as radius from "src/styles/constants/radius";

// common
export const FlexColoumProvider = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const FlexSpaceProvider = styled.div`
  height: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const FlexColumnCentered = styled(FlexColoumProvider)`
  align-items: center;
`;

export const ErrorText = styled.div`
  color: ${colorsOld.red};
  font-size: ${fontSize.t3};
  line-height: 1.5;
  font-weight: 600;
  margin: 4px 0;
`;

export const InfoText = styled.div`
  color: ${colorsOld.black};
  font-size: ${fontSize.t3};
  font-family: Lato;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  min-height: 20px;
  letter-spacing: normal;
  width: auto;
`;

export const AuthInfoText = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: ${fontSize.t2};
  line-height: 20px;
  font-weight: 400;
`;

export const Link = styled.div`
  color: ${colorsOld.blue};
  font-size: ${fontSize.t3};
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
`;

export const ForgotPasswordLink = styled(Link)`
  display: flex;
  justify-content: end;
  margin-top: 0.5rem;
`;

export const ResendLink = styled(Link)`
  text-decoration: underline;
  font-size: ${fontSize.t3};
  line-height: 16px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: ${fontSize.t3};
  margin-top: 16px;
  line-height: 1.5;
  letter-spacing: normal;
`;

export const ContainerLeftAligned = styled(Container)`
  align-items: flex-start;
  margin: ${spacing.spacing0} ${spacing.spacing2};
  flex-flow: column;
  min-height: 36px;
`;

export const AuthDiv = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 328px;
  height: 357px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${colorsOld.loginFormBoxShadowColor};
  background-color: ${colorsOld.pureWhite};
  width: auto;
  height: auto;
`;

// Page
export const FormWrapper = styled.div`
  background: ${colorsOld.backgroundWithGradient};
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

// The Container
export const OuterWithLogoAndNav = styled(FlexColoumProvider)`
  min-width: 328px;
  min-height: 500px;
  height: auto;
  width: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

// Logo
export const LogoWrapper = styled.div`
  padding: ${spacing.spacing4} 0;
  height: 96px;
  display: flex;
  justify-content: center;
  background: transparent;
`;

export const UnifizeLogo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  width: 126px;
  height: 32px;
`;

export const RequestSentImage = styled.div`
  background-image: url(${requestSentLogo});
  width: 82.9px;
  height: 54.9px;
`;

export const RequestSentText = styled.p`
  font-weight: 600;
  margin-bottom: 0;
`;

// Form
export const AuthForm = styled.form`
  display: flex;
  flex-flow: column;
  max-width: 328px;
  width: 100%;
  height: 357px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${colorsOld.loginFormBoxShadowColor};
  background-color: ${colorsOld.pureWhite};
  width: auto;
  height: auto;
`;

export const ForgotPasswordForm = styled(AuthForm)`
  padding: 1rem;
`;

export const AuthFormHeader = styled.div`
  height: 51px;
  background: ${colorsOld.aliceBlue};
  color: ${colorsOld.mediumBlack};
  border-radius: 4px;
  text-align: left;
  font-size: ${fontSize.t1};
  width: inherit;
  padding: ${spacing.spacing2};
  padding-right: 0;
  font-weight: 600;
  margin-bottom: ${spacing.spacing0};
`;

export const AuthHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const InputAndLabelWrapper = styled(FlexColoumProvider)`
  margin: ${spacing.spacing0} ${spacing.spacing2};
  text-align: left;
  width: auto;
`;

export const FormLabel = styled(FlexSpaceProvider)`
  text-align: left;
  min-height: 20px;
`;

export const FormInput = styled.input`
  border-radius: 2px;
  padding: 7px 0 7px 8px;
  border: 1px solid ${colorsOld.grey};
  font-size: ${fontSize.t3};
  line-height: 1.5;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colorsOld.silver};
    opacity: 1; /* Firefox */
  }
`;

export const ForgotPasswordFormInput = styled(FormInput)`
  width: 100%;
  margin-top: 1rem;
`;

// Error Message
export const ErrorMessageWrapper = styled(FlexColoumProvider)`
  margin: 0.5rem;
  padding: ${spacing.spacing0} 0;
  text-align: center;
  width: auto;
  max-height: 52px;
  height: auto;
  background: ${colorsOld.aliceBlue};

  & > div {
    margin: 0;
  }
`;

// Button
export const Button = styled.button`
  margin: ${spacing.spacing2};
  margin-top: ${spacing.spacing0};
  flex-grow: 1;
  border-radius: 2px;
  box-shadow: 0 -1px 2px 0 var(--black-12);
  background-color: ${colorsOld.blue};
  font-size: ${fontSize.t2};
  color: ${colorsOld.pureWhite};
  font-weight: bold;
  padding: ${spacing.spacing0};
  text-align: center;
  border: 0;
  cursor: pointer;
  &:disabled {
    background-color: ${colorsOld.grey};
  }
  :focus,
  :active {
    box-shadow: 0 0 5px ${colors.active};
    outline-color: ${colors.active};
  }
`;

// Text Container
export const TextContainer = styled(Container)`
  min-height: 20px;
  padding-bottom: 4px;
`;

export const ErrorTextContainer = styled(TextContainer)`
  justify-content: center;
`;

export const ParaContainer = styled(Container)`
  margin-bottom: ${spacing.spacing0};
`;

// Nav
export const NavWrapper = styled.div`
  margin: 1rem;
  background: transparent;
  color: ${colorsOld.pureWhite};
  display: flex;
  justify-content: center;
`;

export const NavLink = styled.a`
  color: ${colorsOld.pureWhite};
  margin: 4px ${spacing.spacing0};
  font-weight: 500;
  cursor: pointer;
  font-size: ${fontSize.t2};
  line-height: 1;
`;

export const NavDelimiter = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${colorsOld.pureWhite};
  margin: ${spacing.spacing0} 0;
`;

export const PasswordLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Loader = styled.div`
  border: 0.3em solid rgba(231, 232, 236, 0.4);
  border-radius: 50%;
  border-top: 0.3em solid ${colorsOld.pureWhite};
  width: 51.2px;
  height: 51.2px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: 18.2px;

  ${props => props.color && `border-top: 0.3em solid ${props.color};`};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadText = styled.div`
  color: ${colorsOld.pureWhite};
  margin: 4px ${spacing.spacing0};
  font-weight: 500;
  font-size: ${fontSize.t2};
  height: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
`;

export const EmailSent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 0;
`;

export const AuthContainer: SystemCSSProperties = {
  bg: colors.brightLight,
  p: 4,
  width: "20.5rem",
  borderRadius: radius.medium
};

export const AuthButton: SystemCSSProperties = {
  mt: 4,
  w: "100%",
  borderRadius: radius.small,
  "&:hover": {
    boxShadow: `0 0 5px ${colors.blue1}`
  },
  "&:disabled": {
    border: `1px solid ${colors.active}`,
    color: colors.brightLight
  }
};

export const AuthButtonSecondary: SystemCSSProperties = {
  ...AuthButton,
  backgroundColor: colors.brightLight,
  border: `1px solid ${colors.active}`,
  color: colors.active,
  "&:hover": {
    backgroundColor: colors.blue0
  }
};

export const AuthInput: SystemCSSProperties = {
  borderRadius: radius.small,
  border: `1px solid ${colors.grey4}`,
  p: "0.375rem 0.5rem",
  height: "32px",
  fontSize: fontSize.t2
};

export const flexCenter: SystemCSSProperties = {
  ...AuthContainer,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "1rem"
};

export const LinkButton: SystemCSSProperties = {
  border: "none",
  bg: "transparent",
  color: colors.active,
  textDecoration: "underline",
  "&:hover": {
    bg: "transparent"
  }
};
