// @flow

import moment from "moment";
import React, { useCallback } from "react";
import * as R from "ramda";
import Linkify from "react-linkify";

import { Text } from "@chakra-ui/react";
import AddData from "./AddData";
import useEditHover from "./useEditHover";
import { DisplayContainer, Display, IconContainer } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Icon from "src/icons";

type Props = {
  edit: boolean,
  update: Function,
  children: any,
  value: string | number,
  type: string,
  openEdit: Function,
  isSelect?: boolean,
  clickToEdit?: boolean,
  disabled: boolean,
  disableAdd?: boolean,
  isMandatory?: boolean
};

const Single = ({
  edit,
  update,
  children,
  value,
  openEdit,
  type,
  isSelect,
  clickToEdit,
  disabled,
  disableAdd,
  isMandatory
}: Props) => {
  const { hover, handleMouseEnter, handleMouseLeave } = useEditHover();
  const handleClick = useCallback(() => {
    if (disabled) return;
    if (clickToEdit) openEdit();
  }, [clickToEdit, disabled]);

  const formattedValue = String(
    type === "date" && value ? moment(value).format("MMM DD, YYYY") : value
  );

  const componentDecorator = useCallback(
    (href, text, key) => (
      <a
        href={href}
        key={key}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {text}
      </a>
    ),
    []
  );

  if (edit)
    return (
      <OutsideClickHandler onClickOutside={update}>
        {children}
      </OutsideClickHandler>
    );

  if (R.isNil(value) || R.isEmpty(formattedValue)) {
    if (disableAdd) return null;

    return (
      <AddData
        disabled={disabled}
        type={type}
        handleClick={openEdit}
        isSelect={isSelect}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <DisplayContainer
      isText={type === "text"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Display
        disabled={disabled}
        clickToEdit={clickToEdit}
        onClick={handleClick}
        isText={type === "text"}
        isMandatory={isMandatory}
      >
        <Linkify componentDecorator={componentDecorator}>
          <Text m={0} noOfLines={type === "select" ? 1 : null}>
            {formattedValue}
          </Text>
        </Linkify>
        <IconContainer>
          {!disabled && hover && (
            <Icon type="checklistEdit" handleClick={openEdit} />
          )}
        </IconContainer>
      </Display>
    </DisplayContainer>
  );
};

Single.defaultProps = {
  isSelect: false,
  clickToEdit: false
};

export default Single;
