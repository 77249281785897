export const getTooltipText = users =>
  `Only these users are allowed to create a revision:\n\n${users.join("\n")}`;

export const getFilteredFields = (fieldsBySection, allFieldsById) => {
  const forbiddenFieldTypes = ["section", "subSection"];
  let filteredFields = [];
  fieldsBySection.forEach(item => {
    if (typeof item !== "object") {
      if (
        !forbiddenFieldTypes.includes(
          allFieldsById.get(String(item)).get("type")
        )
      ) {
        filteredFields = filteredFields.concat([item]);
      }
    } else {
      filteredFields = filteredFields.concat([
        ...getFilteredFields(item.fields, allFieldsById)
      ]);
    }
  });

  return filteredFields;
};

export const metaDataLabels = {
  title: "Title",
  owner: "Owner",
  members: "Participants",
  dueDate: "Due Date",
  files: "Files",
  messages: "All comments and messages"
};

const defaultUncheckedMetaData = ["messages"];

export const getMetaDataInitialState = () => {
  const initialState = {};

  Object.keys(metaDataLabels).forEach(key => {
    initialState[key] = !defaultUncheckedMetaData.includes(key);
  });

  return initialState;
};

export const getChecklistFieldsToCopyInitialState = (
  fields,
  defaultCopyableFields,
  fieldsById
) => {
  const initialState = {};

  fields.forEach(key => {
    const fieldType = fieldsById.get(String(key)).get("type");

    initialState[key] = forbiddenFieldTypes.includes(fieldType)
      ? false
      : defaultCopyableFields.includes(Number(key));
  });

  return initialState;
};

export const forbiddenFieldTypes = [
  "revision",
  "approval",
  "link",
  "section",
  "subSection"
];
