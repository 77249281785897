// @flow

import React from "react";

import {
  Suggestion as StyledSuggestion,
  SpecialMention as StyledSpecialMention
} from "./styles";
import AllImage from "./all.svg";
import OwnerImage from "./owner.png";
import CreatorImage from "./creator.png";
import UserAndGroup from "./UserAndGroup";

import type { Mentions } from "src/types";

type Props = {
  focused: boolean,
  mention: Mentions
};

const Suggestion = ({ mention, focused, ...parentProps }: Props) => {
  switch (mention.id) {
    case "!everyone":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <StyledSpecialMention>
            <img src={AllImage} alt="All" />
            <strong>All</strong>
          </StyledSpecialMention>
          <span>(every participant of the conversation)</span>
        </StyledSuggestion>
      );

    case "!signatories":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <StyledSpecialMention>
            <img src={AllImage} alt="All" />
            <strong>Signatories</strong>
          </StyledSpecialMention>
        </StyledSuggestion>
      );
    case "!owner":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <StyledSpecialMention>
            <img src={OwnerImage} alt="Owner" />
            <strong>Owner</strong>
          </StyledSpecialMention>
          <span>(current owner of the conversation)</span>
        </StyledSuggestion>
      );
    case "!creator":
      return (
        <StyledSuggestion
          tabIndex="0"
          role="button"
          isFocused={focused}
          {...parentProps}
        >
          <StyledSpecialMention>
            <img src={CreatorImage} alt="Owner" />
            <strong>Creator</strong>
          </StyledSpecialMention>
          <span>(creator of the conversation)</span>
        </StyledSuggestion>
      );
    default:
      return (
        <UserAndGroup focused={focused} mention={mention} {...parentProps} />
      );
  }
};

export default Suggestion;
