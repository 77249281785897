// @flow

import React, { Component } from "react";
import pdfjsLib from "pdfjs-dist";
import workerSrc from "pdfjs-dist/build/pdf.worker.entry";

import type { T_PDFJS, T_PDFJS_Document } from "../types";

type Props = {
  url: string,
  beforeLoad: React$Element<*>,
  errorMessage: React$Element<*>,
  children: (pdfDocument: T_PDFJS_Document) => React$Element<*>
};

type State = {
  pdfDocument: ?T_PDFJS_Document,
  loadingError: boolean
};

pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

class PdfLoader extends Component<Props, State> {
  state = {
    pdfDocument: null,
    loadingError: false
  };

  componentDidMount() {
    this.loadDocument();
  }

  async loadDocument() {
    const { url } = this.props;
    try {
      const pdfDocument = await pdfjsLib.getDocument({
        url
      }).promise;

      this.setState({ pdfDocument });
    } catch (e) {
      this.setState({ loadingError: true });
      console.log("Error in loading pdf - ", e);
    }
  }

  render() {
    const { children, beforeLoad, errorMessage } = this.props;
    const { pdfDocument, loadingError } = this.state;
    if (pdfDocument) {
      return children(pdfDocument);
    }
    if (loadingError) {
      return errorMessage;
    }

    return beforeLoad;
  }
}

export default PdfLoader;
