// @flow

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { Checklist as StyledChecklist, FieldRow, Field, Label } from "./styles";
import Icon from "src/icons";
import Text from "src/components/Dock/Checklist/Text";
import Number from "src/components/Dock/Checklist/Number";
import Date from "src/components/Dock/Checklist/Date";
import Picklist from "src/components/Dock/Checklist/Picklist";
import Conversation from "src/components/Dock/Checklist/Conversation";
import ChecklistUsers from "src/components/Dock/Checklist/ChecklistUsers";
import Approval from "src/components/Dock/Checklist/Approval";
import LinkedField from "src/components/Dock/Checklist/LinkedField";
import File from "src/components/Dock/Checklist/File";
import useFields from "src/components/Dock/Checklist/useFields";
import { setConversationAttributes } from "src/actions/chatroom";
import { setChecklistValue } from "src/actions/checklist";
import { removePromptField } from "src/actions/prompts";
import location from "src/constants/location";
import {
  getRoomFieldValueStatus,
  getLockedStatus,
  getChecklistFieldBehavior
} from "src/reducers";
import HttpMethods from "src/constants/httpMethods";
import * as morpheus from "src/utils/morpheus";
import type {
  AppState,
  RoomId,
  FieldId,
  ChecklistValue,
  ChecklistField,
  DataStage
} from "src/types";

type Props = {
  field: ChecklistField,
  id: FieldId,
  roomId: RoomId,
  checklistValue: ChecklistValue,
  _setChecklistValue: Function,
  _removePromptField: Function,
  setShowPrompt: Function,
  done: number,
  valueStatus: ?DataStage,
  locked: boolean
};

const Checklist = ({
  field,
  id,
  roomId,
  checklistValue,
  _setChecklistValue,
  _removePromptField,
  setShowPrompt,
  done,
  valueStatus,
  locked
}: Props) => {
  const type = field ? field.get("type") : "";
  const label = field ? field.get("label") : "";
  let FieldComp = null;

  const handlePromptCallback = useCallback(() => {
    setShowPrompt(false);
    _removePromptField(roomId, id);
    setTimeout(() => {
      setShowPrompt(true);
    }, 3000);
  }, [roomId]);

  const setFieldValue = ({
    value,
    httpMethod,
    extraBody
  }: {
    value: any,
    httpMethod?: HttpMethods,
    extraBody?: Object
  }) => {
    _setChecklistValue({
      roomId,
      id,
      value: {
        value,
        type,
        checked: true
      },
      progress: true,
      httpMethod,
      extraBody
    });
    handlePromptCallback();
  };

  const {
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  } = useFields({
    checklistValue,
    details: field
  });

  // Empty local state before displaying next prompt
  // else previous  prompt displays in next prompt
  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setValue(null);
      }, 1250);
    }
  }, [done, field]);

  const forwardProps = {
    fieldId: id,
    roomId,
    details: field,
    checklistValue,
    setChecklistValue: _setChecklistValue,
    setFieldValue,
    promptCallback: handlePromptCallback,
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue,
    formId: null,
    valueStatus,
    locked,
    location: location.conversationDialog
  };

  switch (type) {
    case "file":
    case "pdf":
      FieldComp = <File {...forwardProps} type={type} />;
      break;
    case "text":
      FieldComp = <Text {...forwardProps} />;
      break;
    case "number":
      FieldComp = <Number {...forwardProps} />;
      break;
    case "date":
      FieldComp = <Date {...forwardProps} />;
      break;
    case "select":
      FieldComp = <Picklist {...forwardProps} />;
      break;
    case "user":
      FieldComp = <ChecklistUsers {...forwardProps} />;
      break;
    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      FieldComp = <Conversation {...forwardProps} />;
      break;
    case "childConversation":
      FieldComp = <Conversation {...forwardProps} parentConversation />;
      break;
    case "approval":
      FieldComp = (
        <Approval
          {...forwardProps}
          settings={morpheus.approval(forwardProps.settings)}
        />
      );
      break;
    case "link":
      FieldComp = <LinkedField {...forwardProps} />;
      break;
    default:
      FieldComp = null;
  }

  return (
    <StyledChecklist>
      <div>
        <Label title={label}>{label}</Label>
        <Icon type="steps" />
      </div>
      <FieldRow>
        <Field>{FieldComp}</Field>
      </FieldRow>
    </StyledChecklist>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { id, roomId }: Props) => {
  const behavior = getChecklistFieldBehavior(app, { roomId, fieldId: id });
  return {
    valueStatus: getRoomFieldValueStatus(app, id, roomId),
    locked: getLockedStatus(app, roomId, id, behavior)
  };
};

export default connect(mapStateToProps, {
  _setChecklistValue: setChecklistValue,
  _removePromptField: removePromptField,
  _setConversationAttributes: setConversationAttributes
})(Checklist);
