// @flow

import React, { useCallback } from "react";
import * as R from "ramda";
import transit from "transit-js";
import Linkify from "react-linkify";
import moment from "moment";

import fieldReader from "src/transit/checklist/field/reader";

import { OldValue, NewValue } from "../styles";
import Conversation from "./Conversation";
import Conversations from "./Conversations";
import User from "./User";
import Users from "./Users";
import Files from "./Files";
import Approval from "./Approval";

type Props = {
  timestamp: string,
  field: {
    old: any,
    new: any,
    type: string
  }
};

const Value = ({ timestamp, field }: Props) => {
  const componentDecorator = useCallback(
    (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
    []
  );

  try {
    const reader = transit.reader("json", { handlers: fieldReader });
    const { type } = reader.read(field.type);

    switch (type) {
      case "conversation":
      case "chatPickList":
      case "workflow":
      case "task":
      case "group":
      case "childConversation": {
        if (Array.isArray(field.old) || Array.isArray(field.new))
          return (
            <Conversations
              oldValue={field.old}
              newValue={field.new}
              timestamp={timestamp}
            />
          );
        return <Conversation oldValue={field.old} newValue={field.new} />;
      }
      case "user": {
        if (Array.isArray(field.old) || Array.isArray(field.new))
          return (
            <Users
              oldValue={field.old}
              newValue={field.new}
              timestamp={timestamp}
            />
          );
        return <User oldValue={field.old} newValue={field.new} />;
      }
      case "file":
      case "pdf":
        if (Array.isArray(field.old) || Array.isArray(field.new))
          return (
            <Files
              oldValue={field.old ? field.old[0] : field.old}
              newValue={field.new}
            />
          );
        break;
      case "date":
        return (
          <>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old ? moment(field.old).format("MMM DD, YYYY") : null}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.new ? moment(field.new).format("MMM DD, YYYY") : null}
              </Linkify>
            </NewValue>
          </>
        );
      case "approval":
        return <Approval data={field.new} />;

      case "link":
        return (
          <Conversations
            oldValue={(field.old || []).map(val => val.chatroom)}
            newValue={(field.new || []).map(val => val.chatroom)}
            timestamp={timestamp}
          />
        );

      default:
        return (
          <p>
            <OldValue>
              <Linkify componentDecorator={componentDecorator}>
                {field.old}
              </Linkify>
            </OldValue>
            <NewValue>
              <Linkify componentDecorator={componentDecorator}>
                {R.type(field.new) === "Array"
                  ? field.new.join("\n ")
                  : field.new}
              </Linkify>
            </NewValue>
          </p>
        );
    }
  } catch (e) {
    console.error({ field });
    console.error(e);
    return <></>;
  }
  return <></>;
};

export default Value;
