// @flow

import * as atypes from "src/constants/actionTypes";

import type { WorkflowId, ChartFields, ChartConfig, ChartId } from "src/types";

export const getChart = (id: number) => ({
  type: atypes.GET_CHART_REQUEST,
  payload: { id }
});

export const setNewChartDialogAttributes = (attributes: Object) => ({
  type: atypes.SET_NEW_CHART_DIALOG_ATTRIBUTES,
  payload: attributes
});

export const createChart = () => ({
  type: atypes.CREATE_CHART_REQUEST,
  payload: {}
});

export const closeNewChartDialog = () => ({
  type: atypes.CLOSE_NEW_CHART_DIALOG,
  payload: {}
});

export const getCharts = () => ({
  type: atypes.GET_CHARTS_REQUEST,
  payload: {}
});

export const getReportsChartJSON = (id: number, dialog?: boolean) => ({
  type: atypes.GET_REPORTS_CHART_JSON_REQUEST,
  payload: { id, dialog }
});

export const getChartJSON = (id: number) => ({
  type: atypes.GET_CHART_JSON_REQUEST,
  payload: { id }
});

export const refreshChart = (id: number) => ({
  type: atypes.REFRESH_CHART_REQUEST,
  payload: { id }
});

export const editChart = ({
  id,
  type,
  reportId,
  workflowId
}: {
  id: number,
  type: string,
  reportId: string,
  workflowId: number
}) => ({
  type: atypes.EDIT_CHART,
  payload: { id },
  meta: {
    query: {
      builder: type,
      reportId,
      workflowId
    }
  }
});

export const updateChart = () => ({
  type: atypes.UPDATE_CHART_REQUEST,
  payload: {}
});

export const deleteChart = (id: number) => ({
  type: atypes.DELETE_CHART_REQUEST,
  payload: { id }
});

export const searchColumns = (searchTerm: string, fieldType: string) => ({
  type: atypes.SEARCH_COLUMN_NAMES_REQUEST,
  payload: {
    searchTerm,
    fieldType
  }
});

export const showEditChartModal = (id: string) => ({
  type: atypes.SHOW_EDIT_CHART_MODAL,
  payload: {
    id
  }
});

export const copyChartUrl = () => ({
  type: atypes.COPY_CHART_URL,
  payload: {}
});

export const getAllCharts = () => ({
  type: atypes.GET_ALL_CHARTS_REQUEST,
  payload: {}
});

export const expandChart = (chartId: number | string) => ({
  type: atypes.EXPAND_CHART,
  payload: { chartId }
});

export const setCreateChart = ({
  chartName,
  reportId,
  workflowId
}: {
  chartName: string,
  reportId: string,
  workflowId: number
}) => ({
  type: atypes.SET_CHART_REQUEST,
  meta: {
    query: {
      chart: chartName,
      reportId,
      workflowId
    }
  }
});

export const setSelectedChart = ({
  chartName,
  selectedChart,
  reportId,
  workflowId
}: {
  chartName: string,
  selectedChart: string,
  reportId: string,
  workflowId: number
}) => ({
  type: atypes.SELECTED_CHART_REQUEST,
  meta: {
    query: {
      chart: chartName,
      builder: selectedChart,
      reportId,
      workflowId
    }
  }
});

export const getPrimaryFieldList = (
  allFields: Array<ChartFields>,
  workflowId: WorkflowId
) => ({
  type: atypes.GET_PRIMARY_FIELD_LIST_REQUEST,
  payload: { allFields, workflowId }
});

export const getRecordCountFieldList = (
  allFields: Array<ChartFields>,
  workflowId: WorkflowId
) => ({
  type: atypes.GET_RECORD_COUNT_FIELD_LIST_REQUEST,
  payload: { allFields, workflowId }
});

export const getNumericalFieldList = (
  allFields: Array<ChartFields>,
  workflowId: WorkflowId
) => ({
  type: atypes.GET_NUMERICAL_FIELD_LIST_REQUEST,
  payload: { allFields, workflowId }
});

export const getEmbeddedNumericalFieldList = (workflowId: WorkflowId) => ({
  type: atypes.GET_EMBEDDED_NUMERICAL_FIELD_LIST_REQUEST,
  payload: { workflowId }
});

export const createComparisonChart = (request: ChartConfig) => ({
  type: atypes.CREATE_COMPARISON_CHART_REQUEST,
  payload: { request }
});

export const getGroupByFieldList = (
  allFields: Array<ChartFields>,
  workflowId: WorkflowId,
  linkedOrFormFieldId: ?number
) => ({
  type: atypes.GET_GROUP_BY_FIELD_LIST_REQUEST,
  payload: { allFields, workflowId, linkedOrFormFieldId }
});

export const updateComparisonChart = (id: number, request: ChartConfig) => ({
  type: atypes.UPDATE_COMPARISON_CHART_REQUEST,
  payload: { id, request }
});

export const fetchChart = (id: number) => ({
  type: atypes.FETCH_CHART_REQUEST,
  payload: { id }
});

export const setChartLoadingState = (chartIds: Array<number>) => ({
  type: atypes.SET_CHART_LOADING_STATE,
  payload: { chartIds }
});

export const getChartJsonSuccess = (chartId: ChartId, chartData: any) => ({
  type: atypes.GET_CHART_JSON_SUCCESS,
  payload: {
    id: chartId,
    ...chartData
  }
});
