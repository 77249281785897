// @flow

import React, { useMemo } from "react";
import { connect } from "react-redux";
import { rgba } from "polished";
import useClientDimensions from "react-client-dimensions";

import Archived from "./Archived";
import { Value, DueStatus, StatusTextContainer } from "./styles";
import * as colors from "src/styles/constants/colors";
import { ACTIVE } from "src/constants/status";
import Icon from "src/icons";
import {
  getStatusTitle,
  getTemplateId,
  getActiveStatus,
  isStatusDisabled,
  getStatusArchiveMode,
  getChatroomManualArchived
} from "src/reducers";

import type { AppState, ArchiveMode } from "src/types";

type Props = {
  title: number,
  active: boolean,
  isOverDue: boolean,
  id: number,
  disabled: boolean,
  archiveMode: ArchiveMode,
  manuallyArchived: boolean,
  hideArchive?: boolean,
  location: string
};

const Custom = ({
  title,
  active,
  isOverDue,
  disabled,
  archiveMode,
  manuallyArchived,
  hideArchive,
  location
}: Props) => {
  const { vw } = useClientDimensions();

  const color = useMemo(
    () =>
      // If workflow had been converted to feed apply this color
      // i.e if disabled is true
      disabled
        ? rgba(colors.secondary, 0.2)
        : active
        ? rgba(colors.secondary, 0.2)
        : rgba(colors.success, 0.2),
    [active, disabled]
  );

  const textColor = useMemo(
    () =>
      disabled ? colors.primary : active ? colors.primary : colors.success,
    [active, disabled]
  );

  if (isOverDue) {
    if (active) {
      return (
        <Value color={color} textColor={textColor} location={location}>
          <DueStatus>
            <div>{title}</div>
          </DueStatus>
          <Icon type="alarmClock" />
        </Value>
      );
    }
  }

  let archived =
    manuallyArchived === null || manuallyArchived === undefined
      ? archiveMode === "archive"
      : manuallyArchived;

  return (
    <Value color={color} textColor={textColor} location={location} vw={vw}>
      <StatusTextContainer>{disabled ? ACTIVE : title}</StatusTextContainer>
      {archived && !hideArchive ? <Archived /> : null}
    </Value>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const templateId = getTemplateId(app, `${props.roomId}`);
  return {
    title: getStatusTitle(app, `${props.id}`),
    active: getActiveStatus(app, `${templateId}`, `${props.id}`),
    archiveMode: getStatusArchiveMode(app, `${templateId}`, `${props.id}`),
    manuallyArchived: getChatroomManualArchived(app, props.roomId),
    disabled: isStatusDisabled(app, props.roomId)
  };
};

export default connect(mapStateToProps)(Custom);
