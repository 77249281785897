// @flow

import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";

import type { HomescreenSection, Action } from "src/types";

const metaData = (
  state: Array<HomescreenSection> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_HOME_SCREEN_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const myConversationChart = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_MY_CONVERSATION_CHART_SUCCESS:
      return { ...state, [payload.tileId]: payload.metaData };
    default:
      return state;
  }
};

const loadingConversationChart = (
  state: Object = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_MY_CONVERSATION_CHART_REQUEST:
      return { ...state, [payload.tileId]: true };
    case atypes.GET_MY_CONVERSATION_CHART_SUCCESS:
    case atypes.GET_MY_CONVERSATION_CHART_FAILURE:
      return { ...state, [payload.tileId]: false };
    default:
      return state;
  }
};

const loading = (state: ?boolean = null, { type }: Action) => {
  switch (type) {
    case atypes.GET_HOME_SCREEN_SUCCESS:
    case atypes.GET_HOME_SCREEN_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  metaData,
  myConversationChart,
  loadingConversationChart
});
