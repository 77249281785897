// @flow

import React from "react";
import { useSelector } from "react-redux";
import { getUsersFromIds, getCurrentUserId } from "src/reducers";
import type { RoomId, FieldId, ApprovalFieldSettingsV2, UID } from "src/types";

import { VStack, useBoolean, Popover, PopoverTrigger } from "@chakra-ui/react";

import Button from "src/components/Buttons/ChakraButton";
import SignatureModal from "./SignatureModal";
import SignatoriesPopover from "./SignatoriesPopover";

type Props = {
  hasUserSigned: boolean,
  done: number,
  total: number,
  bordered?: boolean,
  disabled?: boolean,
  handleSign: Function,
  roomId: RoomId,
  fieldId: FieldId,
  settings: ApprovalFieldSettingsV2,
  signatories: UID[]
};

const borderedStyles = {
  border: "2px",
  borderColor: "purple.200",
  padding: 2
};

function SignatureButtonGroup({
  hasUserSigned,
  done,
  total,
  bordered,
  handleSign,
  disabled,
  roomId,
  fieldId,
  settings,
  signatories
}: Props) {
  const startingSigPos = done + 1;
  const pendingSignatures = Math.max(0, total - done);
  const pendingSignaturePositions = [...Array(pendingSignatures)].map(
    (_, i) => i + startingSigPos
  );

  const rootStyles = bordered ? borderedStyles : {};

  const [isSignatureModalOpen, setSignatureModal] = useBoolean();
  const [isPopoverOpen, setIsPopoverOpen] = useBoolean(false);

  const currentUserId = useSelector(state => getCurrentUserId(state.app));
  const signatoriesData = useSelector(state =>
    getUsersFromIds(state.app, signatories)
  );

  const isUserSignatory = signatories.includes(currentUserId);

  const isCommentRequired = settings.requireComment;

  if (pendingSignatures === 0) {
    return null;
  }

  return (
    <Popover isOpen={isPopoverOpen} onClose={setIsPopoverOpen.off}>
      <VStack
        w="100%"
        {...rootStyles}
        onMouseEnter={() => {
          if (disabled && !isUserSignatory) {
            setIsPopoverOpen.on();
          }
        }}
        onMouseLeave={() => {
          if (disabled) {
            setIsPopoverOpen.off();
          }
        }}
      >
        {pendingSignaturePositions.map(pos => {
          const isDisabled =
            disabled || hasUserSigned ? true : pos - startingSigPos !== 0;

          return (
            <PopoverTrigger key={pos}>
              <Button
                key={pos}
                onClick={setSignatureModal.on}
                isDisabled={isDisabled}
                variant="add"
                rootProps={{ width: "100%" }}
              >
                &#43; Add Signature {pos}
              </Button>
            </PopoverTrigger>
          );
        })}

        {isPopoverOpen ? (
          <SignatoriesPopover
            title="Signatories"
            signatories={signatoriesData}
          />
        ) : null}

        <SignatureModal
          isOpen={isSignatureModalOpen}
          onClose={setSignatureModal.off}
          handleSign={handleSign}
          roomId={roomId}
          fieldId={fieldId}
          isCommentRequired={isCommentRequired}
        />
      </VStack>
    </Popover>
  );
}

export default SignatureButtonGroup;
