// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import NewRoom from "./NewRoom";
import Loader from "./Loader";
import AddConversation from "./AddConversation";
import Dropdown from "./Dropdown";
import SelectionSingle from "./FieldItem";

import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";

import { getSelectedChecklist, getCreatingConversation } from "src/reducers";

import type {
  RoomId,
  CreatingConversation,
  AppState,
  ColumnId,
  SelectedChecklist
} from "src/types";

type Props = {
  formId: ?number,
  creatingConversation: CreatingConversation,
  value: Array<number>,
  settings: Object,
  setValue: Function,
  roomId: RoomId,
  columnId: ColumnId,
  fieldId: number,
  checklistId: number,
  newRoom: boolean,
  dropdown: boolean,
  setDropdown: Function,
  setChecklistValue: Function,
  handleCreate: Function,
  handleClose: Function,
  workflow: ?number,
  showFieldNames: boolean,
  fields: Array<number>,
  type: string,
  showMetaData: boolean,
  handleNewRoom: Function,
  promptCallback?: ?Function,
  disabled: boolean,
  isMandatory: ?boolean,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>,
  selectedChecklist: SelectedChecklist
};

const Conversation = ({
  formId,
  value,
  roomId,
  fieldId,
  creatingConversation,
  checklistId,
  settings,
  setValue,
  setChecklistValue,
  handleCreate,
  newRoom,
  dropdown,
  setDropdown,
  workflow,
  type,
  handleClose,
  handleNewRoom,
  showMetaData,
  showFieldNames,
  fields,
  promptCallback,
  disabled,
  isMandatory,
  dependentOptions,
  currentBehavior,
  selectedChecklist
}: Props) => {
  const { columnId, embeddedIndex } = selectedChecklist;

  const handleRemove = useCallback(() => {
    const newValue = [];

    setValue(newValue);

    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: newValue,
        type: "conversation",
        checked: false
      },
      columnId,
      embeddedIndex,
      progress: true,
      formId
    });
  }, [formId, roomId, fieldId, checklistId, setValue, setChecklistValue]);

  const handleSelect = (newValue: string) => {
    const updatedValue = [parseInt(newValue, 10)];
    setValue(updatedValue);
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: parseInt(newValue, 10),
        type: "conversation",
        checked: true
      },
      columnId,
      embeddedIndex,
      progress: true,
      formId
    });

    if (promptCallback) promptCallback();

    setDropdown(false);
  };

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationExclude],
    dependentOptions
  );

  if (
    !R.isEmpty(creatingConversation) &&
    creatingConversation.fieldId === fieldId &&
    creatingConversation.roomId === roomId
  ) {
    return <Loader />;
  }
  const { create, select } = settings || {};
  return (
    <>
      {!newRoom && !dropdown && (value || []).length === 0 && (
        <AddConversation
          settings={settings || {}}
          handleDropdown={() => setDropdown(true)}
          disabled={disabled}
          isMandatory={isMandatory}
        />
      )}

      {!newRoom && !dropdown && value && (value || []).length > 0 && (
        <SelectionSingle
          value={value[0]}
          showMetaData={showMetaData}
          showFieldNames={showFieldNames}
          fields={fields}
          handleRemove={handleRemove}
          setDropdown={setDropdown}
          disabled={disabled}
        />
      )}

      {dropdown && (
        <Dropdown
          roomId={roomId}
          showRemove={Boolean(value && (value || []).length > 0)}
          workflow={workflow}
          type={type}
          selectedValue={value}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={create}
          select={select}
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
        />
      )}

      {newRoom && !dropdown && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  creatingConversation: getCreatingConversation(app),
  selectedChecklist: getSelectedChecklist(app)
});

Conversation.defaultProps = {
  promptCallback: null
};

export default connect(mapStateToProps)(Conversation);
