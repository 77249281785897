// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";

export const Button = styled.button`
  background-color: ${colors.sky};
  outline: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-left: 2em;
  margin-bottom: 2rem;
  padding: 0.4em;
  > span {
    padding: 0 4px;
  }
`;

export const Reminder = styled.div`
  border: 1px solid ${colors.grey4};
  padding: 1em;
  padding-bottom: 0.8em;
  width: 86%;
  margin-bottom: 1em;
  margin-left: 2em;
  border-radius: ${radius.medium};
  :hover,
  :focus {
    outline: none;
    border: 1px solid ${colors.grey5};
  }
`;

export const Days = styled.input`
  width: 4em;
  border: 1px solid ${colors.grey4};
  margin: 0 8px;
  padding: 0.4em;
  :hover,
  :focus {
    outline: none;
    border: 1px solid ${colors.grey5};
  }
`;

export const Select = styled.select`
  width: 10em;
  margin: 0px 4px;
  padding: 0.4em;
`;

export const Row = styled.div`
  margin-top: 0.8em;
  display: flex;
  justify-content: space-between;
`;

export const Message = styled.div`
  cursor: text;
  background-color: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  border-radius: ${radius.medium};
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  min-height: 10em;
  :hover,
  :focus {
    outline: none;
    border: 1px solid ${colors.grey5};
  }

  > div:first-of-type {
    width: 100%;
    height: 100%;
    min-height: 10em;
    > div:first-of-type > div:first-of-type {
      max-height: 20em;
      margin: 0px;
      padding: 0px;
      outline: 0px;
      border: 0px;
      min-height: 10em;
      line-height: initial;
      height: auto !important;
      position: static !important;
    }
  }

  textarea {
    border: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 10em;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    max-height: 20em;
    bottom: 0px;
    overflow: auto;
    resize: none;
    margin: 0px;
    padding: 0px;
    outline: 0px;
    border: 0px;
    line-height: initial;
    height: auto !important;
  }

  .mention-suggestion {
    position: relative;
    background: ${colors.grey15};
    font-weight: bold;
    opacity: 100%;
    z-index: 1000;
    color: ${colors.active};
    li:focus {
      background-color: ${colors.grey0};
    }
  }

  ul {
    max-height: 18em;
    overflow: auto;
    border-top: 1px solid ${colors.grey4};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${colors.grey4};
    border-radius: ${radius.medium};
    li {
      width: 25em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const IconButton = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.brightLight};
  margin-top: 8px;
  margin-left: -10px;
  display: block;
`;

export const RemindersContainer = styled.div`
  margin-top: 1rem;
  height: 70vh;
  overflow: auto;
  padding-bottom: 5.5rem;
  width: 100%;
`;
