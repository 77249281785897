// @flow

import React from "react";
import * as R from "ramda";

import type {
  RoomId,
  FieldId,
  LinkedFieldValue,
  LinkedFieldSettings
} from "src/types";
import useBoolean from "src/hooks/useBoolean";

import { VStack } from "@chakra-ui/react";
import WorkflowName from "src/containers/workflow/Name";
import Dropdown from "src/components/Dock/Checklist/Conversation/Dropdown";
import { AddButton } from "../styles";
import ChatroomCard from "src/components/Dock/Checklist/Conversation/FieldItem";
import NewRoom from "src/components/Dock/Checklist/Conversation/NewRoom";
import SingleModal from "./SingleModal";
import RemoveLinkModal from "./RemoveLinkModal";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";

import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";

type Props = {
  settings: LinkedFieldSettings,
  checklistValue: LinkedFieldValue,
  roomId: RoomId,
  fieldId: FieldId,
  target: boolean,
  dropdown: {
    visible: boolean,
    set: Function,
    open: Function
  },
  modal: {
    state: {
      open: boolean,
      roomId: ?number,
      edit: boolean
    },
    close: Function
  },
  newRoom: boolean,
  handleNewRoom: Function,
  handleClose: Function,
  handleCreate: Function,
  handleVersionClick: Function,
  selectNewRoomVersions: Function,
  removeLink: Function,
  editParentField: Function,
  removeRoom: Function,
  selectChatroom: Function,
  handleSelect: Function,
  isMandatory: boolean,
  locked: boolean,
  updating: boolean,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>
};

const Single = ({
  settings,
  checklistValue,
  roomId,
  fieldId,
  target,
  dropdown,
  modal,
  newRoom,
  handleNewRoom,
  handleClose,
  handleCreate,
  handleVersionClick,
  selectNewRoomVersions,
  removeLink,
  editParentField,
  removeRoom,
  selectChatroom,
  handleSelect,
  isMandatory,
  locked,
  updating,
  currentBehavior,
  dependentOptions
}: Props) => {
  const { entities: { chatrooms = {} } = {}, result = [] } =
    checklistValue || {};

  const value = R.isEmpty(result) || !chatrooms ? null : chatrooms[result[0]];

  const {
    value: isRemoveLinkModalVisible,
    setTrue: showRemoveLinkModal,
    setFalse: hideRemoveLinkModal
  } = useBoolean(false);

  const handleRemove = () => {
    if (!target) {
      removeRoom(value?.originChatroomId);
    } else {
      showRemoveLinkModal();
    }
  };

  const handledEditParentField = newVersion => {
    editParentField({
      newValue: newVersion,
      sourceChatroom: value?.originChatroomId
    });
  };

  const handleRemoveLink = () => {
    removeLink(value?.originChatroomId);
  };

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldExclude],
    dependentOptions
  );

  return (
    <VStack alignItems="start" width="100%">
      {!dropdown.visible && !newRoom && value && (
        <ChatroomCard
          value={value.chatroom.id}
          {...settings}
          showFieldNames={settings.showCompressedPreview}
          handleRemove={handleRemove}
          setDropdown={dropdown.set}
          disabled={locked}
          onVersionClick={handleVersionClick}
          autoLink={value.linkAllVersions || false}
        />
      )}

      {dropdown.visible && (
        <Dropdown
          roomId={roomId}
          showRemove={Boolean(value)}
          workflow={settings.workflow}
          type="workflow"
          selectedValue={value ? [value.chatroom.id] : []}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={settings.create}
          select={settings.select}
          groupVersions
          selectNewRoomVersions={selectNewRoomVersions}
          hideSelectedVersions
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={Boolean(settings.alwaysAllowManualLinks)}
        />
      )}

      {updating ? (
        <ButtonLoader />
      ) : (
        !newRoom &&
        !dropdown.visible &&
        !value && (
          <AddButton
            type="button"
            onClick={dropdown.open}
            disabled={locked}
            isMandatory={isMandatory}
          >
            &#43; Add <WorkflowName id={settings.workflow} />
          </AddButton>
        )
      )}

      {newRoom && !dropdown.visible && (
        <NewRoom
          settings={settings}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      {modal.state.open && !R.isNil(modal.state.roomId) && (
        <SingleModal
          open
          roomId={modal.state.roomId}
          value={value}
          autoLink={Boolean(value?.linkAllVersions)}
          defaultEdit={modal.state.edit}
          selectChatroom={selectChatroom}
          onClose={modal.close}
        />
      )}

      {value && isRemoveLinkModalVisible && (
        <RemoveLinkModal
          open
          onClose={hideRemoveLinkModal}
          roomId={roomId}
          fieldId={fieldId}
          sourceChatroomId={value?.originChatroomId}
          handledEditParentField={handledEditParentField}
          handleRemoveLink={handleRemoveLink}
        />
      )}
    </VStack>
  );
};

export default Single;
