// @flow

import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import * as R from "ramda";

import Item from "./Item";
import { List as StyledList } from "./styles";
import VirtualList from "src/components/VirtualList";
import { RemoveOption } from "../Search/styles";
import Icon from "src/icons";
import {
  getFilteredChatroomExcluding,
  filterCurrentVersions
} from "src/reducers";

import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  showRemove: boolean,
  groupVersions: boolean,
  selectedValue: Array<number>,
  handleSelect: Function,
  handleRemove?: Function,
  selectNewRoomVersions?: Function,
  hideSelectedVersions?: boolean,
  dependentInclude?: ?Array<number>,
  dependentExclude?: ?Array<number>,
  alwaysShowRevisionIcon: boolean
};

const List = ({
  roomId,
  showRemove,
  selectedValue,
  hideSelectedVersions,
  groupVersions,
  selectNewRoomVersions,
  handleSelect,
  handleRemove,
  dependentInclude,
  dependentExclude,
  alwaysShowRevisionIcon
}: Props) => {
  const rooms = R.uniq([parseInt(roomId, 10), ...selectedValue]);
  const results = useSelector(state =>
    getFilteredChatroomExcluding(state.app, rooms)
  );
  const result = useSelector(state =>
    groupVersions
      ? filterCurrentVersions(
          state.app,
          results,
          hideSelectedVersions ? selectedValue : null
        )
      : results
  );

  const filteredResults: Array<number> = useMemo(() => {
    if (dependentInclude?.length) {
      return R.uniq(dependentInclude || []);
    } else if (dependentExclude?.length) {
      return R.uniq(R.without(dependentExclude || [], result));
    }

    return R.uniq(result);
  }, [result, dependentInclude, dependentExclude]);

  const renderRow = useCallback(
    (index: number) => {
      const room = filteredResults[index];
      if (room) {
        return (
          <Item
            key={room}
            id={room}
            handleSelect={handleSelect}
            selectNewRoomVersions={selectNewRoomVersions}
            alwaysShowRevisionIcon={alwaysShowRevisionIcon}
          />
        );
      }
      return <></>;
    },
    [result, handleSelect, selectNewRoomVersions]
  );

  return (
    <StyledList>
      <ul tabIndex="-1">
        {showRemove && handleRemove && (
          <RemoveOption onClick={handleRemove}>
            Remove
            <Icon type="checklistRemove" />
          </RemoveOption>
        )}

        <VirtualList
          initialAmount={20}
          progressiveAmount={20}
          role="list"
          renderItem={renderRow}
          renderLoader={() => <div />}
          rowCount={filteredResults.length}
        />
      </ul>
    </StyledList>
  );
};

List.defaultProps = {
  groupVersions: false,
  hideSelectedVersions: false
};

export default List;
