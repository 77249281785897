import "react-hot-loader/patch";
import "core-js";
import fetchIntercept from "fetch-intercept";
import "@webcomponents/shadydom";

import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "src/styles/theme";
import * as Sentry from "@sentry/react";

import * as R from "ramda";

import firebase from "firebase/app";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import reactHotLoader, { AppContainer } from "react-hot-loader";

import configureStore from "./configureStore";
import App from "src/components/App";
import ErrorBoundary from "src/components/ErrorBoundary";

import firebaseConfig from "src/config/firebase";
import { getCookie } from "src/utils";
import httpMethods from "./constants/httpMethods";

if (process.env.NODE_ENV !== "production") {
  // const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // whyDidYouRender(React, {
  //   include: [/^Connect/],
  //   logOnDifferentValues: false,
  //   trackHooks: false,
  //   collapseGroups: true
  // });
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const { store, persistor } = configureStore();

export { store };

// Interceptor for fetch API
fetchIntercept.register({
  request: async (url, config = {}) => {
    let newConfig = config;
    let newUrl = url;

    if (newUrl.startsWith(process.env.BACKEND_URL)) {
      newConfig = R.mergeDeepRight(newConfig, {
        credentials: "include"
      });
    }

    const orgInSession = sessionStorage.getItem("lastOrg");

    if (orgInSession) {
      newConfig = R.mergeDeepRight(newConfig, {
        headers: { "x-unifize-org": orgInSession }
      });

      if (newUrl.startsWith(process.env.BACKEND_URL)) {
        newUrl = new URL(newUrl);
        const params = newUrl.searchParams;

        params.set("orgId", orgInSession);

        newUrl.search = params.toString();
        newUrl = newUrl.toString();
      }
    }

    if (
      newUrl.startsWith(process.env.BACKEND_URL) &&
      [
        httpMethods.put,
        httpMethods.patch,
        httpMethods.post,
        httpMethods.delete
      ].includes(config.method)
    ) {
      const csrfToken = getCookie("unifize-csrf-token");
      if (csrfToken) {
        newConfig = R.mergeDeepRight(newConfig, {
          headers: { "x-unifize-csrf-token": csrfToken }
        });
      }
    }

    return [newUrl, newConfig];
  },
  requestError: error => {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },
  response: response => {
    switch (response.status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 408:
      case 409:
      case 500:
        return Promise.reject(response);
      default:
        return response;
    }
  },
  responseError: error => {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: "production",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [/^https:\/\/app\.unifize\.com\//]
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.unifize\.com\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Allow errors from these sources
  allowUrls: [/^https:\/\/app\.unifize\.com\//],

  // Attaches stack traces with all the logged messages
  attachStackTrace: true
});

const render = Component => {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />} showDialog>
      <AppContainer>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider theme={theme} resetCSS={false}>
              <Component />
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </AppContainer>
    </Sentry.ErrorBoundary>,

    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  reactHotLoader.disableProxyCreation;
  module.hot.accept("src/components/App", () => render(App));
}

// // Register service worker
// if ("serviceWorker" in navigator) {
//   try {
//     console.info("gonna register");
//     runtime.register();
//   } catch (error) {
//     console.error("Unable to register service worker", error);
//   }
// }
