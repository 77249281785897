// @flow

import React, { useCallback, useLayoutEffect, useRef } from "react";
import TextArea from "react-autosize-textarea";
import * as R from "ramda";

import Single from "./Single";
import { TextField, Done } from "./styles";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import type {
  FieldId,
  ChecklistValue,
  RoomId,
  ReactRef,
  ChecklistField,
  ColumnId
} from "src/types";
import { extractChecklistValue, getPlaceholder } from "src/utils/checklist";

type Props = {
  formId: ?number,
  checklistValue: ?ChecklistValue,
  setChecklistValue: Function,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  details: ChecklistField,
  promptCallback?: ?Function,
  edit?: boolean,
  settings?: Object,
  value?: any,
  handleChange?: Function,
  openEdit?: Function,
  closeEdit?: Function,
  setValue?: Function,
  locked: boolean,
  isMandatory?: boolean
};

const Text = ({
  formId,
  checklistValue,
  setChecklistValue,
  roomId,
  columnId,
  fieldId,
  promptCallback,
  edit,
  settings,
  value,
  handleChange,
  openEdit,
  closeEdit,
  setValue,
  locked,
  isMandatory
}: Props) => {
  const textAreaRef: ReactRef = useRef(null);

  const placeholder = getPlaceholder(settings, "Enter Text");

  const updateChecklistValue = useCallback(() => {
    const prevValue = extractChecklistValue(checklistValue);
    if (
      prevValue === null ||
      R.trim(`${prevValue || ""}`) !== R.trim(`${value || ""}`)
    )
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: R.trim(value || ""),
          type: "text",
          checked: true
        },
        progress: true,
        formId,
        columnId
      });

    if (promptCallback) promptCallback();
    if (closeEdit) {
      closeEdit();
    }
  }, [roomId, formId, columnId, fieldId, value, promptCallback]);

  // set focus at the end of text on textarea
  useLayoutEffect(() => {
    if (edit && textAreaRef.current)
      textAreaRef.current.setSelectionRange(
        (value || "").length,
        (value || "").length
      );
  }, [edit]);

  const { handleShiftEnter } = useKeyDownHandlers(updateChecklistValue);

  const handleKeyDown = (e: any) => {
    const prevValue = extractChecklistValue(checklistValue);
    // If escape is pressed cancel editing
    if (e.keyCode === 27 && setValue) {
      setValue(prevValue);
      if (closeEdit) {
        closeEdit();
      }
    } else {
      handleShiftEnter(e);
    }
  };

  const forwardProps = {
    edit,
    update: updateChecklistValue,
    value,
    type: "text",
    openEdit,
    clickToEdit: true,
    disabled: locked,
    isMandatory
  };

  return (
    <Single {...forwardProps}>
      <TextField disabled={locked}>
        <TextArea
          placeholder={placeholder}
          value={value || ""}
          onChange={handleChange}
          autoFocus
          innerRef={el => {
            textAreaRef.current = el;
          }}
          onKeyDown={handleKeyDown}
          readOnly={locked}
          disabled={locked}
        />
        <Done onClick={updateChecklistValue}>Done</Done>
      </TextField>
    </Single>
  );
};

Text.defaultProps = {
  promptCallback: null,
  edit: false,
  settings: {},
  value: "",
  locked: false,
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  setValue: () => {}
};

export default Text;
