// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { List } from "immutable";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import type {
  Action,
  WorkflowChecklists,
  ChecklistHeader,
  ChecklistInstances,
  RoomId,
  FieldId
} from "src/types";
import { mergeChecklistValues } from "src/utils";
import { linkedFieldDeleteHandler } from "src/utils/checklist";
import { behaviorToSettings, behaviors } from "src/conditions";

const initialState = List([]);

const checklists = (
  state: List<ChecklistHeader> = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return initialState;

    case atypes.GET_CHECKLIST_SUCCESS:
      return List(payload.checklists);

    default:
      return state;
  }
};

const values = (state: any = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    case atypes.HIDE_DOCK:
      return {};

    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);
    case atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS:
      return mergeChecklistValues(state, payload);
    case atypes.SET_EMBEDDED_VALUE_SUCCESS: {
      const { embedded } = payload;
      return {
        ...state,
        ...embedded
      };
    }
    case atypes.SET_CHECKLIST_VALUE_SUCCESS:
    case atypes.GET_CHECKLIST_FIELD_VALUE_COMPLETE:
    case atypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS: {
      const { chatroomId: roomId, fieldId } = payload;

      const roomValues = state[roomId] || [];
      const index = R.findIndex(R.propEq("fieldId", fieldId))(roomValues);
      if (index === -1) {
        return {
          ...state,
          [roomId]: [...roomValues, { ...payload }]
        };
      }

      const newRoomValues = [
        ...R.slice(0, index, roomValues),
        payload,
        ...R.slice(index + 1, Infinity, roomValues)
      ];

      return {
        ...state,
        [roomId]: newRoomValues
      };
    }

    case atypes.DELETE_CHECKLIST_VALUE_SUCCESS: {
      const { roomId, id: fieldId } = payload;

      const checklistValues = state[roomId] || [];

      const newChecklistValues = checklistValues.map(item => {
        if (item.fieldId !== fieldId) return item;

        if (payload.value.type !== "link") return item;

        return linkedFieldDeleteHandler({ item, payload });
      });

      return {
        ...state,
        [roomId]: newChecklistValues
      };
    }

    case atypes.REPLACE_CHECKLIST_VALUE: {
      const { chatroomId: roomId } = payload;
      return {
        ...state,
        [roomId]: [payload]
      };
    }

    default:
      return state;
  }
};

const instances = (
  state: Array<ChecklistInstances> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_INSTANCES_SUCCESS:
      return payload.checklist;
    default:
      return state;
  }
};

// Set by approval fields
const lockedFields = (
  state: $PropertyType<WorkflowChecklists, "lockedFields"> = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);

    case atypes.SET_LOCKED_CHECKLIST_FIELDS: {
      const { roomId, fields } = payload;
      return {
        ...state,
        [roomId]: fields
      };
    }

    default:
      return state;
  }
};

// Set by approval fields
const hiddenFields = (
  state: $PropertyType<WorkflowChecklists, "hiddenFields"> = { byRoom: {} },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return R.omit([payload.roomId], state);

    case atypes.SET_HIDDEN_CHECKLIST_FIELDS: {
      const { roomId, fields } = payload;
      return {
        ...state,
        [roomId]: fields
      };
    }

    default:
      return state;
  }
};

const workflowChecklists = combineReducers({
  checklists,
  values,
  instances,
  lockedFields,
  hiddenFields
});

export default workflowChecklists;

export const getCurrentChecklist = (state: WorkflowChecklists) =>
  state.checklists;

export const getFirstChecklist = (state: WorkflowChecklists) =>
  state.checklists.get(0);

export const getChecklistValue = (
  state: WorkflowChecklists,
  id: ?FieldId,
  roomId: RoomId
) => {
  if (!id || !state.values[roomId]) return null;

  return R.find(R.propEq("fieldId", id))(state.values[roomId]);
};

export const getChecklistInstances = (state: WorkflowChecklists) =>
  state.instances ? state.instances : [];

export const getChecklistFieldVisibility = (
  state: WorkflowChecklists,
  id: number,
  roomId: RoomId
) => !(state.hiddenFields[roomId] || []).includes(id);

export const getLockedFields = (state: WorkflowChecklists) =>
  state.lockedFields;

export const getLockedStatus = createSelector(
  [getLockedFields],
  lockedFields => (roomId, id, behavior) => {
    const isFieldLocked = (lockedFields[roomId] || []).includes(id);
    const isBehaviourDisabled =
      behavior?.current === behaviorToSettings[behaviors.disableField];
    return isFieldLocked || isBehaviourDisabled;
  }
);
