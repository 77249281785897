// // @flow

import { connect } from "react-redux";
import React from "react";

import { Heading } from "@chakra-ui/react";
import {
  List as StyledList,
  OrgItem as StyledItem,
  ListContainer
} from "src/styles/splitPane";

import { setOrgSettings } from "src/actions/orgSettings";
import type { AppState } from "src/types";

type Props = {
  current: ?string,
  _setOrgSettings: Function
};

const listItems = [
  { id: "home-screen", title: "Home Screen" },
  { id: "org-details", title: "Org Details" },
  { id: "role-management", title: "Role Management" }
];

const List = ({ current, _setOrgSettings }: Props) => (
  <StyledList>
    <Heading
      as="h2"
      sx={{
        fontSize: "24px",
        lineHeight: 1.2,
        fontWeight: 700,
        letterSpacing: 0
      }}
    >
      Org Settings
    </Heading>

    <ListContainer>
      {listItems.map(item => (
        <StyledItem
          key={item.id}
          active={current === item.id}
          onClick={() => _setOrgSettings(item.id)}
        >
          <Heading
            as="h4"
            size="sm"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: 1.2
            }}
          >
            {item.title}
          </Heading>
        </StyledItem>
      ))}
    </ListContainer>
  </StyledList>
);

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: app.orgSettings.current
});

export default connect(mapStateToProps, {
  _setOrgSettings: setOrgSettings
})(List);
