// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";
import type { Map as ImmutableMap } from "immutable";
import { getSelectedChecklist, getWorkflowSelectedRows } from "src/reducers";
import Field from "src/components/Manage/Workflow/Instances/ChecklistField";

import { closeChecklistManageView } from "src/actions/checklist";
import { getChecklistFieldDetails } from "src/reducers";
import { Modal, Overlay } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";

import type { AppState, SelectedChecklist } from "src/types";

type Props = {
  details: ImmutableMap<string, any>,
  selectedChecklist: SelectedChecklist,
  _closeChecklistManageView: Function,
  isBulkUpdate: boolean,
  Portal: Function,
  isOpen: boolean,
  isParent: boolean,
  closePortal: Function
};

const EditChecklistModal = ({
  details,
  selectedChecklist,
  isBulkUpdate,
  Portal,
  closePortal,
  isParent
}: Props) => {
  const { fieldId, value, roomId, columnId } = selectedChecklist;
  const type = details ? details.get("type") : "";

  useEffect(() => {
    // Handle bulk update of form without the modal
    if (R.isEmpty(selectedChecklist) || (type === "form" && isBulkUpdate)) {
      closePortal();
    }
  }, [selectedChecklist, type]);

  if (R.isEmpty(selectedChecklist)) {
    return <></>;
  }

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={closePortal}>
          <Modal isParent={isParent}>
            <Field
              id={fieldId}
              roomId={`${roomId}`}
              columnId={columnId}
              type={type}
              selectedValue={value}
              handleClose={closePortal}
              fromManageView
            />
          </Modal>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const selectedChecklist = getSelectedChecklist(app);
  const selectedRows = getWorkflowSelectedRows(app) || [];
  const isBulkUpdate = selectedRows.length > 1;
  return {
    selectedChecklist,
    isBulkUpdate,
    details: getChecklistFieldDetails(app, `${selectedChecklist.fieldId}`)
  };
};

export default connect(mapStateToProps, {
  _closeChecklistManageView: closeChecklistManageView
})(EditChecklistModal);
