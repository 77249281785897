// @flow
import React from "react";

import { Dot1, Dot2, Dot3, Loader as StyledLoader } from "./styles";

import { Button as StyledButton } from "src/styles/buttons";

const Loader = () => {
  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledButton
      type="button"
      onClick={preventDefault}
      onKeyDown={preventDefault}
    >
      <StyledLoader>
        <Dot1 />
        <Dot2 />
        <Dot3 />
      </StyledLoader>
    </StyledButton>
  );
};

export default Loader;
