// @flow

import React from "react";

import { Grid, GridItem, HStack, VStack, Text } from "@chakra-ui/react";

import type { Stitch as StitchType } from "src/types";

import Icon from "src/icons";
import Select from "src/components/Select";
import StitchData from "./StitchData";
import * as styles from "./styles";

const stitchTypes = {
  api: "PDF Generator API",
  template: "Adobe Generate PDF from Template",
  nativeFile: "Adobe Convert Native File to PDF"
};

type Props = {
  data: StitchType,
  onUpdate: Function
};

const getStitchType = stitch => {
  if (stitch.templateId !== undefined) {
    return "api";
  }

  if (stitch.fieldId !== undefined) {
    return "nativeFile";
  }

  return "template";
};

const valueMap = {
  api: "templateId",
  nativeFile: "fieldId",
  template: "name"
};

export default function Stitch({ data, onUpdate }: Props) {
  const stitchType = getStitchType(data);
  const valueKey = valueMap[stitchType];

  const setStitchType = newType => {
    onUpdate(data.seqNo, {
      seqNo: data.seqNo,
      [valueMap[newType]]: null
    });
  };

  const updateStitchData = newData => {
    onUpdate(data.seqNo, { ...data, [valueKey]: newData });
  };

  return (
    <Grid {...styles.StitchContainer}>
      <GridItem>
        <VStack {...styles.StitchItem}>
          <HStack>
            <span style={styles.StitchLabel}>TYPE</span>
            <Icon type="info" filled width="13" height="13" />
          </HStack>

          <Select
            value={stitchType}
            label={stitchTypes[stitchType]}
            onChange={setStitchType}
            width="100%"
          >
            {Object.keys(stitchTypes).map(item => (
              <Text key={item} value={item} my={0}>
                {stitchTypes[item]}
              </Text>
            ))}
          </Select>
        </VStack>
      </GridItem>

      <GridItem>
        {/* $FlowFixMe */}
        <StitchData
          stitchType={stitchType}
          // $FlowFixMe
          value={data[valueKey]}
          onUpdate={updateStitchData}
        />
      </GridItem>
    </Grid>
  );
}
