// @flow

import React from "react";
import moment from "moment";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { Text } from "@chakra-ui/react";
import Title from "./Title";
import Priority from "./Priority";
import Status from "./Status";
import DueDate from "./DueDate";
import Parent from "./Parent";
import Owner from "./Owner";
import Members from "./Members";
import Description from "./Description";
import {
  SeqNo,
  User as StyledUser,
  CenterCell,
  Cell,
  Age as StyledAge
} from "./styles";
import { ValueWrapper } from "./ChecklistItem/styles";
import User from "src/containers/user/ImageLabel/Small";
import {
  size,
  manageViewFieldWidthMap
} from "src/constants/processInstanceColumns";
import { getFieldWidth } from "src/reducers";

import type { WorkflowInstances } from "src/types";

type Props = {
  selected: boolean,
  last?: boolean,
  index: number,
  column: string,
  expand: boolean,
  depth: number,
  parentTitle?: string,
  parentId?: ?number,
  toggleExpand: Function,
  process: WorkflowInstances,
  isPrivate: boolean
};

const allowedColumns = ["seqNo", "title"];

const Item = ({
  selected,
  index,
  last,
  column,
  depth,
  expand,
  process,
  parentTitle,
  parentId,
  toggleExpand,
  isPrivate
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, column));

  // If isPrivate and column is not allowed then render "Private"
  if (isPrivate && !allowedColumns.includes(column)) {
    return (
      <Cell width={fieldWidth} minWidth={manageViewFieldWidthMap[column]}>
        <Text textStyle="privateConversationTitle" as="i">
          Private
        </Text>
      </Cell>
    );
  }

  if (!R.keys(process).includes("seqNo") && column !== "title") {
    return (
      <Cell
        width={fieldWidth}
        minWidth={manageViewFieldWidthMap[column]}
      ></Cell>
    );
  }

  switch (column) {
    case "seqNo":
      return (
        <SeqNo
          width={manageViewFieldWidthMap[column]}
          selected={selected}
          depth={depth}
        >
          <ValueWrapper>{depth > 0 ? "" : process.autoNo}</ValueWrapper>
        </SeqNo>
      );
    case "title":
      return (
        <Title
          selected={selected}
          index={index}
          id={process.chatroomId}
          title={process.title}
          parentId={parentId}
          depth={depth}
          last={last}
          canceled={process.canceled}
          address={process.address}
          seqNo={process.seqNo}
          autoNo={process.autoNo}
          processTitle={process.processTitle}
          expand={expand}
          toggleExpand={toggleExpand}
          childCount={process.childCount || 0}
        />
      );
    case "priority":
      return (
        <Priority
          selected={selected}
          index={index}
          depth={depth}
          id={process.chatroomId}
          value={process.priority}
          parentId={parentId}
        />
      );
    case "status":
      return (
        <Status
          selected={selected}
          id={process.chatroomId}
          value={process.status}
          index={index}
          parentId={parentId}
          depth={depth}
          templateId={process.id}
        />
      );
    case "dueDate":
      return (
        <DueDate
          selected={selected}
          id={process.chatroomId}
          value={process.dueDate}
          index={index}
          parentId={parentId}
          depth={depth}
          active={process.active}
          templateId={process.id}
        />
      );
    case "parent":
      return (
        <Parent
          selected={selected}
          id={process.parentId}
          title={depth > 0 ? parentTitle : process.parentTitle}
          index={index}
          depth={depth}
          chatroomId={process.chatroomId}
          parentId={parentId}
        />
      );
    case "childCount":
      return (
        <CenterCell
          width={fieldWidth}
          minWidth={size[column]}
          selected={selected}
        >
          <ValueWrapper>{process.childCount}</ValueWrapper>
        </CenterCell>
      );
    case "createdAt":
      return (
        <Cell selected={selected} width={fieldWidth} minWidth={size[column]}>
          <ValueWrapper>
            {process.createdAt
              ? moment(process.createdAt).format("MMM DD, YYYY hh:mm a")
              : "_"}
          </ValueWrapper>
        </Cell>
      );
    case "updatedAt":
      return (
        <Cell selected={selected} width={fieldWidth} minWidth={size[column]}>
          <ValueWrapper>
            {process.updatedAt
              ? moment(process.updatedAt).format("MMM DD, YYYY hh:mm a")
              : "_"}
          </ValueWrapper>
        </Cell>
      );
    case "completedAt":
      return (
        <Cell selected={selected} width={fieldWidth} minWidth={size[column]}>
          <ValueWrapper>
            {process.completedAt
              ? moment(process.completedAt).format("MMM DD, YYYY hh:mm a")
              : "_"}
          </ValueWrapper>
        </Cell>
      );
    case "age":
      return (
        <StyledAge
          width={fieldWidth}
          completed={process.active === false}
          selected={selected}
        >
          <ValueWrapper>{process.age}</ValueWrapper>
        </StyledAge>
      );
    case "creator":
    case "completedBy":
      return (
        <StyledUser
          selected={selected}
          width={fieldWidth}
          minWidth={size[column]}
        >
          <ValueWrapper>
            <User uid={process.creator} />
          </ValueWrapper>
        </StyledUser>
      );
    case "owner":
      return (
        <Owner
          selected={selected}
          id={process.chatroomId}
          value={process.owner}
          index={index}
          parentId={parentId}
          depth={depth}
        />
      );
    case "members":
      return (
        <Members
          selected={selected}
          id={process.chatroomId}
          value={process.members}
          index={index}
          count={(process.memberCount || 0) > 3 ? process.memberCount - 3 : 0}
        />
      );
    case "description":
      return (
        <Description
          selected={selected}
          id={process.chatroomId}
          description={process.description}
          index={index}
          parentId={parentId}
          depth={depth}
        />
      );
    case "revision":
      return (
        <Cell
          width={fieldWidth}
          minWidth={manageViewFieldWidthMap.revision}
          selected={selected}
        >
          <ValueWrapper>
            <span>{process.version}</span>
          </ValueWrapper>
        </Cell>
      );
    default:
      return (
        <CenterCell selected={selected} width={fieldWidth} minWidth="200px">
          <ValueWrapper>{column}</ValueWrapper>
        </CenterCell>
      );
  }
};

Item.defaultProps = {
  last: false,
  parentId: null,
  parentTitle: ""
};

export default Item;
