// @flow

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  HStack,
  Text,
  Center,
  Skeleton
} from "@chakra-ui/react";

import {
  getFileSize,
  getFilePreviewSupport,
  getFileSourceDisplayName
} from "src/utils/file";
import { getFilePreviewURL } from "src/reducers";

import * as styles from "./styles";
import FilePreview from "src/components/messages/Chat/Notification/FileOrEmail/FilePreview";

import type { UnifizeFile } from "src/types";

type Props = {
  file: UnifizeFile,
  triggerMode?: "hover" | "click",
  children: React$Element<*>
};

const HoverPreview = ({ file, triggerMode = "hover", children }: Props) => {
  const fileName = file?.originalName;
  const fileSource = file?.source;

  const filePreview = useSelector(({ app }) =>
    getFilePreviewURL(app, file?.name)
  );

  const hasPreviewSupport = getFilePreviewSupport(file);
  const fileUploadedTime = file?.uploadTime
    ? moment(file?.uploadTime).format("MMM DD, YYYY")
    : "";
  const fileSourceDisplayName = getFileSourceDisplayName(fileSource);

  return (
    <Popover trigger={triggerMode}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent {...styles.popover.content}>
          <PopoverBody {...styles.popover.body}>
            <Text {...styles.popover.fileName}>{fileName}</Text>

            <HStack {...styles.popover.fileData}>
              <Text>{getFileSize(file?.size)}</Text>
              <Text>|</Text>
              <Text>Uploaded on: {fileUploadedTime}</Text>
              {fileSourceDisplayName && (
                <>
                  <Text>|</Text>
                  <Text>{fileSourceDisplayName}</Text>
                </>
              )}
            </HStack>

            {!hasPreviewSupport && (
              <Center {...styles.popover.noPreview.container}>
                <Text {...styles.popover.noPreview.text}>
                  No Preview Available
                </Text>
              </Center>
            )}

            {hasPreviewSupport &&
              (filePreview ? (
                <FilePreview preview={filePreview?.url} />
              ) : (
                <Skeleton height="372px" width="100%" />
              ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default HoverPreview;
