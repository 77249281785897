// @flow

import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { getFileType } from "src/utils/file";
import { openViewer } from "src/actions/file";
import { fetchFileUrlV2 } from "src/api/file";

import type { RoomId, UnifizeFile } from "src/types";

type Params = {
  roomId: ?RoomId,
  file: ?UnifizeFile,
  modal: boolean
};

const useFileViewer = ({ roomId, file, modal }: Params) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    fetching: false,
    url: null,
    error: null
  });

  const openFile = async () => {
    if (!file || !roomId) return;
    if (file.source === "microsoft365" && file) {
      setData({
        fetching: true,
        url: null,
        error: null
      });

      try {
        const res = await fetchFileUrlV2({
          roomId,
          name: file.name,
          generation: file.generation,
          versionId: file.versionId
        });

        if (!res.url) throw new Error();

        window.open(res.url);

        setData({
          fetching: false,
          url: res.url,
          error: null
        });
      } catch (error) {
        console.error("Open file error", { error, cause: error.cause });
        if (error.cause?.response?.status === 403) {
          toast.error(
            error.cause?.result?.error ||
              "This file has not been shared with you"
          );
        }

        setData({
          fetching: false,
          error,
          url: null
        });
      }
    } else {
      dispatch(
        openViewer({
          roomId,
          location: modal ? "conversation-dialog" : "chat-history",
          type: getFileType(file.originalName),
          name: file.name,
          generation: file.generation,
          versionId: file.versionId,
          mimeType: file.mimeType
        })
      );
    }
  };

  return { data, openFile };
};

export default useFileViewer;
