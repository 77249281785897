// @flow

import { combineReducers } from "redux";
import * as R from "ramda";
import moment from "moment";

import type { Action, ProcessIdMap, FilterTimeMap } from "src/types";

import * as atypes from "src/constants/actionTypes";

const involvedProcesses = (
  state: ProcessIdMap = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_INVOLVED_PROCESSES:
      return R.mergeWith(R.add, state, payload);
    default:
      return state;
  }
};

const standardLastUpdated = (
  state: FilterTimeMap = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_STANDARD_FILTER_LAST_UPDATED:
      return {
        ...state,
        ...R.mapObjIndexed((val, key) => {
          if (!R.has(key, state) || val.isAfter(state[key])) {
            return val;
          }
          return state[key];
        }, payload)
      };
    default:
      return state;
  }
};

const standardLastRead = (
  state: FilterTimeMap = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_STANDARD_FILTER_LAST_READ_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case atypes.SYNC_STANDARD_FILTER_LAST_READ_SUCCESS:
      return R.map(val => moment(val.toDate()), payload);
    default:
      return state;
  }
};

const customLastUpdated = (
  state: FilterTimeMap = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CUSTOM_FILTER_LAST_UPDATED_SUCCESS:
      return {
        ...state,
        ...R.mapObjIndexed((val, key) => {
          if (!R.has(key, state) || val.isAfter(state[key])) {
            return val;
          }
          return state[key];
        }, payload)
      };
    default:
      return state;
  }
};

const customLastRead = (
  state: FilterTimeMap = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CUSTOM_FILTER_LAST_READ_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case atypes.SYNC_CUSTOM_FILTER_LAST_READ_SUCCESS:
      return R.map(val => moment(val.toDate()), payload);
    default:
      return state;
  }
};

const standard = combineReducers({
  lastUpdated: standardLastUpdated,
  lastRead: standardLastRead
});

const custom = combineReducers({
  lastUpdated: customLastUpdated,
  lastRead: customLastRead
});

const pinnedFilters = combineReducers({
  involvedProcesses,
  standard,
  custom
});

export default pinnedFilters;
