//@flow

import React from "react";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import AllIcon from "../all.svg";
import Owner from "../Owner";
import type { UID } from "src/types";
import OwnerImage from "../owner.png";
import CreatorImage from "../creator.png";

import {
  MentionWrapper,
  AvatarContainer,
  AvatarWrapper,
  UserDetailsContainer,
  UserDetailsWrapper,
  UserMentionSubtext
} from "./styles";

type Props = {
  userDisplayName: string,
  userEmail: string,
  userUID: UID,
  userPhotoUrl: string
};

function UserMention({
  userDisplayName,
  userEmail,
  userUID,
  userPhotoUrl = ""
}: Props) {
  const isAll = userUID == "!everyone";
  const isOwner = userUID == "!owner";
  const isCreator = userUID == "!creator";
  const isSignatory = userUID == "!signatories";

  const bg = isAll || isOwner || isCreator || isSignatory ? "white" : undefined;

  const _getPhotoUrl = () => {
    if (isAll || isSignatory) {
      return AllIcon;
    }

    if (isOwner) {
      return OwnerImage;
    }

    if (isCreator) {
      return CreatorImage;
    }

    return userPhotoUrl;
  };

  const _getInfoText = () => {
    if (isAll) {
      return <Text>(every participant of the conversation)</Text>;
    }

    if (isOwner) {
      return <Text>(current owner of the conversation)</Text>;
    }

    if (isCreator) {
      return <Text>(creator of the conversation)</Text>;
    }

    return null;
  };

  const _getDisplayText = () => {
    if (isAll) {
      return "All";
    }

    if (isSignatory) {
      return "Signatories";
    }

    if (isOwner) {
      return "Owner";
    }

    if (isCreator) {
      return "Creator";
    }

    return userDisplayName;
  };

  return (
    <Box>
      <Flex sx={MentionWrapper}>
        <Flex sx={AvatarContainer}>
          <Avatar
            name={userDisplayName}
            src={_getPhotoUrl()}
            id="chatIconMention"
            size="sm"
            style={{
              background: bg
            }}
            sx={AvatarWrapper}
          />
        </Flex>
        <Flex sx={UserDetailsContainer}>
          <Flex sx={UserDetailsWrapper}>
            <Box
              sx={{
                fontSize: "12px"
              }}
            >
              {_getDisplayText()}
            </Box>
            <Box>
              {userEmail ? <p style={UserMentionSubtext}>{userEmail}</p> : null}
            </Box>
          </Flex>
          {_getInfoText()}
          <Owner uid={userUID} />
        </Flex>
      </Flex>
    </Box>
  );
}

export default UserMention;
