// @flow

import React from "react";

import Privacy from "./Privacy";
import Objective from "./Objective";
import Files from "./Files";
import Checklist from "./Checklist";
import Info from "./Info";
import Details from "./Details";

import type { RoomId } from "src/types";

type Props = {
  activeDock: string,
  room: RoomId,
  isSrw: boolean,
  showClose: boolean
};

const Panels = ({ activeDock, room, isSrw, showClose }: Props) => {
  switch (activeDock) {
    case "info":
      return <Info room={room} activeDock={activeDock} isSrw={isSrw} />;
    case "checklist":
      return (
        <Checklist
          room={room}
          activeDock={activeDock}
          isSrw={isSrw}
          showClose={showClose}
        />
      );
    case "files":
      return <Files room={room} activeDock={activeDock} isSrw={isSrw} />;
    case "objective":
      return <Objective room={room} activeDock={activeDock} isSrw={isSrw} />;
    case "privacy":
      return <Privacy room={room} activeDock={activeDock} isSrw={isSrw} />;
    case "details":
      return <Details room={room} activeDock={activeDock} isSrw={isSrw} />;
    default:
      return <></>;
  }
};

export default Panels;
