// @flow

import React from "react";
import { connect } from "react-redux";

import type {
  State,
  FieldId,
  RoomId,
  ChecklistValue,
  ChecklistField
} from "src/types";
import { setChecklistValue } from "src/actions/checklist";
import { getChecklistFieldDetails } from "src/reducers";
import useFields from "src/components/Dock/Checklist/useFields";

import RevisionField from "src/components/Dock/Checklist/Revision";

type Props = {
  fieldId: FieldId,
  roomId: RoomId,
  details: ChecklistField,
  checklistValue: ChecklistValue,
  setChecklistValue: Function
};

function Popover({
  roomId,
  fieldId,
  details,
  checklistValue,
  setChecklistValue
}: Props) {
  const { settings } = useFields({
    details,
    checklistValue
  });

  return (
    <RevisionField
      settings={settings}
      checklistValue={checklistValue}
      setChecklistValue={setChecklistValue}
      roomId={roomId}
      fieldId={fieldId}
      formId={null}
      scrollContent
    />
  );
}

const mapStateToProps = ({ app }: State, { fieldId }: Props) => ({
  details: getChecklistFieldDetails(app, `${fieldId}`)
});

const mapDispatchToProps = {
  setChecklistValue
};

export default connect(mapStateToProps, mapDispatchToProps)(Popover);
