// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import type {
  AppState,
  ApprovalFieldSettingsV2,
  ApprovalFieldAdhocConfig,
  UID
} from "src/types";
import { multiSelectHandler } from "src/utils";
import { getUserCount } from "src/reducers/users";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Button,
  HStack
} from "@chakra-ui/react";
import GroupMultiSelect from "src/containers/Group/MultiSelect";
import UserSelectMultiple from "src/containers/user/SelectMultiple.new";

type Props = {
  isOpen: boolean,
  onClose: Function,
  settings: ApprovalFieldSettingsV2,
  saveConfig: Function,
  signatories: UID[]
};

const AdHocModal = ({
  isOpen,
  onClose,
  settings,
  saveConfig,
  signatories
}: Props) => {
  const [config, setConfig] = useState<ApprovalFieldAdhocConfig>({
    minApprovers: 1,
    approvers: {
      users: [],
      groups: []
    }
  });

  const memberCount = useSelector(({ app }: { app: AppState }) =>
    getUserCount(app, [...config.approvers.users, ...config.approvers.groups])
  );

  const handleMinApproversChange = value => {
    setConfig(
      R.mergeDeepLeft({
        minApprovers: Number(value)
      })
    );
  };

  const handleMultiSelectChange = path => (action, item) => {
    setConfig(prev =>
      R.assocPath(
        path,
        multiSelectHandler({
          action,
          // $FlowFixMe
          list: R.path(path, prev),
          item
        }),
        prev
      )
    );
  };

  const handleSaveConfig = () => {
    saveConfig(
      R.mergeDeepLeft({
        approvers: {
          groups: config.approvers.groups.map(Number)
        }
      })(config)
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configure Ad-hoc Signature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel>Number of signatories</FormLabel>
              <NumberInput
                size="sm"
                value={config.minApprovers}
                onChange={handleMinApproversChange}
                min={1}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Users</FormLabel>

              <UserSelectMultiple
                value={config.approvers.users}
                superset={R.isEmpty(signatories) ? null : signatories}
                onChange={handleMultiSelectChange(["approvers", "users"])}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Groups</FormLabel>

              <GroupMultiSelect
                value={config.approvers.groups}
                superset={
                  R.isEmpty(settings.approvers.groups)
                    ? null
                    : settings.approvers.groups
                }
                onChange={handleMultiSelectChange(["approvers", "groups"])}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter
          sx={{
            justifyContent: "flex-start"
          }}
        >
          <HStack>
            <Button variant="uSecondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="uBlueFilled"
              onClick={handleSaveConfig}
              disabled={
                (R.isEmpty(config.approvers.users) &&
                  R.isEmpty(config.approvers.groups)) ||
                config.minApprovers < 1 ||
                memberCount < config.minApprovers
              }
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdHocModal;
