// @flow

import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  createContext
} from "react";
import * as R from "ramda";
import { connect, useDispatch } from "react-redux";
import Measure from "react-measure";
import {
  setChecklistValue,
  getChecklistFieldValue
} from "src/actions/checklist";
import { uploadFileToChecklist } from "src/actions/file";
import {
  getChecklistValue,
  getChecklistFieldDetails,
  getChecklistFieldVisibility,
  getChecklistFieldBehavior,
  getWhetherMandatoryField,
  getRoomFieldValueStatus,
  getChatRoom
} from "src/reducers";
import type {
  AppState,
  ChecklistId,
  FieldId,
  ChecklistValue,
  RoomId,
  DataStage,
  ChecklistField,
  ChecklistFieldTypes,
  HttpMethods
} from "src/types";
import { useDropzone } from "react-dropzone";
import uuid from "uuid/v4";
import * as morpheus from "src/utils/morpheus";
import { Tooltip } from "@chakra-ui/react";

import SkeletonLoader from "src/components/LoadingState";

import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import Conversation from "./Conversation";
import Picklist from "./Picklist";
import File from "./File";
import ChecklistUsers from "./ChecklistUsers";
import useFields from "./useFields";
import Form from "./Form";
import Approval from "./Approval";
import { Container, ChecklistLabel, More, FieldContainer } from "./styles";
import { toast } from "react-toastify";
import Revision from "./Revision";
import LinkedField from "./LinkedField";
import location from "src/constants/location";

import { behaviorToSettings, behaviors } from "src/conditions";
import { dataStages } from "src/constants";
import { getLinkedFieldSettings } from "src/utils/checklist";
import useOverflowCheck from "src/hooks/useOverflowCheck";
interface MyContextType {
  fieldWidth: number;
}

const defaultContextValue: MyContextType = {
  fieldWidth: 0
};
const ChecklistFieldContext = createContext<MyContextType>(defaultContextValue);
type Props = {
  details: ChecklistField,
  roomId: RoomId,
  checklistValue: ChecklistValue,
  id: FieldId,
  checklistId?: ChecklistId,
  _setChecklistValue: Function,
  title: string,
  isFieldInsideSection: boolean,
  value: any,
  formId?: ?number,
  locked: boolean,
  hidden: boolean,
  _uploadFileToChecklist: Function,
  behavior: Object,
  isMandatory: boolean,
  valueStatus: ?DataStage,
  label: string
};

const Field = ({
  details,
  roomId,
  checklistValue,
  id,
  checklistId,
  formId,
  _setChecklistValue,
  isFieldInsideSection,
  locked,
  hidden,
  _uploadFileToChecklist,
  behavior,
  isMandatory,
  valueStatus,
  label
}: Props) => {
  const [showMore, setShowMore] = useState(false);
  const type = details ? details.get("type") : "";
  const [fieldWidth, setFieldWidth] = useState(0);

  const dispatch = useDispatch();

  const labelRef = useRef();
  const hasLabelOverflown = useOverflowCheck(labelRef);

  let FieldComp = null;

  const {
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  } = useFields({
    checklistValue,
    details
  });

  useEffect(() => {
    if (R.isNil(value)) {
      dispatch(getChecklistFieldValue(roomId, id, true));
    }
  }, [value]);

  const setFieldValue = ({
    value,
    httpMethod,
    extraBody
  }: {
    value: any,
    httpMethod?: HttpMethods,
    extraBody?: Object
  }) => {
    _setChecklistValue({
      roomId,
      id,
      value: {
        value,
        type,
        checked: true
      },
      progress: true,
      formId,
      httpMethod,
      extraBody
    });
  };

  const forwardProps = {
    fieldId: id,
    checklistId,
    formId,
    roomId,
    details,
    checklistValue,
    setChecklistValue: _setChecklistValue,
    // **** Use this please ****
    setFieldValue,
    edit,
    settings,
    value,
    setValue,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    locked,
    isMandatory,
    valueStatus,
    location: location.checklistDock
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error("Cannot upload multiple files to single file field.");
        return;
      }

      const storageFileName = uuid();

      _uploadFileToChecklist({
        fileData: settings?.multiple ? acceptedFiles : acceptedFiles[0],
        roomId,
        storageFileName,
        fieldId: id,
        value: (value || []).map(file =>
          typeof file === "object" ? file.name : file
        ),
        multiple: settings?.multiple,
        location: "checklist-upload",
        dispatch,
        formId
      });
    },
    [
      _uploadFileToChecklist,
      roomId,
      id,
      value,
      settings,
      setChecklistValue,
      formId
    ]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: settings?.multiple ? 0 : 1
  });
  const dragAndDropProps = type === "file" && !locked ? getRootProps() : {};

  switch (type) {
    case "file":
    case "pdf":
      FieldComp = <File {...forwardProps} type={type} />;
      break;
    case "text":
      FieldComp = <Text {...forwardProps} />;
      break;
    case "number":
      FieldComp = <Number {...forwardProps} />;
      break;
    case "date":
      FieldComp = <Date {...forwardProps} />;
      break;
    case "select":
      FieldComp = <Picklist {...forwardProps} />;
      break;
    case "user":
      FieldComp = <ChecklistUsers {...forwardProps} />;

      break;
    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      FieldComp = <Conversation {...forwardProps} />;
      break;
    case "childConversation":
      FieldComp = <Conversation {...forwardProps} parentConversation />;
      break;
    case "form":
      FieldComp = <Form {...forwardProps} />;
      break;
    case "approval":
      FieldComp = (
        <Approval
          {...forwardProps}
          settings={morpheus.approval(forwardProps.settings)}
        />
      );
      break;
    case "revision":
      FieldComp = <Revision {...forwardProps} />;
      break;
    case "link":
      FieldComp = <LinkedField {...forwardProps} />;
      break;
    default:
      FieldComp = null;
      break;
  }

  const handleResize = content => {
    setFieldWidth(content.bounds.width);
  };

  if (type !== "section" && R.isNil(valueStatus)) return null;

  if (type !== "section" && valueStatus && valueStatus <= dataStages.fetching)
    return <SkeletonLoader type="checklistField" />;

  if (behavior?.current === "hidden") return null;

  return (
    !hidden &&
    (type === "section" ? null : (
      <ChecklistFieldContext.Provider value={{ fieldWidth }}>
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <FieldContainer
              ref={measureRef}
              isFieldInsideSection={isFieldInsideSection}
            >
              <Tooltip
                hasArrow
                label={label.length > 30 ? label : ""}
                sx={{ backgroundColor: "unifize_unifizeBlue" }}
              >
                <ChecklistLabel
                  isFieldInsideSection={isFieldInsideSection}
                  more={showMore}
                  ref={labelRef}
                >
                  {label}
                  {/* Show more button if label overflows */}
                  {hasLabelOverflown && !showMore && (
                    <More onClick={() => setShowMore(true)}>...more</More>
                  )}
                </ChecklistLabel>
              </Tooltip>
              <Container
                {...dragAndDropProps}
                isDragActive={isDragActive}
                isFieldInsideSection={isFieldInsideSection}
              >
                {FieldComp}
              </Container>
            </FieldContainer>
          )}
        </Measure>
      </ChecklistFieldContext.Provider>
    ))
  );
};

const getFieldLabel = ({
  templateId,
  details,
  type
}: {
  templateId: ?number,
  details: ?ChecklistField,
  type: ChecklistFieldTypes
}): string => {
  let label = details ? details.get("label") : "";

  if (details && type === "link" && templateId) {
    // $FlowFixMe
    const settings = getLinkedFieldSettings({ templateId, details });
    label = settings.label;
  }

  return label;
};

const mapStateToProps = (
  { app }: { app: AppState },
  { id, roomId }: { id: FieldId, roomId: RoomId }
) => {
  const behavior = getChecklistFieldBehavior(app, { roomId, fieldId: id });
  const { templateId } = getChatRoom(app, roomId);

  const details = getChecklistFieldDetails(app, `${id}`);

  const type = details ? details.get("type") : "text";
  const fieldHidden = details ? details.get("hidden") : false;

  const label = getFieldLabel({ templateId, details, type });

  return {
    valueStatus: getRoomFieldValueStatus(app, id, roomId),
    checklistValue: getChecklistValue(app, id, roomId),
    details,
    label,
    locked:
      (app.workflowChecklists.lockedFields[roomId] || []).includes(id) ||
      behavior?.current === behaviorToSettings[behaviors.disableField],
    hidden:
      fieldHidden || getChecklistFieldVisibility(app, id, roomId) === false,
    behavior,
    isMandatory: getWhetherMandatoryField(app, id)
  };
};

Field.defaultProps = {
  formId: null
};

export default connect(mapStateToProps, {
  _setChecklistValue: setChecklistValue,
  _uploadFileToChecklist: uploadFileToChecklist
})(Field);

export { ChecklistFieldContext };
