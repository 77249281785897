// @flow

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getCurrentUserId, getUsersFromIds } from "src/reducers";
import type {
  State,
  UID,
  RoomId,
  FieldId,
  RevisionFieldSettings,
  RevisionFieldValue
} from "src/types";
import { getUserDisplayNames } from "src/utils";
import { getTooltipText } from "./utils";

import { Tooltip, VStack } from "@chakra-ui/react";
import Button from "src/components/Buttons/ChakraButton";
import RevisionConversation from "src/components/Dock/Checklist/Conversation/Revision";
import ConfirmModal from "./ConfirmModal";
import RevisionModal from "./Modal";
import * as styles from "./styles";

type Props = {
  settings: RevisionFieldSettings,
  checklistValue: ?RevisionFieldValue,
  setChecklistValue: Function,
  roomId: RoomId,
  fieldId: FieldId,
  promptCallback?: ?Function,
  formId: number,
  currentUserId: UID,
  authorizedUserNames: string[],
  scrollContent: boolean
};

const modalStages = {
  confirm: 0,
  create: 1
};

function Revision({
  settings,
  setChecklistValue,
  currentUserId,
  authorizedUserNames,
  promptCallback,
  roomId,
  fieldId,
  formId,
  checklistValue,
  scrollContent
}: Props) {
  const [modalStage, setModalStage] = useState(null);
  const [revisionsCreated, setRevisionsCreated] = useState(false);
  const [revisionIds, setRevisionIds] = useState([]);
  const [highlightRevisionIds, setHighlightRevisionIds] = useState([]);

  const { authorizedUsers = [], copyableFields = [] } = settings;

  const allowed = authorizedUsers.includes(currentUserId);

  const create = value => {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value,
        type: "revision",
        checked: true
      },
      progress: true,
      formId
    });
    setRevisionsCreated(true);
  };

  const cancel = () => {
    setModalStage(null);
    if (promptCallback) promptCallback();
  };

  const start = () => {
    setModalStage(modalStages.confirm);
  };

  const confirm = () => {
    setModalStage(modalStages.create);
  };

  const renderModal = () => {
    switch (modalStage) {
      case modalStages.confirm:
        return <ConfirmModal open onClose={cancel} onSuccess={confirm} />;

      case modalStages.create:
        return (
          <RevisionModal
            open
            onClose={cancel}
            onSuccess={create}
            defaultCopyableFields={copyableFields}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    const newRevisionIds = (checklistValue?.value || []).map(room => room.id);
    setRevisionIds(newRevisionIds);

    let timeout;

    if (revisionsCreated) {
      setHighlightRevisionIds(R.difference(newRevisionIds, revisionIds));
      timeout = setTimeout(() => setHighlightRevisionIds([]), 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [checklistValue]);

  return (
    <VStack {...styles.fieldContainer({ scrollContent })}>
      <VStack {...styles.revisionList({ scrollContent })}>
        {(checklistValue?.value || []).map(room => (
          <RevisionConversation
            key={room.id}
            {...room}
            privacy={room.privacy}
            currentRoomId={roomId}
            fieldId={fieldId}
            highlight={highlightRevisionIds.includes(room.id)}
            disabled={!allowed}
          />
        ))}
      </VStack>

      <Tooltip
        label={!allowed && getTooltipText(authorizedUserNames)}
        whiteSpace="pre-wrap"
        hasArrow
      >
        <Button
          variant="add"
          onClick={start}
          isDisabled={!allowed}
          {...styles.createVersion({ scrollContent })}
        >
          Create Revision
        </Button>
      </Tooltip>
      {renderModal()}
    </VStack>
  );
}

Revision.defaultProps = {
  scrollContent: false,
  promptCallback: null
};

const mapStateToProps = ({ app }: State, { settings }: Props) => ({
  currentUserId: getCurrentUserId(app),
  authorizedUserNames: getUserDisplayNames(
    getUsersFromIds(app, settings.authorizedUsers)
  )
});

export default connect(mapStateToProps, null)(Revision);
