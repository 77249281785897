// @flow

import * as React from "react";
import { VStack, SystemStyleObject } from "@chakra-ui/react";

type Props = {
  children: React.Node,
  sx?: SystemStyleObject
};

const AccordionContent = ({ children, sx = {}, ...restProps }: Props) => (
  <VStack
    {...restProps}
    sx={{
      width: "100%",
      alignItems: "start",
      p: 3,
      ...sx
    }}
  >
    {children}
  </VStack>
);

export default AccordionContent;
