// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const initialState = [];

const roomGroups = (
  state: Array<number> = initialState,
  { type, payload }: Action
): Array<number> => {
  switch (type) {
    case atypes.ADD_ROOM_GROUP_SUCCESS:
      return [...state, ...payload];

    case atypes.REMOVE_ROOM_GROUP_SUCCESS:
      return state.filter(id => id !== payload.id);

    case atypes.LOAD_CHATROOM_SUCCESS:
      return payload.groups;

    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.OPEN_CONVERSATION_MODAL:
    case atypes.CLOSE_CONVERSATION_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default roomGroups;
