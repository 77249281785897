// @flow

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import * as R from "ramda";

import Loader from "src/components/Dashboard/Body/Loader";
import { getChartDataLoading, getChartReportId } from "src/reducers";
import { setReports } from "src/actions/reports";
import { updateInstanceFilter } from "src/actions/workflows";
import { setNewChartDialogAttributes, expandChart } from "src/actions/chart";
import { getModifiedChartData } from "src/utils/charts.js";

import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

type Props = {
  chartId: number | string,
  chartData: Object,
  height?: string,
  width?: string
};

const Visualization = ({
  chartId,
  chartData,
  height = "100%",
  width = "100%"
}: Props) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ app }) =>
    getChartDataLoading(app, parseInt(chartId))
  );
  const reportId = useSelector(({ app }) => getChartReportId(app, chartId));

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 20);
    return () => clearTimeout(timer);
  }, [setShow]);

  if (loading) {
    return <Loader />;
  }

  const dataplotClick = e => {
    window.openchart = (filters: string) => {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();

      dispatch(setNewChartDialogAttributes({ show: false }));
      dispatch(expandChart(chartId));

      // If the user is not on the manage view, then redirect to the
      // manage view.
      if (!location.pathname.includes("/reports")) {
        dispatch(setReports(reportId));
      }

      const parsedFilters = Object.entries(JSON.parse(filters)).reduce(
        (acc, [key, value]) => {
          // Replace > with - on filters to match our chart form
          // filter implementation
          return { ...acc, [key.replace(/>/g, "-")]: value };
        },
        {}
      );

      dispatch(
        updateInstanceFilter(
          // Make null or empty values "null" to make it work with blank filter.
          R.map(
            value => (value === null || R.isEmpty(value) ? "null" : value),
            parsedFilters
          )
        )
      );
    };
  };

  const modifiedChartData = getModifiedChartData({
    type: chartData.type,
    chartData,
    chartId,
    onDataPlotClick: dataplotClick
  });

  const chartConfigs = {
    type: modifiedChartData.type,
    height: height,
    width: width,
    dataFormat: "json",
    dataSource: modifiedChartData.dataSource,
    events: { dataplotClick },
    dataLoadErrorMessage:
      "Failed to load chart data. Please refresh after some time",
    typeNotSupportedMessage:
      "Failed to load chart data. Please refresh after some time"
  };

  return <>{show && <ReactFC {...chartConfigs} />}</>;
};

export default Visualization;
