// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import MessageCard from "./MessageNotch";
import Forward from "./Forward";
import {
  UserInfo,
  ChatCard,
  Name as StyledName,
  ForwardedMessage,
  MenuIcon
} from "./styles";
import User from "src/components/user/Photo";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import NameOrEmail from "src/containers/user/NameOrEmail";
import ReplyTo from "src/components/messages/ReplyTo";
import MessageText from "src/components/messages/MessageText";
import { getAuthorColor } from "src/utils";
import Icon from "src/icons";
import type { UID, RoomId, AppState } from "src/types";
import {
  getCurrentUserId,
  getCurrentRoomId,
  getChatroomAddress,
  getSelectedAction
} from "src/reducers";
import { selectMessage, showMobileOptions } from "src/actions/chatmenu";

type Props = {
  message: any,
  type: any,
  currentUser: UID,
  currentRoomAddress: RoomId,
  selectedAction: string,
  isSrwMobile: boolean,
  _selectMessage: Function,
  _showMobileOptions: Function
};

const Message = ({
  message,
  type,
  currentUser,
  currentRoomAddress,
  selectedAction,
  isSrwMobile,
  _selectMessage,
  _showMobileOptions
}: Props) => {
  const {
    id,
    author,
    timestamp,
    inReplyTo,
    origin,
    deleted,
    read,
    delivered
  } = message;

  const isCurrentUser = currentUser === author;

  const selectMode = useCallback(
    (e: any) => {
      if (!selectedAction) {
        _showMobileOptions();
        _selectMessage(id, timestamp, isCurrentUser);
        e.stopPropagation();
      }
    },
    [selectedAction, id, timestamp, isCurrentUser]
  );

  return (
    <ChatCard
      first={type === "first"}
      showSelect={selectedAction && selectedAction !== "MOBILE"}
    >
      {type === "first" ? (
        <UserInfo>
          <User uid={author} size="sm" shape="ellipse" />
        </UserInfo>
      ) : null}

      <MessageCard
        first={type === "first"}
        isCurrentUser={isCurrentUser}
        singleLine={
          message.text?.length > 76 && R.includes("```", message.text)
        } // Prevent scroll bar to appear over timestamp
      >
        {type === "first" && (
          <StyledName hashColor={getAuthorColor(author, currentRoomAddress)}>
            {isCurrentUser ? <span>You</span> : <NameOrEmail uid={author} />}
          </StyledName>
        )}

        {origin && <Forward message={message} />}
        {origin ? (
          <ForwardedMessage isCurrentUser={isCurrentUser}>
            <NameOrEmail uid={origin.author} />
            <MessageText message={message} isForwarded />
          </ForwardedMessage>
        ) : (
          <>
            {!deleted && (
              <ReplyTo id={inReplyTo} isCurrentUser={isCurrentUser} />
            )}
            <MessageText message={message} />
          </>
        )}
        <ChatTimestamp
          read={read}
          delivered={delivered}
          timestamp={timestamp}
        />
        {isSrwMobile && (
          <MenuIcon onClick={selectMode}>
            <Icon type="menuDown" />
          </MenuIcon>
        )}
      </MessageCard>
    </ChatCard>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUserId(app),
  currentRoomAddress: getChatroomAddress(app, getCurrentRoomId(app)),
  selectedAction: getSelectedAction(app),
  isSrwMobile: app.srw.isMobile
});

export default connect(mapStateToProps, {
  _selectMessage: selectMessage,
  _showMobileOptions: showMobileOptions
})(Message);
