// @flow

import React from "react";

import StyledRadio from "./styles";

type Props = {
  name?: string,
  checked: boolean,
  id: string,
  children?: any,
  handleChange: Function
};

const Radio = ({ name, checked, id, children, handleChange }: Props) => {
  return (
    <StyledRadio>
      <label tabIndex="0" role="radio" aria-checked={checked} htmlFor={id}>
        <input
          type="radio"
          id={id}
          name={name}
          onChange={handleChange}
          checked={checked}
        />
        <div>
          <span />
        </div>
        <span>{children}</span>
      </label>
    </StyledRadio>
  );
};

Radio.defaultProps = {
  children: [],
  name: ""
};

export default Radio;
