// @flow

import * as R from "ramda";
import moment from "moment";
import ColorHash from "color-hash";

import { Set as ImmutableSet } from "immutable";

import * as defaultStatus from "src/constants/status";
import { strongPassword, mediumPassword } from "src/constants/password";
import type {
  UnifizeChatRoom,
  RoomId,
  UnifizeUser,
  SavedFilter,
  FilterItem,
  PrincipalChecklist,
  WorkflowInstances,
  UID,
  Workflow
} from "src/types";
import {
  formatManageViewFieldValue,
  defaultFieldValues
} from "src/formatChecklistFieldValue";
import { multiSelectActions } from "src/constants";
import type { MultiSelectActions } from "src/constants";

// Object type is used here since it is a generic method
const sortColumn = (
  sortby: string | Array<string>,
  ascending: boolean,
  matches: Array<Object>
): Array<Object> => {
  const comparator = (item: Object) => {
    if (typeof item[sortby] === "string") {
      return R.toLower(item[sortby]);
    }
    if (Array.isArray(sortby)) {
      const val = R.path(sortby, item);
      if (R.type(val) === "string") {
        return R.toLower(val);
      }
      return val;
    }

    return item[sortby];
  };

  if (ascending) {
    return R.sortBy(comparator, matches);
  }

  return R.reverse(R.sortBy(comparator, matches));
};

const getParent = (parent: string) => {
  try {
    const parentDOM = document.querySelector(parent);
    return parentDOM;
  } catch (e) {
    return {
      appendChild: () => {},
      removeChild: () => {}
    };
  }
};

const getStatus = ({
  status,
  dueDate,
  active
}: {
  status: number,
  dueDate?: ?string,
  active?: ?boolean
}): number => {
  if (dueDate && active !== false) {
    const days = moment(dueDate).diff(moment(), "days");
    if (days < 0) {
      return defaultStatus.OVERDUE;
    }
  }

  if (status < 0) {
    return status;
  }

  if (active === false) {
    return defaultStatus.COMPLETED;
  }
  return defaultStatus.PENDING;
};

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const getTime = (date: Date) => {
  if (date !== null) {
    const referenceDate = moment(date);
    if (referenceDate.isSame(moment(), "day")) {
      return referenceDate.format("hh:mm a");
    }
    return referenceDate.fromNow();
  }
  return null;
};

const searchRoom = (workflow: ?number, type: ?string, value: string) => (
  room: UnifizeChatRoom
) => {
  const containsTitle =
    R.includes(
      R.toLower(value || ""),
      `${R.toLower(room.title || "")} ${room.seqNo || ""}
     ${R.toLower(room.processTitle || "")}`
    ) &&
    !room.canceled &&
    !R.isEmpty(room.id);

  if (workflow) {
    return containsTitle && room.templateId === parseInt(workflow, 10);
  }
  if (type) {
    return containsTitle && room.type === type;
  }
  return containsTitle && !room.canceled;
};

const getRoomIds = (rooms: Array<UnifizeChatRoom>): Array<RoomId> =>
  ImmutableSet(rooms.map(room => room.id)).toArray();

const getAbsoluteTimeDifference = (time1: Date, time2: Date) =>
  Math.abs(moment.duration(moment(time1).diff(time2)).asMinutes());

const sortByNameCaseInsensitive: Function = R.sortBy((u: UnifizeUser): string =>
  R.trim(R.toLower(u.displayName || u.email))
);

const getDiff = (string: string, diffBy: string) =>
  string.split(diffBy).join("");

export const customHash = (str: string) =>
  str.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

const getAuthorColor = (author: string, roomAddress: string) => {
  const colorHashObj = new ColorHash({
    hash: customHash,
    lightness: [0.2, 0.3, 0.4, 0.5]
  });
  return colorHashObj.hex(author + roomAddress);
};

const getRandomHexColor = (num: number) => {
  const hexDigits = "0123456789ABCDEF";
  let colorCode = "#";
  let rand;
  for (let i = 0; i < 6; i++) {
    rand = Math.sin(num + i) * 10000; // generate a pseudo-random number using the num and current index
    colorCode += hexDigits[Math.floor((rand - Math.floor(rand)) * 16)]; // choose a hex digit based on the fractional part of the pseudo-random number
  }
  return colorCode;
};

const getDueDate = (dueDate: ?string | Object) => {
  if (dueDate) {
    if (typeof dueDate === "object" && dueDate.toDate) {
      return new Date(dueDate.toDate());
    }
    return new Date(dueDate);
  }
  return null;
};

const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const getDimensionObject = (node: any) => {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: "x" in rect ? rect.x : rect.top,
    left: "y" in rect ? rect.y : rect.left,
    x: "x" in rect ? rect.x : rect.left,
    y: "y" in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom
  };
};

/**
 * All immutable objects have hash code this compares hash code of old and newValue
 * and returns old value if hash code did not change
 */
const memoizeByHashCode = () => {
  let oldValue = {};
  return (value: Object) => {
    if (R.isEmpty(oldValue)) {
      oldValue = value;
      return value;
    } else if (value.hashCode() === oldValue.hashCode()) {
      return oldValue;
    } else {
      oldValue = value;
      return value;
    }
  };
};

const getMapArray = (n: number) => {
  const arr = [];
  for (let i = 1; i <= n; i += 1) arr.push(i);
  return arr;
};

/**
 * Checks if value is not null or undefined
 * @param {any} value
 */
const hasValue = (value: any) => {
  return value !== null && value !== undefined;
};

/**
 * Merges values when there are multiple checklist in conversation
 * @param {Object} obj1 Values of checklist 1
 * @param {Object} obj2 values of checklist 2
 */
const mergeChecklistValues = (obj1: Object, obj2: Object) => {
  const res = R.clone(obj1);

  R.keys(obj2).forEach(obj2Key => {
    if (!obj1[obj2Key]) {
      res[obj2Key] = R.clone(obj2[obj2Key]);
    } else {
      res[obj2Key] = R.reduce(
        (acc, val) => {
          const fieldIndex = R.findIndex(R.propEq("fieldId", val.fieldId))(acc);

          if (fieldIndex === -1) {
            return R.concat(acc, val);
          }

          return R.update(
            fieldIndex,
            R.mergeDeepRight(val, acc[fieldIndex]),
            acc
          );
        },
        res[obj2Key],
        obj2[obj2Key]
      );
    }
  });

  return res;
};

const convertStatusIdSetToArray = (filters: SavedFilter): SavedFilter =>
  R.map((item: FilterItem): FilterItem => {
    if (item.filter.customStatuses && item.filter.customStatuses.toJS) {
      return {
        name: item.name,
        filter: {
          ...item.filter,
          customStatuses: item.filter.customStatuses.toJS()
        }
      };
    }
    return item;
  }, filters);

const processColumns = {
  owner: -1,
  creator: -2,
  priority: -3,
  status: -4,
  parent: -5,
  age: -6,
  children: -7
};

export const getField = (field: ?string) => {
  if (field) {
    if (isNaN(field)) {
      return processColumns[field];
    }
    return parseInt(field, 10);
  }
  return null;
};

export const getColumnName = (id: ?string | ?number) => {
  if (id) {
    const processNames = R.invertObj(processColumns);
    if (processNames[`${id}`]) {
      return processNames[`${id}`];
    }

    return `${id}`;
  }
  return null;
};

// Converts Object to url param
export const toUrl = (obj: Object) => {
  let url = [];

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (R.type(value) === "Array") {
      if (value.length > 0) {
        url.push(value.map(val => `${key}=${val}`).join("&"));
      }
    } else {
      url.push(`${key}=${encodeURIComponent(obj[key])}`);
    }
  });
  return url.join("&");
};

// Formats mention message
export const formatMentions = (text: string) => {
  return (text || "") // eslint-disable-next-line no-useless-escape
    .replace(/@[(\[].*?[)\]] */g, "")
    .replace(/<@!(team\^\d+)>/g, "<!$1>")
    .replace("<@!everyone>", "<!everyone>")
    .replace("<@!owner>", "<!owner>")
    .replace("<@!creator>", "<!creator>")
    .replace("<@!signatories>", "<!signatories>");
};

export const getGroupMentionId = (id: string) => {
  const gid = id.split("^");
  return gid[gid.length - 1];
};

export const getChecklistNotificationDiff = (
  newValue: Array<any> = [],
  oldValue: Array<any> = []
) => {
  const diffValue: Array<any> = R.difference(newValue, oldValue);
  //  Only if a new item is added, return the difference
  return newValue.length > 0 && diffValue.length === 0 ? newValue : diffValue;
};

export const toHomeScreenURL = (obj: Object) => {
  let url = [];

  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (R.type(value) === "Array") {
      if (value.length > 0) {
        url.push(value.map(val => `${key}=${val}`).join("&"));
      }
    } else if (value) {
      url.push(`column=${encodeURIComponent(key)}`);
    }
  });
  return url.join("&");
};

/**
 * Merge multiple revisions of processes into one
 * @param {Object} items - array of processes
 * @param {string} idKey key identify a process (autoNo, seqNo)
 * @param {string} idAsNumber key to covert id as number type
 * @return {Object} object with merged instances with idKey as key
 */
export const mergeRevisionsById = ({
  items,
  idAsNumber
}: {
  items: Array<Object>,
  idKey?: string,
  idAsNumber?: boolean
}): Object => {
  const intermediate = {};

  // Group the instances by versions
  // {seqNo: <number of instances with the same seqNo>}
  items.map(item => {
    intermediate[item.seqNo] = (intermediate[item.seqNo] ?? 0) + 1;
  });

  const result = items.reduce((acc, item) => {
    const calculatedAutoNo =
      intermediate[item.seqNo] > 1
        ? `${item.seqNo}/${item.version}`
        : item.seqNo;

    // Use the data from the version with highest status
    // to avoid using cancelled versions with status -3
    if (!acc[calculatedAutoNo] || item.status > acc[calculatedAutoNo].status) {
      acc[calculatedAutoNo] = {
        ...item,
        id: idAsNumber ? Number(calculatedAutoNo) : `${calculatedAutoNo}`,
        autoNo: calculatedAutoNo
      };
    }

    return acc;
  }, {});

  return result;
};

/**
 * Convert an array of objects into a map with their IDs as the keys
 * @param {Object} items - array of items
 * @param {string} idKey key identify an item (autoNo, seqNo, id)
 * @param {string} idAsNumber key to covert id as number type
 * @return {Object} object with merged instances with idKey as key
 */
export const generateById = ({
  items,
  idKey,
  idAsNumber
}: {
  items: Array<Object>,
  idKey?: string,
  idAsNumber?: boolean
}): Object => {
  const result = items.reduce((acc, item) => {
    const key = idKey ? item[idKey] : item.id;

    acc[key] = {
      ...item,
      id: idAsNumber ? Number(key) : `${key}`
    };

    return acc;
  }, {});

  return result;
};
/**
 * Get date time string from seconds
 * @param {number | string} seconds - unix seconds
 * @param {boolean} ampm - If true use 12HR format else 24HR format
 * @return {string} date times string
 */
export const getDateTimeFromSeconds = (
  seconds: number | string,
  ampm?: boolean
) => {
  const timeString = ampm ? "hh:mm a" : "HH:mm";
  const formatString = `MMM DD, YYYY ${timeString}`;
  return R.type(seconds) === "String"
    ? moment(seconds).format(formatString)
    : moment(moment.unix(seconds).toString()).format(formatString);
};

/**
 * Get date time string from raw ISO date time string
 * @param {string} dateTimeString - ISO date time string
 * @param {boolean} ampm - If true use 12HR format else 24HR format
 * @return {string} date times string
 */
export const getDateTimeFromString = (
  dateTimeString: ?string,
  ampm?: boolean
) => {
  const dateTimeSeconds = Date.parse(dateTimeString || "") / 1000;
  return getDateTimeFromSeconds(dateTimeSeconds, ampm);
};

export const getUserDisplayNames = (
  users: Array<?UnifizeUser>
): Array<string> =>
  R.map(
    user =>
      !user || (!user.displayName && !user.email)
        ? "Deleted User"
        : user.displayName || user.email,
    users
  );

export const getEmailPrefix = (email: string) => email.split("@")[0];

/**
 * Sort a list of objects by `seqNo` property
 * @param {Object[]} array
 * @returns {Object[]} sorted array of objects
 */
export const sortBySeqNo = (array: Object[]): Object[] =>
  R.sortBy(R.prop("seqNo"), array);

export const convertToCamcelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const convertToSentence = (str: string) =>
  str
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1")
    .toLowerCase();

export const convertToTitleCase = (str: string) => {
  const text = str.replace(/([A-Z])/g, " $1");

  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Pick keys which have truthy values in the object
 * @param  {Object} obj
 * @returns string[] array of keys
 */
export const pickTruthyKeys = (obj: Object): string[] =>
  Object.keys(R.pickBy(val => Boolean(val), obj));

// Format manage view field values similar to checklist field values
export const getWorkflowsWithFormattedValues = (
  workflows: WorkflowInstances[],
  principalChecklist: PrincipalChecklist
): WorkflowInstances[] => {
  return workflows.map(workflow => {
    let updatedWorkflow = R.clone(workflow);

    principalChecklist.fields.forEach(field => {
      updatedWorkflow = R.mergeDeepRight(updatedWorkflow, {
        [field.id]: formatManageViewFieldValue(
          workflow[field.id] || defaultFieldValues[field.type],
          field.type
        )
      });
    });

    return updatedWorkflow;
  });
};
/**
 * Converts the following cases to kebab case:
 * camel, pascal, snake, sentence, title
 * @param  {string} str
 * @returns string
 */
export const toKebabCase = (str: string): string => {
  return str
    .split("")
    .map(letter => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join("")
    .trim()
    .replace(/[_\s]+/g, "-");
};

/**
 * Check if a user has access to a room
 *
 * @param  {UID} uid - UID of a user
 * @param  {WorkflowInstances} process - Process template of the room
 * @param  {Workflow} workflow - The room
 * @returns boolean
 */
export const isRoomPrivateForUser = ({
  uid,
  workflow,
  process
}: {
  uid: UID,
  process: WorkflowInstances,
  workflow: Workflow
}): boolean => {
  try {
    if (R.isEmpty(workflow)) {
      return false;
    }

    // If both process and room aren't private then user does have access
    if (workflow.privacy === "none" && process.privacy === "none") {
      return false;
    }

    const authorizedUsers = R.reject(
      R.isNil,
      R.uniq([
        workflow.owner || "",
        ...(workflow.members || []),
        ...(workflow.processOwners || []),
        ...(R.path(["privacySettings", "whitelist"], workflow) || []),
        process.owner,
        ...(process.members || [])
      ])
    );

    return !R.includes(uid, authorizedUsers);
  } catch (error) {
    console.error({
      error,
      uid,
      workflow,
      process
    });
    return false;
  }
};

export const getPasswordStrength = (password: string) => {
  if (password.length === 0) return "empty";

  if (password.length < 4) return "weak";

  if (strongPassword.test(password)) return "strong";

  if (mediumPassword.test(password)) return "good";

  return "fair";
};
/**
 * Return the value of a cookie
 *
 * @param  {string} name - The name of the cookie
 * @returns string | null - The value of the cookie if found
 */
const getCookie = (name: string): string | null => {
  const cookieArr = document.cookie.split(";");

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");

    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};

const multiSelectHandler = <T>({
  action,
  list,
  item
}: {
  action: MultiSelectActions,
  list: T[],
  item: T
}): T[] => {
  switch (action) {
    case multiSelectActions.select:
      return toggleListItem({ list, item });

    case multiSelectActions.remove:
      return R.reject(R.equals(item))(list);

    case multiSelectActions.removeLast:
      return R.dropLast(1, list);

    case multiSelectActions.removeAll:
      return [];

    default:
      return list;
  }
};

const toggleListItem = <T>({
  list,
  item
}: {
  list: T[],
  item: T | T[]
}): T[] => {
  if (R.includes(item, list)) {
    return R.reject(R.equals(item), list);
  }

  if (Array.isArray(item)) {
    if (R.isEmpty(item)) {
      /*Condition for deselect all options */
      return [];
    } else {
      /*Condition for select all options */
      return R.uniq([...list, ...item]);
    }
  }
  return R.uniq([...list, item]);
};

/**
 * Create an HTML element from string
 * @param {string} htmlString - HTML formatted as a string
 * @returns {Object}
 */
export const createElementFromHTMLStr = (htmlString: string): Object => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes.
  return tempElement.firstChild;
};

/**
 * Check if the given input is a valid base64 string
 * @param {string} str - string that needs to be tested
 * @returns {boolean}
 */
export const isValidBase64 = (str: string): boolean => {
  const regex =
    "(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?";

  return new RegExp("^" + regex + "$", "gi").test(str);
};

/**
 * Copy given text to clipboard
 * @param {string} str - text that needs to be copied
 */
export const copyToClipboard = (str: string) => {
  try {
    navigator.clipboard.writeText(str);
  } catch (e) {
    console.error(e);
  }
};

export {
  getCookie,
  multiSelectHandler,
  toggleListItem,
  mergeChecklistValues,
  hasValue,
  memoizeByHashCode,
  getDueDate,
  sortColumn,
  getParent,
  getStatus,
  validateEmail,
  getTime,
  searchRoom,
  getRoomIds,
  getAbsoluteTimeDifference,
  sortByNameCaseInsensitive,
  getDiff,
  getAuthorColor,
  getRandomHexColor,
  capitalize,
  getDimensionObject,
  getMapArray,
  convertStatusIdSetToArray
};
