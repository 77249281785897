// @flow

import styled from "@emotion/styled";

import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";

import {
  AccordionSection as StyledAccordionSection,
  AccordionTitle as StyledAccordionTitle,
  AccordionContent as StyledAccordionContent,
  StatusType as StyledStatusType
} from "src/components/Accordion/styles";

const AccordionTitle = styled(StyledAccordionTitle)`
  padding: 0px ${spacing.space_m};
  background-color: ${colors.purpleLighter};
  line-height: 1rem;
  border-radius: ${spacing.space_xs};
  margin-top: ${spacing.space_m};
  z-index: 2;
`;

const AccordionSection = styled(StyledAccordionSection)`
  margin-top: ${spacing.space_s};
`;

const AccordionContent = styled(StyledAccordionContent)`
  ${props =>
    props.active && !props.isFieldInsideSection && `padding-top: 1rem`};
  overflow: visible;
`;
const StatusType = styled(StyledStatusType)`
  color: ${colors.purpleDark};
  letter-spacing: 0.5px;
  font-weight: ${typography.semiBold};
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-left: 0px;
  line-height: 1rem;
`;

export { AccordionSection, AccordionTitle, AccordionContent, StatusType };
