// @flow
import React from "react";
import { useSelector } from "react-redux";

import type {
  RoomId,
  ChecklistId,
  FieldId,
  Section as SectionType
} from "src/types";
import { breakpointMaxWidths } from "src/components/Dock/DockLayout/utils";

import Field from "../Field";
import Section from "../Section";
import { GridItem } from "@chakra-ui/layout";
import { generateLayout, removeHiddenFields, addFieldDetails } from "./utils";
import RowItem from "./RowItem";

import DockLayout from "src/components/Dock/DockLayout";

type Props = {
  id: ChecklistId,
  roomId: RoomId,
  fields: $ReadOnlyArray<FieldId | SectionType>
};

export default function WideChecklist({ id, roomId, fields }: Props) {
  const visibleFields = useSelector(({ app }) => {
    return removeHiddenFields({
      fields: addFieldDetails({ fields, app }),
      roomId,
      app
    });
  });

  return (
    <DockLayout>
      {({ breakpoint }) => {
        const layout = generateLayout(visibleFields, breakpoint);
        return layout.map(field => {
          const column = {
            minWidth: `${field.w * 200}px`
          };
          return (
            <>
              {field.startOnNewRow ? (
                <GridItem
                  key={field.id}
                  colSpan={breakpointMaxWidths[breakpoint]}
                  sx={{ ...column, justifySelf: "stretch" }}
                >
                  <RowItem breakpoint={breakpoint} fieldW={field.w}>
                    <Field
                      key={field.id}
                      id={field.id}
                      checklistId={id}
                      roomId={roomId}
                    />
                  </RowItem>
                </GridItem>
              ) : (
                <GridItem
                  key={field.id}
                  colSpan={field.w}
                  sx={{ ...column, justifySelf: "stretch" }}
                >
                  {field.type === "section" ? (
                    <Section
                      key={field.id}
                      id={field.id}
                      checklistId={id}
                      roomId={roomId}
                      layout={field.layout}
                      fields={field.fields}
                      breakpoint={breakpoint}
                      isFieldInsideSection={typeof field === "object"}
                    />
                  ) : (
                    <>
                      {field.type !== "subSection" && (
                        <Field
                          key={field.id}
                          id={field.id}
                          checklistId={id}
                          roomId={roomId}
                        />
                      )}
                    </>
                  )}
                </GridItem>
              )}
            </>
          );
        });
      }}
    </DockLayout>
  );
}
