// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import { ScrollableBox, ListItemMedium } from "src/styles/box";
import { Input } from "src/styles/input";

export const HomeScreen = styled.div`
  padding: 2.5em 4em;
  overflow: auto;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 10px;
  display: grid;
  z-index: 3;
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  background: ${colors.brightLight};
  visibility: hidden;
  > svg {
    position: relative;
    top: 3px;
    cursor: pointer;
    height: 0.9em;
    width: 0.9em;
  }
`;

export const SectionHeader = styled.div`
  > h2 {
    color: ${colors.grey5};
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  > p {
    height: 2em;
    margin-top: 0.2em;
    padding-left: 30px;
  }
  :hover {
    ${DeleteButton} {
      visibility: visible;
    }
  }
`;

export const AddSubSection = styled.button`
  position: relative;
  outline: none;
  border: 1px solid ${props => (props.active ? colors.primary : colors.skyDark)};
  border-radius: ${radius.small};
  background: ${colors.brightLight};
  height: 2.3em;
  width: 104%;
  cursor: pointer;
  margin-left: 35px;
  min-width: 352px;
  :hover,
  :focus {
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`;

export const Dropdown = styled(ScrollableBox)`
  position: absolute;
  min-width: 126px;
  z-index: 1;
  width: 100%;
  left: 0;
  right: 0;
  top: ${props => (props.top ? props.top : "3em")};
  bottom: ${props => (props.bottom ? props.bottom : "auto")};
  > ul {
    max-height: 23em;
  }

  ${ListItemMedium} {
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    color: ${colors.primary};
  }
  ${Input} {
    margin-bottom: 10px;
  }
`;

export const RelativeBox = styled.div`
  height: 2.3em;
  width: 63.3%;
  position: relative;
  ${Dropdown} {
    width: 103.9%;
    margin-left: 34px;
    min-width: 352px;
  }
`;

export const AddUserSegment = styled(AddSubSection)`
  width: 100%;
  margin-left: 0.6em;
`;

export const AddSection = styled(AddSubSection)`
  height: 2.5em;
  color: ${colors.grey5};
  font-size: 15px;
  margin-top: 1em;
  font-weight: bold;
  width: 65.9%;
  background: ${props => (props.loading ? colors.active : colors.brightLight)};
  border: 1px solid ${props => (props.loading ? colors.active : colors.primary)};
  cursor: ${props => (props.loading ? "not-allowed" : "pointer")};
  :hover,
  :focus {
    border: 1px solid
      ${props => (props.loading ? colors.active : colors.primary)};
  }
`;

export const SortableList = styled.ul`
  padding-left: 0;
`;

export const Header = styled.h2`
  display: flex;
  align-items: center;
  > div {
    padding-left: 0.5em;
  }
`;
