// @flow
import { combineReducers } from "redux";

import * as atypes from "../constants/actionTypes";
import type { SignInError, Action, UID, AuthProvider } from "../types";

const error = (state: SignInError = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.API_AUTH_FAILURE:
    case atypes.GET_AUTH_PROVIDER_FAILURE:
      return {
        ...state,
        login: payload
      };

    case atypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: payload
      };

    case atypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: payload
      };

    case atypes.SERVER_AUTH_FAILURE:
    case atypes.SRW_SERVER_AUTH_FAILURE:
    case atypes.SEND_AUTH_TOKEN_FAILURE:
    case atypes.LOAD_CHATROOMS_FAILURE:
      return {
        ...state,
        sendAuthToken: payload
      };

    case atypes.GET_CURRENT_ORG_FAILURE:
      return {
        ...state,
        org: payload
      };

    case atypes.CLEAR_SIGN_IN_ERRORS:
      return {};

    default:
      return state;
  }
};

const warning = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SIGN_IN:
      if (payload.message) {
        return payload.message;
      }
      return null;
    default:
      return state;
  }
};

const email = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_SIGN_IN_EMAIL:
      return payload.email;
    default:
      return state;
  }
};

const resetCode = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_RESET_CODE:
      return payload.resetCode;
    default:
      return state;
  }
};

const isAuthenticationChecked = (state: ?boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SIGNED_IN:
    case atypes.SIGNED_OUT:
      return true;
    default:
      return state;
  }
};

const initialState = { id: "", location: null };

const requestedChatRoom = (
  state: { id: string, location?: ?string } = initialState,
  { type, payload, meta }: Action
) => {
  switch (type) {
    case atypes.SET_REQUESTED_CHATROOM:
      const source = ((meta || {}).query || {}).source;
      if (source) {
        return {
          id: payload.id,
          source: source || null,
          templateId: payload.templateId || null
        };
      }

      return {
        id: payload.id,
        source: payload.source || null,
        templateId: payload.templateId || null
      };
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

// This stores the route that needs to be called after authentication
// if the route was entered in the url bar directly

const requestedPage = (
  state: {
    page: string,
    query?: Object
  } = { page: "" },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_REQUESTED_PAGE:
      return { page: payload.page, query: payload.query };
    case atypes.SET_GROUPS_SUCCESS:
    case atypes.SET_CONTACTS_SUCCESS:
    case atypes.SET_SETTINGS_SUCCESS:
    case atypes.SET_USER_ANALYTICS_SUCCESS:
    case atypes.SET_PROCESS_SUCCESS:
    case atypes.SET_REPORTS_SUCCESS:
    case atypes.SET_ORG_SETTINGS_SUCCESS:
    case atypes.SET_DASHBOARD_SUCCESS:
    case atypes.SET_FORMS_SUCCESS:
      return { page: "" };
    default:
      return state;
  }
};

const loginAs = (state: ?UID = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.LOGIN_AS_REQUEST:
      return payload.uid;
    case atypes.LOGIN_WITH_TOKEN_SUCCESS:
    case atypes.LOGIN_WITH_TOKEN_FAILURE:
      return null;
    default:
      return state;
  }
};

const authProvider = (state: AuthProvider = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_AUTH_PROVIDER_SUCCESS:
      return payload;
    case atypes.CLEAR_AUTH_PROVIDER:
    case atypes.LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  error,
  warning,
  email,
  resetCode,
  isAuthenticationChecked,
  requestedChatRoom,
  loginAs,
  requestedPage,
  authProvider
});
