// @flow

import React from "react";

import API from "./API";
import Template from "./Template";
import Native from "./Native";

type Props =
  | {|
      stitchType: "nativeFile",
      value: number | null,
      onUpdate: Function
    |}
  | {|
      stitchType: "template",
      value: string | null,
      onUpdate: Function
    |}
  | {|
      stitchType: "api",
      value: string | null,
      onUpdate: Function
    |};

export default function StitchData({ stitchType, value, onUpdate }: Props) {
  switch (stitchType) {
    case "api":
      // $FlowFixMe
      return <API value={value} onUpdate={onUpdate} />;

    case "template":
      // $FlowFixMe
      return <Template value={value} onUpdate={onUpdate} />;

    case "nativeFile":
    default:
      // $FlowFixMe
      return <Native value={value} onUpdate={onUpdate} />;
  }
}
