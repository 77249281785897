// @flow

// import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { connectRoutes } from "redux-first-router";
import type { Options as RFROptions } from "redux-first-router";
import { persistStore } from "redux-persist";
import queryString from "query-string";
import restoreScroll from "redux-first-router-restore-scroll";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createTracker } from "redux-segment";
import Raven from "raven-js";
import * as Sentry from "@sentry/react";
import * as R from "ramda";

import type { State } from "src/types";
import { resetWorkflowInstanceFilter } from "src/actions/reports";
import segmentMiddleware from "src/actions/segmentMiddleware";
import chatApp from "./reducers";
import { routesMap } from "./routesMap";
import saga from "./sagas";
import { monitorAuthSate } from "./sagas/auth";
import title from "./reducers/title";
import * as atypes from "src/constants/actionTypes";

const configureStore = () => {
  // Create segment tracker
  const tracker = createTracker();

  const options: RFROptions = {
    querySerializer: queryString,
    queryParser: queryString.parse,
    initialDispatch: false,
    restoreScroll: restoreScroll(),
    displayConfirmLeave: (message, callback) => {
      const canLeave = can => {
        store.dispatch({ type: atypes.HIDE_BLOCK_NAVIGATION_MODEL });
        if (can) store.dispatch({ type: atypes.HIDE_WORKFLOW_BUILDER });

        return callback(can);
      };

      store.dispatch({
        type: atypes.SHOW_BLOCK_NAVIGATION_MODAL,
        payload: { message, canLeave }
      });
    },
    onBeforeChange: (dispatch, getState) => {
      const state: State = getState();
      const { location } = state;

      // When navigating away from a report, we reset the workflow instance filter
      if (R.path(["prev", "type"], location) === atypes.SET_REPORTS_REQUEST) {
        dispatch(resetWorkflowInstanceFilter());
      }
    }
  };

  // $FlowFixMe
  const router = connectRoutes(routesMap, options);
  const { reducer, middleware, enhancer } = router;

  const appReducer = combineReducers({
    title,
    location: reducer,
    app: chatApp
  });

  // invalidates the store after logout
  const rootReducer = (state, action) => {
    if (action.type === atypes.LOGOUT_SUCCESS) {
      const {
        location,
        title,
        app: { signin }
      } = state;
      state = { location, title, app: { signin } };
    }
    return appReducer(state, action);
  };

  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      console.error("mid", error);
      try {
        Raven.captureException(error);
        window.crashReport = error.message;
      } catch (e) {
        console.log("Error occured while logging error", error);
      }
    }
  });

  window.onerror = (msg, url, lineNo, columnNo, error) => {
    Raven.captureException({ msg, url, lineNo, columnNo, error });
    return false;
  };

  const middlewares = [
    segmentMiddleware,
    middleware,
    thunk,
    sagaMiddleware,
    tracker
  ];
  if (process.env.NODE_ENV !== "production") {
    // middlewares.push(createLogger());
  }

  // For devtools extension
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 1000 })
      : compose;

  // Enhancer for sending redux data to sentry error logs
  const sentryEnhancer = Sentry.createReduxEnhancer({});

  const store = createStore(
    rootReducer,
    composeEnhancers(enhancer, applyMiddleware(...middlewares), sentryEnhancer)
  );

  const persistor = persistStore(store, {}, () => {
    store.dispatch({
      type: atypes.REHYDRATION_COMPLETE,
      payload: {}
    });
  });
  window.persistor = persistor;

  sagaMiddleware.run(monitorAuthSate);
  sagaMiddleware.run(saga);

  router.initialDispatch();

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }

  return { store, persistor };
};

export default configureStore;
