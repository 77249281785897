// @flow

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import File from "./File";
import { OldValue, NewValue, ValueList, Name } from "../styles";
import { FileData } from "src/components/Dock/Checklist/styles";
import { getFile, getCurrentRoomId } from "src/reducers";
import { downloadFile } from "src/actions/file";
import location from "src/constants/location";

type Props = {
  oldValue: string,
  newValue: ?Array<string>
};

const Files = ({ newValue = [], oldValue }: Props) => {
  const dispatch = useDispatch();

  const oldFile = useSelector(({ app }) => getFile(app, oldValue));
  const modal = useSelector(({ app }) => app.conversationModal.show);
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));

  const handleDownload = (file: Object) => {
    dispatch(
      downloadFile({
        roomId,
        location: modal ? location.conversationDialog : location.chatHistory,
        name: file.name,
        originalName: file.originalName,
        generation: file.generation,
        versionId: file.versionId
      })
    );
  };

  return (
    <>
      <OldValue>
        <ValueList>
          {oldFile && (
            <FileData>
              <Name>{oldFile.originalName}</Name>
            </FileData>
          )}
        </ValueList>
      </OldValue>

      <NewValue>
        <ValueList>
          {newValue &&
            newValue.map(fileId => (
              <File key={fileId} handleDownload={handleDownload} id={fileId} />
            ))}
        </ValueList>
      </NewValue>
    </>
  );
};

export default Files;
