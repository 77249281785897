// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  Filter as StyledFilter,
  ListItem,
  SubHeading,
  Chatroom as StyledChatroom,
  Separator
} from "./styles";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker.js";
import { filterEmbeddedFields } from "src/actions/workflows";
import Checkbox from "src/components/Checkbox";
import AllRecordsFilter from "./AllRecordsFilter";
import {
  getUniqueValues,
  getChecklistFieldsById,
  getAllWorkflows,
  getAllRecords
} from "src/reducers";
import BlanksNonBlanks from "./BlanksNonBlanks";
import { getAllRecordsLabel } from "src/utils/filters";

import type { AppState } from "src/types";

type Props = {
  column: string,
  fieldId: string,
  values: Array<number>,
  parentRef: any,
  handleClose: Function,
  setFilter: Function,
  toggleSortBy: Function,
  filter: Array<any>,
  isSortedDesc: boolean
};

const Select = ({
  parentRef,
  values,
  column,
  handleClose,
  setFilter,
  toggleSortBy,
  filter
}: Props) => {
  const dispatch = useDispatch();
  const allRecords = useSelector(({ app }) => getAllRecords(app));
  const workflow = useSelector(({ app }) => getAllWorkflows(app));
  const fieldsById = useSelector(({ app }) => getChecklistFieldsById(app));
  const filters = workflow.instanceFilter;
  const chatroomAttributes = ["owner", "dueDate", "status"];
  const instancesById = workflow.instancesById;
  const updatedInstances = { ...instancesById };

  const allRecordsColumnId = column.includes("-")
    ? column.split("-")[0]
    : column;

  let workerData = {
    workflow,
    fieldsById: fieldsById.toJS(),
    instancesById,
    filters,
    chatroomAttributes,
    updatedInstances,
    allRecords
  };

  const preventDefault = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeModal =
    ((event: any) => {
      if (event.keyCode === 13) {
        handleClose();
      }
    },
    [handleClose]);

  const clickOutside = useCallback(
    (event: any) => {
      if (parentRef && parentRef.current) {
        if (!parentRef.current.contains(event.target)) {
          handleClose();
        }
      }
    },
    [parentRef, handleClose]
  );

  useEffect(() => {
    // $FlowFixMe
    document.addEventListener("keydown", closeModal, false);
    document.addEventListener("click", clickOutside, false);
    return () => {
      // $FlowFixMe
      document.removeEventListener("keydown", closeModal, false);
      document.removeEventListener("click", clickOutside, false);
    };
  }, []);

  const handleSort = (ascending: boolean) => {
    if (ascending) {
      toggleSortBy(false, true);
    } else {
      toggleSortBy(true, true);
    }
    handleClose();
  };

  const handleSelect = useCallback(
    (event: Event, item: string | number) => {
      event.preventDefault();
      event.stopPropagation();

      const webWorker = new WebWorker(worker);
      if (R.includes(item, filter || [])) {
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });
        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: R.reject(R.equals(item), filter)
          }
        };

        webWorker.postMessage(workerData);
        setFilter(column, R.reject(R.equals(item), filter));
      } else {
        // $FlowFixMe- Flow type infer error, type of event listener not clear
        webWorker.addEventListener("message", event => {
          dispatch(filterEmbeddedFields({ instances: event.data }));
        });

        workerData = {
          ...workerData,
          payload: {
            ...filters,
            [column]: [...(filter || []), item]
          }
        };

        webWorker.postMessage(workerData);
        setFilter(column, [...(filter || []), item]);
      }
    },
    [column, filter, allRecords]
  );

  return (
    <StyledFilter onClick={preventDefault}>
      <SubHeading>Sort By</SubHeading>
      <ul>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(true)}>
          Ascending
        </ListItem>
        <ListItem tabIndex="0" role="button" onClick={() => handleSort(false)}>
          Descending
        </ListItem>
      </ul>
      <Separator />
      <SubHeading>Filter</SubHeading>
      <BlanksNonBlanks handleSelect={handleSelect} filter={filter} />
      <Separator />
      {column.includes("-") && (
        <AllRecordsFilter
          label={`All ${getAllRecordsLabel(
            allRecordsColumnId,
            fieldsById
          )} records`}
          isAllRecords={allRecords[allRecordsColumnId]}
          columnId={allRecordsColumnId}
          workerData={workerData}
        />
      )}
      <ul>
        {values.map(value => (
          <StyledChatroom
            tabIndex="0"
            role="button"
            key={value}
            onClick={event => handleSelect(event, value)}
          >
            <Checkbox
              id={`chatroom${value}`}
              checked={R.includes(value, filter || [])}
              handleChange={event => handleSelect(event, value)}
            />
            {value}
          </StyledChatroom>
        ))}
      </ul>
    </StyledFilter>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  values: getUniqueValues(app, props.column)
});

export default connect(mapStateToProps, {})(Select);
