// @flow

import { createSelector } from "reselect";
import * as R from "ramda";

import type { AppState } from "src/types";

const firstLetterUpperCase = str => str[0].toUpperCase() + str.slice(1);

const getAllUsers = (state: AppState) => {
  const users = R.values(state.users.byId).filter(
    user => user && user?.disabled !== true && user?.orgRole !== "contact"
  );

  return users.map(user => ({
    type: "user",
    id: user.uid,
    name: user.displayName,
    email: user.email
  }));
};

const getAllGroups = (state: AppState) => {
  const groups = R.values(state.groups.byId);
  return groups.map(group => ({
    type: "group",
    id: Number(group.id),
    name: group.title
  }));
};

export const getAllMembersAndGroups = createSelector(
  [getAllUsers, getAllGroups],
  (members, groups) => {
    return R.concat(members, groups);
  }
);

export const getSortedMembersAndGroups = createSelector(
  getAllMembersAndGroups,
  allMembersAndGroups => {
    return [...allMembersAndGroups].sort((a, b) => {
      const aName = firstLetterUpperCase(a.name);
      const bName = firstLetterUpperCase(b.name);

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
);

export const getAllUsersAndGroupsSearchResults = createSelector(
  [getSortedMembersAndGroups, (_, searchQuery) => searchQuery],
  (allMembersAndGroups, query) => {
    if (query === "") {
      return allMembersAndGroups;
    }
    return allMembersAndGroups.filter(({ name, email, type }) => {
      return type === "user"
        ? name.toLowerCase().includes(query.toLowerCase()) ||
            email.toLowerCase().includes(query.toLowerCase())
        : name.toLowerCase().includes(query.toLowerCase());
    });
  }
);

/**
 * Filters out the existing users and groups from the search results
 * @param {Array} usersAndGroupsSearchResults - Initial list of users and groups
 * @param {string} searchQuery - The search query to filter by name and email
 * @param {boolean} multiple - If the field is a multi-value field
 * @param {(string|number)[]} userAndGroupIds - List of existing users and groups
 * @returns {Array} - The filtered list of users and groups
 */
export const getFilteredSearchResults = createSelector(
  [
    getAllUsersAndGroupsSearchResults,
    (_, searchQuery) => searchQuery,
    (_, searchQuery, multiple) => multiple,
    (_, searchQuery, multiple, userAndGroupIds) => userAndGroupIds
  ],
  (usersAndGroupsSearchResults, searchQuery, multiple, userAndGroupIds) => {
    if (multiple) {
      return R.filter(val => {
        const idToCheck = val?.type === "group" ? "id" : "uid";
        return !userAndGroupIds.includes(val?.[idToCheck]);
      }, usersAndGroupsSearchResults);
    } else {
      return R.filter(val => val.type !== "group", usersAndGroupsSearchResults);
    }
  }
);
