// @flow

import { StackProps, TextProps } from "@chakra-ui/react";

export const container: StackProps = {
  width: "570px",
  px: 4,
  pt: 1,
  spacing: 6,
  alignItems: "start"
};

export const automationActionContainer: StackProps = {
  alignItems: "start",
  width: "100%",
  gap: 1
};

export const label: TextProps = {
  fontSize: "sm",
  my: 0
};
