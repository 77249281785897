// @flow

import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import * as R from "ramda";

import type { FieldId, Privacy, RoomId } from "src/types";
import { getChecklistFieldValue } from "src/actions/checklist";
import * as chatroomApi from "src/api/chatroom";
import useBoolean from "src/hooks/useBoolean";
import locations from "src/constants/location";
import { ChecklistFieldContext } from "src/components/Dock/Checklist/Field";
import Tooltip from "src/components/Tooltip";

import {
  IconButton,
  HStack,
  Button,
  Grid,
  GridItem,
  Box,
  Text
} from "@chakra-ui/react";
import Icons from "src/icons";
import Owner from "src/containers/chatroom/Owner";
import Status from "src/components/chatroom/Status";
import DueDate from "src/components/chatroom/DueDate";
import ProcessTitle from "./Title";
import Comment from "./Comment";
import { getChipWidth } from "../utils";

import {
  SelectionName as StyledSelectionName,
  Title,
  WideMetaData as MetaData,
  SelectionWithMetaData as StyledSelectionWithMetaData,
  TitleRow
} from "../styles";
import * as styles from "./styles";
import * as colors from "src/styles/constants/colors";
import UpdateCurrentVersionConfirmationModal from "./UpdateCurrentVersionConfirmationModal";

type Props = {
  id: number,
  privacy: Privacy,
  type: string,
  autoNo: string,
  title: string,
  address: string,
  templateId: number,
  currentRoomId: RoomId,
  currentVersion: boolean,
  versionComment: ?string,
  fieldId: FieldId,
  highlight: boolean,
  disabled: boolean
};

function Revision({
  id,
  privacy,
  type,
  autoNo,
  title,
  address,
  templateId,
  currentRoomId,
  fieldId,
  currentVersion,
  versionComment,
  highlight,
  disabled
}: Props) {
  const dispatch = useDispatch();
  const { fieldWidth } = useContext(ChecklistFieldContext);
  const chipWidth = getChipWidth(fieldWidth);

  const {
    value: isCommentIconVisible,
    setTrue: showCommentIcon,
    setFalse: hideCommentIcon
  } = useBoolean(!R.isEmpty(versionComment || ""));

  const {
    value: isCommentBoxVisible,
    setFalse: hideCommentBox,
    toggleBoolean: toggleCommentBox
  } = useBoolean(!R.isEmpty(versionComment || ""));

  const {
    value: isUpdateCurrentVersionConfirmationModalOpen,
    setTrue: openUpdateCurrentVersionConfirmationModal,
    setFalse: closeUpdateCurrentVersionConfirmationModal
  } = useBoolean(false);

  const [comment, setComment] = useState(versionComment || "");

  const updateComment = () => {
    chatroomApi.updateVersionComment(id, comment).finally(() => {
      dispatch(getChecklistFieldValue(currentRoomId, fieldId, true));
    });
  };

  const updateCurrentVersion = () => {
    closeUpdateCurrentVersionConfirmationModal();
    chatroomApi.updateCurrentVersion(id).finally(() => {
      dispatch(getChecklistFieldValue(currentRoomId, fieldId, true));
    });
  };

  if (privacy === "full" && !type) {
    return (
      <StyledSelectionName>
        <TitleRow>
          <Title expand={false}>
            <i>Private</i>
          </Title>
        </TitleRow>
      </StyledSelectionName>
    );
  }

  return (
    <StyledSelectionWithMetaData
      onMouseEnter={() => {
        !isCommentBoxVisible && showCommentIcon();
      }}
      onMouseLeave={() => {
        !isCommentBoxVisible && hideCommentIcon();
      }}
      highlight={highlight}
    >
      <TitleRow>
        <Title expand={false}>
          <ProcessTitle
            id={id}
            seqNo={autoNo}
            title={title}
            templatedId={templateId}
            address={address}
          />
        </Title>
        {isCommentIconVisible && (
          <IconButton
            icon={<Icons type="comment" />}
            onClick={toggleCommentBox}
            className="ignore-react-onclickoutside" // OutsideClickHandler in Comment event propagates upward
            {...styles.commentIcon}
          />
        )}
      </TitleRow>

      <MetaData fieldWidth={fieldWidth && fieldWidth}>
        <Box display="flex" alignItems="start">
          <Status
            roomId={id}
            location={locations.activityPanel}
            chatroomOverlay={locations.activityPanel}
          />
        </Box>
        <Box minW={chipWidth} maxW={chipWidth} p={1}>
          <Owner
            roomId={id}
            location={locations.activityPanel}
            chatroomOverlay={locations.activityPanel}
            hideName={fieldWidth && fieldWidth < 360}
          />
        </Box>
        <Box
          display="flex"
          minW={chipWidth}
          w={fieldWidth < 300 && "3.75rem"}
          alignItems="start"
          _hover={{ backgound: colors.grey32 }}
        >
          <DueDate
            roomId={String(id)}
            location={locations.activityPanel}
            fieldWidth={fieldWidth && fieldWidth}
          />
        </Box>
        {currentVersion ? (
          <Button variant="uBlueOutlined" size="xs" sx={styles.CurrentRevision}>
            <Tooltip title="Current revision">
              <Icons type="currentRevision" />
              {fieldWidth > 360 && (
                <Text as="span" ml={2}>
                  Current
                </Text>
              )}
            </Tooltip>
          </Button>
        ) : (
          <Button
            variant="uBlueOutlined"
            size="xs"
            onClick={openUpdateCurrentVersionConfirmationModal}
            isDisabled={disabled}
            sx={styles.MakeCurrentButton}
          >
            <Tooltip title="Make current revision">
              <Icons type="currentRevision" />
              {fieldWidth > 360 && (
                <Text as="span" ml={2}>
                  Make current
                </Text>
              )}
            </Tooltip>
          </Button>
        )}
      </MetaData>

      {`${currentRoomId}` === `${id}` && (
        <Grid {...styles.actionBar}>
          <GridItem {...styles.actionBarItem} {...styles.viewingNow}>
            <HStack spacing={1}>
              <Icons type="eye" />
              <span>Viewing now</span>
            </HStack>
          </GridItem>
        </Grid>
      )}

      {isCommentBoxVisible && (
        <Comment
          initialComment={versionComment || ""}
          comment={comment}
          onChange={setComment}
          onUpdate={updateComment}
          onHide={hideCommentBox}
        />
      )}

      <UpdateCurrentVersionConfirmationModal
        roomTitle={title}
        open={isUpdateCurrentVersionConfirmationModalOpen}
        onSuccess={updateCurrentVersion}
        onClose={closeUpdateCurrentVersionConfirmationModal}
      />
    </StyledSelectionWithMetaData>
  );
}

export default Revision;
