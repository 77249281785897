// @flow

import React, { useState } from "react";

import { AddButton } from "src/components/Dock/Checklist/styles";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";

import {
  useBoolean,
  Text,
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";

import type { HandleCreateRevision } from "./index";

type Props = {
  signatureCount: number,
  isCommentRequired: boolean,
  createRevision: HandleCreateRevision,
  disabled: boolean,
  updating: boolean
};

function Revision({
  createRevision,
  disabled,
  updating,
  isCommentRequired,
  signatureCount
}: Props) {
  const [comment, setComment] = useState("");
  const [isModalOpen, setModalVisibility] = useBoolean();

  const label = signatureCount > 0 ? "Cancel Approval" : "Cancel Request";

  const handleCreateRevision = () => {
    if (comment) {
      createRevision(comment);
    } else {
      createRevision();
    }

    setModalVisibility.off();
  };

  return (
    <>
      {updating ? (
        <ButtonLoader />
      ) : (
        <AddButton onClick={setModalVisibility.on} disabled={disabled}>
          {label}
        </AddButton>
      )}

      <Modal isOpen={isModalOpen} onClose={setModalVisibility.off} isCentered>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader fontWeight={700} fontSize="lg">
            {label}
          </ModalHeader>

          <ModalBody>
            <Text my={0}>
              Are you sure you want to you cancel approval? This will unlock the
              checklist fields, change the status and deprecate the previous
              approval.
            </Text>

            {isCommentRequired && (
              <>
                <br />
                <FormControl isRequired>
                  <FormLabel>Please enter a comment</FormLabel>
                  <Textarea
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder="Enter comment here"
                    resize="vertical"
                  />
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button variant="uSecondary" onClick={setModalVisibility.off}>
                No
              </Button>

              <Button
                variant="uDanger"
                onClick={handleCreateRevision}
                isDisabled={isCommentRequired && !comment}
              >
                Yes
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Revision;
