// @flow

import type { UID, PrivacyModes, PrivacyAutomationData } from "src/types";
import { toggleListItem, multiSelectHandler } from "src/utils";
import type { MultiSelectActions } from "src/constants";

const handleUsersChange = ({
  data,
  currentData
}: {
  data: UID,
  currentData: UID[]
}): {
  data: UID[]
} => {
  return {
    data: toggleListItem({ list: currentData, item: data })
  };
};

const handleMessageChange = ({ data }: { data: string }) => {
  return { data };
};

const handlePrivacyChange = ({
  data,
  currentData
}: {
  data: {| mode: PrivacyModes |} | {| user: UID, action: MultiSelectActions |},
  currentData: PrivacyAutomationData
}): {
  data: PrivacyAutomationData
} => {
  if (data.mode)
    return {
      data: {
        ...currentData,
        mode: data.mode
      }
    };

  return {
    data: {
      ...currentData,
      whitelistedUsers: multiSelectHandler({
        action: data.action,
        list: currentData.whitelistedUsers,
        item: data.user
      })
    }
  };
};

const handleStatusChange = ({ data }: { data: number | null }) => {
  return { data };
};

const handleAutoArchiveChange = ({ data }: { data: boolean }) => {
  return { data, active: data };
};

export const automationDataUpdater = {
  addParticipants: handleUsersChange,
  removeParticipants: handleUsersChange,
  sendMessage: handleMessageChange,
  updatePrivacy: handlePrivacyChange,
  updateStatus: handleStatusChange,
  archive: handleAutoArchiveChange
};
