// @flow

import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import type { ApprovalFieldSettingsV2, FieldId } from "src/types";
import type { MultiSelectActions } from "src/constants";
import {
  getWorkflowBuilderStatuses,
  getCurrentChecklistBuilderFields
} from "src/reducers";
import { multiSelectHandler } from "src/utils";
import { sanitizeSettings } from "./utils";

import {
  RadioGroup,
  Radio,
  Text,
  VStack,
  Checkbox,
  Flex,
  NumberInput,
  NumberInputField,
  Box,
  FormControl,
  FormLabel
} from "@chakra-ui/react";

import Automations from "src/components/Manage/Builder/Checklist/SettingsBuilder/Automations";
import Accordion from "src/components/Accordion.v2";
import StatusSelect from "src/components/Manage/Builder/Checklist/SettingsBuilder/StatusSelect";
import UserSelectMultiple from "src/containers/user/SelectMultiple.new";
import MultiSelect from "src/components/ChakraPro/MultiSelect";
import GroupMultiSelect from "src/containers/Group/MultiSelect";
import RoleMultiSelect from "src/containers/Role/MultiSelect";
import AccordionContent from "./AccordionContent";
import MentionInput from "./MentionInput";

import { FormField, fieldUpdater } from "src/components/FormField";
import type { FormFieldTypes } from "src/components/FormField";

import * as styles from "./styles";

const FieldMultiSelect = MultiSelect<FieldId>();

type Props = {
  position: number,
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

export default function AdvancedApprovalSettings({
  position,
  settings,
  saveSettings
}: Props) {
  const [newSettings, setNewSettings] = useState<ApprovalFieldSettingsV2>(
    settings
  );

  const allStatuses = useSelector(({ app }) => getWorkflowBuilderStatuses(app));
  const checklistFields = useSelector(({ app }) =>
    getCurrentChecklistBuilderFields(app)
  );

  // approval fields except the current field
  const otherApprovalFields = checklistFields.filter(
    (field, fieldPosition) =>
      fieldPosition !== position && field.type === "approval"
  );

  useEffect(() => {
    saveSettings(sanitizeSettings(newSettings));
  });

  // If adhoc mode is chosen then request button should be
  // shown and the setting should be disabled in the UI
  useEffect(() => {
    if (newSettings.requiredApprovers === "adhoc") {
      handleSettingsDataChange(["showRequestApprovalButton"])(true);
    }
  }, [newSettings.requiredApprovers]);

  // If the new status for an automation is not chosen, then
  // set `lockStatus` = `false` for that automation
  useEffect(() => {
    if (R.isNil(newSettings.automations.started[0].data)) {
      handleSettingsDataChange(["lockStatus", "onStart"])(false);
    }

    if (R.isNil(newSettings.automations.approved[0].data)) {
      handleSettingsDataChange(["lockStatus", "onApproval"])(false);
    }

    if (R.isNil(newSettings.automations.rejected[0].data)) {
      handleSettingsDataChange(["lockStatus", "onRejection"])(false);
    }

    if (R.isNil(newSettings.automations.cancelled[0].data)) {
      handleSettingsDataChange(["lockStatus", "onCancellation"])(false);
    }
  }, [
    newSettings.automations.started[0].data,
    newSettings.automations.approved[0].data,
    newSettings.automations.rejected[0].data,
    newSettings.automations.cancelled[0].data
  ]);

  const handleSettingsDataChange = path => value => {
    // $FlowFixMe
    setNewSettings(R.assocPath(path, value));
  };

  const handleMultiSelectChange = path => (
    action: MultiSelectActions,
    item
  ) => {
    setNewSettings(prev =>
      R.assocPath(
        path,
        multiSelectHandler({
          action,
          // $FlowFixMe
          list: R.path(path, prev),
          item
        }),
        prev
      )
    );
  };

  const handleOwnerAutomationDataChange = path => (
    action: MultiSelectActions,
    item
  ) => {
    setNewSettings(prev => {
      const currentValue = R.path(path, prev);
      let newValue = null;

      if (action === "select" && item !== currentValue) {
        newValue = item;
      }

      return R.assocPath(path, newValue, prev);
    });
  };

  const handlePrivacyAutomationDataChange = path => data => {
    if (data.mode) {
      handleSettingsDataChange([...path, "mode"])(data.mode);
    } else if (data.action) {
      handleMultiSelectChange([...path, "whitelistedUsers"])(
        data.action,
        data.user
      );
    }
  };

  const handleChange = (
    type: FormFieldTypes,
    path: (string | number)[],
    data: any
  ) => {
    // $FlowFixMe
    setNewSettings(prev => fieldUpdater(prev, type, path, data));
  };

  const handleSelectAll = onChange => {
    const allFieldValues = R.map(({ id }) => id, checklistFields);
    return onChange(allFieldValues);
  };

  const handleDeselectAll = onChange => onChange([]);

  return (
    <VStack>
      <Accordion title="Approval details">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <VStack sx={styles.formItem}>
              <Text textStyle="formSectionHeader">Number of approvers</Text>

              <FormField
                data={newSettings}
                path={["requiredApprovers"]}
                type="direct"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <RadioGroup size="sm" value={value} onChange={onChange}>
                    <VStack sx={styles.verticalRadioGroupList}>
                      <Flex alignItems="center">
                        <Radio size="sm" value="some">
                          Get signature of any
                        </Radio>
                        <FormField
                          data={newSettings}
                          path={["minApprovers"]}
                          type="number"
                          onChange={handleChange}
                          render={({ value, onChange }) => (
                            <NumberInput
                              value={value}
                              onChange={onChange}
                              min={1}
                              size="xs"
                              sx={styles.numberInput}
                            >
                              <NumberInputField sx={styles.numberInputField} />
                            </NumberInput>
                          )}
                        />
                        user(s)
                      </Flex>

                      <Radio value="adhoc">Select ad-hoc</Radio>
                    </VStack>
                  </RadioGroup>
                )}
              />
            </VStack>

            <FormField
              data={newSettings}
              path={["showRequestApprovalButton"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={newSettings.requiredApprovers === "adhoc"}
                >
                  Request approval botton
                </Checkbox>
              )}
            />

            <FormField
              data={newSettings}
              path={["canCancelPartialApproval"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox size="sm" isChecked={value} onChange={onChange}>
                  Allow cancelling of approvals-in-progress
                </Checkbox>
              )}
            />

            <VStack sx={styles.formItem}>
              <Text textStyle="formSectionHeader">Who can approve</Text>

              <VStack sx={styles.formItem}>
                <Text textStyle="label">Users</Text>

                <FormField
                  data={newSettings}
                  path={["approvers", "users"]}
                  type="multiSelect2"
                  onChange={handleChange}
                  render={({ value, onChange }) => (
                    <UserSelectMultiple
                      value={value}
                      onChange={onChange}
                      inputPlaceholder="Select users"
                    />
                  )}
                />
              </VStack>

              <VStack sx={styles.formItem}>
                <Text textStyle="label">Roles</Text>

                <FormField
                  data={newSettings}
                  path={["approvers", "roles"]}
                  type="multiSelect2"
                  onChange={handleChange}
                  render={({ value, onChange }) => (
                    <RoleMultiSelect value={value} onChange={onChange} />
                  )}
                />
              </VStack>

              <VStack sx={styles.formItem}>
                <Text textStyle="label">Groups</Text>

                <FormField
                  data={newSettings}
                  path={["approvers", "groups"]}
                  type="multiSelect2"
                  onChange={handleChange}
                  render={({ value, onChange }) => (
                    <GroupMultiSelect value={value} onChange={onChange} />
                  )}
                />
              </VStack>
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="formSectionHeader">Who can cancel approval</Text>

              <FormField
                data={newSettings}
                path={["allowedCancellers"]}
                type="direct"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <RadioGroup
                    size="sm"
                    value={value}
                    onChange={onChange}
                    width="100%"
                  >
                    <VStack sx={styles.verticalRadioGroupList}>
                      <Radio value="everyone">Everybody</Radio>
                      <Radio value="approvers">
                        Approvers/Signatories only
                      </Radio>
                      <Radio value="cancellers">Select manually</Radio>
                    </VStack>

                    <FormControl
                      as={VStack}
                      sx={{ ...styles.formItem, mt: 1, pl: 5 }}
                      isDisabled={
                        newSettings.allowedCancellers !== "cancellers"
                      }
                    >
                      <FormControl
                        as={VStack}
                        sx={styles.formItem}
                        isDisabled={
                          newSettings.allowedCancellers !== "cancellers"
                        }
                      >
                        <FormLabel textStyle="label">Users</FormLabel>

                        <FormField
                          data={newSettings}
                          path={["cancellers", "users"]}
                          type="multiSelect2"
                          onChange={handleChange}
                          render={({ value, onChange }) => (
                            <UserSelectMultiple
                              value={value}
                              onChange={onChange}
                              inputPlaceholder="Select users"
                              disabled={
                                newSettings.allowedCancellers !== "cancellers"
                              }
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl
                        isDisabled={
                          newSettings.allowedCancellers !== "cancellers"
                        }
                        as={VStack}
                        sx={styles.formItem}
                      >
                        <FormLabel textStyle="label">Roles</FormLabel>

                        <FormField
                          data={newSettings}
                          path={["cancellers", "roles"]}
                          type="multiSelect2"
                          onChange={handleChange}
                          render={({ value, onChange }) => (
                            <RoleMultiSelect
                              value={value}
                              onChange={onChange}
                              disabled={
                                newSettings.allowedCancellers !== "cancellers"
                              }
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl
                        isDisabled={
                          newSettings.allowedCancellers !== "cancellers"
                        }
                        as={VStack}
                        sx={styles.formItem}
                      >
                        <FormLabel textStyle="label">Groups</FormLabel>

                        <FormField
                          data={newSettings}
                          path={["cancellers", "groups"]}
                          type="multiSelect2"
                          onChange={handleChange}
                          render={({ value, onChange }) => (
                            <GroupMultiSelect
                              value={value}
                              onChange={onChange}
                              disabled={
                                newSettings.allowedCancellers !== "cancellers"
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </FormControl>
                  </RadioGroup>
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="formSectionHeader">Approval comments</Text>

              <FormField
                data={newSettings}
                path={["requireComment"]}
                type="checkbox"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <Checkbox size="sm" isChecked={value} onChange={onChange}>
                    Make comments during signature mandatory
                  </Checkbox>
                )}
              />

              <FormField
                data={newSettings}
                path={["requireCancellationComment"]}
                type="checkbox"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <Checkbox size="sm" isChecked={value} onChange={onChange}>
                    Make comments while cancelling mandatory
                  </Checkbox>
                )}
              />
            </VStack>
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Contingent on previous approval">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <VStack sx={styles.formItem}>
              <Text textStyle="label">Contingent on previous approval(s)</Text>

              <FormField
                data={newSettings}
                path={["contingentApprovals"]}
                type="multiSelect"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <FieldMultiSelect
                    value={value}
                    onChange={onChange}
                    placeholder="Select approval fields from checklist"
                    size="sm"
                    sx={{ width: "380px" }}
                  >
                    {otherApprovalFields.map(item => (
                      <p key={item.id} value={item.id}>
                        {item.label}
                      </p>
                    ))}
                  </FieldMultiSelect>
                )}
              />
            </VStack>

            <FormField
              data={newSettings}
              path={["inactiveBehavior"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <RadioGroup size="sm" value={value} onChange={onChange}>
                  <VStack sx={styles.verticalRadioGroupList}>
                    <Radio
                      value="hide"
                      isDisabled={newSettings.contingentApprovals.length === 0}
                    >
                      Hide when inactive
                    </Radio>
                    <Radio
                      value="disable"
                      isDisabled={newSettings.contingentApprovals.length === 0}
                    >
                      Disable when inactive
                    </Radio>
                  </VStack>
                </RadioGroup>
              )}
            />

            <FormField
              data={newSettings}
              path={["canCancelContingentApprovals"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={newSettings.contingentApprovals.length === 0}
                >
                  Disable previous approvals while field is approved or in
                  progress
                </Checkbox>
              )}
            />
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Lock fields">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <FormField
              data={newSettings}
              path={["lockFields", "mode"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <RadioGroup size="sm" value={value} onChange={onChange}>
                  <VStack sx={styles.verticalRadioGroupList}>
                    <Radio
                      value="inherit"
                      isDisabled={newSettings.contingentApprovals.length === 0}
                    >
                      Continue to lock fields under previous approval
                    </Radio>
                    <Radio value="new">
                      Lock checklist fields during and post approval/rejection:
                    </Radio>
                  </VStack>

                  <Box mt={2}>
                    <FormField
                      data={newSettings}
                      path={["lockFields", "fields"]}
                      type="multiSelect"
                      onChange={handleChange}
                      render={({ value, onChange }) => (
                        <FieldMultiSelect
                          value={value}
                          onChange={onChange}
                          placeholder="Select checklist fields to lock"
                          size="sm"
                          sx={{ width: "380px" }}
                          handleSelectAll={() => handleSelectAll(onChange)}
                          handleDeselectAll={() => handleDeselectAll(onChange)}
                        >
                          {checklistFields.map(item => {
                            return (
                              <p key={item.id} value={item.id}>
                                {item.label}
                              </p>
                            );
                          })}
                        </FieldMultiSelect>
                      )}
                    />
                  </Box>
                </RadioGroup>
              )}
            />
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Automations on start / request of signature">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <FormField
              data={newSettings}
              path={["automations", "started", 0, "data"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <StatusSelect
                  options={allStatuses}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <FormField
              data={newSettings}
              path={["lockStatus", "onStart"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={R.isNil(newSettings.automations.started[0].data)}
                >
                  Lock status (prevent users from changing status)
                </Checkbox>
              )}
            />

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Add participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "started", 1, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Remove participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "started", 2, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Change owner to</Text>

              <UserSelectMultiple
                value={
                  newSettings.automations.started[3].data
                    ? [newSettings.automations.started[3].data]
                    : []
                }
                onChange={handleOwnerAutomationDataChange([
                  "automations",
                  "started",
                  3,
                  "data"
                ])}
                inputPlaceholder="Select user"
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Send a message</Text>

              <FormField
                data={newSettings}
                path={["automations", "started", 4, "data"]}
                type="event"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <MentionInput value={value} onChange={onChange} />
                )}
              />
            </VStack>

            <Automations
              action="updatePrivacy"
              value={newSettings.automations.started[5].data}
              onChange={handlePrivacyAutomationDataChange([
                "automations",
                "started",
                5,
                "data"
              ])}
            />
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Automations on approval">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <FormField
              data={newSettings}
              path={["allowCancelOnApproval"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox size="sm" isChecked={value} onChange={onChange}>
                  Allow cancellation post approval
                </Checkbox>
              )}
            />

            <FormField
              data={newSettings}
              path={["automations", "approved", 0, "data"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <StatusSelect
                  options={allStatuses}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <FormField
              data={newSettings}
              path={["lockStatus", "onApproval"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={R.isNil(newSettings.automations.approved[0].data)}
                >
                  Lock status (prevent users from changing status)
                </Checkbox>
              )}
            />

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Add participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "approved", 1, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Remove participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "approved", 2, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Change owner to</Text>
              <UserSelectMultiple
                value={
                  newSettings.automations.approved[3].data
                    ? [newSettings.automations.approved[3].data]
                    : []
                }
                onChange={handleOwnerAutomationDataChange([
                  "automations",
                  "approved",
                  3,
                  "data"
                ])}
                inputPlaceholder="Select user"
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Send a message</Text>

              <FormField
                data={newSettings}
                path={["automations", "approved", 4, "data"]}
                type="event"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <MentionInput value={value} onChange={onChange} />
                )}
              />
            </VStack>

            <Automations
              action="updatePrivacy"
              value={newSettings.automations.approved[5].data}
              onChange={handlePrivacyAutomationDataChange([
                "automations",
                "approved",
                5,
                "data"
              ])}
            />
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Automations on rejection">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <FormField
              data={newSettings}
              path={["automations", "rejected", 0, "data"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <StatusSelect
                  options={allStatuses}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <FormField
              data={newSettings}
              path={["lockStatus", "onRejection"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={R.isNil(newSettings.automations.rejected[0].data)}
                >
                  Lock status (prevent users from changing status)
                </Checkbox>
              )}
            />

            <FormField
              data={newSettings}
              path={["cancelContingentApprovalsOnRejection"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox size="sm" isChecked={value} onChange={onChange}>
                  Cancel all previous approvals (within hierarchy)
                </Checkbox>
              )}
            />

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Add participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "rejected", 1, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Remove participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "rejected", 2, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Change owner to</Text>
              <UserSelectMultiple
                value={
                  newSettings.automations.rejected[3].data
                    ? [newSettings.automations.rejected[3].data]
                    : []
                }
                onChange={handleOwnerAutomationDataChange([
                  "automations",
                  "rejected",
                  3,
                  "data"
                ])}
                inputPlaceholder="Select users"
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Send a message</Text>

              <FormField
                data={newSettings}
                path={["automations", "rejected", 4, "data"]}
                type="event"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <MentionInput value={value} onChange={onChange} />
                )}
              />
            </VStack>

            <Automations
              action="updatePrivacy"
              value={newSettings.automations.rejected[5].data}
              onChange={handlePrivacyAutomationDataChange([
                "automations",
                "rejected",
                5,
                "data"
              ])}
            />
          </VStack>
        </AccordionContent>
      </Accordion>

      <Accordion title="Automations on cancellation">
        <AccordionContent>
          <VStack sx={styles.formItem} spacing={4}>
            <FormField
              data={newSettings}
              path={["automations", "cancelled", 0, "data"]}
              type="direct"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <StatusSelect
                  options={allStatuses}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <FormField
              data={newSettings}
              path={["lockStatus", "onCancellation"]}
              type="checkbox"
              onChange={handleChange}
              render={({ value, onChange }) => (
                <Checkbox
                  size="sm"
                  isChecked={value}
                  onChange={onChange}
                  isDisabled={R.isNil(
                    newSettings.automations.cancelled[0].data
                  )}
                >
                  Lock status (prevent users from changing status)
                </Checkbox>
              )}
            />

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Add participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "cancelled", 1, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Remove participants</Text>

              <FormField
                data={newSettings}
                path={["automations", "cancelled", 2, "data"]}
                type="multiSelect2"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <UserSelectMultiple
                    value={value}
                    onChange={onChange}
                    inputPlaceholder="Select users"
                  />
                )}
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Change owner to</Text>
              <UserSelectMultiple
                value={
                  newSettings.automations.cancelled[3].data
                    ? [newSettings.automations.cancelled[3].data]
                    : []
                }
                onChange={handleOwnerAutomationDataChange([
                  "automations",
                  "cancelled",
                  3,
                  "data"
                ])}
                inputPlaceholder="Select user"
              />
            </VStack>

            <VStack sx={styles.formItem}>
              <Text textStyle="label">Send a message</Text>

              <FormField
                data={newSettings}
                path={["automations", "cancelled", 4, "data"]}
                type="event"
                onChange={handleChange}
                render={({ value, onChange }) => (
                  <MentionInput value={value} onChange={onChange} />
                )}
              />
            </VStack>

            <Automations
              action="updatePrivacy"
              value={newSettings.automations.cancelled[5].data}
              onChange={handlePrivacyAutomationDataChange([
                "automations",
                "cancelled",
                5,
                "data"
              ])}
            />
          </VStack>
        </AccordionContent>
      </Accordion>
    </VStack>
  );
}
