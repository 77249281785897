// @flow

import { combineReducers } from "redux";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type { Action, DashBordbyId, DashboardState } from "src/types";

const current = (state: ?number = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_DASHBOARD_REQUEST:
      if (payload.id && !isNaN(payload.id)) {
        return parseInt(payload.id, 10);
      }
      return state;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.DELETE_DASHBOARD_SUCCESS:
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_CONTACTS_REQUEST:
      return null;
    default:
      return state;
  }
};

const loadingDashboard = (state: ?number = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_DASHBOARD_REQUEST:
      if (payload.id) {
        return payload.id;
      }
      return state;
    case atypes.LOADED_DASHBOARD:
      return null;
    default:
      return state;
  }
};

const byId = (state: DashBordbyId = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_ALL_DASHBOARD_SUCCESS:
      return R.mergeAll(
        payload.dashboards.map(dashboard => ({
          [dashboard.id]: {
            ...dashboard
          }
        }))
      );
    case atypes.CREATE_DASHBOARD_SUCCESS:
      return { ...state, [payload.id]: payload };
    case atypes.EDIT_DASHBOARD_TITLE_OPTIMISTIC:
    case atypes.EDIT_DASHBOARD_TITLE_FAILURE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], title: payload.title }
      };
    default:
      return state;
  }
};

const creating = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.CREATE_DASHBOARD_REQUEST:
      return true;
    case atypes.CREATE_DASHBOARD_FAILURE:
    case atypes.CREATE_DASHBOARD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const metaData = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_DASHBOARD_SUCCESS:
      return payload;
    case atypes.EDIT_DASHBOARD_TITLE_OPTIMISTIC:
    case atypes.EDIT_DASHBOARD_TITLE_FAILURE:
      return { ...state, title: payload.title };
    case atypes.DELETE_CHART_FROM_DASHBOARD_SUCCESS:
      return {
        ...state,
        charts: R.reject(chart => chart.id == payload.chart, state.charts)
      };
    case atypes.ADD_CHART_TO_DASHBOARD_SUCCESS:
      return {
        ...state,
        charts: [...(state.charts || []), payload.chart]
      };
    case atypes.GET_ALL_DASHBOARD_FAILURE:
    case atypes.DELETE_DASHBOARD_SUCCESS:
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.SET_CONTACTS_REQUEST:
    case atypes.SET_REPORTS_REQUEST:
      return {};
    default:
      return state;
  }
};

const addingChart = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.ADD_CHART_TO_DASHBOARD_REQUEST:
      return true;
    case atypes.ADD_CHART_TO_DASHBOARD_FAILURE:
    case atypes.ADD_CHART_TO_DASHBOARD_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  current,
  byId,
  creating,
  metaData,
  addingChart,
  loadingDashboard
});

// $FlowFixMe
export const sortByTitleCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop("title"))
);

export const getDashboardsSortedByName = (state: DashboardState) => {
  const dashboards = R.values(state.byId);

  return sortByTitleCaseInsensitive(dashboards);
};

export const getDashboard = (state: DashboardState, id: number) =>
  state.byId[`${id}`] || {};
