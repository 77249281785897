// @flow

import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";
import type { SignUpError, Action } from "src/types";

const error = (state: SignUpError = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.EMAIL_VALIDATION_FAILURE:
      return {
        ...state,
        emailValidation: payload
      };
    case atypes.SEND_VERIFICATION_MAIL_FAILURE:
      return {
        ...state,
        sendVerification: payload
      };
    case atypes.JOIN_ORG_FAILURE:
      return {
        ...state,
        join: payload
      };
    case atypes.CREATE_USER_FAILURE:
      return {
        ...state,
        createUser: payload
      };
    case atypes.UPDATE_DISPLAY_NAME_FAILURE:
      return {
        ...state,
        displayName: payload
      };
    case atypes.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        password: payload
      };
    case atypes.UPLOAD_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        profilePicture: payload
      };
    case atypes.VALIDATE_SIGN_UP_CODE_FAILURE:
      return {
        ...state,
        emailVerification: payload
      };
    case atypes.SETUP_ORG_FAILURE:
      return {
        ...state,
        setupOrg: payload
      };
    case atypes.JOIN_EXISTING_ORG_FAILURE:
      return {
        ...state,
        joinExistingOrg: payload
      };
    case atypes.CLEAR_SIGNUP_ERRORS:
      return {};
    default:
      return state;
  }
};

const email = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_SIGN_UP_EMAIL:
      return payload.email;
    case atypes.CLEAR_SIGN_UP_EMAIL:
      return null;
    default:
      return state;
  }
};

const orgs = (state: Array<Object> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_AVAILABLE_ORGS:
      return payload.orgs;
    default:
      return state;
  }
};

const joiningOrg = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.JOIN_ORG_REQUEST:
      return true;
    case atypes.JOIN_ORG_FAILURE:
    case atypes.JOIN_ORG_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  error,
  email,
  orgs,
  joiningOrg
});
