// @flow

import React, { useState, useRef, useCallback } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getOndriveFilePickerAuthData } from "src/api/microsoft365";

import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
import { AddButton, MainFilesWrapper } from "../styles";
import SingleFile from "./Single";
import ProgressBar from "./ProgressBar";
import { getChecklistFileUploadProgress } from "src/reducers/checklist";
import { searchConversationFile, unpinFile } from "src/actions/file";
import { dataStages } from "src/constants";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";

import useOAuthIntegration from "src/hooks/useOAuthIntegrationWindow";

import type {
  AppState,
  RoomId,
  ChecklistFieldUploadProgress,
  DataStage,
  ChecklistField,
  ColumnId
} from "src/types";
import { integrationStatus } from "src/constants";
import NativeFilePicker from "./NativeFilePicker";
import SharepointFilePicker from "./SharepointFilePicker";
import SourcePicker from "./SourcePicker";
import SharepointAdminIntegrationModal from "./SharepointAdminIntegrationModal";

export const fileMenuStates = Object.freeze({
  idle: null,
  pickSource: "pickSource",
  pickNativeFile: "pickNativeFile"
});

export const fileSources = Object.freeze({
  native: "native",
  local: "local",
  microsoft365: "microsoft365"
});

export const sharepointAdminIntegrationModalInitialState:
  | {
      open: false,
      data: null
    }
  | {
      open: true,
      data: {
        url: string
      }
    } = {
  open: false,
  data: null
};

export type SharepointIntegrationData =
  | {|
      status: typeof integrationStatus.done,
      url: string,
      accessTokens: Object
    |}
  | {|
      status:
        | typeof integrationStatus.userPending
        | typeof integrationStatus.adminPending,
      authorizationUrl: string
    |};

export type SharepointIntegrationState = {
  fetching: boolean,
  error: ?string,
  data: SharepointIntegrationData | null
};

export type HandleIntegrationItemClick = (name: string) => void;

type Props = {
  settings: Object,
  checklistValue: Object,
  details: ChecklistField,
  roomId: RoomId,
  columnId: ColumnId,
  formId: number,
  fieldId: number,
  setChecklistValue: Function,
  progress: ChecklistFieldUploadProgress,
  promptCallback?: ?Function,
  locked: boolean,
  location?: string,
  type: "file" | "pdf",
  isMandatory?: boolean,
  valueStatus: DataStage,
  _unpinFile: Function
};

const File = ({
  checklistValue,
  settings,
  roomId,
  columnId,
  formId,
  fieldId,
  setChecklistValue,
  _unpinFile,
  progress,
  locked,
  location,
  type,
  isMandatory,
  valueStatus,
  promptCallback
}: Props) => {
  const preview = settings?.preview ?? false;
  const multiple = settings?.multiple || false;

  const updating = valueStatus === dataStages.updating;

  const files = R.reject(R.isNil)(checklistValue?.value || []);
  const fileIds = R.pluck("name")(files);

  const sharepointIntegrationPopup = useOAuthIntegration({
    app: "microsoft365"
  });

  const sharepointPicker = useRef<SharepointFilePicker | null>(null);

  const [menu, setMenu] = useState<$Values<typeof fileMenuStates>>(
    fileMenuStates.idle
  );

  const [
    sharepointData,
    setSharepointData
  ] = useState<SharepointIntegrationState>({
    fetching: true,
    error: null,
    data: null
  });

  const [
    sharepointAdminIntegrationModalState,
    setSharepointAdminIntegrationModalState
  ] = useState(sharepointAdminIntegrationModalInitialState);

  const buttonText =
    (settings || {}).buttonText ||
    (type === "pdf" ? "Generate PDF" : "+ Attach File");

  const fetchSharepointIntegrationData = async () => {
    try {
      setSharepointData({
        fetching: true,
        error: null,
        data: null
      });

      const { url, accessTokens } = await getOndriveFilePickerAuthData();

      setSharepointData({
        fetching: false,
        error: null,
        data: {
          status: integrationStatus.done,
          url,
          accessTokens
        }
      });
    } catch (e) {
      if (e.cause.response && e.cause.response.status == 403) {
        const { authorizationUrl, adminConsent } = e.cause.result;

        setSharepointData({
          fetching: false,
          error: null,
          data: {
            status: adminConsent
              ? integrationStatus.userPending
              : integrationStatus.adminPending,
            authorizationUrl
          }
        });
      } else {
        console.error(e);
        setSharepointData({
          fetching: false,
          error: e.message,
          data: null
        });
      }
    }
  };

  const generatePdf = useCallback(() => {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: null,
        type: "pdf",
        checked: true
      },
      progress: true,
      formId,
      columnId
    });
  }, [formId, columnId, setChecklistValue, roomId, fieldId]);

  const handleRemove = useCallback(
    (option: string) => {
      const updatedFileIds = R.without([option])(fileIds);

      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: updatedFileIds,
          type: type,
          checked: true
        },
        progress: true,
        formId,
        columnId
      });
    },
    [formId, roomId, columnId, fieldId, fileIds, setChecklistValue]
  );

  const handleDelete = useCallback(
    (option: string) => {
      handleRemove(option);
      _unpinFile(option);
    },
    [_unpinFile, handleRemove]
  );

  const handleAttachBtnClick = () => {
    if (type === "file") {
      setMenu(fileMenuStates.pickSource);
      fetchSharepointIntegrationData();
    } else if (type === "pdf") {
      generatePdf();
    }
  };

  const handleFileSourceChange = (source: $Values<typeof fileSources>) => {
    if (source === fileSources.native) {
      setMenu(fileMenuStates.pickNativeFile);
    }
  };

  const handleCloseMenu = () => {
    setMenu(fileMenuStates.idle);
    if (sharepointPicker.current) {
      sharepointPicker.current.destroy();
    }
  };

  const handleReOpenSourcePicker = () => {
    setMenu(fileMenuStates.pickSource);
  };

  const handleSelectNativeFile = fileId => {
    if (!fileIds.includes(fileId)) {
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: R.append(fileId)(fileIds),
          type,
          checked: true
        },
        progress: true,
        formId,
        columnId
      });

      setMenu(fileMenuStates.idle);
    }

    if (promptCallback) promptCallback();
  };

  const handleSharepointAdminIntegrationCancel = () => {
    setSharepointAdminIntegrationModalState(
      sharepointAdminIntegrationModalInitialState
    );
  };

  const handleSharepointAdminIntegrationStart = () => {
    if (!sharepointAdminIntegrationModalState.open) return;

    sharepointIntegrationPopup.start(
      sharepointAdminIntegrationModalState.data.url
    );

    handleSharepointAdminIntegrationCancel();
  };

  const addSharepointFiles = files => {
    const newFiles = R.map(R.mergeDeepLeft({ source: "microsoft365" }))(files);

    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: R.concat(fileIds, newFiles),
        type,
        checked: true
      },
      progress: true,
      formId,
      columnId
    });

    setMenu(fileMenuStates.idle);

    if (sharepointPicker.current) sharepointPicker.current.destroy();
    if (promptCallback) promptCallback();
  };

  const handleIntegrationItemClick: HandleIntegrationItemClick = name => {
    if (name === "Sharepoint") {
      if (!sharepointData.data) return;

      if (sharepointData.data.status === integrationStatus.done) {
        if (sharepointPicker.current && sharepointPicker.current.isActive()) {
          sharepointPicker.current.focus();
        } else {
          sharepointPicker.current = new SharepointFilePicker({
            url: sharepointData.data.url,
            accessTokens: sharepointData.data.accessTokens,
            multiple,
            onPick: addSharepointFiles
          });
        }
      } else {
        if (sharepointData.data.status === integrationStatus.userPending) {
          sharepointIntegrationPopup.start(
            sharepointData.data.authorizationUrl
          );
          setMenu(fileMenuStates.idle);
        } else {
          setSharepointAdminIntegrationModalState({
            open: true,
            data: {
              url: sharepointData.data.authorizationUrl
            }
          });
        }
      }
    }
  };

  return (
    <MainFilesWrapper>
      {/* $FlowFixMe */}
      {files.map(file => (
        <SingleFile
          roomId={roomId}
          key={file.name}
          originalName={file.originalName}
          handleRemove={handleRemove}
          handleDelete={handleDelete}
          file={file}
          preview={preview}
          disabled={locked}
        />
      ))}

      {progress &&
        R.keys(progress).map(fileName => (
          <ProgressBar progress={progress[fileName]} key={fileName} />
        ))}

      <Popover
        placement="bottom-start"
        isOpen={menu !== fileMenuStates.idle}
        onClose={handleCloseMenu}
        strategy="fixed"
      >
        <PopoverTrigger>
          <Trigger
            updating={updating}
            type={type}
            multiple={multiple}
            files={fileIds}
            handleAttachBtnClick={handleAttachBtnClick}
            disabled={locked}
            isMandatory={Boolean(isMandatory)}
            buttonText={buttonText}
            isActive={menu !== fileMenuStates.idle}
          />
        </PopoverTrigger>

        <PopoverContent
          sx={{
            borderRadius: 4,
            _focus: {
              boxShadow: "none"
            }
          }}
        >
          {menu === fileMenuStates.pickSource && (
            <SourcePicker
              roomId={roomId}
              fieldId={fieldId}
              columnId={columnId}
              formId={formId}
              setChecklistValue={setChecklistValue}
              files={files}
              location={location}
              multiple={multiple}
              onClose={handleCloseMenu}
              onIntegrationItemClick={handleIntegrationItemClick}
              onSourceChange={handleFileSourceChange}
              sharepointData={sharepointData}
              promptCallback={promptCallback}
            />
          )}

          {menu === fileMenuStates.pickNativeFile && (
            <NativeFilePicker
              roomId={roomId}
              fileIds={fileIds}
              onSelect={handleSelectNativeFile}
              onBack={handleReOpenSourcePicker}
              onClose={handleCloseMenu}
            />
          )}
        </PopoverContent>
      </Popover>

      <SharepointAdminIntegrationModal
        isOpen={sharepointAdminIntegrationModalState.open}
        onClose={handleSharepointAdminIntegrationCancel}
        onConfirm={handleSharepointAdminIntegrationStart}
      />
    </MainFilesWrapper>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId, fieldId }) => ({
  progress: getChecklistFileUploadProgress(app.checklist, roomId, fieldId)
});

File.defaultProps = {
  promptCallback: null
};

export default connect(mapStateToProps, {
  _unpinFile: unpinFile,
  _searchConversationFile: searchConversationFile
})(File);

type TriggerProps = {
  updating: boolean,
  type: "file" | "pdf",
  multiple: boolean,
  files: string[],
  handleAttachBtnClick: Function,
  disabled: boolean,
  isMandatory: boolean,
  buttonText: string,
  isActive: boolean
};

const Trigger = React.forwardRef(
  (
    {
      updating,
      type,
      multiple,
      files,
      handleAttachBtnClick,
      disabled,
      isMandatory,
      buttonText,
      isActive
    }: TriggerProps,
    ref
  ) => {
    if (updating) return <ButtonLoader />;

    if (type === "pdf" || multiple || R.isEmpty(files))
      return (
        <AddButton
          type="button"
          onClick={handleAttachBtnClick}
          disabled={disabled}
          isMandatory={isMandatory}
          ref={ref}
          isActive={isActive}
        >
          {buttonText}
        </AddButton>
      );

    return null;
  }
);
Trigger.displayName == "Trigger";
