// @flow

import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  IconButton,
  HStack
} from "@chakra-ui/react";
import { connect } from "react-redux";
import * as R from "ramda";

import {
  tabListContainer,
  tabContainer,
  tabsContainer
} from "src/components/Manage/Builder/styles";
import FieldSettings from "./FieldSettings";
import Conditions from "./Conditions";
import Prompts from "./Prompts";
import type {
  ChecklistFieldSettings,
  ChecklistBuilderField,
  AppState
} from "src/types";
import FormConditions from "./FormConditions";
import {
  cancelFieldSettingsChanges,
  setFieldSettingsChanges
} from "src/actions/workflows";
import Button from "src/components/Buttons/ChakraButton";
import { setChecklistBuilderAttribute } from "src/actions/checklistBuilder";
import { getCurrentChecklistBuilderFields } from "src/reducers";
import { CloseIcon } from "@chakra-ui/icons";
import Layout from "./Layout";
import type { Layout as LayoutType } from "src/types";
import { getDefaultFieldLayout } from "./Layout/utils";

const checklistBuilderTabs = ["field-settings", "conditions", "prompts"];

type Props = {
  cancelFieldSettingsChanges: Function,
  setFieldSettingsChanges: Function,
  setChecklistBuilderAttribute: Function,
  closeSettings: Function,
  showSettings: boolean,
  currentFields: ChecklistBuilderField[],
  checklistFieldSettings: ChecklistFieldSettings
};

const SettingsBuilder = ({
  checklistFieldSettings,
  cancelFieldSettingsChanges,
  setChecklistBuilderAttribute,
  setFieldSettingsChanges,
  closeSettings,
  currentFields
}: Props) => {
  const field = currentFields[checklistFieldSettings.position];

  const [tabIndex, setTabIndex] = useState(0);

  // Holds field settings of current field item
  const [localSettings, setLocalSettings] = useState({});

  // layout settings
  const [layout, setLayout] = useState<LayoutType>(
    field.layout || getDefaultFieldLayout(field.type)
  );

  const [selectedFormId, setSelectedFormId] = useState(null);
  // Form field which is chosen to be edited in the conditions builder
  const [selectedFormField, selectFormField] = useState(null);

  const handleLayoutUpdate = data => setLayout(R.mergeDeepLeft(data));

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  const handleCancel = () => {
    if (
      checklistFieldSettings.type === "form" &&
      tabIndex === 2 &&
      selectedFormField &&
      selectedFormId
    ) {
      selectFormField(null);
    } else {
      closeSettings();
      cancelFieldSettingsChanges();
    }
  };

  // Persist field settings to reducer
  const syncSettings = () => {
    const checklistFieldPosition = checklistFieldSettings.position;

    const newFields = R.adjust(
      checklistFieldPosition,
      data => ({ ...data, settings: localSettings, layout }),
      currentFields
    );

    setChecklistBuilderAttribute({
      id: "fields",
      value: newFields
    });
  };

  const handleSave = () => {
    if (
      checklistFieldSettings.type === "form" &&
      tabIndex === 2 &&
      selectedFormField &&
      selectedFormId
    ) {
      syncSettings();
      selectFormField(null);
    } else {
      closeSettings(); // Close settings builder
      setFieldSettingsChanges(); // Clears current field meta data
      syncSettings();
    }
  };

  // Programatically change tab based on current tab in checklistFieldSettings
  useEffect(() => {
    if (checklistFieldSettings.tab) {
      const realTabIndex = checklistBuilderTabs.findIndex(
        t => t === checklistFieldSettings.tab
      );

      if (realTabIndex > -1 && realTabIndex < checklistBuilderTabs.length) {
        setTabIndex(realTabIndex);
      }
    }
  }, [checklistFieldSettings.tab]);

  const fieldSettingsTabLabel = `${checklistFieldSettings.type} Field Settings`;

  // Default tab structure for all field types
  let renderTabs = (
    <Tabs sx={tabsContainer} index={tabIndex} onChange={handleTabsChange}>
      <TabList sx={tabListContainer}>
        <Tab sx={tabContainer}>{fieldSettingsTabLabel}</Tab>
        <Tab sx={tabContainer}>Conditions</Tab>
        <Tab sx={tabContainer}>Prompts</Tab>
        <Tab sx={tabContainer}>Layout</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <FieldSettings setLocalSettings={setLocalSettings} />
        </TabPanel>
        <TabPanel>
          <Conditions setLocalSettings={setLocalSettings} />
        </TabPanel>
        <TabPanel>
          <Prompts />
        </TabPanel>
        <TabPanel>
          <Layout value={layout} onUpdate={handleLayoutUpdate} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );

  // Forms don't have prompt settings
  if (checklistFieldSettings.type === "form") {
    renderTabs = (
      <Tabs sx={tabsContainer} index={tabIndex} onChange={handleTabsChange}>
        <TabList sx={tabListContainer}>
          <Tab sx={tabContainer}>{fieldSettingsTabLabel}</Tab>
          <Tab sx={tabContainer}>Conditions</Tab>
          <Tab sx={tabContainer}>Form Conditions</Tab>
          <Tab sx={tabContainer} isDisabled>
            Prompts
          </Tab>
          <Tab sx={tabContainer}>Layout</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FieldSettings setLocalSettings={setLocalSettings} />
          </TabPanel>
          <TabPanel>
            <Conditions setLocalSettings={setLocalSettings} />
          </TabPanel>
          <TabPanel>
            <FormConditions
              setLocalSettings={setLocalSettings}
              selectedFormId={selectedFormId}
              setSelectedFormId={setSelectedFormId}
              selectedFormField={selectedFormField}
              selectFormField={selectFormField}
            />
          </TabPanel>
          <TabPanel>
            <Prompts />
          </TabPanel>
          <TabPanel>
            <Layout value={layout} onUpdate={handleLayoutUpdate} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  // Sections dont have prompt or field settings
  if (checklistFieldSettings.type === "section") {
    renderTabs = (
      <Tabs sx={tabsContainer} index={tabIndex} onChange={handleTabsChange}>
        <TabList sx={tabListContainer}>
          <Tab sx={tabContainer}>{fieldSettingsTabLabel}</Tab>
          <Tab sx={tabContainer}>Conditions</Tab>
          <Tab sx={tabContainer} isDisabled>
            Prompts
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <FieldSettings setLocalSettings={setLocalSettings} />
          </TabPanel>
          <TabPanel>
            <Conditions setLocalSettings={setLocalSettings} />
          </TabPanel>
          <TabPanel>
            <Prompts />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return (
    <Modal
      isOpen
      onClose={closeSettings}
      size="6xl"
      isCentered
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          as={HStack}
          sx={{
            mt: 2,
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Text textStyle="large">{checklistFieldSettings.label}</Text>

          <IconButton bg="transparent" onClick={closeSettings}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <ModalBody>{renderTabs}</ModalBody>

        <ModalFooter
          sx={{
            justifyContent: "flex-start",
            gap: 12
          }}
        >
          <Button variant="uSecondary" onClick={handleCancel}>
            Cancel Changes
          </Button>
          <Button variant="uPrimary" onClick={handleSave}>
            Update Field Settings
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentFields: getCurrentChecklistBuilderFields(app, true),
  checklistFieldSettings: app.workflow.builderDialog.checklistFieldSettings
});

export default connect(mapStateToProps, {
  setFieldSettingsChanges,
  cancelFieldSettingsChanges,
  setChecklistBuilderAttribute
})(SettingsBuilder);
