// @flow

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import type { Map } from "immutable";
import * as R from "ramda";

import { Grid, GridItem } from "@chakra-ui/react";
import Accordion from "./Accordion";

import Field from "./Field";
import SubSection from "./SubSection";
import type {
  RoomId,
  AppState,
  FieldId,
  UnifizeChatRoom,
  ChecklistId
} from "src/types";

import { breakpointMaxWidths } from "src/components/Dock/DockLayout/utils";
import RowItem from "./WideChecklist/RowItem";
import * as styles from "./styles";

import {
  getChecklistFieldDetails,
  getChatRoom,
  getChecklistFieldBehavior,
  getSectionMandatoryFieldCount
} from "src/reducers";
import { openSection, closeSection } from "src/actions/checklist";

import { behaviorToSettings, behaviors } from "src/conditions";
import type { Layout } from "./WideChecklist/utils";
import type { Breakpoint } from "src/types";

type Props = {
  details: Map<string, any>,
  roomId: RoomId,
  checklistId: number,
  id: FieldId,
  fields: any,
  currentChatRoom: UnifizeChatRoom,
  isFieldInsideSection: boolean,
  behavior: Object,
  mandatoryFieldCount: number,
  openSection: Function,
  closeSection: ({
    checklistId: ChecklistId,
    id: FieldId,
    roomId: RoomId
  }) => void,
  layout?: Layout,
  breakpoint?: Breakpoint
};

const Section = ({
  roomId,
  details,
  checklistId,
  id,
  fields,
  currentChatRoom,
  isFieldInsideSection,
  behavior,
  mandatoryFieldCount,
  openSection,
  closeSection,
  layout,
  breakpoint
}: Props) => {
  const label = details ? details.get("label") : "";
  const settings = details ? details.get("settings") : "{}";
  // $FlowFixMe
  const fieldHidden = details?.get("hidden") || false;

  const [isOpen, setOpen] = useState(
    R.includes(currentChatRoom.status, JSON.parse(settings).status || [])
  );

  const toggleActive = () => {
    setOpen(prev => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      openSection({ checklistId, id, roomId });
    } else {
      closeSection({ checklistId, id, roomId });
    }
  }, [isOpen]);

  if (
    behavior?.current === behaviorToSettings[behaviors.hideField] ||
    fieldHidden
  )
    return null;
  if (layout && breakpoint) {
    return (
      <Accordion
        title={label}
        isExpanded={isOpen}
        isFieldInsideSection={typeof fields[1] === "object"}
        mandatoryFieldCount={mandatoryFieldCount}
        toggleActive={toggleActive}
      >
        <Grid
          templateColumns={`repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`}
          key={id}
          gap={"0"}
          sx={styles.SectionGrid}
        >
          {layout.map(field => {
            if (field.type === "subSection") {
              return (
                <GridItem key={field.id} colSpan={field.w}>
                  <SubSection
                    fields={field.fields}
                    checklistId={checklistId}
                    sectionId={id}
                    id={field.id}
                    isFieldInsideSection={isFieldInsideSection}
                    roomId={roomId}
                    layout={field.layout}
                    breakpoint={breakpoint}
                  />
                </GridItem>
              );
            }
            return (
              <>
                {field.startOnNewRow ? (
                  <GridItem
                    key={field.id}
                    colSpan={breakpointMaxWidths[breakpoint]}
                    sx={{
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <RowItem breakpoint={breakpoint} fieldW={field.w}>
                      <Field
                        key={field.id}
                        id={field.id}
                        checklistId={id}
                        roomId={roomId}
                        section={field.id}
                        isFieldInsideSection={isFieldInsideSection}
                      />
                    </RowItem>
                  </GridItem>
                ) : (
                  <GridItem
                    key={field.id}
                    colSpan={field.w}
                    sx={{
                      minWidth: `${field.w * 200}px`,
                      justifySelf: "stretch"
                    }}
                  >
                    <Field
                      key={field.id}
                      id={field.id}
                      checklistId={id}
                      roomId={roomId}
                      section={field.id}
                      isFieldInsideSection={isFieldInsideSection}
                    />
                  </GridItem>
                )}
              </>
            );
          })}
        </Grid>
      </Accordion>
    );
  }

  return (
    <Accordion
      title={label}
      isExpanded={isOpen}
      isFieldInsideSection={typeof fields[1] === "object"}
      mandatoryFieldCount={mandatoryFieldCount}
      toggleActive={toggleActive}
    >
      {fields.map(field => (
        <>
          {typeof field === "object" ? (
            <SubSection
              fields={field.fields}
              checklistId={checklistId}
              sectionId={id}
              id={field.sectionId}
              isFieldInsideSection={isFieldInsideSection}
              roomId={roomId}
            />
          ) : (
            <Field
              key={field}
              id={field}
              checklistId={id}
              roomId={roomId}
              section={field}
              isFieldInsideSection={isFieldInsideSection}
            />
          )}
        </>
      ))}
    </Accordion>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { id, roomId, fields }: Props
) => ({
  details: getChecklistFieldDetails(app, `${id}`),
  currentChatRoom: getChatRoom(app, `${roomId}`),
  behavior: getChecklistFieldBehavior(app, { roomId, fieldId: id }),
  mandatoryFieldCount: getSectionMandatoryFieldCount(app, fields)
});

const mapDispatchToProps = {
  openSection,
  closeSection
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
