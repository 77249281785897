// @flow

import React, { useRef, useState } from "react";

import type { ReactRef } from "src/types";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";

import Textarea from "react-autosize-textarea";
import {
  TextField as StyledTextFieldContainer,
  Done as StyledUpdateButton
} from "src/components/Dock/Checklist/styles";
import EditableTextField from "src/components/Dock/Checklist/Single";

type Props = {
  initialComment: ?string,
  comment: ?string,
  onChange: Function,
  onUpdate: Function,
  onHide: Function
};

export default function Comment({
  initialComment,
  comment,
  onChange,
  onUpdate,
  onHide
}: Props) {
  const textAreaRef: ReactRef = useRef(null);
  const [edit, setEdit] = useState(!initialComment);

  const reset = () => {
    onChange(initialComment);
    setEdit(false);
    !initialComment && onHide();
  };

  const update = () => {
    onUpdate();
    setEdit(false);
  };

  const { handleShiftEnter } = useKeyDownHandlers(update);

  const handleKeyDown = (e: any) => {
    // If escape is pressed cancel editing
    if (e.keyCode === 27) {
      reset();
    } else {
      handleShiftEnter(e);
    }
  };

  const editChecklistFieldProps = {
    edit,
    update: reset,
    value: comment || "",
    openEdit: () => setEdit(true),
    type: "text",
    clickToEdit: true,
    disabled: false,
    disableAdd: true
  };

  return (
    <EditableTextField {...editChecklistFieldProps}>
      <StyledTextFieldContainer>
        <Textarea
          autoFocus
          value={comment}
          onChange={e => onChange(e.target.value)}
          onKeyDown={handleKeyDown}
          innerRef={el => {
            textAreaRef.current = el;
          }}
        />
        <StyledUpdateButton onClick={update}>Update</StyledUpdateButton>
      </StyledTextFieldContainer>
    </EditableTextField>
  );
}
