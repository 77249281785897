// @flow

import { combineReducers } from "redux";
import * as R from "ramda";
import moment from "moment";
import { arrayMove } from "react-sortable-hoc";

import * as atypes from "src/constants/actionTypes";

import type {
  FieldId,
  Action,
  ChecklistBuilder,
  ChecklistBuilderField,
  CurrentChecklistBuilder,
  ApprovalFieldSettingsV2
} from "src/types";

const initialChecklistBuilder = {
  fields: [],
  deletedFields: [],
  title: "",
  id: NaN,
  oldSettings: {}
};

const getUpdatedApprovalField = (
  field: ChecklistBuilderField,
  updatedFieldIds: FieldId[]
) => {
  const prevSettings: ApprovalFieldSettingsV2 = field.settings;
  const updatedLockedFields = (
    prevSettings.lockFields.fields || []
  ).filter(item => updatedFieldIds.includes(item));

  const newSettings = R.mergeDeepRight(prevSettings, {
    lockFields: {
      fields: updatedLockedFields
    }
  });

  return R.mergeDeepRight(field, {
    settings: newSettings
  });
};

const getUpdatedRevisionField = (
  field: ChecklistBuilderField,
  updatedFieldIds: FieldId[]
) => {
  const prevSettings = field.settings;
  const updatedCopyableFields = (
    prevSettings.copyableFields || []
  ).filter(item => updatedFieldIds.includes(item));

  const newSettings = R.mergeDeepRight(prevSettings, {
    copyableFields: updatedCopyableFields
  });

  return R.mergeDeepRight(field, {
    settings: newSettings
  });
};

const current = (
  state: CurrentChecklistBuilder = initialChecklistBuilder,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHECKLIST:
      return {
        title: payload.title,
        id: payload.id,
        fields: payload.fields,
        deletedFields: payload.deletedFields
      };

    case atypes.ADD_FIELDS_IN_CHECKLIST_BUILDER: {
      const { index, fields } = payload;

      const updaterFunc = R.isNil(index)
        ? R.insertAll(state.fields.length, fields)
        : R.insertAll(index, fields);

      return R.mergeDeepRight(state, {
        fields: updaterFunc(state.fields)
      });
    }

    case atypes.UPDATE_FIELD_IN_CHECKLIST_BUILDER: {
      const { position, data } = payload;

      return {
        ...state,
        fields: R.update(
          position,
          R.mergeDeepRight(state.fields[position], data),
          state.fields
        )
      };
    }

    case atypes.MOVE_FIELD_IN_CHECKLIST_BUILDER: {
      return {
        ...state,
        fields: arrayMove(state.fields, payload.oldIndex, payload.newIndex)
      };
    }

    case atypes.DELETE_FIELD_IN_CHECKLIST_BUILDER: {
      const { position, uid } = payload;
      const { fields, deletedFields } = state;

      const deletedField = R.mergeDeepRight(fields[position], {
        deleted: true,
        deletedBy: uid,
        deletedAt: moment().format()
      });

      let updatedFields = R.remove(position, 1, fields);

      // $FlowFixMe
      const updatedFieldIds: FieldId[] = R.pipe(
        R.pluck("id"),
        R.reject(R.isNil)
      )(updatedFields);

      updatedFields = updatedFields.map(field => {
        switch (field.type) {
          case "approval":
            // $FlowFixMe
            return getUpdatedApprovalField(field, updatedFieldIds);

          case "revision":
            return getUpdatedRevisionField(field, updatedFieldIds);

          default:
            return field;
        }
      });

      const updatedDeletedFields = R.isNil(deletedField.id)
        ? deletedFields
        : R.append(deletedField, deletedFields);

      return R.mergeDeepRight(state, {
        fields: updatedFields,
        deletedFields: updatedDeletedFields
      });
    }

    case atypes.SET_CHECKLIST_BUILDER_ATTRIBUTE:
      return {
        ...state,
        [payload.id]: payload.value
      };

    case atypes.SET_CHECKLIST_BUILDER_ATTRIBUTES:
      return {
        ...state,
        ...payload.value
      };
    case atypes.SET_PROCESS_REQUEST:
    case atypes.CLEAR_CURRENT_CHECKLIST:
      return initialChecklistBuilder;
    default:
      return state;
  }
};

const parentConversation = (
  state: ?number = null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CHECKLIST_BUILDER_PARENT_CONVERSATION:
      return payload.parent;
    case atypes.SHOW_CHECKLIST_BUILDER:
    case atypes.HIDE_CHECKLIST_BUILDER:
    case atypes.TOGGLE_CHECKLIST_BUILDER:
      return null;
    default:
      return state;
  }
};

// Conditions
const shown = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHECKLIST_FIELD_CONDITIONS:
      return !state;
    default:
      return state;
  }
};

const settings = (state: ?Object = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHECKLIST_FIELD_CONDITIONS:
      return payload?.settings || state;
    default:
      return state;
  }
};

const fieldPosition = (state: ?number = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHECKLIST_FIELD_CONDITIONS:
      return typeof payload?.position === "number" ? payload.position : state;
    default:
      return state;
  }
};

const fieldId = (state: ?number = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHECKLIST_FIELD_CONDITIONS:
      return payload?.fieldId || null;
    default:
      return state;
  }
};

const conditions = combineReducers({
  shown,
  settings,
  fieldPosition,
  fieldId
});

const checklistBuilder = combineReducers({
  current,
  parentConversation,
  conditions
});

export default checklistBuilder;

export const getCurrentChecklistBuilder = (state: ChecklistBuilder) =>
  state.current;

export const getCurrentChecklistBuilderFields = (
  state: ChecklistBuilder,
  includeNewFields?: boolean = false
): ChecklistBuilderField[] =>
  includeNewFields
    ? state.current.fields
    : state.current.fields.filter(R.has("id"));

export const getCurrentChecklistByField = (
  state: ChecklistBuilder,
  position: number
) => state.current.fields[position];

export const getConditionsShown = (state: ChecklistBuilder) =>
  state.conditions.shown;

export const getConditionsSettings = (
  state: ChecklistBuilder,
  position: number
) => state.current.fields[position].settings;

export const getConditionsFieldPosition = (state: ChecklistBuilder) =>
  state.conditions.fieldPosition;

export const getConditionsFieldId = (state: ChecklistBuilder) =>
  state.conditions.fieldId;
