// @flow
/* eslint no-unused-vars: 0 */

import * as R from "ramda";
import moment from "moment";

import type {
  ConditionSatisfactionMapArgs,
  UnifizeChatRoom,
  LinkedFieldValue
} from "src/types";

const conditions = {
  isAnyOf: "is any of",
  isNoneOf: "is none of",
  isPartOf: "is part of",
  isOfRole: "is of role",
  isNotOfRole: "is not of role",
  isCreator: "is creator",
  isOwner: "is owner",
  isFilled: "is filled",
  isEmpty: "is empty",
  isOverdue: "is overdue",
  isGreaterThan: "is greater than",
  isLessThan: "is less than",
  isEqualTo: "is equal to",
  isApproved: "is approved",
  isCurrentUser: "is current user"
};

export const behaviors = {
  hideField: "Hide field",
  disableField: "Disable/Lock field",
  showField: "Show field",
  hideStatus: "Hide Status",
  disableStatus: "Disable/Lock Status",
  showStatus: "Show Status",
  dependentPicklistInclude: "Dependent picklist include",
  dependentPicklistExclude: "Dependent picklist exclude",
  lockField: "Lock field",
  dependentFormInclude: "Dependent form include",
  dependentFormExclude: "Dependent form exclude",
  dependentLinkedFieldInclude: "Dependent linked field include",
  dependentLinkedFieldExclude: "Dependent linked field exclude",
  dependentConversationInclude: "Dependent conversation include",
  dependentConversationExclude: "Dependent conversation exclude"
};

export const fields = {
  checklistField: "Checklist Field",
  formField: "Form Field",
  owner: "Owner",
  dueDate: "Due Date",
  status: "Status",
  currentUser: "User (Current user)"
};

export const behaviorToSettings = {
  [behaviors.hideField]: "hidden",
  [behaviors.disableField]: "locked",
  [behaviors.showField]: "shown",
  [behaviors.hideStatus]: "hidden",
  [behaviors.disableStatus]: "locked",
  [behaviors.showStatus]: "shown",
  [behaviors.dependentPicklistInclude]: "dependentPicklistInclude",
  [behaviors.dependentPicklistExclude]: "dependentPicklistExclude",
  [behaviors.dependentLinkedFieldInclude]: "dependentLinkedFieldInclude",
  [behaviors.dependentLinkedFieldExclude]: "dependentLinkedFieldExclude",
  [behaviors.dependentFormInclude]: "dependentFormInclude",
  [behaviors.dependentFormExclude]: "dependentFormExclude",
  [behaviors.dependentConversationInclude]: "dependentConversationInclude",
  [behaviors.dependentConversationExclude]: "dependentConversationExclude"
};

// List of supported fields apart from checklist fields
export const fieldList = [
  fields.owner,
  fields.dueDate,
  fields.status,
  fields.currentUser
];

// Map of conditions supported by each type of field
export const conditionByField = {
  select: [conditions.isAnyOf, conditions.isNoneOf],
  text: [conditions.isFilled, conditions.isEmpty],
  number: [
    conditions.isGreaterThan,
    conditions.isLessThan,
    conditions.isEqualTo
  ],
  user: [
    conditions.isFilled,
    conditions.isEmpty,
    conditions.isCurrentUser,
    conditions.isAnyOf,
    conditions.isNoneOf
  ],
  conversation: [conditions.isAnyOf, conditions.isNoneOf],
  childConversation: [conditions.isAnyOf, conditions.isNoneOf],
  link: [conditions.isAnyOf, conditions.isNoneOf],
  file: [conditions.isFilled, conditions.isEmpty],
  date: [conditions.isFilled, conditions.isEmpty],
  form: [conditions.isFilled, conditions.isEmpty],
  approval: [conditions.isEmpty, conditions.isApproved],
  [fields.owner]: [
    conditions.isAnyOf,
    conditions.isPartOf,
    conditions.isEmpty,
    conditions.isFilled
  ],
  [fields.dueDate]: [
    conditions.isEmpty,
    conditions.isFilled,
    conditions.isOverdue
  ],
  [fields.status]: [conditions.isAnyOf, conditions.isNoneOf],
  [fields.currentUser]: [
    conditions.isAnyOf,
    conditions.isOfRole,
    conditions.isNotOfRole,
    conditions.isCreator,
    conditions.isOwner,
    conditions.isPartOf
  ]
};

// Map of behaviors supported by each type of field
export const behaviorByField = {
  user: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  [fields.currentUser]: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField
  ],
  select: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField,
    behaviors.dependentPicklistInclude,
    behaviors.dependentPicklistExclude
  ],
  text: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  section: [behaviors.hideField, behaviors.showField],
  subSection: [behaviors.hideField, behaviors.showField],
  number: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  file: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  form: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField,
    behaviors.dependentFormInclude,
    behaviors.dependentFormExclude
  ],
  pdf: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  link: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField,
    behaviors.dependentLinkedFieldInclude,
    behaviors.dependentLinkedFieldExclude
  ],
  approval: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  date: [behaviors.hideField, behaviors.disableField, behaviors.showField],
  childConversation: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField,
    behaviors.dependentConversationInclude,
    behaviors.dependentConversationExclude
  ],
  conversation: [
    behaviors.hideField,
    behaviors.disableField,
    behaviors.showField,
    behaviors.dependentConversationInclude,
    behaviors.dependentConversationExclude
  ],
  status: [behaviors.hideStatus, behaviors.disableStatus, behaviors.showStatus]
};

export const initialConditionBlock = [
  {
    conditions: [
      {
        field: "Checklist Field",
        checklistFieldId: null,
        type: null,
        value: []
      }
    ],
    behavior: {}
  }
];

export const supportedFields = [
  "select",
  "text",
  "file",
  "form",
  "currentUser",
  "conversation",
  "childConversation",
  "approval",
  "number",
  "user",
  "link",
  "date"
];

/**
 * Checks whether the field value is equal to the
 * configured condition value.
 */
const isEqualTo = ({
  conditionValue,
  fieldValue
}: ConditionSatisfactionMapArgs) => {
  return R.equals(conditionValue, fieldValue);
};

/**
 * Checks whether the field value contains
 * any of the configured condition value.
 */
const isAnyOf = ({
  conditionValue,
  fieldValue,
  conditionField,
  currentChatroom,
  currentUserId
}: {
  ...ConditionSatisfactionMapArgs,
  fieldValue: Array<UnifizeChatRoom> | LinkedFieldValue
}) => {
  if (conditionField === fields.status) {
    return conditionValue.includes(currentChatroom.status);
  } else if (conditionField === fields.owner) {
    return conditionValue.includes(currentChatroom.owner);
  } else if (conditionField === fields.currentUser) {
    return conditionValue.includes(currentUserId);
  }
  // Check whether it's a user field
  else if (Array.isArray(fieldValue) && fieldValue[0]?.uid) {
    return conditionValue.some(uid => R.find(R.propEq("uid", uid))(fieldValue));
  }
  // Check whether it's a conversation field
  else if (Array.isArray(fieldValue) && fieldValue[0]?.id) {
    return conditionValue.some(r =>
      fieldValue.map(room => room.id).includes(parseInt(r, 10))
    );
  }
  // Check whether it's a linked field
  else if (!Array.isArray(fieldValue) && fieldValue?.result) {
    return conditionValue.some(r =>
      fieldValue.result.includes(parseInt(r, 10))
    );
  }

  // Multi Select
  else if (Array.isArray(fieldValue)) {
    // $FlowFixMe
    return conditionValue.some(r => fieldValue?.includes(r));
  }

  // Single Select
  return R.find(R.equals(fieldValue))(conditionValue);
};

/**
 * Checks whether the field value is none
 * of the configured condition value.
 */
const isNoneOf = ({
  conditionValue,
  fieldValue,
  conditionField,
  currentChatroom
}: {
  ...ConditionSatisfactionMapArgs,
  fieldValue: Array<UnifizeChatRoom> | LinkedFieldValue
}) => {
  if (conditionField === fields.status) {
    return !conditionValue.includes(currentChatroom.status);
  }
  // Check whether it's a user field
  else if (Array.isArray(fieldValue) && fieldValue[0]?.uid) {
    return conditionValue.every(
      uid => !R.find(R.propEq("uid", uid))(fieldValue)
    );
  }
  // Check whether it's a conversation field
  else if (Array.isArray(fieldValue) && fieldValue[0]?.id) {
    return conditionValue.every(
      r => !fieldValue.map(room => room.id).includes(parseInt(r, 10))
    );
  }
  // Check whether it's a linked field
  else if (!Array.isArray(fieldValue) && fieldValue?.result) {
    return conditionValue.every(
      r => !fieldValue.result.includes(parseInt(r, 10))
    );
  }

  return (
    // $FlowFixMe
    conditionValue.every(r => !fieldValue?.includes(r)) ||
    (Array.isArray(fieldValue) && fieldValue.length === 0)
  );
};

/** Checks whether the field is filled. */
const isFilled = ({
  fieldValue,
  conditionField,
  currentChatroom
}: ConditionSatisfactionMapArgs) => {
  if (conditionField === fields.dueDate) {
    return !!currentChatroom.dueDate;
  }

  return !R.isEmpty(fieldValue) && !R.isNil(fieldValue);
};

/** Checks whether the field is empty. */
const isEmpty = ({
  fieldValue,
  conditionField,
  currentChatroom
}: ConditionSatisfactionMapArgs) => {
  if (conditionField === fields.dueDate) {
    return !currentChatroom.dueDate;
  }

  return R.isEmpty(fieldValue) || R.isNil(fieldValue);
};

/**
 * Checks whether the current user belongs to any
 * of the configured roles in the condition.
 */
const isOfRole = ({
  conditionValue,
  currentUserRole
}: ConditionSatisfactionMapArgs) => {
  return conditionValue.some(role => role === currentUserRole);
};

/**
 * Checks whether the current user doesn't belong
 * to any of the configured roles in the condition.
 */
const isNotOfRole = ({
  conditionValue,
  currentUserRole
}: ConditionSatisfactionMapArgs) => {
  return conditionValue.every(role => role !== currentUserRole);
};

/**
 * Checks whether the current user is the owner of
 * current chatroom.
 */
const isOwner = ({
  currentUserId,
  currentChatroom
}: ConditionSatisfactionMapArgs) => {
  return currentChatroom.owner === currentUserId;
};

/**
 * Checks whether the current user is the creator of
 * current chatroom.
 */
const isCreator = ({
  currentUserId,
  currentChatroom
}: ConditionSatisfactionMapArgs) => {
  return currentChatroom.creator === currentUserId;
};

/**
 * Checks whether the current user belongs to any
 * of the configured groups in the condition.
 */
const isPartOf = ({
  currentUserGroups,
  chatroomOwnerGroups,
  conditionField,
  conditionValue
}: ConditionSatisfactionMapArgs) => {
  const userGroups =
    conditionField === fields.owner ? chatroomOwnerGroups : currentUserGroups;
  // $FlowFixMe
  return userGroups?.some(group => conditionValue?.includes(`${group}`));
};

/** Checks whether the current chatroom is overdue */
const isOverdue = ({
  currentChatroom
}: {
  currentChatroom: UnifizeChatRoom
}) => {
  if (currentChatroom.dueDate && currentChatroom.active) {
    const days = moment(currentChatroom.dueDate).diff(moment(), "days");
    if (days < 0) {
      return true;
    }
  }
};

/** Checks whether an approval field is approved */
const isApproved = ({ fieldValue }: ConditionSatisfactionMapArgs) => {
  // Use `completed` attribute to find out whether
  // an approval is approved
  return fieldValue.completed;
};

/** Checks whether the number field's value
 *  is greater than the configured value
 */
const isGreaterThan = ({
  conditionValue,
  fieldValue
}: ConditionSatisfactionMapArgs) => {
  return fieldValue > conditionValue;
};

/** Checks whether the number field's value
 *  is less than the configured value
 */
const isLessThan = ({
  conditionValue,
  fieldValue
}: ConditionSatisfactionMapArgs) => {
  return fieldValue < conditionValue;
};

/** Checks whether the current user is
 *  in the user field's value
 */
const isCurrentUser = ({
  fieldValue,
  currentUserId
}: ConditionSatisfactionMapArgs) => {
  const valueUIDs = (fieldValue || []).map(user => user.uid);

  return valueUIDs.includes(currentUserId);
};

// This map is used to evaluate condtions.
// The values are methods that return a boolean
// stating whether the condition is passed.
export const conditionSatisfactionMap = {
  isEqualTo,
  isAnyOf,
  isNoneOf,
  isFilled,
  isEmpty,
  isOfRole,
  isNotOfRole,
  isOwner,
  isCreator,
  isPartOf,
  isOverdue,
  isApproved,
  isGreaterThan,
  isLessThan,
  isCurrentUser
};

// Migrates old condition schema to the new one.
// Triggered when opening the checklist builder.
export const updateConditionsSchema = (settingsJSON: string) => {
  try {
    const settings = JSON.parse(settingsJSON || "{}");

    // Change behaviorUntilConditionMet -> defaultState
    if (settings.conditionSettings?.behaviorUntilConditionMet) {
      settings.defaultState =
        settings.conditionSettings.behaviorUntilConditionMet;
      delete settings.conditionSettings;
    }

    if (settings.conditionBlocks) {
      for (let conditionBlock of settings.conditionBlocks) {
        for (let condition of conditionBlock.conditions) {
          if (!condition.field) {
            // Use field instead of conversationCondition
            condition.field = condition.fieldId
              ? "Checklist Field"
              : condition.conversationCondition;
          }

          // fieldId -> checklistFieldId
          if (R.hasIn("fieldId", condition)) {
            condition.checklistFieldId = condition.fieldId;
            delete condition.fieldId;
          }

          if (condition.conversationCondition) {
            delete condition.conversationCondition;
          }
        }

        // Update behavior
        if (conditionBlock.behavior) {
          const current = R.keys(
            R.pick(R.values(behaviorToSettings), conditionBlock.behavior || {})
          )[0];

          if (current) {
            conditionBlock.behavior.current = current;
            delete conditionBlock.behavior[current];
          }
        }
      }
    }

    return JSON.stringify(settings);
  } catch (error) {
    console.error("error converting conditions schema", error);
    return settingsJSON;
  }
};

/**
 * Maps the dependent options array to an array of integers if the current behavior matches the dependent behavior
 *
 * @param {string} currentBehavior - The current behavior
 * @param {string} dependentBehavior - The dependent behavior to match
 * @param {Array<string>} dependentOptions - The array of dependent options
 * @return {Array<number> | null} - An array of integers or null if the behavior doesn't match
 */
export const getDependentOptions = (
  currentBehavior: ?string,
  dependentBehavior: string,
  dependentOptions: ?Array<string>
): Array<number> | null => {
  if (
    currentBehavior === dependentBehavior &&
    Array.isArray(dependentOptions) &&
    dependentOptions?.length
  ) {
    return dependentOptions.map(roomId => parseInt(roomId));
  }
  return null;
};
