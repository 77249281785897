// @flow

import React, { useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import type {
  RoomId,
  FieldId,
  LinkedFieldValue,
  LinkedFieldSettings
} from "src/types";
import { getMutualVersions } from "src/reducers";
import { getOriginChatroomId, groupVersionsTogether } from "./utils";

import { VStack } from "@chakra-ui/react";
import SelectionMultiple from "src/components/Dock/Checklist/Conversation/SelectionMultiple";
import Dropdown from "src/components/Dock/Checklist/Conversation/Dropdown";
import NewMultiSelectConversation from "src/components/Dock/Checklist/Conversation/NewMultiSelectConversation";
import NewRoom from "src/components/Dock/Checklist/Conversation/NewRoom";
import MultiModal from "./MultiModal";
import RemoveLinkModal from "./RemoveLinkModal";

import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";

type Props = {
  settings: LinkedFieldSettings,
  roomId: RoomId,
  fieldId: FieldId,
  checklistValue: LinkedFieldValue,
  target: boolean,
  dropdown: {
    visible: boolean,
    open: Function
  },
  modal: {
    state: {
      open: boolean,
      roomId: ?number,
      edit: boolean
    },
    close: Function
  },
  newRoom: boolean,
  handleNewRoom: Function,
  handleClose: Function,
  handleCreate: Function,
  handleVersionClick: Function,
  selectNewRoomVersions: Function,
  removeLink: Function,
  editParentField: Function,
  removeRoom: Function,
  selectChatrooms: Function,
  handleSelect: Function,
  isMandatory: boolean,
  locked: boolean,
  updating: boolean,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>
};

const Multi = ({
  settings,
  roomId,
  fieldId,
  checklistValue,
  target,
  dropdown,
  modal,
  newRoom,
  handleNewRoom,
  handleClose,
  handleCreate,
  handleVersionClick,
  selectNewRoomVersions,
  removeLink,
  editParentField,
  removeRoom,
  selectChatrooms,
  handleSelect,
  isMandatory,
  locked,
  updating,
  currentBehavior,
  dependentOptions
}: Props) => {
  const { entities: { chatrooms = {} } = {}, result = [] } =
    checklistValue || {};
  const noValue = R.isEmpty(result);

  // id of chatroom whose checklist value you want to edit after clicking on "Reassign versions"
  const [removeLinkModalState, setRemoveLinkModalState] = useState({
    open: false,
    roomId: null
  });

  const [selectedRoomIds, autoLinkedRooms] = groupVersionsTogether(
    result,
    chatrooms
  );

  const modalSelectedRooms = useSelector(({ app }) => {
    const { roomId } = modal.state;
    return roomId !== null && roomId !== undefined
      ? getMutualVersions(app, R.values(chatrooms), roomId)
      : [];
  });

  const modalSelectedRoomIds = modalSelectedRooms.map(
    item => `${item.chatroom.id}`
  );

  const handleRemove = id => {
    if (!target) {
      const originChatroomId = getOriginChatroomId(id, result, chatrooms);

      removeRoom(originChatroomId);
    } else {
      setRemoveLinkModalState({
        open: true,
        roomId: id
      });
    }
  };

  const handledEditParentField = value => {
    if (!removeLinkModalState.roomId) return;

    const originChatroomId = getOriginChatroomId(
      removeLinkModalState.roomId,
      result,
      chatrooms
    );

    editParentField({
      newValue: value,
      sourceChatroom: originChatroomId
    });
  };

  const handleRemoveLink = () => {
    if (!removeLinkModalState.roomId) return;

    const originChatroomId = getOriginChatroomId(
      removeLinkModalState.roomId,
      result,
      chatrooms
    );

    removeLink(originChatroomId);
  };

  const hideRemoveLinkModal = () => {
    setRemoveLinkModalState({ open: false, roomId: null });
  };

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldExclude],
    dependentOptions
  );

  return (
    <VStack alignItems="start" width="100%">
      {!noValue && (
        <SelectionMultiple
          value={selectedRoomIds}
          autoLinkedRooms={autoLinkedRooms}
          onVersionClick={handleVersionClick}
          showMetaData={settings.showMetaData}
          handleRemove={handleRemove}
          showFieldNames={settings.showCompressedPreview}
          sortBy="added"
          fields={settings.fields}
          disabled={locked}
          alwaysAllowManualLinks={Boolean(settings.alwaysAllowManualLinks)}
        />
      )}

      {dropdown.visible && (
        <Dropdown
          roomId={roomId}
          workflow={settings.workflow}
          type="workflow"
          selectedValue={result}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={settings.create}
          select={settings.select}
          groupVersions
          selectNewRoomVersions={selectNewRoomVersions}
          hideSelectedVersions
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={Boolean(settings.alwaysAllowManualLinks)}
        />
      )}

      {newRoom && !dropdown.visible && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      <NewMultiSelectConversation
        settings={settings || {}}
        roomId={roomId}
        fieldId={fieldId}
        newRoom={newRoom}
        dropdown={dropdown.visible}
        handleDropdown={dropdown.open}
        updating={updating}
        disabled={locked}
        isMandatory={isMandatory}
      />

      {modal.state.open && !R.isNil(modal.state.roomId) && (
        <MultiModal
          open
          roomId={modal.state.roomId}
          selectedRooms={modalSelectedRoomIds}
          // If autoLink is true, then the first value
          // will have `linkAllVersions` set to true
          autoLink={modalSelectedRooms[0]?.linkAllVersions}
          defaultEdit={modal.state.edit}
          onSuccess={selectChatrooms}
          onClose={modal.close}
        />
      )}

      {!noValue && removeLinkModalState.open && (
        <RemoveLinkModal
          open
          onClose={hideRemoveLinkModal}
          roomId={roomId}
          fieldId={fieldId}
          sourceChatroomId={removeLinkModalState.roomId}
          handledEditParentField={handledEditParentField}
          handleRemoveLink={handleRemoveLink}
        />
      )}
    </VStack>
  );
};

export default Multi;
