// @flow

import firebase from "firebase/app";

import "firebase/auth";
import "firebase/storage";

import connection from "src/db";
import { backendUrl } from "src/config/firebase";
import type { UID, RoomId } from "src/types";

export const disableUser = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ disabled: true })
  });

  switch (response.status) {
    case 401:
      throw new Error("No permission to disable user");
    default:
  }
};

export const enableUser = async (uid: UID) => {
  await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ disabled: false })
  });
};

export const makeAdmin = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ role: "admin" })
  });

  switch (response.status) {
    case 401:
      throw new Error("No permission to make user admin");
    default:
  }
};

export const makeMember = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ role: "member" })
  });

  switch (response.status) {
    case 401:
      throw new Error("No permission to make member");
    default:
  }
};

const searchUsers = async (searchString: Object) => {
  const response = await fetch(`${backendUrl}/usersearch`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({ search: searchString })
  });
  return response.json();
};

/**
 * Gets others users profile details
 * @param {UID} uid uid of the user whose details is to be obtained
 * @return {UserProfile} returns user profile of the given user
 */
const getUser = async (uid: UID): Object => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

/**
 * sets given image in firebase storage as profile photo
 * @param {string} profilePhoto the url of the firebase image to be set as profile photo
 * @return {string} the image set as profile photo
 */
const profilePhoto = async (photoUrl: string) => {
  const user = firebase.auth().currentUser;
  await user.updateProfile({
    displayName: user.displayName,
    photoURL: photoUrl
  });
  return photoUrl;
};

const updatePassword = async (password: string) => {
  const user = firebase.auth().currentUser;
  await user.updatePassword(password);
};

/**
 * Uploads the given image into firebase storage
 * @param {File} profileFile File to be uploaded in firebase storage
 * @return {string} the download url of the image uploaded in frebase
 */
const profilePhotoFile = async (profileFile: File) => {
  const { uid } = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref(`${uid}`);
  return storageRef
    .put(profileFile)
    .then(snapshot => snapshot.ref.getDownloadURL());
};
/**
 * Get profile details of other users
 * @param {UID} uid uid of the user
 */
const getOtherUser = async (uid: UID): Object => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

const getUsers = async (): Object => {
  const response = await fetch(`${backendUrl}/user`, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

/**
 * Get Settings from userData
 * @returns {Array} array of saved filters
 */
const getUserSettings = async ({
  uid,
  attribute
}: {
  uid: UID,
  attribute: string
}) => {
  const docs = await connection()
    .collection(`userData/${uid}/appData`)
    .doc(attribute)
    .get();

  return docs.data();
};

/**
 * Invite a new user using their email address
 *
 * @param {string} email - email address
 * @param {string} orgRole - The role of the user in the org
 */
const invite = async (payload: {
  email: string,
  displayName: string,
  orgRole: string,
  chatRoomId: RoomId
}) => {
  const response = await fetch(`${backendUrl}/invite`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  if (response.status === 200) {
    const uid = await response.json();
    return uid;
  }
};

const updateUser = async (attr: string, value: string) => {
  const user = firebase.auth().currentUser;
  try {
    await user.updateProfile({ [attr]: value });
  } catch (e) {
    throw new Error(e);
  }
};

const updateDisplayName = (displayName: string) =>
  updateUser("displayName", displayName);

const updatePhoneNumber = (phoneNumber: string) =>
  updateUser("phoneNumber", phoneNumber);

const updateProfilePicture = (url: string) => updateUser("photoURL", url);

/**
 * Edit user
 *
 * @param {string} displayName - display name of the user
 * @param {string} email - email of the user
 */
const editUser = async ({
  uid,
  ...attributes
}: {
  uid: UID,
  displayName: ?string,
  phoneNumber: ?string,
  department: ?string
}) => {
  await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(attributes)
  });
  return { uid, ...attributes };
};

/**
 * Get list of departments
 *
 * @returns Array<Department>
 */
const getDepartments = async () => {
  const response = await fetch(`${backendUrl}/department`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.json();
};

/**
 * Sync the user's name on Firestore
 *
 */
const updateProfile = async () => {
  await fetch(`${backendUrl}/profile`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    }
  });
};

const resendInvite = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/invite/resend/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    }
  });

  if (response.status === 500) {
    throw new Error("Unable to invite user");
  }
};

const getUserProcess = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/user/${uid}/processes`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

const getUserOnboardingStatus = async (uid: UID) => {
  const response = await fetch(`${backendUrl}/user/${uid}/invite-status`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

const updateOtherUserDetails = async (
  uid: UID,
  value: {
    department: string,
    phoneNumber: string
  }
) => {
  const response = await fetch(`${backendUrl}/user/${uid}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(value)
  });

  switch (response.status) {
    case 401:
      throw new Error("No permission to update");
    case 403:
      throw new Error("Only admin can edit other user details");
    default:
  }
};

export {
  updateOtherUserDetails,
  getUserOnboardingStatus,
  getUserProcess,
  searchUsers,
  getUser,
  profilePhoto,
  profilePhotoFile,
  updateDisplayName,
  updateProfilePicture,
  getOtherUser,
  getUsers,
  getUserSettings,
  updatePassword,
  invite,
  editUser,
  getDepartments,
  updatePhoneNumber,
  updateProfile,
  resendInvite
};
