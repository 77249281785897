// @flow

import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { MultiSelect as StyledMultiSelect } from "./styles";
import NewMultiSelectConversation from "./NewMultiSelectConversation";
import NewRoom from "./NewRoom";
import Dropdown from "./Dropdown";
import SelectionMultiple from "./SelectionMultiple";
import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";
import { getSelectedChecklist } from "src/reducers";

import type { RoomId, ColumnId } from "src/types";

type Props = {
  formId: ?number,
  value: ?Array<number>,
  settings: Object,
  setValue: Function,
  roomId: RoomId,
  columnId: ColumnId,
  fieldId: number,
  checklistId: number,
  setChecklistValue: Function,
  handleCreate: Function,
  newRoom: boolean,
  dropdown: boolean,
  setDropdown: Function,
  workflow: ?number,
  type: string,
  showFieldNames: boolean,
  sortBy: string,
  fields: Array<number>,
  showMetaData: boolean,
  handleClose: Function,
  handleNewRoom: Function,
  promptCallback?: ?Function,
  disabled: boolean,
  isMandatory: ?boolean,
  updating: boolean,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>
};

const MultiSelect = ({
  formId,
  value,
  roomId,
  fieldId,
  checklistId,
  settings,
  setValue,
  showMetaData,
  showFieldNames,
  sortBy,
  fields,
  setChecklistValue,
  handleCreate,
  newRoom,
  dropdown,
  setDropdown,
  workflow,
  type,
  handleClose,
  handleNewRoom,
  promptCallback,
  disabled,
  isMandatory,
  updating,
  currentBehavior,
  dependentOptions
}: Props) => {
  const { columnId, embeddedIndex } = useSelector(({ app }) =>
    getSelectedChecklist(app)
  );

  const handleRemove = useCallback(
    (id: number) => {
      const newValue = (value || []).filter(
        conversation => conversation !== parseInt(id, 10)
      );

      setValue(newValue);
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "conversation",
          checked: false
        },
        columnId,
        embeddedIndex,
        progress: true,
        formId
      });
    },
    [
      formId,
      roomId,
      fieldId,
      columnId,
      value,
      checklistId,
      setValue,
      setChecklistValue
    ]
  );

  const handleSelect = useCallback(
    (id: number) => {
      const newValue = [...(value || []), parseInt(id, 10)];
      setValue(newValue);
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "conversation",
          checked: true
        },
        columnId,
        embeddedIndex,
        progress: true,
        formId
      });
      if (promptCallback) promptCallback();
      setDropdown(false);
    },
    [
      formId,
      roomId,
      fieldId,
      columnId,
      checklistId,
      value,
      setValue,
      setChecklistValue,
      setDropdown,
      promptCallback
    ]
  );

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentConversationExclude],
    dependentOptions
  );

  const { create, select } = settings || {};
  return (
    <StyledMultiSelect>
      {value && (value || []).length > 0 && (
        <SelectionMultiple
          value={value}
          showMetaData={showMetaData}
          handleRemove={handleRemove}
          showFieldNames={showFieldNames}
          sortBy={sortBy}
          fields={fields}
          disabled={disabled}
        />
      )}

      {dropdown && (
        <Dropdown
          roomId={roomId}
          workflow={workflow}
          type={type}
          selectedValue={value || []}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          create={create}
          select={select}
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={false}
        />
      )}

      {newRoom && !dropdown && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      {!newRoom && !dropdown && (
        <NewMultiSelectConversation
          settings={settings || {}}
          roomId={roomId}
          fieldId={fieldId}
          newRoom={newRoom}
          dropdown={dropdown}
          handleDropdown={() => setDropdown(true)}
          disabled={disabled}
          isMandatory={isMandatory}
          updating={updating}
        />
      )}
    </StyledMultiSelect>
  );
};

MultiSelect.defaultProps = {
  promptCallback: null
};

export default MultiSelect;
