// @flow

import React from "react";

import type { UnifizeUser } from "src/types";

import {
  PopoverContent,
  PopoverBody,
  useBoolean,
  Button,
  Text
} from "@chakra-ui/react";
import SignatoriesModal from "../SignatoriesModal";

type Props = {
  title: string,
  signatories: UnifizeUser[]
};

export default function SignatoriesPopover({ signatories, title }: Props) {
  const [isSignatoriesModalOpen, setSignatoriesModalVisibility] = useBoolean();

  return (
    <PopoverContent
      sx={{
        zIndex: 3,
        width: "fit-content"
      }}
    >
      <PopoverBody
        sx={{
          background: "red !important",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 4
        }}
      >
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
            m: 0
          }}
        >
          Authorized {title}:
        </Text>
        {signatories.slice(0, 5).map(user => (
          <Text
            key={user.uid}
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "20px",
              m: 0
            }}
          >
            {user?.displayName}
          </Text>
        ))}

        {signatories.length > 5 && (
          <Button
            variant="link"
            sx={{
              color: "white",
              textDecoration: "underline"
            }}
            onClick={setSignatoriesModalVisibility.on}
          >
            and {signatories.length - 5} others
          </Button>
        )}
      </PopoverBody>

      <SignatoriesModal
        isOpen={isSignatoriesModalOpen}
        onClose={setSignatoriesModalVisibility.off}
        title={title}
        signatories={signatories}
      />
    </PopoverContent>
  );
}
