// @flow

import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import * as styles from "./styles";

const SpinnerLoader = () => {
  return (
    <Flex sx={styles.SpinnerWrapper}>
      <Spinner
        thickness="4px"
        speed="0.5s"
        emptyColor="unifize_grey4"
        color="unifize_blue"
        size="xl"
      />
    </Flex>
  );
};

export default SpinnerLoader;
