// @flow
import * as R from "ramda";

import {
  getChecklistFieldDetails,
  getChecklistFieldBehavior,
  getChecklistFieldVisibility
} from "src/reducers";

import type {
  FieldId,
  RoomId,
  AppState,
  ChecklistFieldTypes,
  SectionId,
  Section as SectionType,
  Breakpoint
} from "src/types";
import type { Layout as LayoutType } from "src/types";
import { getDefaultFieldLayout } from "src/components/Manage/Builder/Checklist/SettingsBuilder/Layout/utils";

import {
  breakpoints,
  breakpointMaxWidths
} from "src/components/Dock/DockLayout/utils";

export const gridTemplates = {
  [breakpoints.small]: "1fr",
  [breakpoints.medium]: "repeat(2, 1fr)",
  [breakpoints.large]: "repeat(3, 1fr)"
};

export type FieldConfig = {
  id: FieldId,
  w: number,
  type: string,
  maxWidth: number,
  fields?: Array<FieldId>,
  layout?: Array<FieldConfig>,
  startOnNewRow?: boolean
};

export type Layout = Array<FieldConfig>;

export type ChecklistLayoutField = {
  id: FieldId,
  type: ChecklistFieldTypes,
  sectionId: SectionId,
  layout: LayoutType,
  fields: Array<ChecklistLayoutField>
};

export type ChecklistLayoutFields = ChecklistLayoutField[];

/**
 * Check whether a checklist field is set to be hidden based on the conditions settings of the checklist
 * @param {FieldId} id Field Id
 * @param {RoomId} roomId Room Id
 * @param {AppState} app
 * @return {boolean}
 */
export const isFieldHidden = ({
  id,
  roomId,
  app
}: {
  id: FieldId,
  roomId: RoomId,
  app: AppState
}) => {
  const details = getChecklistFieldDetails(app, `${id}`);
  const behavior = getChecklistFieldBehavior(app, { roomId, fieldId: id });

  const fieldHidden = details ? details.get("hidden") : false;

  return (
    behavior?.current === "hidden" ||
    fieldHidden ||
    getChecklistFieldVisibility(app, id, roomId) === false
  );
};

/**
 * Remove out all the hidden checklist fields
 * @param {ChecklistLayoutFields} fields Checklist Layout fields
 * @param {RoomId} roomId Room Id
 * @param {AppState} app App state
 * @return {ChecklistLayoutFields} Return visible fields
 */

export const removeHiddenFields = ({
  fields,
  roomId,
  app
}: {
  fields: ChecklistLayoutFields,
  roomId: RoomId,
  app: AppState
}) => {
  const result = [];

  fields.forEach(field => {
    if (field) {
      const hidden = isFieldHidden({ id: field.id, roomId, app });

      if (hidden) return;

      if (!field.fields) {
        result.push(field);
      } else {
        result.push({
          ...field,
          fields: removeHiddenFields({ fields: field.fields, roomId, app })
        });
      }
    }
  });

  return result;
};

/**
 * Get the details of the checklist fields
 * @param {Array} fields Array of checklist field ids and checklist sections
 * @param {RoomId} roomId Room Id
 * @param {AppState} app App state
 * @return {ChecklistLayoutFields}
 */

export const addFieldDetails = ({
  fields,
  app
}: {
  fields: $ReadOnlyArray<FieldId | SectionType>,
  app: AppState
}) =>
  // $FlowFixMe
  fields.map(field => {
    if (typeof field === "number") {
      if (getChecklistFieldDetails(app, String(field)))
        // $FlowFixMe
        return getChecklistFieldDetails(app, String(field)).toJS();
    } else if (getChecklistFieldDetails(app, String(field.sectionId))) {
      return {
        ...field,
        // $FlowFixMe
        ...getChecklistFieldDetails(app, String(field.sectionId)).toJS(),
        fields: addFieldDetails({
          fields: field.fields,
          app
        })
      };
    }
  });

/**
 * Given the checklist layout fields, generate each field's layout at each breakpoint
 * @param {ChecklistLayoutFields} fields Checklist layout fields
 * @param {Breakpoint} breakpoint Checklist breakpoints
 * @return {ChecklistLayoutFields}
 */

export const generateLayout = (
  visibleFields: ChecklistLayoutFields,
  breakpoint: Breakpoint
) => {
  const fieldsInfo = visibleFields.map(field => {
    if (
      field.sectionId &&
      (field.type === "section" || field.type === "subSection")
    ) {
      return {
        id: field.id,
        w: 1,
        maxWidth: breakpointMaxWidths[breakpoint],
        type: field.type,
        fields: R.pluck("id", field.fields),
        layout: generateLayout(field.fields, breakpoint)
      };
    } else {
      const defaultFieldLayout = getDefaultFieldLayout(field.type);
      const defaultMaxWidth = isNaN(parseInt(defaultFieldLayout.maxWidth))
        ? breakpointMaxWidths[breakpoint]
        : parseInt(defaultFieldLayout.maxWidth);

      const maxWidth = field.layout
        ? field.layout.maxWidth === "max"
          ? breakpointMaxWidths[breakpoint]
          : parseInt(field.layout.maxWidth) >= breakpointMaxWidths[breakpoint]
          ? breakpointMaxWidths[breakpoint]
          : parseInt(field.layout.maxWidth)
        : defaultMaxWidth;

      return {
        id: field.id,
        w: 1,
        maxWidth,
        type: field.type,
        startOnNewRow:
          field.layout === null ? false : field.layout.startOnNewRow
      };
    }
  });

  let count = 0;
  const layout: Layout = fieldsInfo.map(field => {
    if (
      count < breakpointMaxWidths[breakpoint] &&
      field.type !== "section" &&
      field.type !== "subSection" &&
      !field.startOnNewRow
    ) {
      if (field.maxWidth - field.w > 0) {
        if (
          field.maxWidth - field.w + count <
          breakpointMaxWidths[breakpoint]
        ) {
          field.w = field.maxWidth;
        } else {
          field.w = breakpointMaxWidths[breakpoint] - count;
        }
      }

      count += field.w;
    } else if (
      field.type === "section" ||
      field.type === "subSection" ||
      field.startOnNewRow
    ) {
      count = 0;
      field.w = field.maxWidth;
    } else {
      field.w = field.maxWidth;
      count = field.w;
    }
    return field;
  });
  return layout;
};
