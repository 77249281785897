// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import { SortableHandle } from "react-sortable-hoc";

import Title from "./Title";
import Description from "./Description";
import Tiles from "./Tiles";
import {
  Section as StyledSection,
  SectionHeader,
  DeleteButton
} from "./styles";
import AddSubSection from "./AddSubSection";
import { getSection } from "src/reducers";
import Icons from "src/icons";
import { deleteSection } from "src/actions/orgSettings";

import type { HomescreenSection, AppState } from "src/types";

type Props = {
  section: HomescreenSection,
  handleOpenModal: Function,
  _deleteSection: Function
};

const DragHandle = SortableHandle(() => <Icons type="dottedSort" />);

const Section = ({ section, handleOpenModal, _deleteSection }: Props) => {
  const tiles = section.tiles || [];

  const handleDelete = useCallback(() => {
    _deleteSection(section.id);
  }, [section, _deleteSection]);

  return (
    <StyledSection>
      <div>
        <SectionHeader>
          <h2>
            <DragHandle />
            <Title
              value={section.title}
              id={section.id}
              position={section.settings.position}
            />
            <DeleteButton type="button" onClick={handleDelete}>
              <Icons type="deleteTrash" />
            </DeleteButton>
          </h2>
          <Description id={section.id} value={section.description} />
        </SectionHeader>

        <Tiles
          ids={tiles}
          right={section.settings.position == "right"}
          sectionId={section.id}
        />

        <AddSubSection
          title={section.title}
          id={section.id}
          tiles={tiles}
          handleOpenModal={handleOpenModal}
        />
      </div>
    </StyledSection>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  section: getSection(app, props.id)
});

export default connect(mapStateToProps, {
  _deleteSection: deleteSection
})(Section);
