// @flow

import React, { useCallback, useMemo } from "react";

import Link from "redux-first-router-link";
import moment from "moment";

import {
  ChatBlock as StyledChatBlock,
  Labels,
  SequanceWrapper,
  Status as StyledStatus,
  ConversationTypeWrapper,
  ColorIndicator,
  StatusAndOwnerWrapper,
  WarningMessageWrapper,
  SubWrapper
} from "./styles";
import ChatblockColor from "./ChatblockColor";
import ChatBlockTitle from "./Title";
import ConversationTypePill from "./ConversationTypePill";
import * as atypes from "src/constants/actionTypes";

import Owner from "src/components/chatroom/Owner/PillContainer";
import LastMessage from "src/components/chatroom-nav/LastMessage";
import Status from "src/components/chatroom/Status/Badge";
import { CANCELED } from "src/constants/status";

import type { RoomId, ChatroomAddress, ConversationType } from "src/types";

type Props = {
  active: boolean,
  color: string,
  hasUnread: boolean,
  highlight?: boolean,
  status: number,
  roomId: RoomId,
  type: ConversationType,
  address: ChatroomAddress,
  seqNo: ?number,
  dueDate: ?string,
  activeStatus: boolean,
  disabled: boolean,
  templateId: ?number,
  unread: boolean,
  isCurrentVersion: boolean,
  width?: string
};

const ChatBlock = ({
  roomId,
  color,
  active,
  status,
  hasUnread,
  highlight,
  type,
  address,
  seqNo,
  dueDate,
  activeStatus,
  disabled,
  templateId,
  unread,
  isCurrentVersion,
  width
}: Props) => {
  const preventNavigation = useCallback(() => {
    if (active) {
      return false;
    }
    return true;
  }, [active]);

  const overdue = useMemo(() => {
    const days = moment(dueDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days");
    return days < 0;
  }, [dueDate]);

  return (
    <Link
      onClick={preventNavigation}
      to={{
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: { id: address },
        meta: {
          query: {
            templateId
          }
        }
      }}
    >
      <StyledChatBlock
        active={active}
        status={`${status}`}
        hasUnread={hasUnread}
        highlight={highlight}
        tempBorder={active && unread}
        width={width}
      >
        {/* Show message only if unread is applied and current chatblock 
        is open */}
        {active && unread && (
          <WarningMessageWrapper>
            No longer matches the list / filter
          </WarningMessageWrapper>
        )}
        <SubWrapper hasWarning={active && unread}>
          <ChatBlockTitle roomId={roomId} type={type} />
          <LastMessage
            roomId={roomId}
            isCurrentChatroom={active}
            hasUnread={hasUnread}
            direct={type === "direct"}
          />

          <Labels>
            <SequanceWrapper direct={type === "direct"} feed={type === "group"}>
              <div>
                {type !== "direct" && (
                  <ColorIndicator
                    style={{
                      backgroundColor: ChatblockColor(type, color)
                    }}
                  />
                )}
                <ConversationTypeWrapper>
                  <ConversationTypePill roomId={roomId} type={type} />
                </ConversationTypeWrapper>
              </div>
              {seqNo && (
                <span>
                  #{seqNo}
                  {isCurrentVersion && "C"}
                </span>
              )}
            </SequanceWrapper>
            <StatusAndOwnerWrapper>
              {type !== "direct" && <Owner roomId={roomId} active={active} />}
              {type !== "direct" && (
                <StyledStatus
                  active={activeStatus}
                  overdue={type !== "direct" && type !== "group" && overdue}
                  cancelled={status === CANCELED}
                  disabled={disabled}
                >
                  <Status roomId={roomId} isOverDue={overdue} />
                </StyledStatus>
              )}
            </StatusAndOwnerWrapper>
          </Labels>
        </SubWrapper>
      </StyledChatBlock>
    </Link>
  );
};

ChatBlock.defaultProps = {
  highlight: false
};

export default ChatBlock;
