// @flow

import * as R from "ramda";
import { toast } from "react-toastify";
import { buffers, channel } from "redux-saga";
import {
  fork,
  takeEvery,
  put,
  call,
  select,
  delay,
  take,
  all,
  race,
  cancelled,
  takeLatest,
  cancel
} from "redux-saga/effects";

import { formatChecklistFieldValue } from "src/formatChecklistFieldValue";
import {
  getSettings,
  getEmbeddedFields,
  getFormData,
  linkedFieldDeleteHandler,
  getChatroomMetaData,
  getAllContingentApprovals,
  formFieldDeleteHandler,
  userFieldDeleteHandler
} from "src/utils/checklist";
import * as morpheus from "src/utils/morpheus";
import * as api from "src/api/checklist";
import getAppState, { getLocation } from "src/selectors";
import {
  getChecklistTemplates,
  getChecklistFieldDetails,
  getWorkflowInstances
} from "src/reducers";
import locations from "src/constants/location";
import { approvalFieldStatuses, dataStages } from "src/constants";
import * as atypes from "src/constants/actionTypes";
import {
  getLastOrg,
  getNecessaryFields,
  getTopLevelFieldsToFetch,
  getSectionFieldsToFetch,
  getSubSectionFieldsToFetch,
  getRoomFieldsValueStatus,
  getChecklistFieldValue as getChecklistFieldValueSelector,
  getFormFieldValue,
  getFieldsNeededToShowChecklist,
  getChecklistFieldByForm,
  getChecklistFieldById,
  getCurrentChatroom,
  getRoomChecklistValues,
  getFirstChecklist,
  getChecklistFieldsById,
  getChecklistByRoomId
} from "src/selectors";
import { fieldTransitReader } from "src/transit/checklist/field/reader";

import type { Action } from "src/types";

function* getChecklist({ payload }: Action): any {
  const abortController = new AbortController();

  try {
    // Wait for chatroom data to load
    yield take(atypes.LOAD_CHATROOM_SUCCESS);

    const checklists = yield select(
      getChecklistByRoomId(`${payload.workflow}`)
    );

    yield put({
      type: atypes.GET_CHECKLIST_SUCCESS,
      payload: {
        workflow: payload.workflow,
        checklists
      }
    });
  } catch (error) {
    yield put({ type: atypes.GET_CHECKLIST_FAILURE, payload: error });
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* watchGetChecklist(): any {
  yield takeLatest(atypes.GET_CHECKLIST_REQUEST, function* handleGetChecklist(
    action: Action
  ) {
    yield race([
      call(getChecklist, action),
      take(atypes.HIDE_DOCK),
      take(atypes.SET_CURRENT_CHATROOM_REQUEST)
    ]);
  });
}

const getFieldsBySection = fields => {
  const fieldsBySection = [];
  let sectionIndex = null;
  let subSectionIndex = null;
  let sectionIndexOfSubSection = null;

  for (let i = 0; i < fields.length; i++) {
    const isSubSection =
      typeof (getSettings(fields[i].settings): Object)?.level === "number";

    // Checks if type is Section and put field inside Object
    if (fields[i].type === "section" && !isSubSection) {
      sectionIndex = fieldsBySection.length;
      fieldsBySection.push({ sectionId: fields[i].id, fields: [] });
    }

    // Checks if type is Subsection and put field inside section Object
    if (typeof sectionIndex === "number" && isSubSection) {
      subSectionIndex = fieldsBySection[sectionIndex].fields.length;
      sectionIndexOfSubSection = sectionIndex;
      fieldsBySection[sectionIndex].fields.push({
        sectionId: fields[i].id,
        fields: []
      });
      continue;
    }

    // Check whether it's inside subsection
    if (
      typeof subSectionIndex === "number" &&
      typeof sectionIndex === "number" &&
      sectionIndexOfSubSection === sectionIndex
    ) {
      fieldsBySection[sectionIndex].fields[subSectionIndex].fields.push(
        fields[i].id
      );
      continue;
    }

    if (typeof sectionIndex === "number") {
      fieldsBySection[sectionIndex].fields.push(fields[i].id);
    }

    fieldsBySection.push(fields[i].id);
  }

  return fieldsBySection;
};

function* getChatroomChecklistFields({ payload }: Action): any {
  try {
    const fields = yield call(api.getChecklistFields, payload.id);

    // getChecklistSections get called after this action
    yield put({
      type: atypes.GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS,
      payload: {
        roomId: payload.roomId,
        checklistId: payload.id,
        fields
      }
    });

    const fieldsBySection = getFieldsBySection(fields);

    yield put({
      type: atypes.GET_CHECKLIST_FIELDS_BY_SECTIONS,
      payload: { checklistId: payload.id, fieldsBySection }
    });

    if (payload.getValues) {
      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST,
        payload: {
          roomId: payload.roomId,
          checklistId: payload.id
        }
      });
    }

    yield put({
      type: atypes.GET_CHATROOM_CHECKLIST_FIELDS_COMPLETE,
      payload: {
        checklistId: payload.id
      }
    });
  } catch (e) {
    console.error("get fields", e);
    yield put({
      type: atypes.GET_CHATROOM_CHECKLIST_FIELDS_FAILURE,
      payload: e
    });
  }
}

function* watchGetChatroomChecklistFields(): any {
  yield takeEvery(atypes.GET_CHATROOM_CHECKLIST_FIELDS_REQUEST, function* bla(
    action: Action
  ) {
    const location = yield select(getLocation);
    // If the chatroom is opened through "Save and go to conversation"
    // option SET_CURRENT_CHATROOM_REQUEST will always be fired first,
    // so don't consider that
    if (location?.prev?.pathname === "/manage") {
      yield race([
        call(getChatroomChecklistFields, action),
        take(atypes.HIDE_DOCK)
      ]);
    } else {
      yield race([
        call(getChatroomChecklistFields, action),
        take(atypes.HIDE_DOCK),
        take(atypes.SET_CURRENT_CHATROOM_REQUEST)
      ]);
    }
  });
}

/**
 * creates a queue
 *
 * @param {GeneratorFunction} [handle=() => {}] request handler
 * @param {number} [concurrentWorkers=1] number of workers
 */
function* createQueue(handle, concurrentWorkers = 1) {
  const addTaskChannel = yield call(channel, buffers.expanding());

  const runChannel = yield call(channel, buffers.expanding());

  function* watchRequests() {
    // create n worker 'threads'
    try {
      yield all(Array(concurrentWorkers).fill(fork(handleRequest, runChannel)));

      while (true) {
        const action = yield take(addTaskChannel);
        yield put(runChannel, action);
      }
    } finally {
      if (yield cancelled()) {
        addTaskChannel.close();
        runChannel.close();
      }
    }
  }

  function* handleRequest(chan) {
    while (true) {
      const action = yield take(chan);
      yield call(handle, action);
    }
  }

  return {
    watcher: watchRequests,
    addTaskChannel
  };
}

const N_FIELDS_AT_A_TIME = 3;

function* handleFetchFieldValuesFaster({ payload }: Action) {
  try {
    const { watcher, addTaskChannel } = yield createQueue(
      handleGetChecklistFieldValue,
      N_FIELDS_AT_A_TIME
    );

    const watcherTask = yield fork(watcher);

    const { checklistId, roomId } = payload;

    const topLevelFieldsToFetch = yield select(
      getTopLevelFieldsToFetch(checklistId, roomId)
    );

    if (R.isEmpty(topLevelFieldsToFetch)) {
      yield put({
        type: atypes.HIDE_CHECKLIST_LOADER
      });
    }

    yield all(
      topLevelFieldsToFetch.map(fieldId =>
        put({
          type: atypes.GET_CHECKLIST_FIELD_VALUE,
          payload: {
            roomId,
            fieldId,
            checklistId
          }
        })
      )
    );

    yield all(
      topLevelFieldsToFetch.map(fieldId =>
        put(addTaskChannel, {
          type: atypes.GET_CHECKLIST_FIELD_VALUE,
          payload: {
            roomId,
            fieldId,
            checklistId
          }
        })
      )
    );

    yield takeEvery(atypes.OPEN_SECTION, function* ({ payload }: Action) {
      const { checklistId, roomId, id: sectionId } = payload;

      const toFetch = yield select(
        getSectionFieldsToFetch(checklistId, sectionId, roomId)
      );

      yield all(
        toFetch.map(fieldId =>
          put({
            type: atypes.GET_CHECKLIST_FIELD_VALUE,
            payload: {
              roomId,
              fieldId,
              checklistId
            }
          })
        )
      );

      yield all(
        toFetch.map(fieldId =>
          put(addTaskChannel, {
            type: atypes.GET_CHECKLIST_FIELD_VALUE,
            payload: {
              roomId,
              fieldId,
              checklistId
            }
          })
        )
      );
    });

    yield takeEvery(atypes.OPEN_SUB_SECTION, function* ({ payload }: Action) {
      const { checklistId, roomId, id: subSectionId, sectionId } = payload;

      const toFetch = yield select(
        getSubSectionFieldsToFetch(checklistId, sectionId, subSectionId, roomId)
      );

      yield all(
        toFetch.map(fieldId =>
          put({
            type: atypes.GET_CHECKLIST_FIELD_VALUE,
            payload: {
              roomId,
              fieldId,
              checklistId
            }
          })
        )
      );

      yield all(
        toFetch.map(fieldId =>
          put(addTaskChannel, {
            type: atypes.GET_CHECKLIST_FIELD_VALUE,
            payload: {
              roomId,
              fieldId,
              checklistId
            }
          })
        )
      );
    });

    yield takeEvery(
      [atypes.SET_CURRENT_CHATROOM_REQUEST, atypes.HIDE_DOCK],
      function* watchForCancelingChannel() {
        yield cancel(watcherTask);
      }
    );
  } catch (error) {
    console.error(error);
  }
}

function* getChecklistFieldValuesFaster(): any {
  // Every time checklist is opened and field details are fetched or refreshed
  yield takeLatest(
    atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST,
    handleFetchFieldValuesFaster
  );
}

function* handleGetChecklistFieldValue(action: Action): any {
  const fetchingFields = (yield select()).app.checklist.isLoading;

  if (fetchingFields) {
    yield take(atypes.GET_CHATROOM_CHECKLIST_FIELDS_COMPLETE);
  }

  const checklist = yield select(getFirstChecklist);

  // For revision field value in header the fetching shouldn't follow
  // the checklist algo
  if (!action.payload.byPass) {
    const necessaryFields = yield select(getNecessaryFields(checklist.id));

    if (!necessaryFields.includes(action.payload.fieldId)) {
      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUE_CANCELLED,
        payload: {
          fieldId: action.payload.fieldId,
          roomId: action.payload.roomId
        }
      });

      return;
    }

    yield race([
      call(getChecklistFieldValue, action),
      call(watchSectionClose, action.payload.fieldId),
      call(watchSubSectionClose, action.payload.fieldId),
      take(atypes.HIDE_DOCK),
      take(atypes.SET_CURRENT_CHATROOM_REQUEST)
    ]);

    // Check if none of the fields in this room
    // have status as `fetching`
    const roomFieldsValueStatus = yield select(
      getRoomFieldsValueStatus(action.payload.roomId)
    );
    const allFieldsFetched = roomFieldsValueStatus.every(
      field => field.status !== dataStages.fetching
    );

    if (allFieldsFetched) {
      yield put({
        type: atypes.SET_LOCKED_CHECKLIST_FIELDS_REQUEST,
        payload: {
          chatroomId: action.payload.roomId
        }
      });
      yield put({
        type: atypes.RUN_CONDITIONS_CODE,
        payload: {
          roomId: action.payload.roomId
        }
      });
    }

    // Check if fields needed to hide checklist loader are fetched
    const fieldsNeeded = yield select(
      getFieldsNeededToShowChecklist(checklist.id)
    );
    const neededFieldsValues = R.filter(
      field => fieldsNeeded.includes(Number(field.fieldId)),
      roomFieldsValueStatus
    );
    const allNeededFieldsFetched = neededFieldsValues.every(
      field => field.status !== dataStages.fetching
    );
    if (allNeededFieldsFetched) {
      yield put({
        type: atypes.HIDE_CHECKLIST_LOADER
      });
    }
  } else {
    yield race([
      call(getChecklistFieldValue, action),
      take(atypes.SET_CURRENT_CHATROOM_REQUEST)
    ]);
  }
}

function* watchGetFieldValue(): any {
  yield takeEvery(
    atypes.GET_CHECKLIST_FIELD_VALUE_REQUEST,
    handleGetChecklistFieldValue
  );
}

function* watchSectionClose(fieldId: number) {
  while (true) {
    const { payload } = yield take(atypes.CLOSE_SECTION);

    const necessaryFields = yield select(
      getNecessaryFields(payload.checklistId)
    );

    if (!necessaryFields.includes(fieldId)) break;
  }
}

function* watchSubSectionClose(fieldId: number) {
  while (true) {
    const { payload } = yield take(atypes.CLOSE_SUB_SECTION);

    const necessaryFields = yield select(
      getNecessaryFields(payload.checklistId)
    );

    if (!necessaryFields.includes(fieldId)) break;
  }
}

function* getChecklistFieldValue({ payload }: Action): any {
  const abortController = new AbortController();

  try {
    const value = yield call(
      api.getChecklistFieldValue,
      payload,
      abortController.signal
    );

    const fieldTransit = fieldTransitReader.read(value.type);
    const fieldType = fieldTransit.type;

    if (fieldType === "form") {
      const { roomId, fieldId } = payload;

      // update the form field details in checklist.fields.byId
      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: [
          {
            id: fieldId,
            type: fieldTransit,
            target: value.target
          }
        ]
      });

      const {
        fields,
        fieldValues,
        fieldsByForm,
        formTemplates,
        embedded,
        nestedFieldDetails
      } = getFormData({ value, roomId });
      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS,
        payload: embedded
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });

      yield put({
        type: atypes.GET_CHECKLIST_FIELDS_SUCCESS,
        payload: { fields }
      });

      yield put({
        type: atypes.GET_CHECKLIST_FORM_VALUES,
        payload: fieldValues
      });

      yield put({
        type: atypes.FETCH_FORM_TEMPLATES,
        payload: formTemplates
      });

      yield put({
        type: atypes.FETCH_FORM_FIELDS_SUCCESS,
        payload: { fields: fieldsByForm, roomId, fieldId }
      });

      const allFormFields = value.value.reduce((prev, curr) => {
        const res = {
          [curr.id]: curr.fields.map(f => f.fieldId)
        };
        return R.mergeDeepRight(prev, res);
      }, {});

      yield put({
        type: atypes.GET_FORM_FIELD_VALUES_SUCCESS,
        payload: allFormFields
      });

      yield put({
        type: atypes.RUN_CONDITIONS_CODE,
        payload: { roomId }
      });

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS,
        payload: { chatroomId: roomId, fieldId, ...value }
      });
    } else {
      const formattedFieldValue = formatChecklistFieldValue(value);

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: [
          {
            id: payload.fieldId,
            type: fieldTransit,
            target: value.target
          }
        ]
      });

      yield put({
        type: atypes.RUN_CONDITIONS_CODE,
        payload: {
          roomId: payload.roomId
        }
      });

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS,
        payload: {
          chatroomId: payload.roomId,
          ...formattedFieldValue
        }
      });
    }
  } catch (error) {
    console.error(`get field value error | fieldId: ${payload.fieldId}`, error);
    const errorMessage = error?.message;

    toast.error(errorMessage || "Unable to get checklist field value");

    yield put({
      type: atypes.GET_CHECKLIST_FIELD_VALUE_FAILURE,
      payload: {
        error,
        roomId: payload.roomId,
        fieldId: payload.fieldId,
        errorMessage
      }
    });
  } finally {
    if (yield cancelled()) {
      abortController.abort();
      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUE_CANCELLED,
        payload
      });
    }
  }
}

function* setLockedChecklistFields({ payload }: Action): any {
  try {
    const { chatroomId: currentChatRoom } = payload;

    const checklistValues = yield select(
      getRoomChecklistValues(currentChatRoom)
    );

    const approvalFields = checklistValues.filter(
      item => item.val.type === "approval"
    );

    let lockedFields = [];
    let hiddenFields = [];

    for (const fieldValue of approvalFields) {
      const fieldTransit = fieldTransitReader.read(fieldValue.type);
      const settings = morpheus.approval(
        JSON.parse(fieldTransit.settings || "{}")
      );

      // Fields to lock
      if (fieldValue.value.lockChecklistFields) {
        if (settings.lockFields.mode === "new") {
          lockedFields = lockedFields.concat(settings.lockFields.fields);
        }
      }

      // If there are no contingent approvals then ignore
      if (R.isEmpty(settings.contingentApprovals)) continue;

      const isAnyContingentFieldApproved = approvalFields.find(
        fieldValue =>
          settings.contingentApprovals.includes(fieldValue.fieldId) &&
          fieldValue.value.status === approvalFieldStatuses.approved
      );

      // Since none of the contingent approvals are approved either hide/disable this field
      if (!isAnyContingentFieldApproved) {
        if (settings.inactiveBehavior === "disable") {
          lockedFields.push(fieldValue.fieldId);
        } else if (settings.inactiveBehavior === "hide") {
          hiddenFields.push(fieldValue.fieldId);
        }
      } else if (
        settings.canCancelContingentApprovals &&
        [
          approvalFieldStatuses.approved,
          approvalFieldStatuses.started
        ].includes(fieldValue.value.status)
      ) {
        // If we want to prevent canceling contingent approvals then disable them
        lockedFields = lockedFields.concat(settings.contingentApprovals);
      }
    }

    // Add fields inside forms to `lockedFields`
    let formFieldIds = [];

    lockedFields.forEach(fieldId => {
      const checklistValue = checklistValues.find(
        item => item.fieldId === fieldId && item.val.type === "form"
      );

      if (!checklistValue) return;

      formFieldIds = formFieldIds.concat([...(checklistValue.val.value || [])]);
    });

    const formFields = yield select(getChecklistFieldByForm(formFieldIds));

    R.values(formFields || {}).forEach((field: ?(Object[])) => {
      if (!field) return;

      field.forEach(fieldId => {
        if (!fieldId) return;

        if (typeof fieldId === "number") {
          lockedFields.push(fieldId);
        } else {
          if (fieldId?.fields) {
            lockedFields = lockedFields.concat(fieldId?.fields);
          }
        }
      });
    });

    lockedFields = R.uniq(lockedFields);
    hiddenFields = R.uniq(hiddenFields);

    yield put({
      type: atypes.SET_LOCKED_CHECKLIST_FIELDS,
      payload: {
        roomId: currentChatRoom,
        fields: lockedFields
      }
    });

    yield put({
      type: atypes.SET_HIDDEN_CHECKLIST_FIELDS,
      payload: {
        roomId: currentChatRoom,
        fields: hiddenFields
      }
    });
  } catch (error) {
    console.error("setLockedChecklistFields error", error);
    toast.error("Unable to lock checklist fields");
  }
}

function* watchSetLockedChecklistFields(): any {
  yield takeLatest(
    atypes.SET_LOCKED_CHECKLIST_FIELDS_REQUEST,
    setLockedChecklistFields
  );
  yield takeEvery(
    [atypes.SET_CHECKLIST_VALUE_SUCCESS],
    setLockedChecklistFields
  );
}

function* setChecklistValues({ payload }: Action): any {
  try {
    const checklistValue = yield call(api.setChecklistFieldValue, payload);

    let fieldValue = {};

    if (payload.httpMethod === "DELETE") {
      const currentValue = yield select(
        payload.formId
          ? getFormFieldValue({
              roomId: payload.roomId,
              fieldId: payload.id,
              formId: payload.formId
            })
          : getChecklistFieldValueSelector(payload.roomId, payload.id)
      );
      if (payload.value.type === "link") {
        fieldValue = linkedFieldDeleteHandler({
          item: currentValue,
          payload
        });
      } else if (payload.value.type === "form") {
        fieldValue = formFieldDeleteHandler({ currentValue, payload });
      } else if (payload.value.type === "user") {
        fieldValue = userFieldDeleteHandler({
          currentValue,
          payload
        });
      }
    } else {
      if (payload.value.type !== "form")
        fieldValue = formatChecklistFieldValue(checklistValue);
      else fieldValue = checklistValue;
    }

    const conversationFieldTypes = [
      "conversation",
      "chatPickList",
      "group",
      "task",
      "workflow",
      "childConversation",
      "link"
    ];

    if (
      R.path(["value"], fieldValue) &&
      conversationFieldTypes.includes(payload.value.type) &&
      payload.httpMethod !== "DELETE"
    ) {
      const { nestedFieldDetails, chatroomMetaData } = getEmbeddedFields(
        checklistValue.value
      );

      yield put({
        type: atypes.LOAD_CHATROOM_METADATA,
        payload: chatroomMetaData
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });
    }

    // Update chatroom metadata of revisions
    if (payload.value.type === "revision") {
      // $FlowFixMe
      const revisionsMetaData = fieldValue.value.map(getChatroomMetaData);

      yield put({
        type: atypes.LOAD_CHATROOM_METADATA,
        payload: revisionsMetaData
      });
    }

    if (payload.value.type === "pdf" && payload.value.value === null) {
      toast.success("Pdf is being generated now");
    }

    // Since form values are stored in checklist -> formValues
    // Prevent firing SET_CHECKLIST_VALUE_SUCCESS when setting a
    // form value
    if (payload.value.type === "form") {
      const {
        fields,
        fieldValues,
        fieldsByForm,
        formTemplates,
        embedded,
        nestedFieldDetails
      } = getFormData({ value: fieldValue, roomId: payload.roomId });

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS,
        payload: embedded
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });

      yield put({
        type: atypes.GET_CHECKLIST_FIELDS_SUCCESS,
        payload: { fields }
      });

      yield put({
        type: atypes.GET_CHECKLIST_FORM_VALUES,
        payload: fieldValues
      });

      yield put({
        type: atypes.FETCH_FORM_TEMPLATES,
        payload: formTemplates
      });

      yield put({
        type: atypes.FETCH_FORM_FIELDS_SUCCESS,
        payload: {
          fields: fieldsByForm,
          roomId: payload.roomId,
          fieldId: payload.id
        }
      });

      // $FlowFixMe
      const allFormFields = fieldValue.value.reduce((prev, curr) => {
        const res = {
          [curr.id]: curr.fields.map(f => f.fieldId)
        };
        return R.mergeDeepRight(prev, res);
      }, {});

      yield put({
        type: atypes.GET_FORM_FIELD_VALUES_SUCCESS,
        payload: allFormFields
      });
    }

    if (R.path(["formId"], fieldValue)) {
      yield put({
        type: atypes.GET_CHECKLIST_FORM_VALUES,
        payload: {
          [`${payload.roomId}-${checklistValue.fieldId}-${checklistValue.formId}`]: fieldValue
        }
      });

      yield put({
        type: atypes.SET_FORM_FIELD_VALUE_SUCCESS,
        payload: {
          fieldId: payload.id,
          formId: payload.formId
        }
      });
    } else {
      if (payload.value.type === "pdf") {
        const field = yield select(getChecklistFieldById(String(payload.id)));
        const settings = JSON.parse(field.get("settings")) || {};
        const preview = R.path(["preview"], settings) || "";
        const targetField: ?number = settings.targetField;

        // If the generated pdf won't be attached then wait for 1min
        if (preview === "none") {
          yield delay(60 * 1000);
        } else {
          const fieldId = payload.id;

          const currentChatroomId = yield select(getCurrentChatroom);

          if (String(payload.roomId) !== currentChatroomId) {
            // If its an embedded field then wait for 1min
            yield delay(60 * 1000);
          } else {
            // Wait for notification with the new PDF
            while (true) {
              const action = yield take(atypes.NEW_MESSAGES);

              const fieldUpdateNotification = action.payload.messages.result.find(
                messageId => {
                  const message = action.payload.messages.entities[messageId];

                  return Boolean(
                    // If the PDF is uploaded to a file field, look for that in notification
                    R.path(["data", "fields", targetField || fieldId], message)
                  );
                }
              );

              if (fieldUpdateNotification) break;
            }
          }

          // When the PDF is uploaded to a different file field
          if (targetField) {
            // Fetch the latest value of the target file field
            yield call(getChecklistFieldValue, {
              type: atypes.GET_CHECKLIST_FIELD_VALUE,
              payload: {
                roomId: payload.roomId,
                fieldId: targetField
              }
            });
          } else {
            // Fetch the latest value of the PDF field
            yield call(getChecklistFieldValue, {
              type: atypes.GET_CHECKLIST_FIELD_VALUE,
              payload: {
                roomId: payload.roomId,
                fieldId
              }
            });

            return;
          }
        }
      }

      if (payload.value.type === "approval") {
        // TODO: do all this only when rejected and setting is enabled

        const fieldsById = yield select(getChecklistFieldsById);

        // Get ALL contingent fields
        const allContingentApprovals = getAllContingentApprovals({
          fieldId: payload.id,
          fieldsById
        });

        // Get all visible approval fields
        const fieldValues = yield all(
          allContingentApprovals.map(fieldId =>
            select(getChecklistFieldValueSelector(payload.roomId, fieldId))
          )
        );
        const visibleApprovals = R.reject(R.isNil)(fieldValues);
        const allContingentApprovalFieldIDs = R.pluck("fieldId")(
          visibleApprovals
        );

        // Fetch values of the visible contingent approvals
        yield all(
          allContingentApprovalFieldIDs.map(fieldId =>
            call(getChecklistFieldValue, {
              type: atypes.GET_CHECKLIST_FIELD_VALUE,
              payload: {
                roomId: payload.roomId,
                fieldId: fieldId
              }
            })
          )
        );
      }

      yield put({
        type: atypes.SET_CHECKLIST_VALUE_SUCCESS,
        payload: {
          ...fieldValue,
          chatroomId: payload.roomId
        }
      });
    }
  } catch (error) {
    console.error(error);
    let errorMessage = error?.message;

    if (!errorMessage || errorMessage.includes("function")) {
      errorMessage = "Unable to fill checklist value. Please try again";
    }

    toast.error(errorMessage);

    yield put({
      type: atypes.SET_CHECKLIST_VALUE_FAILURE,
      payload: {
        error,
        fieldId: payload.id,
        roomId: payload.roomId,
        formId: payload.formId,
        errorMessage
      }
    });
  } finally {
    if (payload.value.type === "form") {
      yield put({
        type: atypes.HIDE_FORM_CREATION_LOADER,
        payload: {
          roomId: payload.roomId,
          fieldId: payload.id
        }
      });
    }
  }
}

function* watchSetChecklistValues(): any {
  yield takeEvery(atypes.SET_CHECKLIST_VALUE, setChecklistValues);
}

function* getChecklistWorkflows({ payload }: Action): any {
  try {
    const ids = yield call(api.getChecklistWorkflows, payload);

    yield put({
      type: atypes.GET_CHECKLIST_WORKFLOWS_SUCCESS,
      payload: ids
    });
  } catch (e) {
    yield put({
      type: atypes.GET_CHECKLIST_WORKFLOWS_FAILURE,
      payload: e
    });
  }
}

function* watchGetChecklistWorkflows(): any {
  yield takeEvery(atypes.GET_CHECKLIST_WORKFLOWS, getChecklistWorkflows);
}

// When edit icon is tapped on checklist, `editWorkflow` action is fired, it triggers
// this saga which fetches latest checklist data and sets the current tab in
// `workflow.builderDialog` reducer which is how the current tab is set and user
// is navigated to process builder.

function* getChecklistFields({ payload }: Action): any {
  try {
    const { id, builder } = payload;
    const fields = yield call(api.getChecklistFields, id);
    yield put({
      type: atypes.GET_CHECKLIST_FIELDS_SUCCESS,
      payload: {
        checklistId: id,
        fields
      }
    });

    const deletedFields = R.filter(field => field.deleted, fields);
    const presentFields = R.filter(field => !field.deleted, fields);

    if (builder) {
      yield put({
        type: atypes.SET_CURRENT_CHECKLIST,
        payload: {
          id,
          fields: presentFields,
          deletedFields
        }
      });
    }
  } catch (e) {
    yield put({
      type: atypes.GET_CHECKLIST_FIELDS_FAILURE,
      payload: e
    });
  }
}

function* watchChecklistFields(): any {
  yield takeEvery(atypes.GET_CHECKLIST_FIELDS_REQUEST, getChecklistFields);
}

function* getUniqueChecklistValues({ payload }: Action): any {
  try {
    const uniqueValues = yield call(api.getUniqueChecklistValues, payload);

    yield put({
      type: atypes.GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_SUCCESS,
      payload: {
        uniqueValues
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_FAILURE,
      payload: { error }
    });
  }
}

function* watchGetUniqueChecklistValues(): any {
  yield takeEvery(
    atypes.GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_REQUEST,
    getUniqueChecklistValues
  );
}

function* searchChecklist({ payload }: Action): any {
  try {
    const checklists = getChecklistTemplates(yield select(getAppState));
    const { search } = payload;

    const sortByName = R.sortBy(c => R.toLower(c.title || ""));

    const result = R.map(
      c => (c.id ? parseInt(c.id, 10) : c.id),
      sortByName(
        R.filter(
          c => R.includes(R.toLower(search || ""), R.toLower(c.title || "")),
          checklists
        )
      )
    );

    yield put({
      type: atypes.SEARCH_CHECKLIST_SUCCESS,
      payload: {
        result
      }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_CHECKLIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSearchChecklist(): any {
  yield takeEvery(atypes.SEARCH_CHECKLIST_REQUEST, searchChecklist);
}

function* createConversation({ payload }: Action): any {
  try {
    const {
      workflow: workflowId,
      type,
      title,
      fieldId,
      roomId,
      checklistId,
      formId,
      parent
    } = payload.req;

    const { currentUser, orgs } = yield select(getAppState);
    let template = null;
    const workflow = (yield select(getAppState)).workflow.byId;

    if (type === "workflow") template = workflow[workflowId];
    const { uid } = currentUser;

    const checklistField = getChecklistFieldDetails(
      yield select(getAppState),
      checklistId
    );
    const checklistType = checklistField
      ? checklistField.get("type")
      : "conversation";

    let newRoom: Object = {
      title,
      type: type || "group",
      owner: null,
      creator: uid,
      members: [],
      templateId: workflowId || null,
      description: "",
      orgId: orgs.last
    };

    if (parent) {
      newRoom.parent = parent;
    }

    // If the created conversation is workflow fill in the default values from process template
    if (template) {
      const { members, checklists, privacy, numberingScheme, owner } = template;

      newRoom = {
        ...newRoom,
        owner: owner ? owner : newRoom.owner,
        members,
        numberingScheme,
        checklists,
        privacy
      };

      // Assign creator as owner depending on setting
      if (!newRoom.owner && template?.settings?.creatorIsOwner !== false) {
        newRoom.owner = uid;
      }

      // Make creator as member depending on setting
      if (template?.settings?.creatorIsParticipant !== false) {
        if (newRoom.owner) {
          newRoom.members = R.uniq(
            [newRoom.owner, uid, ...newRoom.members] || []
          );
        } else {
          newRoom.members = R.uniq([uid, ...newRoom.members] || []);
        }
      }
    } else {
      newRoom.owner = uid;
      newRoom.members = R.uniq([uid, ...newRoom.members] || []);
    }

    let request = {};

    request = {
      roomId,
      id: fieldId,
      value: {
        type: checklistType,
        value: newRoom,
        checked: true
      },
      formId: formId || null,
      checklistId,
      progress: true
    };

    const checklistValue = yield call(api.setChecklistFieldValue, request);
    const fieldValues = {};

    const conversationFieldTypes = [
      "conversation",
      "chatPickList",
      "group",
      "task",
      "workflow",
      "childConversation",
      "link"
    ];

    if (
      checklistValue.value &&
      conversationFieldTypes.includes(checklistType)
    ) {
      const {
        embedded,
        nestedFieldDetails,
        chatroomMetaData
      } = getEmbeddedFields(checklistValue.value);

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS,
        payload: embedded
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });

      yield put({
        type: atypes.LOAD_CHATROOM_METADATA,
        payload: chatroomMetaData
      });
    }

    if (formId) {
      fieldValues[`${roomId}-${fieldId}-${formId}`] = {
        type: checklistType,
        val: { value: checklistValue.val.value },
        checked: true
      };

      yield put({
        type: atypes.GET_CHECKLIST_FORM_VALUES,
        payload: fieldValues
      });
    } else {
      if (payload.location === locations.manageView) {
        const app = yield select(getAppState);

        const {
          roomId: editRoomId,
          index,
          fieldId
        } = app.checklist?.selectedChecklist;

        // Update the conversation on checklist modal
        yield put({
          type: atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW,
          payload: {
            roomId: editRoomId,
            index,
            fieldId,
            value: checklistValue.val.value
          }
        });

        // Update the conversation on manage view table
        yield put({
          type: atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_REQUEST,
          payload: {
            roomId: editRoomId,
            index,
            id: fieldId,
            value: {
              type: "conversation",
              [fieldId]: checklistValue.val.value,
              value: checklistValue.val.value
            }
          }
        });
      } else {
        yield put({
          type: atypes.SET_CHECKLIST_VALUE_SUCCESS,
          payload: {
            chatroomId: parseInt(roomId, 10),
            fieldId,
            val: {
              type: checklistType,
              value: checklistValue.val.value,
              checked: true
            },
            checklistId,
            progress: true
          }
        });
      }
    }

    yield put({
      type: atypes.CLEAR_CONVERSATION_CHECKLIST_LOADER,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.CLEAR_CONVERSATION_CHECKLIST_LOADER,
      payload: {}
    });
    yield put({
      type: atypes.CREATE_CONVERSATION_FROM_CHECKLIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchCreateConversation(): any {
  yield takeEvery(
    atypes.CREATE_CONVERSATION_FROM_CHECKLIST_REQUEST,
    createConversation
  );
}

function* inviteUserFromChecklist({ payload }: any) {
  try {
    const orgId = yield select(getLastOrg);

    const {
      email,
      displayName,
      fieldId,
      roomId,
      checklistId,
      multiple,
      selected
    } = payload;

    let request = {};

    if (multiple) {
      request = {
        roomId,
        id: fieldId,
        value: {
          type: "user",
          value: [
            ...(selected || []),
            {
              email,
              orgRole: "contact",
              chatRoomId: null,
              displayName
            }
          ],
          checked: true
        },
        checklistId,
        progress: true
      };
    } else {
      request = {
        roomId,
        id: fieldId,
        value: {
          type: "user",
          value: {
            email,
            orgRole: "contact",
            chatRoomId: null,
            displayName
          },
          checked: true
        },
        checklistId,
        progress: true
      };
    }

    const checklistValue = yield call(api.setChecklistFieldValue, request);

    if (checklistValue.value.length > 0) {
      const users = R.mergeAll(
        checklistValue.value.map(user => ({
          [user.uid]: {
            ...user,
            email:
              `${orgId}` === `${208}` || `${orgId}` === `${217}`
                ? ""
                : user.email
          }
        }))
      );

      yield put({
        type: atypes.INVITE_USER_FROM_CHECKLIST_SUCCESS,
        payload: users
      });

      yield put({
        type: atypes.REPLACE_CHECKLIST_VALUE,
        payload: {
          chatroomId: parseInt(roomId, 10),
          fieldId,
          val: {
            type: "user",
            value: checklistValue.val.value,
            checked: true
          },
          checklistId,
          progress: true
        }
      });
    }

    yield put({
      type: atypes.SEARCH_USERS_REQUEST,
      payload: {
        searchString: ""
      }
    });
  } catch (error) {
    toast.error("Unable to invite user");
    yield put({
      type: atypes.INVITE_USER_FROM_CHECKLIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchInviteUserFromChecklist(): any {
  yield takeEvery(
    atypes.INVITE_USER_FROM_CHECKLIST_REQUEST,
    inviteUserFromChecklist
  );
}

function* setEmbeddedFields({ payload }: Action): any {
  try {
    const app = yield select(getAppState);
    const instances = getWorkflowInstances(app);
    let embeddedConversations = instances[payload.index]
      ? instances[payload.index][payload.fieldId]
      : null;

    const field = yield select(getChecklistFieldById(String(payload.fieldId)));

    // Since linked field values are normalized,
    // we need to get the chatroom data by transforming it
    if (field.get("type") === "link") {
      embeddedConversations = payload.value
        ? Object.values(payload.value?.entities?.chatrooms || {}).map(item => ({
            // $FlowFixMe
            ...item.chatroom
          }))
        : null;
    }

    if (embeddedConversations) {
      const { embedded, nestedFieldDetails } = getEmbeddedFields(
        embeddedConversations
      );

      yield put({
        type: atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS,
        payload: embedded
      });

      yield put({
        type: atypes.UPDATE_CHECKLIST_FIELDS,
        payload: nestedFieldDetails
      });
    }
  } catch (e) {
    console.log("Failed to set embedded fields", e);
  }
}

function* watchSetSelectedChecklistField(): any {
  yield takeEvery(
    atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW,
    setEmbeddedFields
  );
}

export function* getEmbeddedFieldDetails({ payload }: Action): any {
  try {
    const fields = yield call(api.getEmbeddedFields, payload);

    yield put({
      type: atypes.GET_EMBEDDED_FIELDS_SUCCESS,
      payload: {
        fields
      }
    });
    return fields;
  } catch (error) {
    yield put({
      type: atypes.GET_EMBEDDED_FIELDS_FAILURE,
      payload: { error }
    });
    console.error(error);
  }
}

function* watchGetEmbeddedField(): any {
  yield takeEvery(atypes.GET_EMBEDDED_FIELDS_REQUEST, getEmbeddedFieldDetails);
}

function* getSignatureURLs(): any {
  try {
    const signatures = yield call(api.getSignatureURLs);

    yield put({
      type: atypes.GET_SIGNATURE_URLS_SUCCESS,
      payload: signatures
    });
  } catch (error) {
    yield put({
      type: atypes.GET_SIGNATURE_URLS_FAILURE,
      payload: { error }
    });
    console.error(error);
  }
}

function* watchGetSignatureURLs(): any {
  yield takeEvery(atypes.GET_SIGNATURE_URLS_REQUEST, getSignatureURLs);
}

function* watchCustomSignatureUpload(): any {
  yield takeEvery(atypes.UPLOAD_CUSTOM_SIGNATURE_SUCCESS, getSignatureURLs);
}

export default [
  watchInviteUserFromChecklist(),
  watchGetChecklist(),
  watchGetChatroomChecklistFields(),
  getChecklistFieldValuesFaster(),
  watchSetChecklistValues(),
  watchGetChecklistWorkflows(),
  watchChecklistFields(),
  watchGetUniqueChecklistValues(),
  watchSearchChecklist(),
  watchCreateConversation(),
  watchSetSelectedChecklistField(),
  watchGetEmbeddedField(),
  watchGetSignatureURLs(),
  watchCustomSignatureUpload(),
  watchSetLockedChecklistFields(),
  watchGetFieldValue()
];
