// @flow

import { connect, useDispatch } from "react-redux";
import * as R from "ramda";
import React, { useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import uuid from "uuid/v4";
import { setChecklistValue } from "src/actions/checklist";
import {
  getChecklistFormValue,
  getChecklistFieldDetails,
  getFormFieldLocked,
  getFormFieldHidden,
  getFormFieldValueStatus,
  getFormFieldMandatoryStatus
} from "src/reducers";
import { uploadFileToChecklist } from "src/actions/file";
import Tooltip from "src/components/Tooltip";
import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import Conversation from "./Conversation";
import Picklist from "./Picklist";
import File from "./File";
import User from "./User";
import useFields from "./useFields";
import Form from "./Form";
import Approval from "./Approval";
import { FieldContainer } from "src/components/Dock/Checklist/styles";
import Revision from "./Revision";
import LinkedField from "./LinkedField";
import { Container, ChecklistLabel, More } from "./styles";
import type {
  AppState,
  FieldId,
  RoomId,
  ChecklistId,
  ChecklistValue,
  DataStage,
  ChecklistField,
  HttpMethods
} from "src/types";
import { toast } from "react-toastify";
import SkeletonLoader from "src/components/LoadingState";
import { dataStages } from "src/constants";
import * as morpheus from "src/utils/morpheus";
import useOverflowCheck from "src/hooks/useOverflowCheck";

type Props = {
  details: ChecklistField,
  roomId: RoomId,
  checklistValue: ChecklistValue,
  id: FieldId,
  checklistId?: ChecklistId,
  _setChecklistValue: Function,
  title: string,
  isFieldInsideSection: boolean,
  value: any,
  formId?: ?number,
  locked: boolean,
  hidden: boolean,
  _uploadFileToChecklist: Function,
  roomFieldFormId: string,
  valueStatus: ?DataStage,
  isMandatory: boolean
};

const Field = ({
  details,
  roomId,
  checklistValue,
  id,
  checklistId,
  formId,
  _setChecklistValue,
  isFieldInsideSection,
  locked,
  hidden,
  _uploadFileToChecklist,
  roomFieldFormId,
  valueStatus,
  isMandatory
}: Props) => {
  const [showMore, setShowMore] = useState(false);
  const type = details ? details.get("type") : "";
  const label = details ? details.get("label") : "";
  const dispatch = useDispatch();

  const labelRef = useRef();
  const hasLabelOverflown = useOverflowCheck(labelRef);

  let FieldComp = null;

  const {
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue
  } = useFields({
    checklistValue,
    details
  });

  const setFieldValue = ({
    roomId,
    value,
    httpMethod,
    extraBody
  }: {
    roomId: RoomId,
    value: any,
    httpMethod?: HttpMethods,
    extraBody?: Object
  }) => {
    _setChecklistValue({
      roomId,
      id,
      value: {
        value,
        type,
        checked: true
      },
      progress: true,
      formId,
      httpMethod,
      extraBody
    });
  };

  const forwardProps = {
    fieldId: id,
    checklistId,
    formId,
    roomId,
    details,
    checklistValue,
    setChecklistValue: _setChecklistValue,
    setFieldValue,
    edit,
    settings,
    value,
    handleChange,
    openEdit,
    closeEdit,
    increment,
    decrement,
    setValue,
    locked,
    valueStatus,
    isMandatory
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error("Cannot upload multiple files to single file field.");
        return;
      }

      const storageFileName = uuid();

      _uploadFileToChecklist({
        fileData: settings?.multiple ? acceptedFiles : acceptedFiles[0],
        roomId,
        storageFileName,
        fieldId: id,
        value: (value || []).map(file =>
          typeof file === "object" ? file.name : file
        ),
        multiple: settings?.multiple,
        location: "checklist-upload",
        dispatch,
        formId
      });
    },
    [
      _uploadFileToChecklist,
      roomId,
      id,
      value,
      settings,
      setChecklistValue,
      formId
    ]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: settings?.multiple ? 0 : 1
  });
  const dragAndDropProps = type === "file" ? getRootProps() : {};

  switch (type) {
    case "file":
    case "pdf":
      FieldComp = (
        <File
          {...forwardProps}
          type={type}
          checklistValue={{
            ...forwardProps.checklistValue,
            fieldId: id,
            val: {
              type: "file",
              value: (value || []).map(file => file?.name || file),
              checked: true
            }
          }}
        />
      );
      break;
    case "text":
      FieldComp = <Text {...forwardProps} />;
      break;
    case "number":
      FieldComp = <Number {...forwardProps} />;
      break;
    case "date":
      FieldComp = <Date {...forwardProps} />;
      break;
    case "select":
      FieldComp = (
        <Picklist {...forwardProps} roomFieldFormId={roomFieldFormId} />
      );
      break;
    case "user":
      FieldComp = <User {...forwardProps} />;
      break;
    case "task":
    case "decision":
    case "group":
    case "workflow":
    case "conversation":
    case "chatPickList":
      FieldComp = (
        <Conversation {...forwardProps} roomFieldFormId={roomFieldFormId} />
      );
      break;
    case "childConversation":
      FieldComp = (
        <Conversation
          {...forwardProps}
          roomFieldFormId={roomFieldFormId}
          parentConversation
        />
      );
      break;
    case "form":
      FieldComp = <Form {...forwardProps} />;
      break;
    case "approval":
      FieldComp = (
        <Approval
          {...forwardProps}
          settings={morpheus.approval(forwardProps.settings)}
        />
      );
      break;
    case "revision":
      FieldComp = <Revision {...forwardProps} />;
      break;
    case "link":
      FieldComp = (
        <LinkedField {...forwardProps} roomFieldFormId={roomFieldFormId} />
      );
      break;
    default:
      FieldComp = null;
      break;
  }

  if (type !== "section" && R.isNil(valueStatus)) return null;

  if (type !== "section" && valueStatus && valueStatus <= dataStages.fetching)
    return <SkeletonLoader type="checklistField" />;

  return (
    !hidden &&
    (type === "section" ? null : (
      <FieldContainer
        {...dragAndDropProps}
        isDragActive={isDragActive}
        formId={formId}
      >
        <Tooltip title={label.length > 30 ? label : ""}>
          <ChecklistLabel
            isFieldInsideSection={isFieldInsideSection}
            more={showMore}
            ref={labelRef}
          >
            {label}
            {/* Show more button if label overflows */}
            {hasLabelOverflown && !showMore && (
              <More onClick={() => setShowMore(true)}>...more</More>
            )}
          </ChecklistLabel>
        </Tooltip>
        <Container isFieldInsideSection={isFieldInsideSection}>
          {FieldComp}
        </Container>
      </FieldContainer>
    ))
  );
};

Field.defaultProps = {
  formId: null
};

const mapStateToProps = (
  { app }: { app: AppState },
  { id, roomId, formId }: { id: FieldId, roomId: RoomId, formId?: ?number }
) => {
  const roomFieldFormId = formId ? `${roomId}-${id}-${formId}` : "";

  return {
    valueStatus: formId ? getFormFieldValueStatus(app, id, formId) : undefined,
    details: getChecklistFieldDetails(app, `${id}`),
    checklistValue: getChecklistFormValue(app, roomFieldFormId),
    isChecklistLoading: app.checklist.isLoading,
    locked:
      (app.workflowChecklists.lockedFields[roomId] || []).includes(id) ||
      getFormFieldLocked(app, roomFieldFormId),
    hidden: getFormFieldHidden(app, roomFieldFormId),
    isMandatory: getFormFieldMandatoryStatus(app, roomFieldFormId),
    roomFieldFormId
  };
};
export default connect(mapStateToProps, {
  _setChecklistValue: setChecklistValue,
  _uploadFileToChecklist: uploadFileToChecklist
})(Field);
