// @flow

import * as React from "react";

import { integrationStatus } from "src/constants";
import type { IntegrationStatus } from "src/constants";

import { Text, HStack, VStack, Button } from "@chakra-ui/react";
import { IconWrapper, IconBox } from "../styles";
import { icons } from "src/styles";

type Props = {
  status: IntegrationStatus,
  name: string,
  onClick: (name: string) => void,
  icon: React.Node
};

const IntegrationItem = ({ status, name, onClick, icon }: Props) => {
  const handleClick = () => onClick(name);

  if (
    status === integrationStatus.done ||
    status === integrationStatus.userPending
  ) {
    return (
      <IconWrapper onClick={handleClick}>
        <IconBox>{icon}</IconBox>

        <Text textStyle="popoverMenuItem">{name}</Text>
      </IconWrapper>
    );
  }

  if (status === integrationStatus.adminPending) {
    return (
      <HStack
        sx={{
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "yellow.50",
          border: "1px solid",
          borderColor: "yellow.200",
          px: 4,
          py: 2
        }}
      >
        <VStack
          sx={{
            alignItems: "start"
          }}
        >
          <Text textStyle="popoverMenuItem">Integrate {name}</Text>

          <HStack
            sx={{
              alignItems: "center"
            }}
          >
            <img src={icons.integrity} />

            <Button
              variant="link"
              onClick={handleClick}
              sx={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "unifize_blue",
                _focus: {
                  boxShadow: null
                }
              }}
            >
              Learn how
            </Button>
          </HStack>
        </VStack>

        {icon}
      </HStack>
    );
  }

  return null;
};

export default IntegrationItem;
