// worker.js

export default () => {
  /**
   * Filters by blanks & non-blanks aplicable for fields such as picklist
   * due date, linked field, conversation, all user related filters
   * (owner, participants etc)
   * @param {*[]} fieldValue - value of the field we're filtering by.
   * @param {string[]} filterValue - column's filter state.
   * @return {boolean} - if the field value passes the filter
   */
  function filterByBlanksAndNonBlanks(fieldValue, filterValue) {
    if (filterValue.includes("null") && filterValue.includes("notnull")) {
      return true;
    } else if (filterValue.includes("null")) {
      return (
        !fieldValue ||
        Object.values(fieldValue).length === 0 ||
        fieldValue[0] === "Invalid date"
      );
    } else if (filterValue.includes("notnull")) {
      return fieldValue && Object.values(fieldValue).length > 0;
    }

    return false;
  }

  /**
   * Filters by blanks & non-blanks (Linked field)
   * @param {Object | undefined} fieldValue - value of the field we're filtering by.
   * @param {string[]} filterValue - column's filter state.
   * @return {boolean} - if the field value passes the filter
   */
  function filterByBlanksLinkedField(fieldValue, filterValue) {
    if (filterValue.includes("null") && filterValue.includes("notnull")) {
      return true;
    } else if (filterValue.includes("null")) {
      return (
        (fieldValue && (fieldValue.result || []).length === 0) || !fieldValue
      );
    } else if (filterValue.includes("notnull")) {
      // When fieldValue exists
      if (fieldValue) {
        return (fieldValue.result || []).length > 0;
      }
      return !!fieldValue;
    }

    return false;
  }

  /**
   * Decides which blankAndNonBlankFilter logic to use
   * @param {Object | undefined} fieldValue - value of the field we're filtering by.
   * @param {Object} filterValue - column's filter state.
   * @param {string} fieldType = the type of column the filter is applied to
   * @return {boolean} - if the field value passes the filter
   */
  function blankAndNonBlankFilter(fieldValue, filterValue, fieldType) {
    if (fieldType === "link") {
      return filterByBlanksLinkedField(fieldValue, filterValue);
    }
    return filterByBlanksAndNonBlanks(fieldValue, filterValue);
  }

  // Find the type of a variable, (typeof isn't working for some reason)
  const trueTypeOf = obj =>
    Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  /**
   * Checks if all the filters are cleared
   * @param {Object} filterValue - all the filter values
   * @return {boolean} - if the field value passes the filter
   */
  function areFilterValuesEmpty(filterValues) {
    return Object.values(filterValues).every(value =>
      Array.isArray(value)
        ? value.length === 0
        : value === null || value === undefined || value === ""
    );
  }

  /**
   * Finds the common elements between two array and return the indices
   * of the common element with respect to arr1
   * @param {Array} arr1 - first array
   * @param {Array} arr2 - second array
   * @return {boolean} - if the field value passes the filter
   */
  function intersection(arr1, arr2) {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    const result = Array.from(
      new Set(Array.from(set1).filter(element => set2.has(element)))
    );

    const indices = result.map(element => arr1.indexOf(element));

    return { result, indices };
  }

  /**
   * Filters the linked fields
   * @param {Object} fieldValue - value stored in the linked field
   * @param {number[] | string[]} filterData - filters applied on the linked field
   * @return {Object} - filtered values of the linked field
   */
  function filterLinkedFields(fieldValue, filterData) {
    const chatroomIds = fieldValue.result || [];
    const chatrooms = (fieldValue.entities || {}).chatrooms || {};

    const { result: filteredRoomIds } = intersection(
      chatroomIds,
      filterData.map(id => parseInt(id))
    );

    const filteredChatrooms = {};

    filteredRoomIds.map(roomId => {
      filteredChatrooms[`${roomId}`] = chatrooms[`${roomId}`];
    });

    const filteredFieldValue = {
      entities:
        filteredRoomIds.length > 0 ? { chatrooms: filteredChatrooms } : {},
      result: filteredRoomIds
    };

    return filteredFieldValue;
  }

  /**
   * Compare fields with object type values (childConversation, user etc.)
   * @param {Object} fieldValue - value stored in the linked field
   * @param {number[] | string[]} filterData - filters applied on the field
   * @param {string} fieldType - type of field
   * @return {Object} - filteredValues and whether the values pass the filter
   */
  function compareObjects(items, filterData, fieldType) {
    if (filterData.length === 0) return true;

    if (fieldType === "user") {
      const values = items.map(value => value.id || value.uid);
      const { result } = intersection(values, filterData);

      return {
        passesFilter:
          result.length > 0 || filterByBlanksAndNonBlanks(items, filterData),
        result
      };
    }
    const values = items.map(value => `${value.id}`);
    const { result } = intersection(values, filterData);
    return {
      passesFilter:
        result.length > 0 || filterByBlanksAndNonBlanks(items, filterData),
      result
    };
  }

  /**
   * Filter the embedded fields
   * @param {Object} instance - current workflow instance
   * @param {Object} filteredInstance - filtered version of the instance
   * @param {string} filterColumnId - columnId of the column the
   * filter is applied to
   * @param {string[]} filterData - the values being used for filtering
   * @return {Object} - filteredValues and whether the values pass the filter
   */
  function filterEmbeddedFields(
    instance,
    filteredInstance,
    filterColumnId,
    fieldType,
    filterData
  ) {
    const fieldValue =
      instance[`${filterColumnId}-original`] || instance[filterColumnId];
    const filteredIndices = [];

    // Filter the target embedded field
    let filteredFieldValues = [];
    if (filterData.length === 0) {
      filteredFieldValues = fieldValue;
    } else {
      fieldValue.map((item, index) => {
        if (Array.isArray(item)) {
          // Handle array of objects (Ex: Multi-User fields)
          if (item[0] && trueTypeOf(item[0]) === "object") {
            const { passesFilter } = compareObjects(
              item,
              filterData,
              fieldType
            );

            if (passesFilter) {
              filteredFieldValues.push(item);
              filteredIndices.push(index);
            }
          } else {
            // Handle regular arrays (Ex: Embedded picklists)
            const { result } = intersection(item, filterData);

            if (
              result.length > 0 ||
              blankAndNonBlankFilter(item, filterData, fieldType)
            ) {
              filteredFieldValues.push(item);
              filteredIndices.push(index);
            }
          }
        } else {
          // Handle single object values (Ex: single user)
          if (item && trueTypeOf(item) === "object") {
            if (fieldType === "user") {
              if (
                filterData.includes(item.id || item.uid) ||
                blankAndNonBlankFilter(item, filterData, fieldType)
              ) {
                filteredIndices.push(index);
                filteredFieldValues.push(item);
              }
            } else if (fieldType === "link") {
              const filteredLinkedFieldValue = filterLinkedFields(
                item,
                filterData
              );

              if (
                filteredLinkedFieldValue.result.length > 0 ||
                blankAndNonBlankFilter(item, filterData, fieldType)
              ) {
                filteredIndices.push(index);
                filteredFieldValues.push(item);
              }
            }
          } else if (fieldType === "date") {
            // TODO: Figure out how to filter dates
            // eslint-disable-next-line no-constant-condition
            filteredIndices.push(index);
          } else if (
            filterData.includes(item) ||
            blankAndNonBlankFilter(item, filterData, fieldType)
          ) {
            filteredIndices.push(index);
            filteredFieldValues.push(item);
          }
        }
      });
    }

    const parentFieldId = filterColumnId.split("-")[0];
    const filteredSiblingFields = {};
    const parentFieldValue =
      instance[`${parentFieldId}-original`] || instance[parentFieldId];
    let filteredParentValues = parentFieldValue;

    // Don't bother updating the sibling and parent fields if no filter
    // is applied
    if (filterData.length !== 0) {
      // Filter out the other embedded fields that belong to the same
      // parent linked field
      Object.keys(instance).map(columnId => {
        if (
          columnId.includes(`${parentFieldId}-`) &&
          !columnId.includes("-original") &&
          columnId !== filterColumnId
        ) {
          // Create duplicates for original values if not already present
          if (!(`${columnId}-original` in instance)) {
            filteredSiblingFields[`${columnId}-original`] =
              `${columnId}-original` in instance
                ? instance[`${columnId}-original`]
                : instance[columnId];
          }

          const siblingFieldValue = [];

          // Filter values for the sibling fields
          // i.e Embedded fields that belong to the same parent field
          filteredIndices.map(filteredIndex => {
            const siblingValues =
              `${columnId}-original` in instance
                ? instance[`${columnId}-original`]
                : instance[columnId];

            siblingFieldValue.push((siblingValues || [])[filteredIndex]);
          });

          // Update the field with filtered values
          filteredSiblingFields[columnId] = siblingFieldValue;
        }
      });

      // Filtering the parent field value
      if (parentFieldValue.entities) {
        // Get the filtered chatroom Ids
        const chatroomIds = filteredIndices.map(
          filteredIndex => parentFieldValue.result[filteredIndex]
        );

        const chatrooms = {};

        // Get the filtered chatrooms
        chatroomIds.map(roomId => {
          chatrooms[`${roomId}`] =
            parentFieldValue.entities.chatrooms[`${roomId}`];
        });

        filteredParentValues = {
          entities: {
            chatrooms: chatrooms
          },
          result: chatroomIds
        };
      } else {
        // If the parent field is a form/are forms
        filteredParentValues = filteredIndices.map(
          filteredIndex => parentFieldValue[filteredIndex]
        );
      }
    }

    return { filteredFieldValues, filteredParentValues, filteredSiblingFields };
  }

  /**
   * Filters the regular linked fields
   * @param {Object} instance - current workflow instance
   * @param {Object} filteredInstance - filtered version of the instance
   * @param {string} filterColumnId - columnId of the column filter is
   * applied to
   * @param {string} fieldType - type of field
   * @param {string[]} filterData - applied filters
   * @param {boolean} allRecords - whether the allRecords filter is
   * applied
   * @return {Object} - filtered linked field values
   **/
  function filterRegularLinkedFields(
    instance,
    filteredInstance,
    filterColumnId,
    fieldType,
    filterData,
    allRecords
  ) {
    const fieldValue =
      instance[`${filterColumnId}-original`] || instance[filterColumnId];

    const linkedFieldIds = fieldValue.result || [];
    const linkedFieldChatrooms = fieldValue?.entities?.chatrooms || {};

    let filteredFieldValue = { entities: {}, result: [] };
    const filteredChatrooms = {};
    let filteredChatroomIds = [];

    const { result } = intersection(
      linkedFieldIds.map(id => `${id}`),
      filterData
    );

    if (allRecords[filterColumnId]) {
      filteredChatroomIds = result || [];
    } else {
      filteredChatroomIds = result.length > 0 ? linkedFieldIds : [];
    }

    Object.keys(linkedFieldChatrooms).map(chatroomId => {
      if (
        filteredChatroomIds.includes(parseInt(chatroomId, 10)) ||
        filteredChatroomIds.includes(chatroomId)
      ) {
        filteredChatrooms[chatroomId] = linkedFieldChatrooms[chatroomId];
      }
    });

    filteredFieldValue["entities"] = filteredFieldValue["entities"] || {};
    filteredFieldValue["entities"]["chatrooms"] = filteredChatrooms;
    filteredFieldValue["result"] = filteredChatroomIds.map(roomId =>
      parseInt(roomId)
    );

    return filteredFieldValue;
  }

  function filterInstances(data) {
    const {
      instancesById: instances,
      fieldsById,
      payload,
      allRecords,
      currentUserUid
    } = data;
    const filterValues = {};
    const chatroomAttributes = ["owner", "dueDate", "status"];

    // Extracting the filterable values from the payload
    Object.keys(payload).forEach(fieldId => {
      const formattedFieldId = fieldId.includes("-")
        ? fieldId.split("-").pop()
        : fieldId;
      const fieldType =
        fieldsById[formattedFieldId] && fieldsById[formattedFieldId].type;

      if (fieldId.includes("-") || fieldType === "link") {
        if (fieldType === "link" && !fieldId.includes("-")) {
          // Do not use this logic for blank and non-blank filters
          // on parent linked fields
          filterValues[fieldId] = (payload[fieldId] || []).filter(
            linkedFieldFilter =>
              linkedFieldFilter !== "null" && linkedFieldFilter !== "notnull"
          );
        } else {
          filterValues[fieldId] = payload[fieldId];
        }
      }
    });

    const instancesById = Object.assign({}, instances);
    const updatedInstances = Object.assign({}, instancesById);

    if (
      Object.values(filterValues).length !== 0 &&
      !areFilterValuesEmpty(filterValues)
    ) {
      Object.keys(instancesById).map(instanceId => {
        const instance = Object.assign({}, instancesById[instanceId]);
        const filteredInstance = Object.assign({}, instance);

        Object.keys(filterValues).forEach(filterColumnId => {
          const filterFieldId = filterColumnId.split("-").pop();

          // Check if the filter has been applied to chatroom attributes
          const chatroomAttributeId = chatroomAttributes.includes(filterFieldId)
            ? filterFieldId
            : false;

          // Check if the filter has been applied to embedded field
          const parentFilterColumnId = filterColumnId.includes("-")
            ? filterColumnId.split("-")[0]
            : false;

          const fieldType = filterColumnId.includes("-")
            ? fieldsById[filterFieldId] && fieldsById[filterFieldId].type
            : fieldsById[filterColumnId] && fieldsById[filterColumnId].type;

          const filterData = filterValues[filterColumnId];

          // Check if the field exists in the instance
          // and deep filter is applied
          if (
            (filterColumnId in instance || !!chatroomAttributeId) &&
            filterColumnId.split("-")[0] in allRecords
          ) {
            // Create a duplicate of the unfiltered values
            if (
              !(`${filterColumnId}-original` in filteredInstance) &&
              !chatroomAttributeId
            ) {
              filteredInstance[`${filterColumnId}-original`] =
                `${filterColumnId}-original` in instance
                  ? instance[`${filterColumnId}-original`]
                  : instance[filterColumnId];
            }

            if (
              parentFilterColumnId &&
              !(`${parentFilterColumnId}-original` in filteredInstance) &&
              !chatroomAttributeId
            ) {
              filteredInstance[`${parentFilterColumnId}-original`] =
                `${parentFilterColumnId}-original` in instance
                  ? instance[`${parentFilterColumnId}-original`]
                  : instance[parentFilterColumnId];
            }

            // Filter the field data
            // Filtering embedded fields
            if (filterColumnId.includes("-") && !chatroomAttributeId) {
              const filterParentId = filterColumnId.split("-")[0];

              // Create copies for all the fields that belong to the
              // same parent
              Object.keys(instance).map(fieldId => {
                if (
                  !fieldId.includes("-original") &&
                  fieldId.includes(`${filterParentId}-`)
                ) {
                  filteredInstance[`${fieldId}-original`] =
                    `${fieldId}-original` in instance
                      ? instance[`${fieldId}-original`]
                      : instance[fieldId];
                }
              });

              const {
                filteredParentValues,
                filteredFieldValues,
                filteredSiblingFields
              } = filterEmbeddedFields(
                instance,
                filteredInstance,
                filterColumnId,
                fieldType,
                filterData
              );

              filteredInstance[filterColumnId] = filteredFieldValues;
              filteredInstance[filterParentId] = filteredParentValues;

              const result = {};

              // Copy properties from filteredInstance to result
              for (const key in filteredInstance) {
                if (key in filteredInstance) {
                  result[key] = filteredInstance[key];
                }
              }

              // Copy properties from filteredSiblingFields to result
              for (const key in filteredSiblingFields) {
                if (key in filteredSiblingFields) {
                  result[key] = filteredSiblingFields[key];
                }
              }

              updatedInstances[instanceId] = result;
            } else if (fieldType === "link") {
              filteredInstance[filterColumnId] = filterRegularLinkedFields(
                instance,
                filteredInstance,
                filterColumnId,
                fieldType,
                filterData,
                allRecords
              );

              // Update the instance with filtered values
              updatedInstances[instanceId] = filteredInstance;
            } else if (chatroomAttributeId) {
              // Filter the chatroom attributes owner and status columns
              // if deep filter is applied
              if (
                parentFilterColumnId in allRecords &&
                allRecords[parentFilterColumnId]
              ) {
                const parentFieldId = filterColumnId.split("-")[0];

                // Create duplicate of the original parent field data
                // if not already present
                if (!(`${parentFieldId}-original` in instance)) {
                  filteredInstance[`${parentFieldId}-original`] =
                    `${parentFieldId}-original` in instance
                      ? instance[`${parentFieldId}-original`]
                      : instance[parentFieldId];
                }

                const parentFieldValue =
                  instance[`${parentFieldId}-original`] ||
                  instance[parentFieldId];

                const selectedChatroomIds = new Set();
                const filteredChatrooms = {};

                Object.keys(
                  (parentFieldValue.entities || {}).chatrooms || {}
                ).map(chatroomId => {
                  const roomDetails =
                    parentFieldValue.entities.chatrooms[chatroomId].chatroom;

                  let formattedFilterData = [];

                  // Handle the "ME" filter
                  if (chatroomAttributeId === "owner") {
                    filterData.map(filter => {
                      if (filter.includes("me-")) {
                        formattedFilterData.push(filter.split("me-")[1]);
                      } else if (filter === "current-user") {
                        formattedFilterData.push(currentUserUid);
                      } else {
                        formattedFilterData.push(filter);
                      }
                    });
                  } else {
                    formattedFilterData = filterData;
                  }

                  // Check if the chatroom attribute matches the filter
                  if (
                    formattedFilterData.includes(
                      roomDetails[chatroomAttributeId]
                    )
                  ) {
                    filteredChatrooms[`${roomDetails.id}`] =
                      parentFieldValue.entities.chatrooms[chatroomId];
                    selectedChatroomIds.add(`${roomDetails.id}`);
                  }
                });

                const filteredChatroomIds = (
                  parentFieldValue.result || []
                ).filter(roomId => {
                  if (selectedChatroomIds.has(`${roomId}`)) {
                    return roomId;
                  }
                });

                const filteredLinkedField = {
                  entities: {},
                  result: []
                };

                if (filteredChatroomIds.length > 0) {
                  filteredLinkedField["entities"][
                    "chatrooms"
                  ] = filteredChatrooms;
                }

                filteredLinkedField["result"] = filteredChatroomIds;

                filteredInstance[parentFieldId] = filteredLinkedField;

                updatedInstances[instanceId] = filteredInstance;
              } else {
                // Remove the deep filter from chatroom attributes
                Object.keys(instancesById).map(instanceId => {
                  const instance = instancesById[instanceId];
                  const resetInstance = {};
                  Object.keys(instance).map(columnId => {
                    if (
                      columnId.includes("-original") &&
                      columnId.includes(parentFilterColumnId)
                    ) {
                      resetInstance[columnId.split("-original")[0]] =
                        instance[columnId];
                    } else {
                      resetInstance[columnId] = instance[columnId];
                    }
                  });

                  updatedInstances[instanceId] = resetInstance;
                });
              }
            }
          } else if (
            parentFilterColumnId &&
            parentFilterColumnId in allRecords &&
            !allRecords[parentFilterColumnId]
          ) {
            // Remove the all records filter
            Object.keys(instancesById).map(instanceId => {
              const instance = instancesById[instanceId];
              const resetInstance = {};
              Object.keys(instance).map(columnId => {
                if (
                  columnId.includes("-original") &&
                  columnId.includes(parentFilterColumnId)
                ) {
                  resetInstance[columnId.split("-original")[0]] =
                    instance[columnId];
                } else {
                  resetInstance[columnId] = instance[columnId];
                }
              });

              updatedInstances[instanceId] = resetInstance;
            });
          }

          return updatedInstances;
        });
      });
    } else if (areFilterValuesEmpty(filterValues)) {
      Object.keys(instancesById).map(instanceId => {
        const instance = instancesById[instanceId];
        const resetInstance = {};
        Object.keys(instance).map(columnId => {
          if (columnId.includes("-original")) {
            resetInstance[columnId.split("-original")[0]] = instance[columnId];
            delete resetInstance[columnId];
          } else {
            resetInstance[columnId] = instance[columnId];
          }
        });

        updatedInstances[instanceId] = resetInstance;
      });
    }

    return updatedInstances;
  }

  onerror = event => {
    console.error("FROM WORKER: ", event);
  };

  onmessage = event => {
    const result = filterInstances(event.data);
    postMessage(result);
  };
};
