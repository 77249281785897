// @flow

import React, { useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import type { Map } from "immutable";
import {
  getChecklistFieldBehavior,
  getBehaviorByFormField
} from "src/reducers";

import { Tooltip } from "@chakra-ui/react";
import { RemoveOption } from "./Search/styles";
import Single from "./Single";
import MultiPicklist from "./MultiPicklist/MultiPicklist";
import SelectedMultipicklists from "./MultiPicklist/SelectedMultiPicklists";

import {
  PicklistContainer,
  PicklistSearch,
  SelectMenu,
  MenuOptions,
  MenuOption,
  CreateNewButton,
  CreateNewState,
  CheckSelect
} from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Icon from "src/icons";
import useAutoPositionDropdown from "src/hooks/useAutoPositionDropdown";
import { setChecklistFromManageView } from "src/actions/checklist";
import type {
  FieldId,
  ChecklistValue,
  RoomId,
  State,
  ColumnId
} from "src/types";

import { behaviorToSettings, behaviors } from "src/conditions";

type Props = {
  formId: ?number,
  fieldId: FieldId,
  details: Map<string, any>,
  checklistValue: ChecklistValue,
  roomId: RoomId,
  columnId: ColumnId,
  setChecklistValue: Function,
  promptCallback?: ?Function,
  index: number,
  edit: boolean,
  settings: Object,
  value: any,
  openEdit: Function,
  closeEdit: Function,
  setValue: Function,
  locked: boolean,
  isMandatory?: boolean,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>,
  roomFieldFormId?: string
};

const SelectOption = ({
  formId,
  fieldId,
  roomId,
  columnId,
  setChecklistValue,
  promptCallback,
  settings,
  value,
  openEdit,
  closeEdit,
  edit,
  setValue,
  locked,
  isMandatory,
  currentBehavior,
  dependentOptions
}: Props) => {
  const { fieldRef, dropdownRef, positionUp } = useAutoPositionDropdown({
    edit
  });
  const [createField: boolean, setCreateField: Function] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState(settings?.options ?? []);

  const resetSearch = useCallback(() => setSearch(""), []);
  const handleSearch = useCallback(e => setSearch(e.target.value), []);

  useEffect(() => {
    if (
      currentBehavior ===
        behaviorToSettings[behaviors.dependentPicklistExclude] &&
      dependentOptions
    ) {
      setOptions(R.without(dependentOptions, options));
    } else if (
      currentBehavior ===
        behaviorToSettings[behaviors.dependentPicklistInclude] &&
      dependentOptions
    ) {
      setOptions(dependentOptions);
    }
  }, [currentBehavior, dependentOptions]);

  const adhoc = settings?.adhoc ?? false;
  const multiple = settings?.multiple ?? false;

  const results = R.filter(
    option =>
      !R.isEmpty(option) && R.includes(R.toLower(search), R.toLower(option)),
    options || []
  );
  // Sort options alphabetically if sortBy is alphabetical
  const sortedResults =
    settings?.sortBy === "alphabetical"
      ? R.sort((a, b) => a.localeCompare(b), results)
      : results;

  const multipleResults = R.filter(
    option =>
      !R.isEmpty(option) &&
      !R.includes(option, value && value.length > 0 ? value : ""),
    sortedResults || []
  );

  const setOption = useCallback(
    option => {
      if (value !== option) {
        setChecklistValue({
          roomId,
          id: fieldId,
          value: {
            value: option,
            type: "picklist",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      }

      setValue(option);
      if (promptCallback) promptCallback();
      closeEdit();
      resetSearch();
    },
    [value, formId, roomId, fieldId, columnId, promptCallback, setValue]
  );

  const setMultipleOption = useCallback(
    option => {
      const newValue = [...(value || []), option];

      if (value !== option) {
        setChecklistValue({
          roomId,
          id: fieldId,
          value: {
            value: newValue,
            type: "picklist",
            checked: true
          },
          progress: true,
          formId,
          columnId
        });
      }
      setValue(newValue);

      if (promptCallback) promptCallback();
      closeEdit();
      resetSearch();
    },
    [
      value,
      formId,
      roomId,
      fieldId,
      columnId,
      promptCallback,
      multiple,
      setValue
    ]
  );

  const setNull = useCallback(() => {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        value: null,
        type: "picklist",
        checked: false
      },
      progress: true,
      formId,
      columnId
    });
    setValue(null);

    closeEdit();
    resetSearch();
  }, [formId, roomId, columnId, fieldId]);

  const addPicklistOption = useCallback(() => {
    if (search.length > 0) {
      if (multiple) {
        setMultipleOption(search);
      } else {
        setOption(search);
      }

      setOptions(options => R.uniq([...options, search]));
      setCreateField(false);
    } else setCreateField(true);
  }, [search, setOption, roomId]);

  const forwardProps = {
    edit,
    update: closeEdit,
    value,
    type: "picklist",
    openEdit,
    isSelect: true,
    clickToEdit: true,
    disabled: locked,
    isMandatory
  };

  const closeCreateField = useCallback(() => {
    closeEdit();
    setCreateField(false);
  }, []);

  const handleRemove = useCallback(
    (option: string) => {
      const newValue = (value || []).filter(pickList => pickList !== option);
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "picklist",
          checked: true
        },
        progress: true,
        formId,
        columnId
      });
      setValue(newValue);
    },
    [formId, roomId, fieldId, columnId, value, setValue]
  );

  if (createField) {
    return (
      <OutsideClickHandler onClickOutside={closeCreateField}>
        <PicklistContainer>
          <PicklistSearch
            value={search}
            onChange={handleSearch}
            autoFocus
            placeholder="Enter title for new item"
          />
          <CreateNewState
            highlight={search.length > 0}
            onClick={addPicklistOption}
          >
            <span>
              &#43; Create {search.length > 0 ? `item "${search}"` : "new item"}
            </span>
          </CreateNewState>
        </PicklistContainer>
      </OutsideClickHandler>
    );
  }

  if (edit && multiple) {
    return (
      <MultiPicklist
        onClickOutside={closeEdit}
        fieldRef={fieldRef}
        onChange={handleSearch}
        autoFocus
        placeholder="Search picklist"
        positionUp={positionUp}
        dropdownRef={dropdownRef}
        onClick={setNull}
        multipleResults={multipleResults}
        setMultipleOption={setMultipleOption}
        value={value}
        adhoc={adhoc}
        search={search}
        addPicklistOption={addPicklistOption}
      />
    );
  }

  if (multiple) {
    return (
      <SelectedMultipicklists
        value={value}
        handleRemove={handleRemove}
        openEdit={openEdit}
        disabled={locked}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <Single
      {...forwardProps}
      value={R.type(value) === "Array" ? value[0] : value}
      type="select"
    >
      <PicklistContainer ref={fieldRef}>
        <PicklistSearch
          value={search}
          onChange={handleSearch}
          autoFocus
          placeholder="Search picklist"
        />
        <SelectMenu positionUp={positionUp} ref={dropdownRef}>
          {value && (
            <RemoveOption onClick={setNull}>
              Remove
              <Icon type="checklistRemove" />
            </RemoveOption>
          )}
          <MenuOptions>
            {sortedResults.map(option => (
              <MenuOption key={option} onClick={() => setOption(option)}>
                <Tooltip label={option.length > 25 && option}>
                  <span>{option}</span>
                </Tooltip>
                {option === value && (
                  <CheckSelect>
                    <Icon type="checkSelect" />
                  </CheckSelect>
                )}
              </MenuOption>
            ))}
          </MenuOptions>
          {adhoc && (
            <CreateNewButton onClick={addPicklistOption}>
              &#43; Create{" "}
              {search.length > 0 ? (
                <span>&nbsp;{`"${search}"`}</span>
              ) : (
                `new item`
              )}
            </CreateNewButton>
          )}
        </SelectMenu>
      </PicklistContainer>
    </Single>
  );
};

SelectOption.defaultProps = {
  promptCallback: null
};

const mapStateToProps = (
  { app }: State,
  { fieldId, roomFieldFormId, roomId }: Props
) => {
  const behavior = roomFieldFormId
    ? getBehaviorByFormField(app, roomFieldFormId)
    : getChecklistFieldBehavior(app, { roomId, fieldId });

  return {
    dependentOptions: behavior.options,
    currentBehavior: behavior.current
  };
};

export default connect(mapStateToProps, {
  _setChecklistFromManageView: setChecklistFromManageView
})(SelectOption);
