// @flow

import * as R from "ramda";
import type { UnifizeChatRoom } from "src/types";

/**
 * Check whether the given argument is a valid Unifize chatroom.
 * @param {object | null} chatroom - The chatroom to check.
 */
export const isValidChatroom = (chatroom: ?Object = {}) =>
  // $FlowFixMe
  R.all(R.has(R.__, chatroom))([
    "status",
    "id",
    "address",
    "title",
    "type",
    "seqNo"
  ]);

// $FlowFixMe
export const sortByAutoNumber = R.sort<UnifizeChatRoom>((roomA, roomB) => {
  if (!roomA.seqNo || !roomB.seqNo) return Number(roomA.id) - Number(roomB.id);

  if (
    !roomA.autoNo ||
    !roomB.autoNo ||
    !roomA.autoNo.includes("/") ||
    !roomB.autoNo.includes("/")
  )
    return Number(roomA.seqNo) - Number(roomB.seqNo);

  let [seqA, versionA] = roomA.autoNo.split("/").map(Number);
  let [seqB, versionB] = roomB.autoNo.split("/").map(Number);

  if (seqA !== seqB) return seqA - seqB;

  return versionA - versionB;
});

// $FlowFixMe
export const reverseSortById = R.sort<UnifizeChatRoom>(
  (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
);
