// @flow

import { combineReducers } from "redux";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";

import type {
  HomeScreenBuilderDialog,
  SectionById,
  OrgSettingsState,
  Action,
  UserSegmentById,
  SegmentUserModal,
  SegmentType,
  PlatformUsage
} from "src/types";

const current = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_ORG_SETTINGS_SUCCESS:
      if (payload.id) {
        return payload.id;
      }
      return null;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.DELETE_DASHBOARD_SUCCESS:
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_CONTACTS_REQUEST:
      return null;
    default:
      return state;
  }
};

const initialHomeScreenDialog = {
  show: false,
  tileType: null
};

const homeScreenBuilderDialog = (
  state: HomeScreenBuilderDialog = initialHomeScreenDialog,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_HOME_SCREEN_BUILDER_ATTRIBUTES:
      return { ...state, ...payload };
    case atypes.CLEAR_HOME_SCREEN_BUILDER_ATTRIBUTES:
      return initialHomeScreenDialog;
    default:
      return state;
  }
};

const sectionById = (state: SectionById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_SECTION_SUCCESS:
    case atypes.UPDATE_SECTION_OPTIMISTIC:
    case atypes.UPDATE_SECTION_FAILURE:
      return { ...state, [payload.id]: payload };
    case atypes.GET_ALL_SECTION_SUCCESS:
      return payload.allSections;
    case atypes.DELETE_SECTION_SUCCESS:
      return R.omit([payload.id], state || {});
    default:
      return state;
  }
};

const sectionsIds = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_SECTION_SUCCESS:
      return [...state, payload.id];
    case atypes.DELETE_SECTION_SUCCESS:
      return R.reject(id => payload.id == id, state || []);
    case atypes.GET_ALL_SECTION_SUCCESS:
      return payload.allIds || [];
    case atypes.UPDATE_SECTION_ORDER_REQUEST:
      return payload.id || [];
    default:
      return state;
  }
};

const creatingSection = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.CREATE_SECTION_SUCCESS:
    case atypes.CREATE_SECTION_FAILURE:
      return false;
    case atypes.CREATE_SECTION_REQUEST:
      return true;
    default:
      return state;
  }
};

const tileTypes = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_TILE_TYPES_SUCCESS:
      return R.mergeAll(payload.map(type => ({ [type.id]: type.title })));
    default:
      return state;
  }
};

const tileById = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_TILE_SUCCESS:
    case atypes.EDIT_TILE_SUCCESS:
    case atypes.UPDATE_TILE_OPTIMISTIC:
    case atypes.UPDATE_TILE_FAILURE:
    case atypes.DELETE_TILE_FAILURE:
      return { ...state, [payload.id]: payload };
    case atypes.GET_ALL_TILES_SUCCESS:
      return payload;
    case atypes.DELETE_TILE_OPTIMISTIC:
      return R.omit([payload.id], state);
    default:
      return state;
  }
};

const userSegmentById = (
  state: UserSegmentById = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_ALL_USER_SEGMENTS_SUCCESS:
      return payload;
    case atypes.CREATE_USER_SEGMENT_SUCCESS:
    case atypes.EDIT_USER_SEGMENT_SUCCESS:
      return { ...state, [payload.id]: payload };
    default:
      return state;
  }
};

const segmentSearchResult = (
  state: Array<number> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_USER_SEGMENTS_SUCCESS:
      return payload.result;
    default:
      return state;
  }
};

const initialSegementUserModal = {
  title: "Untitled user segment",
  show: null,
  edit: false,
  criteria: []
};

const segmentUserModal = (
  state: SegmentUserModal = initialSegementUserModal,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_SEGMENT_USER_MODAL_ATTRIBUTES:
      return { ...state, ...payload };
    case atypes.CLOSE_SEGMENT_USER_MODAL:
      return initialSegementUserModal;
    default:
      return state;
  }
};

const segmentType = (state: SegmentType = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_SEGMENT_TYPE_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const platformUsage = (
  state: PlatformUsage = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_PLATFORM_SUCCESS:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  current,
  homeScreenBuilderDialog,
  sectionById,
  sectionsIds,
  creatingSection,
  tileTypes,
  tileById,
  userSegmentById,
  segmentSearchResult,
  segmentUserModal,
  segmentType,
  platformUsage
});

export const getSection = (state: OrgSettingsState, id: number) =>
  state.sectionById[`${id}`];

export const getTile = (state: OrgSettingsState, id: number) =>
  state.tileById[`${id}`] || {};

export const getSegmentName = (state: OrgSettingsState, id: number) =>
  state.userSegmentById[`${id}`]?.title;
