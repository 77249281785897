// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { switchProp, ifProp } from "styled-tools";
import posed from "react-pose";

import { Capsule, SimpleBox, SmallBox } from "src/styles/box";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import { Input } from "src/styles/input";
import Dropdown from "src/styles/dropdown";

const Small = styled(Capsule)`
  font-weight: bold;
  grid-template-columns: 1fr auto;
  padding-right: ${spacing.space_xs};
  > div:first-of-type {
    color: ${colors.active};
    height: 100%;
  }
  > div:last-child {
    display: flex;
    background: ${colors.grey1};
  }
`;

const Count = styled.div`
  border-right: 1px solid ${colors.grey5};
  padding: 0 ${spacing.space_s};
  > div {
    height: 10px;
    border: ${props => (props.border ? null : "none")};
    padding: ${spacing.space_list_xs};
  }

  :last-child {
    border-right: 1px solid transparent;
  }
  svg {
    height: 15px;
    width: 15px;
  }
  > span > span {
    padding-left: ${spacing.space_xs};
  }
`;

const Body = styled.div`
  margin-top: ${spacing.space_l};
  margin-bottom: ${spacing.space_l};
  padding: ${spacing.space_list_l};
  overflow-y: scroll;
  margin-right: ${spacing.space_s};
  overflow-x: visible;
  max-height: 520px;
  height: 100%;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.space_m} 0;
  padding: ${spacing.space_m} 0;
  position: relative;
  border-bottom: 2px solid ${colors.grey6};
`;

const CategoryRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ControlWrapper = styled.div`
  width: calc(100% - 3px);
  margin-top: ${spacing.space_l};
`;

const tabColor = {
  related: colors.active,
  overdue: colors.danger,
  completed: colors.success,
  owner: colors.primary
};

const CategoryItems = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;

  > svg {
    margin-right: ${spacing.space_s};
  }

  color: ${switchProp("category", tabColor)};
`;

const selectorProps = {
  tab1: {
    left: "0%"
  },
  tab2: {
    left: "50%"
  }
};

const Selector = styled(posed.div(selectorProps))`
  width: 50%;
  height: ${spacing.space_s};
  bottom: 0;
  position: absolute;
  background: ${colors.active};
`;

const Search = styled(Input)`
  margin: ${spacing.space_m} 0 0 0;
  border-color: ${colors.grey5};
  line-height: 1.35;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${spacing.space_l};
`;

const TabRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${spacing.space_m};
  border-bottom: 4px solid ${colors.grey3};
`;

const TabItem = styled.div`
  flex: 1;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  > span > span {
    color: ${ifProp(
      { selected: true },
      colors.active,
      colors.secondary
    )} !important;
  }

  > span > svg {
    stroke: ${ifProp({ selected: true }, colors.active, colors.secondary)};
  }
`;

const ChatBlockWrapper = styled.div`
  margin-bottom: ${spacing.space_m};

  h4 {
    padding: 0;
    background-color: transparent;
    svg:last-child {
      top: 0px;
      left: -${spacing.space_l};
      cursor: default;
    }
  }
`;

const RelatedWrapper = styled(SimpleBox)`
  height: 100%;
  > h4:first-of-type {
    position: relative;
    background: ${colors.grey0};
    margin: 0px;
    padding: ${spacing.space_inset_l};

    > svg:last-child {
      position: absolute;
      top: ${spacing.space_l};
      right: ${spacing.space_l};
      cursor: pointer;
    }
  }
`;

const Categories = styled(SmallBox)`
  display: flex;
  align-items: center;
  padding: ${spacing.space_s} ${spacing.space_m};
  > span {
    flex: 1;
  }
  > svg {
    padding-top: 6px;
    margin-right: -10px;
  }
  border-color: ${colors.grey5};
`;

const CategoryDropdown = styled(Dropdown)`
  top: calc(100% + ${spacing.space_s});
  left: -1px;
  width: calc(100% + ${spacing.space_xs});
  padding-top: ${spacing.space_m};

  ul {
    overflow: visible;
    width: 100%;
    max-height: none;
  }
`;

const categoryIconColor = {
  related: colors.active,
  dateWarning: colors.danger,
  completed: colors.success,
  incomplete: colors.secondary,
  owner: colors.primary
};

const CategoryWrapper = styled.div`
  flex: 1;
  > span > span {
    color: ${switchProp("category", categoryIconColor)} !important;
  }
  ${ifProp(
    { category: "related" },
    css`
      > span > svg {
        stroke: ${colors.active};
      }
    `,
    null
  )}
`;

export {
  Count,
  Small,
  Body,
  Category,
  CategoryRow,
  CategoryItems,
  Selector,
  Search,
  TabContainer,
  TabRow,
  TabItem,
  ChatBlockWrapper,
  RelatedWrapper,
  Categories,
  CategoryDropdown,
  CategoryWrapper,
  ControlWrapper
};
