// @flow

import { connect } from "react-redux";
import React, { useMemo } from "react";
import * as R from "ramda";
import useClientDimensions from "react-client-dimensions";

import { Value, DueStatus } from "./styles";
import statusConstants, {
  ACTIVE,
  COMPLETED,
  CANCELED
} from "src/constants/status";
import {
  getChatroomType,
  isStatusDisabled,
  getChatroomManualArchived
} from "src/reducers";
import Icons from "src/icons";
import Archived from "./Archived";

import type { AppState, ConversationType } from "src/types";

type Props = {
  type: ConversationType,
  id: number,
  isOverDue: boolean,
  disabled: boolean,
  manuallyArchived: boolean,
  hideArchive?: boolean,
  location?: string
};

const Standard = ({
  id,
  type,
  isOverDue,
  disabled,
  manuallyArchived,
  hideArchive,
  location
}: Props) => {
  const { vw } = useClientDimensions();
  const status = useMemo(() => {
    if (type === "workflow" && disabled && R.includes(id, [-1, -2, 2])) {
      return {
        ...(statusConstants[`${id}`] || {}),
        text: ACTIVE,
        color: statusConstants[-1].color,
        textColor: statusConstants[-1].textColor
      };
    }
    if (type === "group" && id === -1) {
      return { ...(statusConstants[`${id}`] || {}), text: ACTIVE };
    }
    return statusConstants[`${id}`] || {};
  }, [id, type, disabled]);

  if (isOverDue && !disabled) {
    if (id === COMPLETED || id === CANCELED) {
      return (
        <Value
          color={status.color}
          textColor={status.textColor}
          location={location}
          vw={vw}
        >
          <DueStatus>{status.text}</DueStatus>
        </Value>
      );
    }
    return (
      <Value
        color={status.color}
        textColor={status.textColor}
        location={location}
      >
        <DueStatus>
          <div>{status.text}</div>
        </DueStatus>
        <Icons type="alarmClock" />
      </Value>
    );
  }

  return (
    <Value
      color={status.color}
      textColor={status.textColor}
      location={location}
      vw={vw}
    >
      {status.text}
      {manuallyArchived && !hideArchive ? <Archived /> : null}
    </Value>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  type: getChatroomType(app, `${props.roomId}`),
  disabled: isStatusDisabled(app, props.roomId),
  manuallyArchived: getChatroomManualArchived(app, props.roomId)
});

export default connect(mapStateToProps)(Standard);
