// @flow

import { useSelector } from "react-redux";
import React from "react";

import SelectionSingle from "./FieldItem";
import { sortConversation } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  showMetaData?: boolean,
  showFieldNames: boolean,
  fields: Array<number>,
  value: Array<number>,
  workflow?: ?number,
  sortBy: string,
  handleRemove: Function,
  onVersionClick?: Function,
  autoLinkedRooms?: number[],
  disabled: boolean,
  alwaysAllowManualLinks?: boolean
};

const SelectionMultiple = ({
  value,
  showMetaData,
  handleRemove,
  showFieldNames,
  fields,
  sortBy,
  disabled,
  onVersionClick,
  autoLinkedRooms = [],
  alwaysAllowManualLinks = false
}: Props) => {
  const sortedValues = useSelector(({ app }: { app: AppState }) =>
    sortConversation(app, sortBy, value)
  );

  return (
    <>
      {(sortedValues || []).map((conversation: number) => (
        <SelectionSingle
          key={conversation}
          value={conversation}
          autoLink={autoLinkedRooms.includes(conversation)}
          onVersionClick={onVersionClick}
          showMetaData={showMetaData}
          handleRemove={handleRemove}
          showFieldNames={showFieldNames}
          fields={fields}
          setDropdown={handleRemove}
          multiple
          disabled={disabled}
          alwaysAllowManualLinks={alwaysAllowManualLinks}
        />
      ))}
    </>
  );
};

export default SelectionMultiple;
