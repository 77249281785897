// @flow

import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const chatroom = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_CHAT_MENU:
      // Reset and show list of items
      return false;
    case atypes.HIDE_CHAT_MENU:
      // Reset
      return false;
    case atypes.SET_CHAT_MENU_COMPONENT:
      return true;
    default:
      return state;
  }
};

const activeChat = combineReducers({
  chatroom
});

export default activeChat;
