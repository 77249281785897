// @flow

import { connect } from "react-redux";
import React, { useState, useRef } from "react";
import usePortal from "react-useportal";
import { changePassword } from "src/actions/auth";
import { toggleChangePasswordModal } from "src/actions/modal";
import type { AppState } from "src/types";

import {
  passwordRequriements,
  validPasswordRegex
} from "src/constants/password";
import {
  Content as StyledContent,
  PrimaryButton,
  Overlay,
  SecondaryFooter,
  HeadingWrapper,
  Secondary,
  Form
} from "src/components/Manage/Reports/Modal/styles";
import Icon from "src/icons";
import { Input } from "src/styles/input";
import { FormGroup, InlineLabel } from "src/styles/form";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import * as colors from "src/styles/constants/colors";
import ButtonLoader from "src/components/ButtonLoader";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import {
  ErrorMessage,
  SuccessText,
  SuccessMessageContainer,
  CircleCheckContainer
} from "./styles";

type Props = {
  _toggleChangePasswordModal: Function,
  _changePassword: Function,
  closeMenu: Function,
  userEmail: string
};

const ChangePassword = ({
  _toggleChangePasswordModal,
  _changePassword,
  closeMenu,
  userEmail
}: Props) => {
  const { Portal } = usePortal();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordInputRef = useRef(null);

  const isValidPassword = validPasswordRegex.test(newPassword);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (confirmPassword === newPassword && isValidPassword) {
      _changePassword(newPassword);
      _toggleChangePasswordModal({ open: false });
    }
  };

  const closeModal = (event: any) => {
    event.preventDefault();
    _toggleChangePasswordModal({ open: false });
    closeMenu();
  };

  return (
    <Portal>
      <Overlay>
        <OutsideClickHandler onClickOutside={closeModal}>
          <StyledContent>
            <Form onSubmit={handleSubmit}>
              <HeadingWrapper>Change Password</HeadingWrapper>
              <ModalFormBody>
                <FormGroup>
                  <InlineLabel>Email</InlineLabel>
                  <Input type="email" value={userEmail} disabled />
                </FormGroup>
                <FormGroup>
                  <InlineLabel>Enter new password</InlineLabel>
                  <Input
                    type="password"
                    value={newPassword}
                    placeholder="New password"
                    onChange={e => setNewPassword(e.target.value)}
                    ref={passwordInputRef}
                  />
                  {!isValidPassword ? (
                    <ErrorMessage>{passwordRequriements}</ErrorMessage>
                  ) : null}
                  {isValidPassword ? (
                    <SuccessMessageContainer>
                      <SuccessText>{passwordRequriements}</SuccessText>
                      <CircleCheckContainer>
                        <Icon type="circleCheck" />
                      </CircleCheckContainer>
                    </SuccessMessageContainer>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <InlineLabel>Confirm new password</InlineLabel>
                  <Input
                    type="password"
                    value={confirmPassword}
                    placeholder="New password"
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  {confirmPassword && newPassword !== confirmPassword ? (
                    <ErrorMessage>(Password does not match)</ErrorMessage>
                  ) : null}
                </FormGroup>
              </ModalFormBody>
              <SecondaryFooter>
                <PrimaryButton
                  disabled={confirmPassword !== newPassword || !isValidPassword}
                  onClick={handleSubmit}
                >
                  <ButtonLoader type="submit" color={colors.blueLight}>
                    Change Password
                  </ButtonLoader>
                </PrimaryButton>
                <Secondary type="button" onClick={closeModal}>
                  Cancel
                </Secondary>
              </SecondaryFooter>
            </Form>
          </StyledContent>
        </OutsideClickHandler>
      </Overlay>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  userEmail: app.currentUser.email
});

export default connect(mapStateToProps, {
  _changePassword: changePassword,
  _toggleChangePasswordModal: toggleChangePasswordModal
})(ChangePassword);
