// @flow

import { connect } from "react-redux";
import React, { useRef, useMemo, useEffect } from "react";
import type { Header } from "@tanstack/react-table";

import {
  NestedTable,
  HeaderItem as StyledHeaderItem,
  LabelWrapper,
  NumberLabelWrapper,
  PicklistLabelWrapper,
  LabelIconWrapper,
  EmbeddedFieldsHeader
} from "./styles";
import Resizer from "./Resizer";
import Sort from "./Filter/Sort";
import User from "./Filter/User";
import Status from "./Filter/Status";
import FormHeaderItem from "./FormHeaderItem";
import CheckItemWithFilter from "./Filter/CheckItemWithFilter";
import Date from "./Filter/Date";
import Select from "./Filter/Select";
import useBoolean from "src/hooks/useBoolean";
import { getInstanceFilterId } from "src/reducers";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";
import { toggleFieldExpansion } from "src/actions/checklist";
import { getFieldUniqueValues } from "src/actions/workflows";
import { updateColumnWidth } from "src/actions/fieldSizeMap";
import ConversationHeaderItem from "./ConversationHeaderItem";
import Chatroom from "./Filter/Chatroom";

import type { AppState, FieldId, ColumnId, Action } from "src/types";

type Props = {
  id: string,
  column: ColumnId,
  label: string,
  type: string,
  settings: string,
  _toggleFieldExpansion: Function,
  getFieldUniqueValues: Function,
  fieldExpanded: ?boolean,
  setFilter: Function,
  toggleSortBy: Function,
  isSortedDesc: boolean,
  filter: Array<any>,
  childColumn: ?boolean,
  formIndex: ?number,
  fieldId: FieldId,
  header: Header,
  _updateColumnWidth: (columnId: ColumnId, width: string) => Action
};

const HeaderItem = ({
  id,
  column,
  label,
  type,
  settings,
  _toggleFieldExpansion,
  fieldExpanded,
  setFilter,
  toggleSortBy,
  isSortedDesc,
  filter,
  getFieldUniqueValues,
  childColumn,
  formIndex,
  fieldId,
  header,
  _updateColumnWidth
}: Props) => {
  const parentRef = useRef(null);
  const {
    value: dropdown,
    toggleBoolean: toggleDropdown,
    setFalse: closeDropdown
  } = useBoolean();

  // Update the widths of the columns
  useEffect(() => {
    _updateColumnWidth(header.column.id, header.getSize());
  }, [header.getSize()]);

  const settingsJSON = useMemo(() => {
    try {
      return JSON.parse(settings || "{}");
    } catch (error) {
      return {};
    }
  }, [settings]);

  // Open the filter dropdown only when the header item is clicked
  const handleHeaderClick = () => {
    toggleDropdown();
    getFieldUniqueValues(column, type);
  };

  switch (type) {
    case "user":
      return (
        <StyledHeaderItem
          width={header.column.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
          isResizing={header.column.getIsResizing()}
        >
          <Tooltip align="down" title={label}>
            <LabelWrapper>{label}</LabelWrapper>
          </Tooltip>
          <Icon type="triangleDown" />
          <Resizer header={header} />
          {dropdown && (
            <User
              column={`${column}`}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    case "conversation":
    case "chatPickList":
    case "workflow":
    case "task":
    case "group":
    case "childConversation":
    case "link":
      return settingsJSON.fields ? (
        <NestedTable>
          <thead>
            <tr>
              {fieldExpanded && typeof formIndex !== "number" ? (
                <EmbeddedFieldsHeader
                  style={{
                    maxWidth: header.column.getSize()
                  }}
                >
                  Embedded Fields
                </EmbeddedFieldsHeader>
              ) : null}
            </tr>
            <tr>
              <ConversationHeaderItem
                childColumn={childColumn}
                formIndex={formIndex}
                toggleDropdown={handleHeaderClick}
                parentRef={parentRef}
                label={label}
                dropdown={dropdown}
                column={column}
                settingsJSON={settingsJSON}
                _toggleFieldExpansion={_toggleFieldExpansion}
                fieldExpanded={!!fieldExpanded}
                setFilter={setFilter}
                filter={filter}
                toggleSortBy={toggleSortBy}
                fieldType={type}
                fieldId={fieldId}
                header={header}
              />
            </tr>
            {dropdown && (
              <Chatroom
                column={`${column}`}
                handleClose={toggleDropdown}
                parentRef={parentRef}
                setFilter={setFilter}
                filter={filter}
                toggleSortBy={toggleSortBy}
              />
            )}
          </thead>
        </NestedTable>
      ) : (
        <ConversationHeaderItem
          childColumn={childColumn}
          formIndex={formIndex}
          toggleDropdown={handleHeaderClick}
          parentRef={parentRef}
          label={label}
          dropdown={dropdown}
          column={column}
          setFilter={setFilter}
          filter={filter}
          toggleSortBy={toggleSortBy}
          fieldExpanded={!!fieldExpanded}
          fieldType={type}
          fieldId={fieldId}
          header={header}
        />
      );
    case "select":
      return (
        <StyledHeaderItem
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            <PicklistLabelWrapper>{label}</PicklistLabelWrapper>
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <Select
              column={`${column}`}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
              isSortedDesc={isSortedDesc}
            />
          )}
        </StyledHeaderItem>
      );
    case "date":
      return (
        <StyledHeaderItem
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            <PicklistLabelWrapper>{label}</PicklistLabelWrapper>
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <Date
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    case "text":
    case "number":
    case "pdf":
    case "file":
      return (
        <StyledHeaderItem
          width={header.getSize()}
          isResizing={header.column.getIsResizing()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label ?? ""}>
            {type === "number" ? (
              <NumberLabelWrapper>{label}</NumberLabelWrapper>
            ) : (
              <LabelWrapper>{label}</LabelWrapper>
            )}
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <CheckItemWithFilter
              column={`${column}`}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    case "form":
      return (
        <FormHeaderItem
          childColumn={childColumn}
          toggleDropdown={handleHeaderClick}
          parentRef={parentRef}
          label={label}
          column={column}
          closeDropdown={closeDropdown}
          toggleSortBy={toggleSortBy}
          dropdown={dropdown}
          settings={settingsJSON}
          setFilter={setFilter}
          filter={filter}
          header={header}
        />
      );
    case "owner":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            <LabelWrapper>{label}</LabelWrapper>
          </Tooltip>
          <LabelIconWrapper onClick={handleHeaderClick}>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <User
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    case "status":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            <LabelWrapper>{label}</LabelWrapper>
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <Status
              id={id}
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    case "dueDate":
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            <LabelWrapper>{label}</LabelWrapper>
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <Date
              column={column}
              handleClose={closeDropdown}
              parentRef={parentRef}
              setFilter={setFilter}
              filter={filter}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
    default:
      return (
        <StyledHeaderItem
          isResizing={header.column.getIsResizing()}
          width={header.getSize()}
          onClick={handleHeaderClick}
          ref={parentRef}
          type={type}
          childColumn={childColumn}
          formIndex={formIndex}
        >
          <Tooltip align="down" title={label}>
            {type === "number" ? (
              <NumberLabelWrapper>{label}</NumberLabelWrapper>
            ) : (
              <LabelWrapper>{label}</LabelWrapper>
            )}
          </Tooltip>
          <LabelIconWrapper>
            <Icon type="triangleDown" />
          </LabelIconWrapper>
          <Resizer header={header} />
          {dropdown && (
            <Sort
              column={`${column}`}
              handleClose={closeDropdown}
              parentRef={parentRef}
              toggleSortBy={toggleSortBy}
            />
          )}
        </StyledHeaderItem>
      );
  }
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  id: getInstanceFilterId(app),
  fieldExpanded: app.checklist.expandedEmbeddedFields[`${props.column}`]
});

export default connect(mapStateToProps, {
  _toggleFieldExpansion: toggleFieldExpansion,
  getFieldUniqueValues: getFieldUniqueValues,
  _updateColumnWidth: updateColumnWidth
})(HeaderItem);
