// @flow

import React from "react";
import { useSelector } from "react-redux";

import { Cell } from "../styles";
import { ParticipantCount } from "./styles";
import { ValueWrapper } from "../ChecklistItem/styles";
import User from "src/containers/user/picture/Small";
import Tooltip from "src/components/Tooltip";
import { size } from "src/constants/processInstanceColumns";
import { getUsersById, getFieldWidth } from "src/reducers";

import type { UID } from "src/types";

type Props = {
  selected: boolean,
  count: number,
  value: Array<UID>
};

const Members = ({ count, selected, value }: Props) => {
  const usersById = useSelector(({ app }) => getUsersById(app));
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, "members"));
  return (
    <Cell selected={selected} width={fieldWidth} minWidth={size.members}>
      <ValueWrapper isUserWrapper={true}>
        {(value || []).map(uid => (
          <Tooltip
            key={uid}
            title={usersById?.[uid]?.displayName || ""}
            description={usersById?.[uid]?.email || ""}
            placement="top"
          >
            <User uid={uid} key={uid} />
          </Tooltip>
        ))}
        <ParticipantCount>{count ? `+${count}` : ""}</ParticipantCount>
      </ValueWrapper>
    </Cell>
  );
};
export default Members;
