// @flow

import React, { useCallback } from "react";

import Single from "./Single";
import { Field, NumberInput, Done, NumberIcons } from "./styles";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import Icon from "src/icons";
import type {
  FieldId,
  ChecklistValue,
  RoomId,
  ChecklistField,
  ColumnId
} from "src/types";
import { extractChecklistValue, getPlaceholder } from "src/utils/checklist";
import { hasValue } from "src/utils";

type Props = {
  formId: ?number,
  checklistValue: ChecklistValue,
  setChecklistValue: Function,
  roomId: RoomId,
  columnId?: ColumnId,
  fieldId: FieldId,
  details: ChecklistField,
  promptCallback?: ?Function,
  edit?: boolean,
  settings?: Object,
  value?: any,
  setValue: Function,
  locked: boolean,
  handleChange?: Function,
  openEdit?: Function,
  closeEdit?: Function,

  isMandatory?: boolean
};

const Number = ({
  formId,
  checklistValue,
  setChecklistValue,
  roomId,
  columnId,
  fieldId,
  promptCallback,
  edit,
  settings,
  value,
  setValue,
  handleChange,
  openEdit,
  closeEdit,
  locked,
  isMandatory
}: Props) => {
  const placeholder = getPlaceholder(settings, "Enter Number");
  const min = settings && settings.min ? settings.min : null;
  const max = settings && settings.max ? settings.max : null;
  const step = settings && settings.step ? settings.step : null;

  const updateChecklistValue = useCallback(() => {
    const prevValueString = extractChecklistValue(checklistValue);
    const prevValue = hasValue(prevValueString)
      ? parseFloat(prevValueString)
      : null;

    const currentValue = hasValue(value) ? parseFloat(value) : null;

    if (prevValue !== currentValue) {
      setChecklistValue({
        roomId,
        id: fieldId,
        value: {
          value: currentValue,
          type: "number",
          checked: currentValue !== null
        },
        progress: true,
        formId,
        columnId
      });
    }
    if (promptCallback) promptCallback();
    if (closeEdit) {
      closeEdit();
    }
  }, [
    checklistValue,
    formId,
    columnId,
    value,
    roomId,
    fieldId,
    promptCallback
  ]);

  const forwardProps = {
    edit,
    update: updateChecklistValue,
    value,
    type: "number",
    openEdit,
    clickToEdit: true,
    disabled: locked,
    isMandatory
  };

  const { handleEnter } = useKeyDownHandlers(updateChecklistValue);

  const increment = () => {
    if (value !== null) {
      const newValue =
        step !== null ? parseInt(value) + step : parseInt(value) + 1;

      if (max !== null && newValue > max) {
        setValue(max);
      } else {
        setValue(newValue);
      }
    } else {
      const stepCount = step ? step : 1;
      const newValue = min !== null ? min : stepCount;
      setValue(newValue);
    }
  };

  const decrement = () => {
    if (value !== null) {
      const newValue =
        step != null ? parseInt(value) - step : parseInt(value) - 1;

      if (min !== null && newValue < min) {
        setValue(min);
      } else {
        setValue(newValue);
      }
    } else {
      const stepCount = step ? step : 1;
      const newValue = min !== null ? min : stepCount;
      setValue(newValue);
    }
  };

  return (
    <Single {...forwardProps}>
      <Field>
        <NumberInput
          type="number"
          placeholder={placeholder}
          value={value || ""}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          autoFocus
          onKeyDown={handleEnter}
        />
        <NumberIcons>
          <Icon type="increment" handleClick={increment} />
          <Icon type="decrement" handleClick={decrement} />
          <Done onClick={updateChecklistValue}>Done</Done>
        </NumberIcons>
      </Field>
    </Single>
  );
};

Number.defaultProps = {
  promptCallback: null,
  edit: false,
  settings: {},
  value: "",
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  locked: false
};

export default Number;
