// @flow

export const customerSuccessTeam = [
  "2Kro7kPQz4XDtZAJRQOJejMzNmg2",
  "RbcIuetAQAeIn7lm96wn7iYcHC32",
  "tuNlkbY8EkNf1wue2LhEpZkkqIk1",
  "ufSdNZUAM2dzS9g24dNE4e5oNnv2",
  "rCqMTLd7mzaDsGdALFeBtYt6InJ2"
];

export const ME_FILTER = "current-user";
