// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { FormGroup, FilterGroup, TileColor, Content } from "./styles";
import { Input } from "src/styles/input";
import {
  setHomeScreenBuilderAttributes,
  createTile,
  editTile
} from "src/actions/orgSettings";
import ProcessSelect from "src/containers/workflow/select";
import tileColors from "src/styles/constants/tileColors";

import type { AppState } from "src/types";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const StartNew = ({ modal, _setHomeScreenBuilderAttributes }: Props) => {
  const workflow1 = modal.workflow1 || null;
  const workflow2 = modal.workflow2 || null;
  const workflow3 = modal.workflow3 || null;
  const workflow4 = modal.workflow4 || null;

  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess1 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow1: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess2 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow2: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess3 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow3: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess4 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow4: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  return (
    <Content>
      <FormGroup>
        <label>Title</label>
        <Input
          type="text"
          placeholder="e.g. Pending Conversations"
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <label>
          Sub-title<span>(Optional)</span>
        </label>
        <Input
          type="text"
          placeholder="e.g. You have #num pending tasks"
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>

      <FormGroup>
        <label>Start New</label>
        <ProcessSelect
          value={workflow1}
          exclude={[workflow2, workflow3, workflow4]}
          handleChange={handleProcess1}
        />
      </FormGroup>

      <FormGroup>
        <label>Start New (Optional)</label>
        <ProcessSelect
          value={workflow2}
          exclude={[workflow1, workflow3, workflow4]}
          handleChange={handleProcess2}
        />
      </FormGroup>

      <FormGroup>
        <label>Start New (Optional)</label>
        <ProcessSelect
          value={workflow3}
          exclude={[workflow1, workflow2, workflow4]}
          handleChange={handleProcess3}
        />
      </FormGroup>

      <FormGroup>
        <label>Start New (Optional)</label>
        <ProcessSelect
          value={workflow4}
          exclude={[workflow1, workflow2, workflow3]}
          handleChange={handleProcess4}
        />
      </FormGroup>

      <FormGroup>
        <label>Tile color</label>
        <FilterGroup>
          {tileColors.map(color => (
            <TileColor
              onClick={() =>
                _setHomeScreenBuilderAttributes({ tileColor: color })
              }
              key={color}
              color={color}
              active={modal.tileColor === color}
            />
          ))}
        </FilterGroup>
      </FormGroup>
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes,
  _createTile: createTile,
  _editTile: editTile
})(StartNew);
