// @flow

import * as R from "ramda";
import type { Set as ImmutableSet } from "immutable";
import { List } from "immutable";

import type {
  State,
  AppState,
  RoomId,
  UID,
  ChecklistId,
  FieldIds,
  FieldId
} from "src/types";
import * as reducers from "src/reducers";
import * as checklistReducers from "src/reducers/checklist";

const getAppState = (state: State): AppState => state.app;

const getCurrentUserId = (state: State): string => state.app.currentUser.uid;

const getSignUpEmail = (state: State): string => state.app.signup.email;

const getRequestedChatRoom = (state: State): Object =>
  state.app.signin.requestedChatRoom;

const getRequestedPage = (state: State): Object =>
  state.app.signin.requestedPage;

const getRecentWorkflows = (state: State): ImmutableSet<number> =>
  state.app.workflow.recent;

export const getWorkflowInstances = (state: State) =>
  state.app.workflow.instancesById;

export const getWorkflowInstanceFilter = (state: State) =>
  reducers.getWorkflowInstanceFilter(state.app);

export const getPrincipalChecklist = (state: State) =>
  state.app.workflow.principalChecklist;

const getLastOrg = (state: State): number => state.app.orgs.last;
const getAllOrgs = (state: State) => state.app.orgs.all;
const getUser = (state: State) => state.app.currentUser;

const getDefaultOrg = (state: State): number =>
  reducers.getDefaultOrg(state.app);

const getMessageCount = (roomId: string) => (state: State) => {
  const room = state.app.chatRooms.byId[`${roomId}`];
  if (room) {
    return room.count || 0;
  }
  return 0;
};

const getChatroom = (roomId: string) => (state: State) =>
  state.app.chatRooms.byId[roomId];

const getLastRead = (state: State) => state.app.recentChats.lastRead;

const getFilter = (state: State) => state.app.chatRooms.filters.current;

const getSavedFilter = (state: State) => state.app.chatRooms.filters.saved;

const getLocation = (state: State) => state.location;

const getCurrentChatroom = (state: State) => state.app.chatRooms.current;

const getUserMembership = (state: State) => state.app.chatRooms.membership;

export const getRoomChecklistValues = (roomId: RoomId) => (state: State) =>
  state.app.workflowChecklists.values[roomId];

export const getChecklistFieldValue = (roomId: RoomId, fieldId: FieldId) => (
  state: State
) => reducers.getChecklistValue(state.app, fieldId, roomId);

export const getChecklistFieldById = (id: string) => (state: State) =>
  state.app.checklist.fields.byId.get(id);

export const getChecklistFieldByForm = (ids: string[]) => (state: State) =>
  R.pick(ids, state.app.checklist.fields.byForms);

export const getEmailState = (state: State) => state.app.email;

export const getUserEmailsFromIds = (idList: UID[]) => ({ app }: State) =>
  reducers.getUserEmailsFromIds(app, idList);

export const getFormValues = (state: State) => state.app.checklist.formValues;

export const getFormFieldValue = ({
  roomId,
  fieldId,
  formId
}: {
  roomId: RoomId,
  fieldId: FieldId,
  formId: FieldId
}) => (state: State) =>
  reducers.getChecklistFormValue(state.app, `${roomId}-${fieldId}-${formId}`);

export const getCurrentChecklistId = (state: State) =>
  reducers.getCurrentChecklistId(state.app);

export const getChecklistValues = (roomId: RoomId) => (state: State) => {
  return state.app.workflowChecklists.values[roomId];
};

export const getChecklistFields = (state: State, id: string): FieldIds =>
  state.app.checklist.fields.byChecklist.get(id) || List([]);

export const getChecklistFieldsById = (state: State) =>
  state.app.checklist.fields.byId;

export const getRoleId = (state: State, uid: UID) =>
  state.app.users.byId[uid]?.orgRoleId || null;

export const getUserGroups = (state: State, uid: UID) =>
  state.app.groups.byUsers[uid];

export const getAllFieldsBySections = (checklistId: ChecklistId) => (
  state: State
) => checklistReducers.getAllFieldsBySections(state.app.checklist, checklistId);

export const getChecklistByRoomId = (roomId: RoomId) => (state: State) =>
  state.app.chatRooms.attributes.checklists[roomId];

export const getDependentFieldsOfFields = (fields: number[]) => (
  state: State
) => checklistReducers.getDependentFieldsOfFields(state.app.checklist, fields);

export const getNecessaryFields = (checklistId: ChecklistId) => (
  state: State
) => checklistReducers.getNecessaryFields(state.app.checklist, checklistId);

export const getTopLevelFieldsToFetch = (
  checklistId: ChecklistId,
  roomId: RoomId
) => (state: State) =>
  checklistReducers.getTopLevelFieldsToFetch(
    state.app.checklist,
    checklistId,
    roomId
  );

export const getSectionFieldsToFetch = (
  checklistId: ChecklistId,
  sectionId: string,
  roomId: RoomId
) => (state: State) =>
  checklistReducers.getSectionFieldsToFetch(
    state.app,
    checklistId,
    sectionId,
    roomId
  );

export const getSubSectionFieldsToFetch = (
  checklistId: ChecklistId,
  sectionId: string,
  subSectionId: string,
  roomId: RoomId
) => (state: State) =>
  checklistReducers.getSubSectionFieldsToFetch(
    state.app,
    checklistId,
    sectionId,
    subSectionId,
    roomId
  );

export const getRoomFieldsValueStatus = (roomId: RoomId) => (state: State) =>
  checklistReducers.getRoomFieldsValueStatus(state.app.checklist, roomId);

export const getFieldsNeededToShowChecklist = (checklistId: ChecklistId) => (
  state: State
) =>
  checklistReducers.getFieldsNeededToShowChecklist(
    state.app.checklist,
    checklistId
  );

export const getOAuthAppName = (state: State) => state.location?.query?.app;

export const getFirstChecklist = (state: State) =>
  reducers.getFirstChecklist(state.app);

export const getOrgCloneState = (state: State) =>
  reducers.getOrgCloneState(state.app);

export const getRoleTitles = (state: State) =>
  reducers.getRoleTitles(state.app);

export const getOAuthState = (state: State) => state.app.oAuth;

export const getReportsById = (state: State) => state.app.reports.byId;

export const getFieldSizeMap = (state: State) => state.app.fieldSizeMap;

export const getChatroomChecklists = (state: State, roomId: string) =>
  state.app.chatRooms.attributes.checklists[roomId] || null;

export default getAppState;

export {
  getLastOrg,
  getDefaultOrg,
  getAllOrgs,
  getUser,
  getChatroom,
  getCurrentUserId,
  getRecentWorkflows,
  getMessageCount,
  getSignUpEmail,
  getRequestedChatRoom,
  getLastRead,
  getFilter,
  getSavedFilter,
  getLocation,
  getCurrentChatroom,
  getRequestedPage,
  getUserMembership
};
