// @flow

import React from "react";

import {
  PopoverHeader,
  PopoverBody,
  Text,
  Box,
  Skeleton,
  VStack,
  IconButton
} from "@chakra-ui/react";
import { IconWrapper, IconBox } from "../styles";
import Upload from "src/containers/chatroom/Upload";
import Icons from "src/icons";
import IntegrationItem from "./IntegrationItem";

import * as styles from "./styles";

import { fileSources } from "./index";
import type {
  SharepointIntegrationState,
  HandleIntegrationItemClick
} from "./index";
import type { RoomId, FieldId, UnifizeFile, ColumnId } from "src/types";
import { CloseIcon } from "@chakra-ui/icons";

type Props = {
  onClose: Function,
  onSourceChange: Function,
  setChecklistValue: Function,
  roomId: RoomId,
  fieldId: FieldId,
  columnId: ColumnId,
  formId: ?FieldId,
  location: ?string,
  multiple: boolean,
  files: UnifizeFile[],
  onIntegrationItemClick: HandleIntegrationItemClick,
  sharepointData: SharepointIntegrationState,
  promptCallback?: ?Function
};

const SourcePicker = ({
  onClose,
  onSourceChange,
  setChecklistValue,
  roomId,
  fieldId,
  columnId,
  formId,
  files,
  location,
  multiple,
  onIntegrationItemClick,
  sharepointData,
  promptCallback
}: Props) => (
  <>
    <PopoverHeader {...styles.popover.header.root}>
      <Text textStyle="popoverHeader">Pick Source</Text>

      <IconButton
        onClick={onClose}
        variant="transparent"
        icon={<CloseIcon />}
        {...styles.popover.header.closeButton}
      />
    </PopoverHeader>

    <PopoverBody {...styles.popover.body.root}>
      <VStack {...styles.popover.body.sourcePicker}>
        <IconWrapper onClick={() => onSourceChange(fileSources.native)}>
          <IconBox>
            <Icons type="attachClip" />
          </IconBox>

          <Text textStyle="popoverMenuItem">Unifize file store</Text>
        </IconWrapper>

        <Upload
          roomId={roomId}
          multiple={multiple}
          location="checklist-upload"
          fieldId={fieldId}
          columnId={columnId}
          formId={formId}
          value={files}
          setChecklistValue={
            location === "manage-view" ? setChecklistValue : null
          }
          render={openFileBrowser => (
            <IconWrapper onClick={openFileBrowser}>
              <IconBox>
                <Icons type="uploadCloud" />
              </IconBox>

              <Text textStyle="popoverMenuItem">Computer</Text>
            </IconWrapper>
          )}
          togglePopover={() => onSourceChange(fileSources.local)}
          multipleFiles={multiple ? true : false}
          onClose={onClose}
          promptCallback={promptCallback}
        />

        {sharepointData.fetching && (
          <Box width="100%" px="16px" py="8px">
            <Skeleton height="18px" width="100%" />
          </Box>
        )}

        {sharepointData.data && (
          <IntegrationItem
            name="Sharepoint"
            icon={<Icons type="sharepoint" />}
            onClick={onIntegrationItemClick}
            status={sharepointData.data.status}
          />
        )}
      </VStack>
    </PopoverBody>
  </>
);

export default SourcePicker;
