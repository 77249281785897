// @flow

import { connect } from "react-redux";
import React from "react";

import User from "./User";
import Parent from "./Parent";
import Status from "./Status";
import Priority from "./Priority";
import Checklist from "./Checklist";
import Date from "./Date";
import { setManageViewFilter } from "src/actions/workflows";

type Props = {
  column: string,
  value: any,
  _setManageViewFilter: Function,
  removeFilter: Function,
  fullColumnId: string
};

const FilterItem = ({ column, value, removeFilter, fullColumnId }: Props) => {
  const clearFilter = () => {
    removeFilter(fullColumnId ?? column, value);
  };

  let blanksFilterText = null;
  if (value === "null" || value === "notnull") {
    blanksFilterText = value === "null" ? "Blank" : "Non-blank";
  }

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(column)) {
    return (
      <Checklist
        column={column}
        value={value}
        handleClose={clearFilter}
        blanksFilterText={blanksFilterText}
        fullColumnId={fullColumnId ?? column}
      />
    );
  }

  switch (column) {
    case "creator":
    case "owner":
    case "members":
    case "completedBy":
      return (
        <User
          uid={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );
    case "parent":
      return (
        <Parent
          id={value}
          column={column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );
    case "status":
      return (
        <Status
          id={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );
    case "priority":
      return (
        <Priority value={value} column={column} handleClose={clearFilter} />
      );
    case "createdAt":
    case "completedAt":
    case "updatedAt":
    case "dueDate":
      return (
        <Date
          value={value}
          column={fullColumnId ?? column}
          handleClose={clearFilter}
          blanksFilterText={blanksFilterText}
        />
      );
    default:
      return <></>;
  }
};

export default connect(null, {
  _setManageViewFilter: setManageViewFilter
})(FilterItem);
