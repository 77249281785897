// @flow

import { connect } from "react-redux";
import React, { useEffect } from "react";
import * as R from "ramda";

import Single from "./Single";
import type { RoomId, DataStage } from "src/types";
import { getChecklistFieldValue } from "src/actions/checklist";
import { dataStages } from "src/constants";
import httpMethods from "src/constants/httpMethods";

export type AddForm = (templateId: number) => void;
export type RemoveForm = (templateId: number) => void;

type Props = {
  settings: Object,
  value: any,
  fieldId: number,
  checklistId: number,
  roomId: RoomId,
  locked: boolean,
  valueStatus: ?DataStage,
  getChecklistFieldValue: Function,
  setFieldValue: Function
};

const Form = ({
  setFieldValue,
  fieldId,
  settings,
  value,
  roomId,
  checklistId,
  locked,
  valueStatus,
  getChecklistFieldValue
}: Props) => {
  const multiple = (settings || {}).multiple || false;
  const selectedForms = (settings || {}).selectedForms || [];

  const fetching =
    !valueStatus ||
    [dataStages.idle, dataStages.fetching].includes(valueStatus);

  // For manage view, since the API does not have form field values
  useEffect(() => {
    if (R.isNil(valueStatus)) {
      getChecklistFieldValue(roomId, fieldId, true);
    }
  }, [valueStatus]);

  const addForm: AddForm = templateId => {
    setFieldValue({
      value: {
        templateId
      },
      httpMethod: httpMethods.patch
    });
  };

  const removeForm: RemoveForm = templateId => {
    setFieldValue({ value: templateId, httpMethod: httpMethods.delete });
  };

  if (!multiple) {
    return (
      <Single
        fetching={fetching}
        selectedForms={selectedForms}
        value={value}
        index={0}
        fieldId={fieldId}
        roomId={roomId}
        checklistId={checklistId}
        disabled={locked}
        addForm={addForm}
        removeForm={removeForm}
      />
    );
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      {(value || []).length > 0 ? (
        (value || [])
          .sort((a, b) => a - b)
          .map((id, index) => (
            <Single
              key={id}
              fetching={fetching}
              selectedForms={selectedForms}
              value={value}
              index={index}
              fieldId={fieldId}
              roomId={roomId}
              checklistId={checklistId}
              multiple
              last={index === value.length - 1}
              disabled={locked}
              addForm={addForm}
              removeForm={removeForm}
            />
          ))
      ) : (
        <Single
          fetching={fetching}
          selectedForms={selectedForms}
          value={value || []}
          fieldId={fieldId}
          index={0}
          roomId={roomId}
          checklistId={checklistId}
          multiple
          disabled={locked}
          addForm={addForm}
          removeForm={removeForm}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getChecklistFieldValue
};

export default connect(null, mapDispatchToProps)(Form);
