// @flow

import { connect } from "react-redux";

import ChatHistory from "src/components/messages/History";
import { getCurrentRoom } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  roomId: getCurrentRoom(app)
});

export default connect(mapStateToProps)(ChatHistory);
