// @flow

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { showFileUploadModal } from "src/actions/file";
import {
  getFileUploadModalStatus,
  getCurrentRoomId,
  getIsSrwMobile
} from "src/reducers";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";

import Overlay from "./Overlay";
import ChatHistory from "./CurrentChatroomHistory";
import ChatInput from "./CurrentChatInput";
import ChatroomHeader from "src/components/chatroom/Header";
import PreviewModal from "src/components/file/PreviewModal";
import RestrictedModal from "./RestrictedModal";
import { Current as StyledCurrent, Main, ChatArea } from "src/styles";
import Dock from "src/containers/CurrentRoomDock";

const Current = () => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);
  const mainContainerRef = useRef();

  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  const onDrop = acceptedFiles => {
    dispatch(showFileUploadModal({ viewId: roomId, files: acceptedFiles }));
  };

  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const dragAndDropProps = getRootProps();

  const showFilesPreviewModal = useSelector(({ app }) =>
    getFileUploadModalStatus(app, roomId)
  );

  const currentRoomRestricted = useSelector(
    ({ app }) =>
      app.chatRooms.currentRoomAccessStatus ===
      currentRoomAccessStatuses.restricted
  );

  return (
    <>
      <RestrictedModal />
      <StyledCurrent ref={currentRef}>
        {showFilesPreviewModal && <PreviewModal roomId={roomId} />}
        {!currentRoomRestricted && (
          <>
            <ChatroomHeader currentRef={currentRef} />

            <Main ref={mainContainerRef}>
              <ChatArea {...dragAndDropProps} isDragActive={isDragActive}>
                <ChatHistory />
                <ChatInput />
              </ChatArea>
              {!isSrwMobile && <Dock parentRef={mainContainerRef} />}
              <Overlay />
            </Main>
          </>
        )}
      </StyledCurrent>
    </>
  );
};

export default Current;
