// @flow

import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  UnorderedList,
  ListItem,
  HStack,
  Center,
  OrderedList,
  Button
} from "@chakra-ui/react";

import sharepointImg from "src/img/sharepoint-integration.png";

type Props = {
  isOpen: boolean,
  onClose: Function,
  onConfirm: Function
};

const SharepointAdminIntegrationModal = ({
  isOpen,
  onClose,
  onConfirm
}: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    sx={{
      maxWidth: "983px"
    }}
  >
    <ModalOverlay />
    <ModalContent
      sx={{
        maxWidth: "983px"
      }}
    >
      <ModalCloseButton
        sx={{
          bg: "transparent"
        }}
      />
      <ModalBody
        sx={{
          p: 6
        }}
      >
        <HStack
          sx={{
            width: "100%"
          }}
          spacing={0}
        >
          <VStack
            sx={{
              backgroundColor: "unifize_grey26",
              px: 8,
              py: 10
            }}
          >
            <img src={sharepointImg} />

            <VStack>
              <Text
                textStyle="heading"
                sx={{
                  lineHeight: "29px"
                }}
              >
                No more download, delete, upload to make changes!
              </Text>

              <UnorderedList
                textStyle="label"
                sx={{
                  pl: "15px"
                }}
              >
                <ListItem>
                  Edit documents directly using Microsoft online editor
                </ListItem>
                <ListItem>
                  Link the documents you already have in sharepoint
                </ListItem>
                <ListItem>
                  Approve and lock down document revisions in Unifize
                </ListItem>
              </UnorderedList>
            </VStack>
          </VStack>

          <Center
            sx={{
              flex: 1
            }}
          >
            <Center
              sx={{
                width: "375px"
              }}
            >
              <VStack
                sx={{
                  alignItems: "start"
                }}
                spacing={6}
              >
                <Text
                  textStyle="modalTitle"
                  sx={{
                    m: 0
                  }}
                >
                  How to Integrate Sharepoint
                </Text>

                <OrderedList
                  sx={{
                    pl: "20px"
                  }}
                >
                  <ListItem>
                    Ask your Office365 Admin to click on the connect button
                    below
                  </ListItem>
                  <ListItem>
                    Give permission for Unifize to access Sharepoint
                  </ListItem>
                  <ListItem>
                    Each user will have to connect their account when they first
                    try to access sharepoint via Unifize
                  </ListItem>
                </OrderedList>

                <Button
                  onClick={onConfirm}
                  sx={{ width: "100%" }}
                  variant="uPrimary"
                >
                  Connect Sharepoint / Onedrive{" "}
                </Button>
              </VStack>
            </Center>
          </Center>
        </HStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default SharepointAdminIntegrationModal;
