// @flow

import React, { useState } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import type {
  LinkedFieldValue,
  LinkedFieldSettings,
  ColumnId,
  WorkflowInstances
} from "src/types";
import { getMutualVersions } from "src/reducers";
import { updateChecklistFromManageView } from "src/actions/workflows";
import httpMethods from "src/constants/httpMethods";
import { groupVersionsTogether } from "src/components/Dock/Checklist/LinkedField/utils";

import SingleModal from "src/components/Dock/Checklist/LinkedField/SingleModal";
import MultiModal from "src/components/Dock/Checklist/LinkedField/MultiModal";

import Conversation from "./Conversation";

type Props = {
  autoNo: string,
  selected: boolean,
  value: LinkedFieldValue,
  settings: LinkedFieldSettings,
  openChecklistEditModal: Function,
  index: number,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  getMutualVersions: Function,
  setFieldValue: Function,
  columnId: ColumnId,
  process: WorkflowInstances,
  embeddedIndex?: number
};

const LinkedField = ({
  autoNo,
  selected,
  value = {},
  settings,
  openChecklistEditModal,
  index,
  fieldId,
  roomId,
  hideEdit,
  getMutualVersions,
  setFieldValue,
  columnId,
  process,
  embeddedIndex
}: Props) => {
  const { entities: { chatrooms = {} } = {}, result = [] } = value;

  const [modalState, setModalState]: [
    {
      open: boolean,
      roomId: ?number,
      edit: boolean
    },
    Function
  ] = useState({
    open: false,
    roomId: null,
    edit: false
  });

  const autoLinkedRooms = groupVersionsTogether(result, chatrooms)[1];
  const formattedValue = autoLinkedRooms.map(roomId => ({
    ...chatrooms[roomId].chatroom
  }));

  const modalSelectedRooms = modalState.roomId
    ? getMutualVersions(Object.values(chatrooms), modalState.roomId)
    : [];
  const modalSelectedRoomIds = modalSelectedRooms.map(
    item => `${item.chatroom.id}`
  );

  const handleVersionClick = versionValue => {
    const originChatroomId = result.find(roomId => {
      return chatrooms[roomId].chatroom.id === versionValue;
    });

    setModalState({
      open: true,
      edit: true,
      roomId: originChatroomId
    });
  };

  const closeModal = () => setModalState(prev => ({ ...prev, open: false }));

  const selectChatroom = value => {
    setFieldValue({
      roomId,
      value: {
        [fieldId]: value,
        type: "link",
        value
      },
      index,
      fieldId,
      httpMethod: httpMethods.patch
    });
  };

  return (
    <>
      <Conversation
        autoNo={autoNo}
        selected={selected}
        value={formattedValue}
        originalValue={value}
        multiple={settings.multiple}
        embeddedFields={settings.fields}
        openChecklistEditModal={openChecklistEditModal}
        index={index}
        embeddedIndex={embeddedIndex}
        fieldId={fieldId}
        roomId={roomId}
        hideEdit={hideEdit}
        autoLinkedRooms={autoLinkedRooms}
        onVersionClick={handleVersionClick}
        settings={settings}
        columnId={columnId}
        type="link"
        process={process}
      />

      {modalState.open &&
        !R.isNil(modalState.roomId) &&
        modalSelectedRooms.length > 0 &&
        (settings.multiple ? (
          <MultiModal
            open
            roomId={modalState.roomId}
            selectedRooms={modalSelectedRoomIds}
            // If autoLink is true, then the first value
            // will have `linkAllVersions` set to true
            autoLink={modalSelectedRooms[0].linkAllVersions}
            defaultEdit={modalState.edit}
            onSuccess={selectChatroom}
            onClose={closeModal}
          />
        ) : (
          <SingleModal
            open
            roomId={modalState.roomId}
            value={modalSelectedRooms[0]}
            autoLink={modalSelectedRooms[0].linkAllVersions}
            defaultEdit={modalState.edit}
            selectChatroom={selectChatroom}
            onClose={closeModal}
          />
        ))}
    </>
  );
};

const mapStateToProps = () => ({
  getMutualVersions
});

const mapDispatchToProps = {
  setFieldValue: updateChecklistFromManageView
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedField);
