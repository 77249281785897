// @flow

import { connect } from "react-redux";
import React, { useCallback, useState } from "react";

import Single from "./Single";
import MultiSelect from "./MultiSelect";
import { createConversation } from "src/actions/checklist";
import useBoolean from "src/hooks/useBoolean";
import { getNextSeqNo } from "src/actions/workflows";
import {
  getCurrentRoomId,
  getBehaviorByFormField,
  getChecklistFieldBehavior
} from "src/reducers";
import { dataStages } from "src/constants";

import type { RoomId, State, DataStage, ColumnId } from "src/types";

type Props = {
  formId: ?number,
  parentConversation: boolean,
  roomId: RoomId,
  columnId: ColumnId,
  fieldId: number,
  checklistId: number,
  seqNo: number,
  setChecklistValue: Function,
  _createConversation: Function,
  _getNextSeqNo: Function,
  currentRoomId: RoomId,
  settings: Object,
  value: any,
  setValue: Function,
  promptCallback?: ?Function,
  locked: boolean,
  isMandatory?: boolean,
  location?: string,
  valueStatus: DataStage,
  roomFieldFormId?: string,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>
};

const Conversation = ({
  formId,
  parentConversation,
  roomId,
  columnId,
  fieldId,
  seqNo,
  checklistId,
  setChecklistValue,
  _createConversation,
  _getNextSeqNo,
  currentRoomId,
  settings,
  value = [],
  setValue,
  promptCallback,
  locked,
  isMandatory,
  location,
  valueStatus,
  dependentOptions,
  currentBehavior
}: Props) => {
  const { value: newRoom, setValue: setNewRoom } = useBoolean();
  const [dropdown, setDropdown] = useState(false);

  const workflow = (settings || {}).workflow || null;
  const type = (settings || {}).type || "group";
  const multiple = (settings || {}).multiple || false;
  const showMetaData = (settings || {}).showMetaData || false;
  const showFieldNames = (settings || {}).showFieldNames || false;
  const fields = (settings || {}).fields || [];
  const sortBy = (settings || {}).sortBy || "added";

  const handleClose = useCallback(() => {
    setNewRoom(false);
    setDropdown(false);
  }, [setNewRoom, setDropdown]);

  const handleNewRoom = useCallback(() => {
    setNewRoom(true);
    setDropdown(false);

    if (workflow) {
      _getNextSeqNo(parseInt(workflow, 10));
    }
  }, [workflow, setNewRoom, setDropdown, _getNextSeqNo]);

  const handleCreate = useCallback(
    (title: ?string) => {
      let req: Object = {
        title,
        workflow,
        formId,
        type,
        fieldId,
        roomId,
        checklistId,
        multiple,
        selected: value
      };

      if (parentConversation) {
        req.parent = parseInt(currentRoomId, 10);
      }

      if (seqNo) {
        req.seqNo = seqNo;
      }

      _createConversation(req, location);
      handleClose();
    },
    [
      formId,
      _createConversation,
      handleClose,
      parentConversation,
      fieldId,
      roomId,
      checklistId,
      multiple,
      seqNo,
      value
    ]
  );

  if (!multiple) {
    return (
      <Single
        formId={formId}
        settings={settings}
        value={value}
        setValue={setValue}
        roomId={roomId}
        columnId={columnId}
        fieldId={fieldId}
        checklistId={checklistId}
        setChecklistValue={setChecklistValue}
        setNewRoom={setNewRoom}
        newRoom={newRoom}
        dropdown={dropdown}
        workflow={workflow}
        type={type}
        setDropdown={setDropdown}
        handleClose={handleClose}
        handleNewRoom={handleNewRoom}
        handleCreate={handleCreate}
        showMetaData={showMetaData}
        showFieldNames={showFieldNames}
        fields={fields}
        promptCallback={promptCallback}
        disabled={locked}
        isMandatory={isMandatory}
        dependentOptions={dependentOptions}
        currentBehavior={currentBehavior}
      />
    );
  }

  return (
    <MultiSelect
      formId={formId}
      settings={settings}
      value={value}
      setValue={setValue}
      roomId={roomId}
      columnId={columnId}
      fieldId={fieldId}
      checklistId={checklistId}
      setChecklistValue={setChecklistValue}
      setNewRoom={setNewRoom}
      newRoom={newRoom}
      dropdown={dropdown}
      setDropdown={setDropdown}
      workflow={workflow}
      type={type}
      handleClose={handleClose}
      handleNewRoom={handleNewRoom}
      handleCreate={handleCreate}
      showMetaData={showMetaData}
      showFieldNames={showFieldNames}
      sortBy={sortBy}
      fields={fields}
      promptCallback={promptCallback}
      disabled={locked}
      isMandatory={isMandatory}
      updating={valueStatus === dataStages.updating}
      dependentOptions={dependentOptions}
      currentBehavior={currentBehavior}
    />
  );
};

const mapStateToProps = (
  { app }: State,
  { roomFieldFormId, fieldId, roomId }: Props
) => {
  const behavior = roomFieldFormId
    ? getBehaviorByFormField(app, roomFieldFormId)
    : getChecklistFieldBehavior(app, { roomId, fieldId });

  return {
    seqNo: app.workflow.nextSeqNo,
    currentRoomId: getCurrentRoomId(app),
    dependentOptions: behavior.options,
    currentBehavior: behavior.current
  };
};

Conversation.defaultProps = {
  promptCallback: null
};

export default connect(mapStateToProps, {
  _createConversation: createConversation,
  _getNextSeqNo: getNextSeqNo
})(Conversation);
