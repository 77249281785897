// @flow
import React, { useEffect, useCallback } from "react";

import {
  PopupContainer,
  closeIcon,
  popupHeader,
  DeleteTextContainer
} from "./styles";
import Icon from "src/components/Icon";
import * as colors from "src/styles/constants/colors";
import { iconSize } from "src/styles/constants/size";
import { HStack, Button, Flex, Box, Text } from "@chakra-ui/react";
import appLocation from "src/constants/location";

type Props = {
  isOpen: boolean,
  setConfirmation: Function,
  name: string,
  handleRemove: Function,
  handleDelete: Function,
  filename: string,
  location?: string
};

const Modal = ({
  isOpen,
  name,
  handleRemove,
  handleDelete,
  setConfirmation,
  filename,
  location
}: Props) => {
  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        setConfirmation(false);
      }
    },
    [setConfirmation]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const removeFile = () => {
    handleRemove(name);
    setConfirmation(false);
  };

  const deleteFile = () => {
    handleDelete(name);
    setConfirmation(false);
  };

  const fileName = filename.slice(0, -4);
  const extension = filename.slice(-3);

  return (
    isOpen && (
      <PopupContainer>
        <Flex {...popupHeader}>
          <Flex sx={DeleteTextContainer}>
            <Text
              as="span"
              sx={{
                display: "flex",
                textOverflow: "ellipsis"
              }}
            >
              {" "}
              {location === appLocation.filesModal ? (
                <>
                  Are you sure you want to delete &quot;
                  <Text
                    as="span"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "50%",
                      display: "inline-block"
                    }}
                  >
                    {`${fileName}`}{" "}
                  </Text>
                  {`.${extension}`}&quot;
                </>
              ) : (
                <>Are you sure you want to remove this file?</>
              )}
            </Text>
          </Flex>
          <Box
            as="button"
            onClick={() => setConfirmation(false)}
            {...closeIcon}
          >
            <Icon icon="close" color={colors.primary} size={iconSize.space_m} />
          </Box>
        </Flex>
        <HStack
          mt={-4}
          sx={
            location === appLocation.filesModal && {
              flexDirection: "row-reverse",
              gap: "0.5rem"
            }
          }
        >
          <Button colorScheme="red" onClick={deleteFile} size="xs">
            Delete
          </Button>
          <Button
            onClick={removeFile}
            size="xs"
            colorScheme="red"
            variant="outline"
          >
            Remove from checklist
          </Button>
        </HStack>
      </PopupContainer>
    )
  );
};

export default Modal;
