// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import { AdditionalFilters as StyledAdditionalFilters } from "./styles";
import FilterItem from "src/components/Manage/Workflow/Instances/FilterItem";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  filter: WorkflowInstanceFilter
};

const AdditionalFilters = ({ filter }: Props) => {
  const filterParams = R.omit(["id", "page", "sort", "search"], filter);

  return (
    <StyledAdditionalFilters>
      {R.map(
        (column: string) =>
          R.map(
            (value: string) => (
              <FilterItem key={value} column={column} value={value} />
            ),
            filterParams[column] || []
          ),
        R.keys(filterParams)
      )}
    </StyledAdditionalFilters>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.workflow.additionalFilters
});

export default connect(mapStateToProps)(AdditionalFilters);
