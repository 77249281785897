// @flow

import React, { useState } from "react";
import { Flex, Avatar, Text, List, ListItem } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { getGroup, getUser } from "src/reducers";
import * as styles from "./styles";
import Icon from "src/icons";

import type { UID } from "src/types";

type GroupMemberProps = {
  id: UID
};

const GroupMember = ({ id }: GroupMemberProps) => {
  const user = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;

  return (
    <ListItem sx={styles.GroupMember}>
      <Avatar
        sx={styles.UserAvatar}
        size="sm"
        name={displayName}
        src={photoUrl}
      />
      <Flex sx={styles.FlexColumn}>
        <Text isTruncated sx={styles.Name}>
          {displayName}
        </Text>
        <Text isTruncated sx={styles.LightGreyText}>
          {email}
        </Text>
      </Flex>
    </ListItem>
  );
};

type GroupProps = {
  id: number
};

const Group = ({ id }: GroupProps) => {
  const group = useSelector(({ app }) => getGroup(app, id));
  const { title, members, photoUrl } = group;

  const [showMembers, setShowMembers] = useState(false);

  return (
    <Flex sx={styles.FlexColumn}>
      <Flex>
        <Flex>
          <Icon
            type="groupAvatar"
            text={title}
            size="sm"
            photoUrl={photoUrl}
            id={id}
          />
          <Flex sx={styles.FlexColumn}>
            <Text isTruncated sx={styles.Name}>
              {title}
            </Text>
            <Text sx={styles.LightGreyText}>
              {members.length} {members.length > 1 ? "members" : "member"}
            </Text>
          </Flex>
        </Flex>

        <Flex
          onClick={e => {
            e.stopPropagation();
            setShowMembers(prev => !prev);
          }}
          sx={showMembers ? styles.ActiveArrowIcon : styles.ArrowIcon}
        >
          {showMembers ? (
            <ChevronUpIcon boxSize={5} />
          ) : (
            <ChevronDownIcon boxSize={5} />
          )}
        </Flex>
      </Flex>

      {showMembers && (
        <List>
          {members.map(user => {
            return <GroupMember key={user} id={user} />;
          })}
        </List>
      )}
    </Flex>
  );
};

export default Group;
