// @flow

import React from "react";
import useClientDimensions from "react-client-dimensions";

import NameOrEmail from "../NameOrEmail";
import Picture from "../picture";
import Icon from "src/icons";

import type { UID, UnifizeUser } from "src/types";

type Props = {
  uid: UID,
  user: UnifizeUser,
  noTooltip?: boolean,
  hideName?: boolean,
  location?: string,
  isActive?: boolean,
  isCurrentUser?: boolean
};

const ImageLabel = ({
  uid,
  user,
  noTooltip,
  hideName,
  isCurrentUser = false
}: Props) => (
  <>
    {isCurrentUser ? <Icon type="me" /> : <Picture uid={uid} user={user} />}
    {!hideName && (
      <NameOrEmail
        uid={uid}
        user={user}
        noTooltip={noTooltip}
        isCurrentUser={isCurrentUser}
      />
    )}
  </>
);

ImageLabel.defaultProps = {
  noTooltip: false,
  hideName: false
};

export default ImageLabel;
