// @flow

import React, { useState, useEffect, useCallback } from "react";

import {
  Settings as StyledFile,
  FormGroup,
  Label,
  Sort as StyledSort
} from "./styles";
import FileSelect from "./FileSelect";
import Checkbox from "src/components/Checkbox.old";
import Radio from "src/components/Radio";
import { Input } from "src/styles/input";
import type { FileFieldSettings } from "src/types";

type Props = {
  type: string,
  settings: FileFieldSettings,
  saveSettings: Function
};

const File = ({ settings, saveSettings }: Props) => {
  const [placeholder, setPlaceHolder] = useState("");
  const [multiple, setMultiple] = useState(false);
  const [preview, setPreview] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [defaultFile, setDefaultFile] = useState([]);
  const [suppressDefaultNotification, setNotifyDefaultValue] = useState(false);

  useEffect(() => {
    if (settings) {
      setPlaceHolder(settings.placeholder);
      setMultiple(settings.multiple);
      setPreview(settings.preview);
      setSortBy(settings.sortBy || "name");
      setDefaultFile(settings.defaultFile || []);
      setNotifyDefaultValue(settings.suppressDefaultNotification || false);
    }
  }, [setPlaceHolder, setMultiple]);

  useEffect(() => {
    saveSettings({
      placeholder,
      multiple,
      preview,
      sortBy,
      defaultFile,
      suppressDefaultNotification
    });
  }, [
    placeholder,
    multiple,
    preview,
    sortBy,
    defaultFile,
    suppressDefaultNotification
  ]);

  const handleText = useCallback(
    (event: any) => {
      setPlaceHolder(event.target.value);
    },
    [setPlaceHolder]
  );

  const handleSort = useCallback(
    (newSort: string) => {
      setSortBy(newSort);
    },
    [setSortBy]
  );

  const handleMultiple = useCallback(
    (event: any) => {
      setMultiple(event.target.checked);
    },
    [setMultiple]
  );

  const handlePreview = useCallback(
    (event: any) => {
      setPreview(event.target.checked);
    },
    [setPreview]
  );

  const handleSuppressDefaultNotification = useCallback(
    (event: any) => {
      setNotifyDefaultValue(event.target.checked);
    },
    [setNotifyDefaultValue]
  );

  return (
    <StyledFile>
      <FormGroup>
        <Label>Placeholder</Label>
        <Input
          type="text"
          value={placeholder}
          placeholder=""
          onChange={handleText}
          autoFocus
        />
      </FormGroup>

      <FormGroup>
        <Checkbox
          handleChange={handleMultiple}
          checked={multiple}
          id="multiple"
        >
          Allow multiple
        </Checkbox>
      </FormGroup>

      <FormGroup>
        <Checkbox handleChange={handlePreview} checked={preview} id="preview">
          Show full preview
        </Checkbox>
      </FormGroup>

      <StyledSort>
        <strong>SortBy:</strong>
        <div>
          <Radio
            id="sortFilesByDate"
            checked={sortBy === "date"}
            handleChange={() => handleSort("date")}
          />
          <label>Date added</label>
        </div>

        <div>
          <Radio
            id="sortFilesByName"
            checked={sortBy === "name"}
            handleChange={() => handleSort("name")}
          />
          <label>Name</label>
        </div>
      </StyledSort>

      <FormGroup>
        <strong>Default file</strong>
        <FileSelect files={defaultFile} handleFiles={setDefaultFile} />
      </FormGroup>

      <FormGroup>
        <Checkbox
          handleChange={handleSuppressDefaultNotification}
          checked={suppressDefaultNotification}
          id="suppressDefaultNotification"
        >
          Suppress notifications on default values
        </Checkbox>
      </FormGroup>
    </StyledFile>
  );
};

export default File;
