// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { StartNew as StyledStartNew, Button } from "./styles";
import Name from "src/containers/workflow/Name";
import { setNewWorkflow } from "src/actions/chatroom";
import useBoolean from "src/hooks/useBoolean";
import AddChatroom from "src/containers/chatroom/creator";

import type { HomescreenTile } from "src/types";

type Props = {
  rooms: Array<number>,
  tile: HomescreenTile,
  _setNewWorkflow: Function
};

const StartNew = ({ tile, _setNewWorkflow }: Props) => {
  const {
    value: newConversation,
    toggleBoolean: toggleConversation
  } = useBoolean();

  const { workflow1, workflow2, workflow3, workflow4 } = tile.settings;

  const handleConversation = useCallback(
    (id: number) => {
      _setNewWorkflow(id);
      toggleConversation();
    },
    [_setNewWorkflow]
  );

  const tileSize = [workflow1, workflow2, workflow3, workflow4].filter(
    w => w && w !== null
  ).length;

  let tile1,
    tile2,
    tile3,
    tile4 = "1";

  switch (tileSize) {
    case 1:
      tile1 = "2";
      break;
    case 2:
      tile1 = "1";
      tile2 = "1";
      break;
    case 3:
      tile1 = "1";
      tile2 = "1";
      tile3 = "2";
      break;
    case 4:
      tile1 = "1";
      tile2 = "1";
      tile3 = "1";
      tile4 = "1";
      break;
    default:
  }

  return (
    <>
      <StyledStartNew
        tile1={tile1}
        tile2={tile2}
        tile3={tile3}
        tile4={tile4}
        color={tile.settings.tileColor}
      >
        {tile.title && <h4>{tile.title}</h4>}
        <div>
          {workflow1 && (
            <Button type="button" onClick={() => handleConversation(workflow1)}>
              <Name id={workflow1} />
            </Button>
          )}

          {workflow2 && (
            <Button type="button" onClick={() => handleConversation(workflow2)}>
              <Name id={workflow2} />
            </Button>
          )}

          {workflow3 && (
            <Button type="button" onClick={() => handleConversation(workflow3)}>
              <Name id={workflow3} />
            </Button>
          )}

          {workflow4 && (
            <Button type="button" onClick={() => handleConversation(workflow4)}>
              <Name id={workflow4} />
            </Button>
          )}
        </div>
      </StyledStartNew>
      {newConversation && (
        <AddChatroom
          show={newConversation}
          position="center"
          modalId="chatroomSearch"
          handleClose={toggleConversation}
          hidePinTo
        />
      )}
    </>
  );
};

export default connect(null, { _setNewWorkflow: setNewWorkflow })(StartNew);
