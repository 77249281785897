// @flow

import { backendUrl } from "src/config/firebase";
import { findMePrefixIndex } from "src/utils/reports";

import { ME_FILTER } from "src/constants/users";

import type { Report } from "src/types";

/**
 * Creates a report from a process
 *
 * @param {Object} report - data to create a new report
 * @returns {Object} - newly created report
 *
 */
const createReport = async (report: Report) => {
  const updatedReport = { ...report };
  const updatedFilters = {};
  const updatedFieldSizeMap = {};

  // Update the saved filters
  for (const [key, value] of Object.entries(updatedReport.filters)) {
    let targetIndex = -1;

    // Convert all "me-uid" filter values to "current-user"
    if (Array.isArray(value)) {
      targetIndex = findMePrefixIndex(updatedReport.filters[key]);
    }

    if (key.includes("-")) {
      // Replacing because dashes in property names of embedded field filters
      // were being removed when saving in firestore
      const replaceWithArrow = key.split("-").join(">");
      updatedFilters[replaceWithArrow] = value;
      if (targetIndex !== -1) {
        updatedFilters[replaceWithArrow][targetIndex] = ME_FILTER;
      }
    } else {
      updatedFilters[key] = value;
      if (targetIndex !== -1) {
        updatedFilters[key][targetIndex] = ME_FILTER;
      }
    }
  }

  // Update the field widths for the report
  for (const [key, value] of Object.entries(
    updatedReport.settings.fieldSizeMap
  )) {
    if (key.includes("-")) {
      // Replacing because dashes in property names of embedded field filters
      // were being removed when saving in firestore
      const replaceWithArrow = key.split("-").join(">");
      updatedFieldSizeMap[replaceWithArrow] = value;
    } else {
      updatedFieldSizeMap[key] = value;
    }
  }

  updatedReport["filters"] = updatedFilters;
  updatedReport["settings"]["fieldSizeMap"] = updatedFieldSizeMap;

  updatedReport["settings"]["expansionState"] = report.settings?.expansionState;

  const response = await fetch(`${backendUrl}/report/process`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(updatedReport)
  });

  return response.json();
};

/**
 * Fetch all existing reports
 *
 * @returns {Array<Object>} List of existing reports
 */
const fetchAllReports = async () => {
  const response = await fetch(`${backendUrl}/report/process`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });
  return response.json();
};

/**
 * Update the given report
 *
 * @param {Object} report to be updated
 * @returns {HTTP status code} 204 for success
 */
const updateReport = async (report: Object) => {
  const updatedReport = { ...report };
  const updatedFilters = {};
  for (const [key, value] of Object.entries(updatedReport.filters)) {
    let targetIndex = -1;

    if (Array.isArray(value)) {
      targetIndex = findMePrefixIndex(updatedReport.filters[key]);
    }

    if (key.includes("-")) {
      // Replacing because dashes in property names of embedded field filters
      // were being removed when saving in firestore
      const replaceWithArrow = key.split("-").join(">");
      updatedFilters[replaceWithArrow] = value;
      if (targetIndex !== -1) {
        updatedFilters[replaceWithArrow][targetIndex] = ME_FILTER;
      }
    } else {
      updatedFilters[key] = value;
      if (targetIndex !== -1) {
        updatedFilters[key][targetIndex] = ME_FILTER;
      }
    }
  }

  updatedReport["filters"] = updatedFilters;
  const response = await fetch(`${backendUrl}/report/process/${report.id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(updatedReport)
  });
  return response;
};

/**
 * Delete the report with the given ID
 * @param an object containing the report ID to delete
 * @returns a response containing success/failure of the action (Success status code: 204)
 */
const deleteReport = async (deleteRequest: { reportId: string }) => {
  const response = await fetch(
    `${backendUrl}/report/process/${deleteRequest.reportId}-report`,
    {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-type": "application/json"
      }
    }
  );

  return response;
};

export { createReport, fetchAllReports, updateReport, deleteReport };
