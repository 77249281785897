// @flow

import * as atypes from "../constants/actionTypes";

import type {
  Action,
  UnifizeFile,
  Folder,
  RoomId,
  FieldId,
  FileDescription,
  FileUpload,
  ProcessTemplateId,
  ColumnId
} from "../types";

const uploadFile = (
  data: Object,
  folderId?: number,
  currentFile?: UnifizeFile,
  description?: string
) => ({
  type: atypes.UPLOAD_FILE_REQUEST,
  payload: { data, folderId, currentFile, description }
});

const downloadFile = ({
  roomId,
  location,
  name,
  originalName,
  generation,
  versionId
}: {
  roomId?: RoomId,
  location: string,
  name: string,
  originalName: string,
  generation: number,
  versionId: ?string
}) => ({
  type: atypes.DOWNLOAD_FILE_REQUEST,
  payload: {
    roomId,
    name,
    originalName,
    generation,
    versionId,
    analytics: {
      location
    }
  }
});

const closeDownloadPreventedModal = () => ({
  type: atypes.CLOSE_DOWNLOAD_PREVENTED_MODAL,
  payload: {}
});

const deleteFile = (name: string) => ({
  type: atypes.DELETE_FILE_REQUEST,
  payload: { name }
});

const startFileSync = (snapshot: Object) => ({
  type: atypes.START_FILE_SYNC,
  payload: { snapshot }
});

const fileSyncError = (error: Object) => ({
  type: atypes.LISTEN_FILE_CHANGE_FAILURE,
  payload: { error }
});

const cancelFileSync = () => ({
  type: atypes.STOP_LISTEN_FILE_CHANGE,
  payload: {}
});

const listenFileChange = () => ({
  type: atypes.LISTEN_FILE_CHANGE,
  payload: {}
});

const createFolder = (name: string) => ({
  type: atypes.CREATE_FOLDER_REQUEST,
  payload: { name }
});

const expandFolder = (folder: Folder) => ({
  type: atypes.EXPAND_FOLDER,
  payload: folder
});

const goToFolder = (folder: Folder) => ({
  type: atypes.GOTO_FOLDER,
  payload: folder
});

const browseInFolder = (folder: Folder) => ({
  type: atypes.BROWSE_IN_FOLDER,
  payload: folder
});

const browseBackFolder = (folder: Folder) => ({
  type: atypes.BROWSE_BACK_FOLDER,
  payload: folder
});

const cancelFileMove = (): Action => ({
  type: atypes.STOP_MOVE_FILE_PROCESS,
  payload: {}
});

const moveFileRequest = (file: UnifizeFile) => ({
  type: atypes.START_MOVE_FILE_PROCESS,
  payload: file
});

const moveFolderRequest = (folder: Folder) => ({
  type: atypes.START_MOVE_FOLDER_PROCESS,
  payload: folder
});

const moveFile = (folderId?: number): Action => ({
  type: atypes.MOVE_FILE_REQUEST,
  payload: { folderId }
});

const moveFolder = (folderId?: number): Action => ({
  type: atypes.MOVE_FOLDER_REQUEST,
  payload: { folderId }
});

const fetchFolder = (id?: number): Action => ({
  type: atypes.FETCH_FOLDER_REQUEST,
  payload: { id }
});

const deleteFolder = (folderId: number): Action => ({
  type: atypes.DELETE_FOLDER_REQUEST,
  payload: { folderId }
});

const cloneFile = (file: UnifizeFile): Action => ({
  type: atypes.CLONE_FILE_REQUEST,
  payload: { file }
});

const showFileMenu = (name: string, type: string = "context"): Action => ({
  type: atypes.SHOW_FILE_MENU,
  payload: { name, type }
});

const hideFileMenu = (): Action => ({
  type: atypes.HIDE_FILE_MENU,
  payload: {}
});

const showFolderMenu = (id: number): Action => ({
  type: atypes.SHOW_FOLDER_MENU,
  payload: { id }
});

const hideFolderMenu = (): Action => ({
  type: atypes.HIDE_FOLDER_MENU,
  payload: {}
});

const startPinProcess = (file: UnifizeFile): Action => ({
  type: atypes.START_PIN_FILE_PROCESS,
  payload: file
});

const stopPinProcess = (): Action => ({
  type: atypes.STOP_PIN_FILE_PROCESS,
  payload: {}
});

const pinFile = (description: string): Action => ({
  type: atypes.PIN_FILE_REQUEST,
  payload: { description }
});

const unpinFile = (fileName: string): Action => ({
  type: atypes.UNPIN_FILE_REQUEST,
  payload: fileName
});

const openViewer = ({
  roomId,
  location,
  type,
  name,
  generation,
  versionId,
  mimeType
}: {
  roomId: RoomId,
  location: string,
  type: string,
  name: string,
  generation: number,
  versionId?: string,
  mimeType: ?string
}): Action => ({
  type: atypes.OPEN_FILE_VIEWER_REQUEST,
  payload: {
    roomId,
    type,
    name,
    generation,
    versionId,
    mimeType,
    analytics: {
      location
    }
  }
});

const closeViewer = (): Action => ({
  type: atypes.CLOSE_FILE_VIEWER,
  payload: {}
});

const changeFileTab = (name: string): Action => ({
  type: atypes.FILE_TAB_CHANGE,
  payload: { name }
});

const uploadFileToChatroom = (
  dispatch: Function,
  location: string,
  data: Object,
  roomId: number,
  description?: string | FileDescription,
  currentFile?: UnifizeFile
): Action => ({
  type: atypes.UPLOAD_FILE_TO_CHAT_REQUEST,
  payload: {
    data,
    roomId,
    description,
    currentFile,
    filename: data.name,
    dispatch,
    analytics: {
      location
    }
  }
});

const getFile = (name: string) => async (
  dispatch: Function,
  getState: Function
) => {
  const { files } = getState().app;

  if (files.byId[name]) return;

  return dispatch({
    type: atypes.GET_FILE_REQUEST,
    payload: { name }
  });
};

const searchFile = (searchText: string) => ({
  type: atypes.SEARCH_FILE_REQUEST,
  payload: { searchText }
});

const changeFilter = (filter: string) => ({
  type: atypes.FILE_FILTER_CHANGE,
  payload: { filter }
});

const goToMyDrive = () => (dispatch: Function, getState: Function) => {
  const { myDrive } = getState().app.files;
  return dispatch({
    type: atypes.GOTO_MY_DRIVE,
    payload: myDrive
  });
};

const goToRootFolder = () => ({
  type: atypes.GOTO_ROOT_FOLDER,
  payload: {}
});

const showNotificationMenu = (name: string): Action => ({
  type: atypes.SHOW_FILE_NOTIFICATION_MENU,
  payload: { name }
});

const hideNotificationMenu = (): Action => ({
  type: atypes.HIDE_FILE_NOTIFICATION_MENU,
  payload: {}
});

const startFileRevision = (file: UnifizeFile): Action => ({
  type: atypes.START_FILE_REVISION,
  payload: { file }
});

const cancelFileRevision = (): Action => ({
  type: atypes.CANCEL_FILE_REVISION,
  payload: {}
});

const changeSorting = (sortBy: "name" | "viewed" | "modified"): Action => ({
  type: atypes.CHANGE_FILE_SORTING,
  payload: { sortBy }
});

const openSortMenu = (isOpen: boolean): Action => ({
  type: atypes.TOGGLE_FILE_SORTMENU,
  payload: { isOpen }
});

const restoreVersion = (name: string, generation: number): Action => ({
  type: atypes.RESTORE_FILE_VERSION_REQUEST,
  payload: { name, generation }
});

const clearFileUploadState = (roomId: number): Action => ({
  type: atypes.CLEAR_FILE_UPLOAD_STATE,
  payload: { roomId }
});

const showManageFile = (payload: { query?: string }): Action => ({
  type: atypes.SET_FILE_REQUEST,
  payload
});

const closeVideoPlayer = () => ({
  type: atypes.CLOSE_VIDEO_PLAYER,
  payload: {}
});

const searchConversationFile = (searchText: string) => ({
  type: atypes.SEARCH_FILES_REQUEST,
  payload: { searchText }
});

const uploadFileToChecklist = ({
  fileData,
  roomId,
  storageFileName,
  fieldId,
  columnId,
  value,
  multiple,
  location,
  dispatch,
  setChecklistValue,
  formId
}: {
  fileData: Object,
  roomId: number,
  storageFileName: string,
  fieldId: number,
  columnId: ColumnId,
  value: Array<string>,
  multiple: boolean,
  location: string,
  dispatch: Function,
  setChecklistValue?: Function,
  formId?: number
}) => ({
  type: atypes.UPLOAD_FILE_TO_CHECKLIST_REQUEST,
  payload: {
    fileData,
    roomId,
    storageFileName,
    fieldId,
    columnId,
    value: value ? value : [],
    multiple,
    dispatch,
    formId,
    setChecklistValue,
    analytics: { location }
  }
});

export const uploadFileInDropdown = (fileData: Object, dispatch: Function) => ({
  type: atypes.UPLOAD_FILE_IN_DROPDOWN_REQUEST,
  payload: { file: fileData, name: fileData.name, dispatch }
});

export const clearFileUploadDropdownError = () => ({
  type: atypes.CLEAR_DROP_DOWN_FILE_UPLOAD_ERROR,
  payload: {}
});

export const getFilePreview = (payload: { name: string, roomId: RoomId }) => ({
  type: atypes.GET_FILE_PREVIEW_REQUEST,
  payload
});

export const getFileThumbnail = (payload: {
  name: string,
  roomId: RoomId
}) => ({
  type: atypes.GET_FILE_THUMBNAIL_REQUEST,
  payload
});

export const uploadCustomSignature = (payload: {
  fileName: string,
  fileData: Object,
  roomId: RoomId,
  fieldId: FieldId,
  dispatch: Function
}) => ({
  type: atypes.UPLOAD_CUSTOM_SIGNATURE_REQUEST,
  payload
});

export const showFileUploadModal = (payload: {
  viewId: RoomId,
  files?: Object[]
}) => ({
  type: atypes.SHOW_FILE_UPLOAD_MODAL,
  payload
});

export const hideFileUploadModal = (payload: { viewId: RoomId }) => ({
  type: atypes.HIDE_FILE_UPLOAD_MODAL,
  payload
});

export const addFileSuccess = (payload: {
  name: string,
  versionId: string,
  size: number,
  originalName: string,
  folderId: ?number
}) => ({
  type: atypes.ADD_FILE_SUCCESS,
  payload
});

const uploadCSVToProcess = ({
  templateId,
  fileData
}: {
  templateId: ProcessTemplateId,
  fileData: FileUpload
}): Action => ({
  type: atypes.UPLOAD_CSV_TO_PROCESS_REQUEST,
  payload: {
    templateId,
    fileData
  }
});

export {
  uploadFileToChecklist,
  searchConversationFile,
  closeVideoPlayer,
  uploadFile,
  downloadFile,
  deleteFile,
  startFileSync,
  fileSyncError,
  cancelFileSync,
  listenFileChange,
  createFolder,
  expandFolder,
  goToFolder,
  browseInFolder,
  browseBackFolder,
  cancelFileMove,
  moveFileRequest,
  moveFolderRequest,
  moveFile,
  moveFolder,
  fetchFolder,
  deleteFolder,
  cloneFile,
  showFileMenu,
  hideFileMenu,
  showFolderMenu,
  hideFolderMenu,
  startPinProcess,
  stopPinProcess,
  pinFile,
  unpinFile,
  openViewer,
  closeViewer,
  changeFileTab,
  uploadFileToChatroom,
  getFile,
  searchFile,
  changeFilter,
  goToMyDrive,
  goToRootFolder,
  showNotificationMenu,
  hideNotificationMenu,
  startFileRevision,
  cancelFileRevision,
  changeSorting,
  openSortMenu,
  restoreVersion,
  clearFileUploadState,
  showManageFile,
  closeDownloadPreventedModal,
  uploadCSVToProcess
};
