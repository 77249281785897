// @flow

export const toggleButton = {
  borderRadius: "6px"
};

export const optionsContainer = {
  top: "calc(100% + 4px)"
};

export const optionsList = {
  paddingInlineStart: 0,
  mt: 0,
  border: "1px",
  borderColor: "gray.300",
  maxHeight: "200px",
  overflow: "auto"
};

export const option = {
  display: "flex",
  gap: "12px"
};
