// @flow

import localForage from "localforage";

/**
 * Fetch data stored in indexdb for given key
 * @param {string} key of the record to fetch
 * @returns {string} value stored in for that key
 */
export const getStorage = async (key: string) => {
  try {
    const value = await localForage.getItem(key);
    return value;
  } catch (error) {
    return null;
  }
};
