// @flow

import React from "react";

import { Tooltip } from "@chakra-ui/react";
import {
  PicklistContainer,
  PicklistSearch,
  SelectMenu,
  MenuOptions,
  MenuOption,
  CreateNewButton,
  CheckSelect
} from "../styles";
import { RemoveOption } from "../Search/styles";
import Icon from "src/icons";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import type { ReactRef } from "src/types";

type Props = {
  onClickOutside: Function,
  fieldRef: ReactRef,
  onChange: Function,
  placeholder: string,
  positionUp: boolean,
  dropdownRef: ReactRef,
  onClick: Function,
  multipleResults: Array<string>,
  setMultipleOption: Function,
  value: Array<string>,
  adhoc: boolean,
  search: string,
  addPicklistOption: Function
};

const MultiPicklist = ({
  onClickOutside,
  fieldRef,
  onChange,
  placeholder,
  dropdownRef,
  onClick,
  multipleResults,
  setMultipleOption,
  value,
  adhoc,
  search,
  positionUp,
  addPicklistOption
}: Props) => (
  <OutsideClickHandler onClickOutside={onClickOutside}>
    <PicklistContainer fieldRef={fieldRef}>
      <PicklistSearch
        value={search}
        onChange={onChange}
        autoFocus
        placeholder={placeholder}
      />
      <SelectMenu positionUp={positionUp} ref={dropdownRef}>
        {value && (
          <RemoveOption onClick={onClick}>
            Remove
            <Icon type="checklistRemove" />
          </RemoveOption>
        )}
        <MenuOptions>
          {multipleResults.map(option => (
            <MenuOption key={option} onClick={() => setMultipleOption(option)}>
              <Tooltip label={option.length > 25 && option}>
                <span>{option}</span>
              </Tooltip>
              {option === value && (
                <CheckSelect>
                  <Icon type="checkSelect" />
                </CheckSelect>
              )}
            </MenuOption>
          ))}
        </MenuOptions>
        {adhoc && (
          <CreateNewButton onClick={addPicklistOption}>
            &#43; Create{" "}
            {search.length > 0 ? (
              <span>&nbsp;{`"${search}"`}</span>
            ) : (
              `new item`
            )}
          </CreateNewButton>
        )}
      </SelectMenu>
    </PicklistContainer>
  </OutsideClickHandler>
);

export default MultiPicklist;
