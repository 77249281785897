// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action, FieldId, RoomId, HttpMethods, ColumnId } from "src/types";

const getChecklist = (id: ?number) => ({
  type: atypes.GET_CHECKLIST_REQUEST,
  payload: {
    workflow: id
  }
});

export const getChecklistFieldValue = (
  roomId: RoomId,
  fieldId: FieldId,
  byPass: boolean
) => ({
  type: atypes.GET_CHECKLIST_FIELD_VALUE_REQUEST,
  payload: { roomId, fieldId, byPass }
});

const showChecklistBuilder = () => ({
  type: atypes.SHOW_CHECKLIST_BUILDER,
  payload: {}
});

const hideChecklistBuilder = () => ({
  type: atypes.HIDE_CHECKLIST_BUILDER,
  payload: {}
});

const getChecklistFields = (id: number) => ({
  type: atypes.GET_CHECKLIST_FIELDS_REQUEST,
  payload: { id }
});

const getChatroomChecklistFields = (
  id: number,
  roomId: string,
  getValues: boolean = true
) => ({
  type: atypes.GET_CHATROOM_CHECKLIST_FIELDS_REQUEST,
  payload: { id, roomId, getValues }
});

const toggleChecklistBuilder = () => ({
  type: atypes.TOGGLE_CHECKLIST_BUILDER,
  payload: {}
});

const setChecklistValue = ({
  roomId,
  id,
  value,
  progress,
  formId,
  httpMethod,
  extraBody
}: {
  roomId: string,
  id: number,
  value: Object,
  progress: boolean,
  formId: ?number,
  httpMethod?: HttpMethods,
  extraBody?: Object
}) => ({
  type: atypes.SET_CHECKLIST_VALUE,
  payload: {
    roomId,
    id,
    value,
    progress,
    formId,
    httpMethod,
    extraBody
  }
});

const getChecklistWorkflows = (id: string) => ({
  type: atypes.GET_CHECKLIST_WORKFLOWS,
  payload: {
    id
  }
});

const clearChecklistInstances = () => ({
  type: atypes.CLEAR_CHECKLIST_INSTANCES,
  payload: {}
});

const downloadInstance = (checklist: number, filter: Object) => ({
  type: atypes.GET_CHECKLIST_INSTANCE_FILE_REQUEST,
  payload: {
    checklist,
    filter
  }
});

const getUniqueValues = (checklist: number) => ({
  type: atypes.GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_REQUEST,
  payload: {
    checklist
  }
});

const handleSearch = (search: string) => ({
  type: atypes.SEARCH_CHECKLIST_REQUEST,
  payload: {
    search
  }
});

const resetSearch = () => ({
  type: atypes.SEARCH_CHECKLIST_REQUEST,
  payload: {
    search: ""
  }
});

const createConversation = (req: any, location?: string) => ({
  type: atypes.CREATE_CONVERSATION_FROM_CHECKLIST_REQUEST,
  payload: { req, location }
});

const viewChecklistDetails = (id: number, title: String): Action => ({
  type: atypes.VIEW_CHECKLIST_DETAILS,
  payload: { id, title }
});

export const setChecklistBuilderParent = (parent: ?number) => ({
  type: atypes.SET_CHECKLIST_BUILDER_PARENT_CONVERSATION,
  payload: { parent }
});

export const setChecklistFromManageView = ({
  index,
  fieldId,
  roomId,
  value,
  formId,
  autoNo,
  embeddedIndex,
  columnId
}: {
  index: number,
  fieldId: FieldId,
  roomId: RoomId,
  value: any,
  formId?: number,
  autoNo?: string,
  embeddedIndex?: number,
  columnId?: ColumnId
}) => ({
  type: atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW,
  payload: {
    index,
    fieldId,
    roomId,
    value,
    formId,
    autoNo,
    embeddedIndex,
    columnId
  }
});

export const closeChecklistManageView = () => ({
  type: atypes.CLOSE_CHECKLIST_MANAGE_VIEW,
  payload: {}
});

export const showFormCreationLoader = ({
  roomId,
  fieldId
}: {
  roomId: number,
  fieldId: number
}) => ({
  type: atypes.SHOW_FORM_CREATION_LOADER,
  payload: {
    roomId,
    fieldId
  }
});

export const toggleFieldExpansion = ({ id }: { id: string }) => ({
  type: atypes.TOGGLE_FIELD_EXPANSION,
  payload: { id }
});

export const toggleRowExpansion = ({
  instanceId,
  id,
  maxRows
}: {
  instanceId: string,
  id: string,
  maxRows: number
}) => ({
  type: atypes.TOGGLE_ROW_EXPANSION,
  payload: { instanceId, id, maxRows }
});

export const togglePicklistExpansion = ({
  instanceId,
  index,
  length
}: {
  instanceId: string,
  index: number,
  length: number
}) => ({
  type: atypes.TOGGLE_PICKLIST_EXPANSION,
  payload: { instanceId, index, length }
});

export const resetPicklistExpansion = ({
  instanceId
}: {
  instanceId: string
}) => ({
  type: atypes.RESET_PICKLIST_EXPANSION,
  payload: { instanceId }
});

export const getEmbeddedFields = ({
  fieldId,
  checklistId
}: {
  fieldId: string,
  checklistId: number
}) => ({
  type: atypes.GET_EMBEDDED_FIELDS_REQUEST,
  payload: { fieldId, checklistId }
});

export const getSignatureURLs = () => ({
  type: atypes.GET_SIGNATURE_URLS_REQUEST,
  payload: {}
});

export const clearChecklistFieldError = (id: FieldId) => ({
  type: atypes.CLEAR_CHECKLIST_FIELD_ERROR,
  payload: {
    fieldId: id
  }
});

export const toggleConditions = (
  details: ?{
    settings: string,
    position: number,
    fieldId: number
  }
) => ({
  type: atypes.TOGGLE_CHECKLIST_FIELD_CONDITIONS,
  payload: details
});

export const openSection = (payload: {
  checklistId: string,
  id: FieldId,
  roomId: RoomId
}) => ({
  type: atypes.OPEN_SECTION,
  payload
});

export const closeSection = (payload: {
  checklistId: string,
  id: FieldId,
  roomId: RoomId
}) => ({
  type: atypes.CLOSE_SECTION,
  payload
});

export const openSubSection = (payload: {
  checklistId: string,
  id: FieldId,
  roomId: RoomId
}) => ({
  type: atypes.OPEN_SUB_SECTION,
  payload
});

export const closeSubSection = (payload: {
  checklistId: string,
  id: FieldId,
  roomId: RoomId
}) => ({
  type: atypes.CLOSE_SUB_SECTION,
  payload
});

export {
  getChecklist,
  showChecklistBuilder,
  hideChecklistBuilder,
  toggleChecklistBuilder,
  getChecklistFields,
  setChecklistValue,
  getChecklistWorkflows,
  clearChecklistInstances,
  getChatroomChecklistFields,
  downloadInstance,
  getUniqueValues,
  handleSearch,
  resetSearch,
  createConversation,
  viewChecklistDetails
};
