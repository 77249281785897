// @flow

import * as React from "react";

import Button from "./Button";
import Loader from "./Loader";

type Props = {
  isLoading?: boolean,
  type: string,
  children?: any,
  disabled?: boolean,
  handleClick?: Function
};

const ButtonLoader = ({
  isLoading,
  type,
  children,
  disabled,
  handleClick
}: Props) =>
  isLoading ? (
    <Loader />
  ) : (
    <Button type={type} disabled={disabled} handleClick={handleClick}>
      {children || null}
    </Button>
  );

ButtonLoader.defaultProps = {
  disabled: false,
  isLoading: false,
  children: null,
  handleClick: () => {}
};

export default ButtonLoader;
