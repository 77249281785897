// @flow

import { combineReducers } from "redux";

import * as R from "ramda";
import { Map as ImmutableMap } from "immutable";
import type { Map as ImmutableMapType } from "immutable";

import * as atypes from "src/constants/actionTypes";

import type { Action, OrgDetails, Organization, AllOrgs } from "src/types";

const last = (state: number | null = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_CURRENT_ORG_SUCCESS:
      return payload.last;
    case atypes.SET_CURRENT_ORG_SRW:
      return payload;
    default:
      return state;
  }
};

const updatingDetails = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.UPDATE_ORG_DETAILS_REQUEST:
      return true;

    case atypes.UPDATE_ORG_DETAILS_SUCCESS:
    case atypes.UPDATE_ORG_DETAILS_FAILURE:
      return false;

    default:
      return state;
  }
};

const details = (
  state: ImmutableMapType<string, OrgDetails> = ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_ORG_DETAILS_SUCCESS:
    case atypes.SET_ORG_DETAILS_SRW:
      return state.set(payload.id, {
        title: payload.title,
        domainName: payload.domainName,
        id: payload.id,
        nickName: payload.nickName,
        allDomains: payload.allDomains,
        cloneable: payload.cloneable || false,
        description: payload.description || null,
        videoLink: payload.videoLink || null
      });

    case atypes.GET_CURRENT_ORG_DETAILS_SUCCESS:
      return state.set(payload.id, {
        title: payload.title,
        domainName: payload.domainName,
        id: payload.id,
        nickName: payload.nickName,
        allDomains: payload.allDomains,
        cloneable: payload.cloneable || false,
        description: payload.description || null,
        videoLink: payload.videoLink || null
      });

    case atypes.ADD_ORG_DOMAIN_REQUEST:
      return state.set(payload.orgId, {
        ...state.get(payload.orgId),
        allDomains: [...state.get(payload.orgId).allDomains, payload.domain]
      });

    case atypes.UPDATE_ORG_DETAILS_SUCCESS:
      return state.set(payload.orgId, {
        ...state.get(payload.orgId),
        ...payload.attrs
      });

    case atypes.DELETE_ORG_DOMAIN_REQUEST: {
      const index = state.get(payload.orgId).allDomains.indexOf(payload.domain);
      state.get(payload.orgId).allDomains.splice(index, 1);
      return state.set(payload.orgId, {
        ...state.get(payload.orgId),
        allDomains: [...state.get(payload.orgId).allDomains]
      });
    }

    case atypes.GET_USERS_ORG_DETAILS_SUCCESS:
      return payload.orgDetails.reduce(
        (details, org) =>
          details.set(parseInt(org.id, 10), {
            title: org.title,
            domainName: org.domainName,
            id: org.id,
            nickName: org.nickName,
            // $FlowFixMe
            allDomains: state?.get(org.id)?.allDomains,
            // $FlowFixMe
            cloneable: state?.get(org.id)?.cloneable || false,
            // $FlowFixMe
            description: state?.get(org.id)?.description || null,
            // $FlowFixMe
            videoLink: state?.get(org.id)?.videoLink || null
          }),
        ImmutableMap()
      );
    default:
      return state;
  }
};

const all = (state: AllOrgs = ImmutableMap(), { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_ORGS_SUCCESS:
      return R.reduce(
        (acc, org) => acc.set(parseInt(org, 10), payload.allOrgs[org]),
        ImmutableMap(),
        R.keys(payload.allOrgs)
      );
    case atypes.CREATE_NEW_ORG_SUCCESS: {
      const { orgId } = payload;
      return state.mergeDeep({
        [orgId]: { role: "admin" }
      });
    }
    default:
      return state;
  }
};

const allowFileUpload = (state: boolean = true, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_FILE_UPLOAD_SETTING:
      return payload;
    default:
      return state;
  }
};

const homeScreenEnabled = (
  state: ?boolean = null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_HOME_SCREEN_STATUS:
    case atypes.TOGGLE_HOME_SCREEN_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const orgs = combineReducers({
  last,
  all,
  updatingDetails,
  details,
  allowFileUpload,
  homeScreenEnabled
});

export default orgs;

export const getOrg = (state: Organization, id: number) =>
  state.details ? state.details.get(id) : {};

export const getOrgName = (state: Organization, id: number) => {
  if (state.details) {
    const org = state.details.get(id);
    return org ? org.title : "";
  }
  return "";
};

export const getDefaultOrg = (state: Organization) =>
  state.all.keySeq().first();

export const getAllOrgs = (state: Organization) => state.all;

export const getAllOrgDetails = (state: Organization) => state.details;

export const getUserRole = (state: Organization) => {
  const { last, all } = state;
  const org = all && all.get(last);
  return (org || {}).role;
};

export const isInternalOrg = (state: Organization) => {
  if (last) {
    return state.last == 1;
  }

  return R.includes("1", R.keys(state.all.toJS() || {}));
};

export const getCurrentOrg = (state: Organization) => {
  // $FlowFixMe
  return state.details?.get(state.last) || {};
};
