// @flow

import * as R from "ramda";
import { call, put, takeLatest, take } from "redux-saga/effects";

import * as atypes from "src/constants/actionTypes";
import * as authApi from "src/api/auth";
import * as chatroomApi from "src/api/chatroom";
import chatroomMetaData from "src/constants/chatroomMetaData";
import { getDueDate } from "src/utils";

function* srw({ payload }): any {
  yield put({
    type: atypes.SRW_AUTH_REQUEST,
    payload
  });
}

function* watchSRW(): any {
  yield takeLatest(atypes.SINGLE_RESPONSE_WINDOW, srw);
}

function* srwAuth({ payload }): any {
  try {
    const { customToken, existingUser, chatroom, canJoin } = yield call(
      authApi.validateAccess,
      payload
    );

    yield put({
      type: atypes.CAN_SRW_USER_JOIN,
      payload: {
        canJoin
      }
    });

    if (existingUser) {
      yield put({
        type: atypes.SRW_SET_EXISTING_USER,
        payload: {}
      });
    }

    yield call(authApi.signInForSRW, customToken);
    yield take(atypes.AUTH_CHANNEL_EVENT_COMPLETE);

    const token = yield call(authApi.getAuthToken, {});

    sessionStorage.setItem("authToken", token);
    sessionStorage.setItem("lastOrg", payload.orgId);

    yield put({
      type: atypes.SRW_SERVER_AUTH_REQUEST,
      payload: { chatroom }
    });
  } catch (err) {
    yield put({
      type: atypes.SRW_AUTH_FAILURE,
      payload: { err }
    });
  }
}

function* watchSrwAuth(): any {
  yield takeLatest(atypes.SRW_AUTH_REQUEST, srwAuth);
}

function* serverAuth({ payload }): any {
  try {
    const { chatroom } = payload;
    const response = yield call(authApi.bootstrap);
    yield put({
      type: atypes.SRW_SERVER_AUTH_SUCCESS,
      payload: { ...response, currentChatroomId: chatroom.id }
    });

    yield put({
      type: atypes.SRW_CHATROOM_METADATA_SUCCESS,
      payload: {
        [chatroom.id]: {
          ...R.pickAll(chatroomMetaData, chatroom),
          id: `${chatroom.id}`,
          createdAt: getDueDate(chatroom.createdAt),
          updatedAt: getDueDate(chatroom.updatedAt),
          dueDate: getDueDate(chatroom.dueDate)
        }
      }
    });

    yield put({
      type: atypes.SRW_CURRENT_CHATROOM,
      payload: chatroom.id
    });

    const breadcrumbs = {
      [chatroom.id]: chatroom.breadcrumbs
    };
    const files = {
      [chatroom.id]: chatroom.files
    };
    const relatedConversations = {
      [chatroom.id]: chatroom.relatedConversations
    };
    const checklists = {
      [chatroom.id]: chatroom.checklists
    };

    yield put({
      type: atypes.SRW_CHATROOM_ATTRIBUTES,
      payload: { breadcrumbs, files, relatedConversations, checklists }
    });

    yield put({
      type: atypes.START_MESSAGE_SYNC,
      payload: {
        roomId: chatroom.id,
        messageCount: 30,
        modal: false
      }
    });

    const { currentOrg } = response;
    yield put({
      type: atypes.SET_CURRENT_ORG_SRW,
      payload: currentOrg.id
    });
    yield put({
      type: atypes.SET_ORG_DETAILS_SRW,
      payload: currentOrg
    });
  } catch (err) {
    yield put({
      type: atypes.SRW_SERVER_AUTH_FAILURE,
      payload: err
    });
  }
}

function* watchServerAuth(): any {
  yield takeLatest(atypes.SRW_SERVER_AUTH_REQUEST, serverAuth);
}

function* srwChecklist({ payload }): any {
  try {
    const { currentChatroomId } = payload;

    yield put({
      type: atypes.GET_CHECKLIST_REQUEST,
      payload: {
        workflow: currentChatroomId
      }
    });
  } catch (error) {
    yield put({
      type: atypes.GET_CHECKLIST_FAILURE,
      payload: { error }
    });
  }
}

function* watchSrwChecklist(): any {
  yield takeLatest(atypes.SRW_SERVER_AUTH_SUCCESS, srwChecklist);
}

function* fetchChatroomMetadata(): any {
  try {
    const chatrooms = yield call(chatroomApi.fetchAllConversations);
    yield put({
      type: atypes.SRW_CHATROOM_METADATA_SUCCESS,
      payload: R.mergeAll(
        chatrooms.map(c => ({
          [c.id]: {
            ...R.omit(
              ["breadcrumbs", "files", "relatedConversations", "checklists"],
              c
            ),
            createdAt: getDueDate(c.createdAt),
            updatedAt: getDueDate(c.updatedAt),
            dueDate: getDueDate(c.dueDate),
            id: `${c.id}`
          }
        }))
      )
    });
  } catch (error) {
    yield put({
      type: atypes.SRW_CHATROOM_METADATA_FAILURE,
      payload: { error }
    });
  }
}

function* watchFetchChatroomMetadata(): any {
  yield takeLatest(atypes.SRW_SERVER_AUTH_SUCCESS, fetchChatroomMetadata);
}

// Prevent access to lite.unifize.com unless user visits a chatroom
// with authcode
function* redirectToApp(): any {
  const params = new URLSearchParams(window.location.search);

  if (
    window.location.host === "lite.unifize.com" &&
    (!params.get("orgId") || !params.get("authCode"))
  ) {
    yield put({ type: atypes.REDIRECT_TO_APP_UNIFIZE_COM });
    window.location.replace("https://app.unifize.com");
  }
}

export default [
  watchFetchChatroomMetadata(),
  watchSrwChecklist(),
  watchServerAuth(),
  watchSrwAuth(),
  watchSRW(),
  redirectToApp()
];
