// @flow

import React, { useRef, useState, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import ReactProgressiveList from "react-progressive-list";
import { VStack, Box, Text, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { validateEmail, getEmailPrefix } from "src/utils";
import location from "src/constants/location";

import { getAllMembersAndGroups, getFilteredSearchResults } from "src/reducers";

import Dropdown from "src/components/Dropdown";
import type { UsersAndGroups, UID, RoomId, FieldId, ColumnId } from "src/types";

import type { DownshiftProps } from "downshift";

import NewUser from "src/components/user/SelectMultipleInvite/NewUser";
import NewGroup from "src/components/user/SelectMultipleInvite/NewGroup";
import SingleSelection from "./SingleSelection";
import MultipleSelection from "./MultipleSelection";
import RemoveSingleUser from "./RemoveSingleUser";
import AddData from "../AddData";

import * as styles from "./styles";

interface UseBooleanReturn {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
}

type Props = {
  setFieldValue: Function,
  promptCallback?: ?Function,
  edit: $PropertyType<UseBooleanReturn, "value">,
  settings: Object,
  value: any,
  openEdit: $PropertyType<UseBooleanReturn, "setTrue">,
  closeEdit: $PropertyType<UseBooleanReturn, "setFalse">,
  locked?: boolean,
  isMandatory?: boolean,
  columnId?: ColumnId,
  roomId: RoomId,
  formId: ?number,
  fieldId: FieldId,
  location: string
};

const ChecklistUsers = ({
  setFieldValue,
  promptCallback,
  settings,
  value = [],
  openEdit,
  closeEdit,
  edit,
  locked = false,
  isMandatory,
  columnId,
  roomId,
  fieldId,
  formId,
  location: sourceLocation
}: Props) => {
  const downshiftRef = useRef<DownshiftProps>(null);
  const [query, setQuery] = useState("");
  const [isEmailInvite, setEmailInvite] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [placeholder, setPlaceholder] = useState("Select users");
  const [debouncedSearch]: [string] = useDebounce<string>(query, 100);
  const multiple: boolean = settings && settings.multiple;

  const userAndGroupIds = R.pipe(R.head, R.is(Object))(value || [])
    ? value.map(
        userOrGroup => userOrGroup[userOrGroup.type === "group" ? "id" : "uid"]
      )
    : value || [];

  const searchedFilteredResults: [UsersAndGroups] = useSelector(({ app }) =>
    getFilteredSearchResults(app, debouncedSearch, multiple, userAndGroupIds)
  );

  const orgUsersAndGroups = useSelector(({ app }) =>
    getAllMembersAndGroups(app)
  );

  const filteredResults = searchedFilteredResults.filter(({ id }) => {
    return !(value || []).includes(id);
  });

  const multipleSelectedValues: {
    type: string,
    uid?: UID,
    id?: number
  }[] = orgUsersAndGroups.reduce((acc, { type, id }) => {
    if (R.includes(id, userAndGroupIds)) {
      if (type === "user") acc.push({ type, uid: id });
      else acc.push({ type, id });
    }
    return acc;
  }, []);

  const handleSelect = useCallback(
    ({ id }) => {
      if (multiple) {
        if (typeof id === "string") {
          setFieldValue({
            roomId,
            id: fieldId,
            value: [
              ...(multipleSelectedValues || []),
              {
                uid: id,
                type: "user"
              }
            ],
            progress: true,
            formId,
            columnId
          });
        } else {
          setFieldValue({
            roomId,
            id: fieldId,
            value: [
              ...(multipleSelectedValues || []),
              {
                id,
                type: "group"
              }
            ],
            progress: true,
            formId,
            columnId
          });
        }
      } else {
        setFieldValue({
          roomId,
          id: fieldId,
          value: {
            uid: id,
            type: "user"
          },
          progress: true,
          formId,
          columnId,
          httpMethod: "POST"
        });
      }
      if (promptCallback) promptCallback();
      closeEdit();
      setQuery("");
    },
    [
      multiple,
      value,
      promptCallback,
      setQuery,
      setFieldValue,
      searchedFilteredResults
    ]
  );

  const onQueryChange = useCallback(
    input => {
      setQuery(input);
      if (filteredResults.length === 0)
        setErrorMessage(`No results for ${input}`);
    },
    [setQuery, filteredResults]
  );

  const handleRemove = useCallback(
    id => {
      let formattedValue = id;
      if (typeof formattedValue === "object") {
        formattedValue =
          formattedValue.type === "group"
            ? formattedValue.id
            : formattedValue.uid;
      }
      if (typeof formattedValue === "string") {
        setFieldValue({
          roomId,
          id: fieldId,
          value: {
            uid: formattedValue,
            type: "user"
          },
          progress: true,
          formId,
          columnId,
          httpMethod: "DELETE"
        });
      } else if (typeof formattedValue === "number") {
        setFieldValue({
          roomId,
          id: fieldId,
          value: {
            id: formattedValue,
            type: "group"
          },
          progress: true,
          formId,
          columnId,
          httpMethod: "DELETE"
        });
      }
    },
    [value, setFieldValue]
  );

  const handleKeyboardSelect = useCallback(
    selectedItem => {
      if (selectedItem) {
        handleSelect({ id: selectedItem });
      }
    },
    [handleSelect]
  );

  const handleInvite = useCallback(
    email => {
      if (multiple) {
        setFieldValue({
          value: {
            type: "user",
            email,
            displayName: getEmailPrefix(email)
          },
          httpMethod: "PATCH"
        });
      } else {
        setFieldValue({
          value: {
            type: "user",
            email,
            displayName: getEmailPrefix(email)
          }
        });
      }

      closeEdit();
      setQuery("");
    },
    [closeEdit, setQuery]
  );

  // If the component receives the entire user object extract the UID
  const singleUserFieldValue =
    typeof value[0] === "object"
      ? value[0]?.uid
      : R.find(R.is(String), value) || "";

  return (
    <VStack
      align="stretch"
      spacing={multiple && multipleSelectedValues.length > 0 && 4}
      sx={styles.ChecklistUsersContainer}
    >
      {edit && value.length === 0 ? (
        <Box sx={styles.EmptySingleUserSelection}>
          <Text sx={styles.EmptySingleUserSelectionText}>No user selected</Text>
        </Box>
      ) : (
        (multipleSelectedValues.length > 0 ||
          singleUserFieldValue.length > 0) && (
          <VStack sx={styles.SelectionContainer}>
            {multiple ? (
              <MultipleSelection
                usersAndGroups={multipleSelectedValues}
                handleRemove={handleRemove}
              />
            ) : (
              <SingleSelection
                user={singleUserFieldValue}
                closeDropdown={closeEdit}
                toggleDropdown={openEdit}
                edit={edit}
              />
            )}
          </VStack>
        )
      )}

      {edit ? (
        <Dropdown
          onItemClick={handleSelect}
          isOpen={edit}
          ref={downshiftRef}
          onOuterClick={closeEdit}
          inputFieldSize="sm"
          query={query}
          onQueryChange={onQueryChange}
          placeholder={placeholder}
          handleKeyboardSelect={handleKeyboardSelect}
          closeDropdown={closeEdit}
          customStyles={{
            DropdownWrapper:
              sourceLocation === location.checklistDock &&
              styles.DropdownWrapper
          }}
        >
          {({
            onItemClick,
            getItemProps,
            highlightedIndex,
            scrollIntoView
          }) => {
            return (
              <>
                {!multiple && value.length > 0 && (
                  <RemoveSingleUser
                    handleRemove={() => handleRemove(value[0])}
                  />
                )}
                {filteredResults.length > 0 && !isEmailInvite ? (
                  <>
                    <VStack spacing="0" sx={styles.DropdownList}>
                      <ReactProgressiveList
                        initialAmount={10}
                        progressiveAmount={10}
                        role="list"
                        rowCount={filteredResults.length}
                        renderItem={index => {
                          if (filteredResults[index]) {
                            const { id, type } = filteredResults[index];
                            if (type === "user") {
                              return (
                                <NewUser
                                  item={filteredResults[index]}
                                  key={`user-${id}`}
                                  index={index}
                                  highlightedIndex={highlightedIndex}
                                  scrollIntoView={scrollIntoView}
                                  {...getItemProps({
                                    item: id,
                                    id: id,
                                    onItemClick: onItemClick
                                  })}
                                />
                              );
                            } else if (type === "group") {
                              return (
                                <NewGroup
                                  item={filteredResults[index]}
                                  key={`group-${id}`}
                                  index={index}
                                  highlightedIndex={highlightedIndex}
                                  scrollIntoView={scrollIntoView}
                                  {...getItemProps({
                                    item: id,
                                    id: id,
                                    onItemClick: onItemClick
                                  })}
                                />
                              );
                            }
                          }
                        }}
                      />
                    </VStack>
                    <Button
                      sx={styles.InviteExternal}
                      leftIcon={<AddIcon boxSize={2} focusable />}
                      variant="link"
                      onClick={() => {
                        setEmailInvite(true);
                        setPlaceholder("Enter user's email");
                      }}
                    >
                      Add new user
                    </Button>
                  </>
                ) : isEmailInvite || validateEmail(query) ? (
                  <Button
                    sx={styles.InviteExternal}
                    leftIcon={<AddIcon boxSize={2} focusable />}
                    variant="link"
                    onClick={() => {
                      setEmailInvite(false);
                      setPlaceholder("Select users");
                      if (validateEmail(query)) {
                        handleInvite(query);
                      } else {
                        setErrorMessage("Enter valid email");
                      }
                    }}
                  >
                    Add new user
                  </Button>
                ) : (
                  <Text sx={styles.ErrorMessage}>{errorMessage}</Text>
                )}
              </>
            );
          }}
        </Dropdown>
      ) : !multiple && value.length === 0 ? (
        <AddData
          disabled={locked}
          type="user"
          handleClick={openEdit}
          isMandatory={isMandatory}
        />
      ) : multiple ? (
        <AddData
          disabled={locked}
          type="user"
          handleClick={openEdit}
          isMandatory={isMandatory}
        />
      ) : null}
    </VStack>
  );
};

export default ChecklistUsers;
