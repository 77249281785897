// @flow

import React from "react";

import { AddButton } from "./styles";

type Props = {
  type?: string,
  isSelect?: boolean,
  disabled: boolean,
  handleClick: Function,
  isMandatory?: boolean
};

const AddData = ({
  type,
  handleClick,
  isSelect,
  disabled,
  isMandatory
}: Props) => {
  if (isSelect)
    return (
      <AddButton
        onClick={handleClick}
        disabled={disabled}
        isMandatory={isMandatory}
      >
        Select
      </AddButton>
    );
  return (
    <AddButton
      onClick={handleClick}
      disabled={disabled}
      isMandatory={isMandatory}
    >
      &#43; Add {type}
    </AddButton>
  );
};

AddData.defaultProps = {
  isSelect: false,
  type: ""
};

export default AddData;
