// @flow

import React from "react";
import { useSelector } from "react-redux";

import { getGroup } from "src/reducers";
import Icon from "src/icons";
import Tooltip from "src/components/Tooltip";
import { Count } from "src/components/Manage/Workflow/Instances/Table/Row/ChecklistItem/styles";

import type { GroupId } from "src/types";

type Props = {
  groupId: GroupId
};

const Group = ({ groupId }: Props) => {
  const group = useSelector(({ app }) => getGroup(app, groupId)) || {};
  const groupSize = (group?.members ?? []).length;

  return (
    <Tooltip
      placement="top"
      title={group?.title || ""}
      description={`${groupSize || 0} ${
        groupSize === 1 ? "member" : "members"
      }`}
    >
      <div>
        <Icon
          type="groupAvatar"
          text={group?.title || ""}
          size="xs"
          photoUrl={group?.photoUrl || ""}
          id={groupId}
        />
        {groupSize > 9 && <Count>{groupSize - 9}</Count>}{" "}
      </div>
    </Tooltip>
  );
};

export default Group;
