// @flow

import { combineReducers } from "redux";

import * as atypes from "src/constants/actionTypes";
import type { Action } from "src/types";

const owner = (state: Function = () => null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_OWNER_TRIGGER:
      return payload;
    default:
      return state;
  }
};

const infoOwner = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_OWNER_TRIGGER_FROM_INFO_PANEL:
      return payload;
    default:
      return state;
  }
};

const participant = (
  state: Function = () => null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_PARTICIPANT_TRIGGER:
      return payload;
    default:
      return state;
  }
};

const priority = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SET_PRIORITY_TRIGGER:
      return !state;
    default:
      return state;
  }
};

const status = (state: Function = () => null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_STATUS_TRIGGER:
      return payload;
    default:
      return state;
  }
};

const uiTrigger = combineReducers({
  owner,
  participant,
  priority,
  status,
  infoOwner
});

export default uiTrigger;
