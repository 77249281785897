// @flow

import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import * as R from "ramda";

import type {
  State,
  RoomId,
  FieldId,
  Workflow,
  UnifizeUser,
  UID,
  DataStage,
  LinkedField as LinkedFieldType
} from "src/types";
import { FieldWrapper } from "./styles";
import useBoolean from "src/hooks/useBoolean";
import httpMethods from "src/constants/httpMethods";
import { dataStages } from "src/constants";
import {
  getBehaviorByFormField,
  getChecklistFieldBehavior,
  getSelectedChecklist,
  getArchivedChatroomsFromIds
} from "src/reducers";

import Single from "./Single";
import Multi from "./Multi";
import { filterArchived } from "./utils";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId: ?number,
  details: LinkedFieldType,
  checklistValue: Object,
  setFieldValue: Function,
  workflowData: Workflow,
  currentUser: UnifizeUser,
  isMandatory: boolean,
  locked: boolean,
  fromManageView: ?boolean,
  valueStatus: DataStage,
  roomFieldFormId?: string,
  currentBehavior: ?string,
  dependentOptions: ?Array<string>,
  templateId: number,
  settings: Object,
  promptCallback?: ?Function
};

function LinkedField({
  roomId,
  fieldId,
  formId,
  details,
  checklistValue,
  setFieldValue,
  workflowData,
  currentUser,
  isMandatory,
  locked,
  fromManageView,
  valueStatus,
  currentBehavior,
  dependentOptions,
  settings,
  promptCallback
}: Props) {
  const { workflow, multiple } = settings;
  const archived =
    useSelector(({ app }) =>
      getArchivedChatroomsFromIds(app, checklistValue?.value.result)
    ) || [];

  const value = filterArchived(checklistValue?.value, archived);
  const target = details.get("target") || false;

  const { columnId, embeddedIndex } = useSelector(({ app }) =>
    getSelectedChecklist(app)
  );

  const {
    value: isDropdownVisible,
    setTrue: openDropdown,
    setFalse: closeDropdown,
    setValue: setDropdown
  } = useBoolean(false);

  const [modalState, setModalState] = useState({
    open: false,
    roomId: null,
    edit: false
  });

  const closeModal = () => setModalState(prev => ({ ...prev, open: false }));

  const { value: newRoom, setValue: setNewRoom } = useBoolean();

  const handleClose = () => {
    setNewRoom(false);
    closeDropdown();
  };

  const handleNewRoom = () => {
    setNewRoom(true);
    closeDropdown();
  };

  const handleCreate = title => {
    const newRoomData: {
      title: string,
      type: string,
      owner: UID | null,
      creator: UID,
      members: UID[],
      templateId: number,
      privacy: string,
      parent?: number
    } = {
      title,
      type: "workflow",
      owner: workflowData.owner || null,
      creator: currentUser.uid,
      members: workflowData.members,
      templateId: settings.workflow,
      privacy: workflowData.privacy
    };

    // Assign creator as owner depending on setting
    if (
      !newRoomData.owner &&
      workflowData?.settings?.creatorIsOwner !== false
    ) {
      newRoomData.owner = currentUser.uid;
    }

    // Make creator as member depending on setting
    if (workflowData?.settings?.creatorIsParticipant !== false) {
      if (newRoomData.owner) {
        newRoomData.members = R.uniq(
          [newRoomData.owner, currentUser.uid, ...newRoomData.members] || []
        );
      } else {
        newRoomData.members = R.uniq(
          [currentUser.uid, ...newRoomData.members] || []
        );
      }
    }

    if (settings.child) {
      newRoomData.parent = parseInt(roomId, 10);
    }

    setFieldValue({
      value: {
        chatroom: newRoomData,
        linkAllVersions: true
      },
      httpMethod: httpMethods.patch,
      columnId,
      embeddedIndex
    });

    if (promptCallback) promptCallback();
    handleClose();
  };

  const handleVersionClick = id => {
    setModalState({
      open: true,
      edit: fromManageView ? true : false,
      roomId: id
    });
  };

  const selectNewRoomVersions = id => {
    setModalState({
      open: true,
      edit: true,
      roomId: id
    });
  };

  const removeLink = (originChatroomId: number) => {
    setFieldValue({
      value: { originChatroomId },
      httpMethod: httpMethods.delete,
      columnId,
      embeddedIndex
    });
  };

  const editParentField = ({ newValue, sourceChatroom }) => {
    setFieldValue({
      value: newValue,
      httpMethod: httpMethods.patch,
      extraBody: {
        sourceChatroom
      },
      columnId,
      embeddedIndex
    });
  };

  const removeRoom = originChatroomId => {
    setFieldValue({
      value: { originChatroomId },
      httpMethod: httpMethods.delete,
      columnId,
      embeddedIndex
    });
  };

  const selectChatroom = value => {
    setFieldValue({
      value,
      httpMethod: httpMethods.patch,
      columnId,
      embeddedIndex
    });
    if (promptCallback) promptCallback();
  };

  const handleSelect = id => {
    selectChatroom({ chatroom: id, linkAllVersions: true });
    if (promptCallback) promptCallback();
    handleClose();
  };

  return (
    <FieldWrapper>
      {multiple ? (
        <Multi
          workflow={workflow}
          settings={settings}
          roomId={roomId}
          fieldId={fieldId}
          formId={formId}
          checklistValue={value}
          target={target}
          dropdown={{
            visible: isDropdownVisible,
            open: openDropdown
          }}
          modal={{
            state: modalState,
            close: closeModal
          }}
          newRoom={newRoom}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleCreate={handleCreate}
          handleVersionClick={handleVersionClick}
          selectNewRoomVersions={selectNewRoomVersions}
          removeLink={removeLink}
          editParentField={editParentField}
          removeRoom={removeRoom}
          selectChatrooms={selectChatroom}
          handleSelect={handleSelect}
          isMandatory={isMandatory}
          locked={locked}
          updating={valueStatus === dataStages.updating}
          currentBehavior={currentBehavior}
          dependentOptions={dependentOptions}
        />
      ) : (
        <Single
          settings={settings}
          roomId={roomId}
          fieldId={fieldId}
          checklistValue={value}
          target={target}
          dropdown={{
            visible: isDropdownVisible,
            set: setDropdown,
            open: openDropdown
          }}
          modal={{
            state: modalState,
            close: closeModal
          }}
          newRoom={newRoom}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleCreate={handleCreate}
          handleVersionClick={handleVersionClick}
          selectNewRoomVersions={selectNewRoomVersions}
          removeLink={removeLink}
          editParentField={editParentField}
          removeRoom={removeRoom}
          selectChatroom={selectChatroom}
          handleSelect={handleSelect}
          isMandatory={isMandatory}
          locked={locked}
          updating={valueStatus === dataStages.updating}
          currentBehavior={currentBehavior}
          dependentOptions={dependentOptions}
        />
      )}
    </FieldWrapper>
  );
}

LinkedField.defaultProps = {
  promptCallback: null,
  checklistValue: {
    value: {
      result: [],
      entities: {
        chatrooms: {}
      }
    }
  }
};

const mapStateToProps = (
  { app }: State,
  { roomFieldFormId, fieldId, roomId, settings }: Props
) => {
  const behavior = roomFieldFormId
    ? getBehaviorByFormField(app, roomFieldFormId)
    : getChecklistFieldBehavior(app, { roomId, fieldId });

  const { workflow } = settings;

  return {
    workflowData: app.workflow.byId[`${workflow || ""}`],
    currentUser: app.currentUser,
    dependentOptions: behavior.options,
    currentBehavior: behavior.current
  };
};

export default connect(mapStateToProps)(LinkedField);
