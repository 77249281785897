// @flow

import React from "react";
import { useSelector } from "react-redux";

import type { FieldId, RoomId } from "src/types";
import Name from "src/components/Form/Name";
import {
  Dropdown as StyledDropdown,
  UnorderedList as StyledUnorderedList
} from "./styles";
import { ListItemMedium } from "src/styles/box";
import useClickoutside from "src/hooks/useClickoutside";
import { getChecklistFieldBehavior } from "src/reducers";

type Props = {
  outerRef: any,
  items: Array<number>,
  handleClose: Function,
  handleSelect: Function,
  fieldId: FieldId,
  roomId: RoomId
};

const Dropdown = ({
  outerRef,
  items,
  handleClose,
  handleSelect,
  fieldId,
  roomId
}: Props) => {
  useClickoutside({ outerRef, handleClose });

  const {
    current: currentBehavior,
    options: dependentOptions = []
  } = useSelector(({ app }) =>
    getChecklistFieldBehavior(app, { roomId, fieldId })
  );

  const filteredItems = items.filter(item => {
    if (currentBehavior === "dependentFormInclude") {
      return dependentOptions.includes(item);
    } else if (currentBehavior === "dependentFormExclude") {
      return !dependentOptions.includes(item);
    }

    return item;
  });

  return (
    <StyledDropdown>
      <StyledUnorderedList>
        {filteredItems.map(item => (
          <ListItemMedium key={item} onClick={() => handleSelect(item)}>
            <Name id={item} />
          </ListItemMedium>
        ))}
      </StyledUnorderedList>
    </StyledDropdown>
  );
};

export default Dropdown;
