// @flow

import { connect, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { HStack, Switch, FormControl, FormLabel } from "@chakra-ui/react";
import * as R from "ramda";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";
import ProcessRefresh from "./Refresh";
import ReportsRefresh from "src/components/Manage/Reports/Instances/Refresh";
import Share from "./Share";
import Customize from "./Customize";
import { MenuBar as StyledMenuBar, InstanceCount, styles } from "./styles";
import {
  getInstanceFilterId,
  getInstanceReportId,
  getReportsTableColumns,
  getProcessTableColumns,
  getCurrentOrg,
  getPrincipalChecklist,
  getAllPrincipalChecklistFields,
  getLoadingInstances,
  getCurrentUserId,
  getComponentPermission
} from "src/reducers";
import Name from "src/containers/workflow/Name";
import { downloadInstance } from "src/actions/workflows";
import { setNewWorkflow } from "src/actions/chatroom";
import useBoolean from "src/hooks/useBoolean";
import AddChatroom from "src/containers/chatroom/creator";
import Icon from "src/icons";
import UploadCSV from "./UploadCSV";
import { reOrderProcessColumn } from "src/actions/workflows";
import { mergeNewFields } from "src/utils/checklist";
import BlueOutlineButton from "src/components/Buttons/BlueOutline";
import { componentPermissions } from "src/constants/roleManagement";
import { customerSuccessTeam } from "src/constants/users";

import type { AppState } from "src/types";

type Props = {
  id: number,
  _downloadInstance: Function,
  _setNewWorkflow: Function,
  _reOrderProcessColumn: Function,
  fields: Array<Object>,
  options: Array<Object>,
  principalChecklistTitle: ?string,
  rowCount: number,
  loading: boolean,
  reportId: ?number,
  getHeaderGroups: Function,
  getRowModel: Function,
  setRevisionsShown: Function,
  allRevisionsShown: Boolean
};

const MenuBar = ({
  id,
  _downloadInstance,
  _setNewWorkflow,
  _reOrderProcessColumn,
  options,
  fields,
  principalChecklistTitle,
  rowCount,
  loading,
  reportId,
  getHeaderGroups,
  getRowModel,
  setRevisionsShown,
  allRevisionsShown
}: Props) => {
  const {
    value: newConversation,
    toggleBoolean: toggleConversation
  } = useBoolean();

  const currentOrg = useSelector(({ app }) => getCurrentOrg(app));
  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const RBAC = useSelector(({ app }) =>
    getComponentPermission(app, componentPermissions.csvUpload)
  );

  const showUploadCSVButton =
    RBAC &&
    (customerSuccessTeam.includes(currentUserId) || currentOrg.id === 1);

  const handleNewConversation = useCallback(() => {
    _setNewWorkflow(id);
    toggleConversation();
  }, [id, toggleConversation, _setNewWorkflow]);

  useEffect(() => {
    // Don't run if fields data is not fetched yet
    if (!R.isNil(principalChecklistTitle)) {
      if (!reportId) {
        const optionsIncludesFields = options.filter(f => f.label).length > 0;

        const optionsWithFields = [
          ...(optionsIncludesFields
            ? mergeNewFields(fields, options)
            : options),
          ...(fields && !optionsIncludesFields
            ? fields.map(field => ({ ...field, key: field.id, active: true }))
            : [])
        ];

        _reOrderProcessColumn(id, optionsWithFields);
      }
    }
  }, [fields, principalChecklistTitle]);

  const downloadAsCSV = () => {
    _downloadInstance(
      id,
      "csv",
      getHeaderGroups()[0]?.headers || [],
      getRowModel().rows
    );
  };

  const downloadAsXLSX = () => {
    _downloadInstance(
      id,
      "xlsx",
      getHeaderGroups()[0]?.headers || [],
      getRowModel().rows
    );
  };

  const downloadWithFiles = () => {
    _downloadInstance(
      id,
      "files",
      getHeaderGroups()[0]?.headers || [],
      getRowModel().rows,
      true
    );
  };

  const downloadAsJson = () => {
    _downloadInstance(
      id,
      "json",
      getHeaderGroups()[0]?.headers || [],
      getRowModel().rows
    );
  };

  return (
    <StyledMenuBar>
      <InstanceCount>
        {loading ? "Loading Results" : `${rowCount} Results`}
      </InstanceCount>
      <HStack spacing={4} position="relative" zIndex={3}>
        <FormControl display="flex" alignItems="center" w="fit-content" pl={4}>
          <FormLabel htmlFor="showRevisions" mb="0">
            Show all revisions
          </FormLabel>
          <Switch
            id="showRevisions"
            onChange={() => setRevisionsShown(!allRevisionsShown)}
          />
        </FormControl>

        {showUploadCSVButton && <UploadCSV templateId={id} />}

        <BlueOutlineButton
          leftIcon={<Icon type="circlePlus" />}
          onClick={handleNewConversation}
        >
          New <Name id={id} />
        </BlueOutlineButton>

        <Customize />

        {reportId ? <ReportsRefresh /> : <ProcessRefresh />}

        <HStack spacing={1}>
          <BlueOutlineButton
            leftIcon={<Icon type="saveAlt" />}
            onClick={downloadAsCSV}
          >
            Download
          </BlueOutlineButton>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Download options"
              icon={<Icon type="down" color={colors.blue} />}
              {...styles.menuButton}
            />
            <MenuList>
              <MenuItem onClick={downloadAsCSV}>Download as CSV</MenuItem>
              <MenuItem onClick={downloadAsXLSX}>Download as XLSX</MenuItem>
              <MenuItem onClick={downloadWithFiles}>
                Download with files
              </MenuItem>
              <MenuItem onClick={downloadAsJson}>
                Download as JSON (beta)
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <Share />
      </HStack>

      {newConversation && (
        <AddChatroom
          show={newConversation}
          position="center"
          modalId="chatroomSearch"
          handleClose={toggleConversation}
          hidePinTo
        />
      )}
    </StyledMenuBar>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const id = getInstanceFilterId(app);
  const reportId = getInstanceReportId(app);
  const principalChecklist = getPrincipalChecklist(app);
  return {
    id,
    options: reportId
      ? getReportsTableColumns(app, reportId)
      : getProcessTableColumns(app, id || ""),
    fields: getAllPrincipalChecklistFields(app),
    principalChecklistTitle: principalChecklist.title,
    loading: getLoadingInstances(app)
  };
};

export default connect(mapStateToProps, {
  _downloadInstance: downloadInstance,
  _setNewWorkflow: setNewWorkflow,
  _reOrderProcessColumn: reOrderProcessColumn
})(MenuBar);
