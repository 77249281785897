// @flow

import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import useClientDimensions from "react-client-dimensions";
import { Flex, VStack } from "@chakra-ui/react";

import SignUpModal from "./SignUpModal";
import Mobile from "./Mobile";
import AppInfo from "./AppInfo";
import { SRW as StyledSRW, Main, Chatroom } from "./styles";
import * as styles from "src/components/SRW/styles";
import LoadingState from "src/components/LoadingState";
import ChatHistory from "src/components/messages/History";
import ChatroomHeader from "src/components/chatroom/Header";
import ChatInput from "src/components/chatroom/Input";
import Dock from "src/containers/CurrentRoomDock";
import PreviewModal from "src/components/file/PreviewModal";
import {
  getCurrentChatRoom,
  getTemplateId,
  getFileUploadModalStatus,
  getSignInError
} from "src/reducers";
import { setMobileResponsiveness } from "src/actions/srw";
import { showFileUploadModal } from "src/actions/file";
import { FullOverlay } from "src/styles";
import AccessDenied from "src/components/auth/AccessDenied";

import type {
  AppState,
  UnifizeUser,
  UnifizeChatRoom,
  AuthTokenError
} from "src/types";

type Props = {
  currentUser: UnifizeUser,
  room: UnifizeChatRoom,
  showSignUpModal: boolean,
  participants: boolean,
  templateId?: number,
  showFilesPreviewModal: boolean,
  error: AuthTokenError
};

const SRW = ({
  room,
  showSignUpModal,
  participants,
  templateId,
  showFilesPreviewModal,
  error,
  currentUser
}: Props) => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);

  const { vw, vh } = useClientDimensions();

  useEffect(() => {
    if (vw < 799) dispatch(setMobileResponsiveness());
  }, [vw]);

  useEffect(() => {
    window.history.replaceState({}, "", `${window.location.pathname}`);
  }, []);

  useEffect(() => {
    if (templateId) {
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?templateId=${templateId}`
      );
    }
  }, [templateId]);

  const onDrop = acceptedFiles => {
    if (room?.id) {
      dispatch(showFileUploadModal({ viewId: room.id, files: acceptedFiles }));
    }
  };
  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  if (error) {
    return (
      <Flex sx={styles.AccessDenied}>
        <AccessDenied />
      </Flex>
    );
  }

  if (vw < 799) {
    return <Mobile vh={vh} />;
  }

  return (
    <StyledSRW ref={currentRef}>
      {showSignUpModal && <SignUpModal />}
      {showFilesPreviewModal && room?.id && <PreviewModal roomId={room.id} />}
      <AppInfo />
      <Main {...getRootProps()} isDragActive={isDragActive}>
        {room && room.id ? (
          <ChatroomHeader roomId={room.id} currentRef={currentRef} isSrw />
        ) : (
          <LoadingState type="header" />
        )}
        <Chatroom>
          <VStack align="stretch" flex={1}>
            {room && room.id ? (
              <ChatHistory roomId={room.id} />
            ) : (
              <LoadingState type="history" />
            )}
            {room && room.id && currentUser.uid ? (
              <ChatInput roomId={room.id} />
            ) : (
              <LoadingState type="input" />
            )}
            {participants && <FullOverlay />}
          </VStack>

          {room && room.id ? <Dock isSrw /> : <LoadingState type="panel" />}
        </Chatroom>
      </Main>
    </StyledSRW>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const room = getCurrentChatRoom(app);
  return {
    currentUser: app.currentUser,
    room,
    participants: app.header.participants,
    showSignUpModal: app.srw.signUpModal,
    templateId: room ? getTemplateId(app, room.id) : null,
    showFilesPreviewModal: room
      ? getFileUploadModalStatus(app, room.id)
      : false,
    error: getSignInError("sendAuthToken")(app)
  };
};

export default connect(mapStateToProps)(SRW);
