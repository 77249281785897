// @flow

import React, { useRef, useLayoutEffect } from "react";
import { findDOMNode } from "react-dom";
import { ListItem as StyledListItem, Text, Flex } from "@chakra-ui/react";
import FieldName from "src/containers/checklist/FieldName";
import { ListItemText, ListItemBox } from "./styles";

type Props = {
  column: Object,
  hidden: boolean,
  selected: boolean,
  focused: boolean,
  handleField: Function,
  isForm: ?boolean
};

const ListItem = ({
  column,
  selected,
  hidden,
  focused,
  handleField,
  isForm
}: Props) => {
  const itemRef = useRef(null);

  useLayoutEffect(() => {
    if (focused && itemRef.current) {
      // Move element into view when it is focused
      // eslint-disable-next-line react/no-find-dom-node
      const target = findDOMNode(itemRef.current);
      // $FlowFixMe
      target.parentNode.scrollTop = target.offsetTop - 5;
    }
  }, [focused, itemRef]);

  if (hidden) {
    return <></>;
  }

  let background = focused ? "gray.200" : "white";

  if (selected) {
    background = "blue.500";
  }

  return (
    <StyledListItem
      _hover={{
        backgroundColor: selected ? "blue.500" : "gray.100",
        color: selected ? "white" : "black"
      }}
      role="option"
      onClick={() => handleField(column)}
      fontSize="sm"
      cursor="pointer"
      color={selected ? "white" : "black"}
      background={background}
      p={[4, 1]}
      tabIndex={focused ? 0 : -1}
      ref={itemRef}
    >
      <Flex sx={ListItemBox}>
        {isForm ? (
          <Flex>
            <Text sx={ListItemText}>{column.name}</Text>
            {column.linkedOrFormFieldId > 0 && (
              <Text sx={ListItemText} color="unifize_inkLightest">
                (FIELD: <FieldName id={column.linkedOrFormFieldId} />)
              </Text>
            )}
          </Flex>
        ) : (
          <Text sx={ListItemText}>{column.name ? column.name : ""}</Text>
        )}
        {column.multiValue && (
          <Text m={0} color="unifize_inkLightest">
            MULTIPLE VALUE FIELD
          </Text>
        )}
      </Flex>
    </StyledListItem>
  );
};

export default ListItem;
